import { Box, Grid } from "@mui/material";
import Icon from "./Icon";
import "./Trade.css";
import TradeTicketDrawer from "./Trade";
import { CloseIcon } from "../../images/Icons";
import { TradeFormData } from "./types";

type TradeTicketMobileProps = {
  tradeFormData?: TradeFormData;
  instrument: any;
  onCloseTradeClick?: (() => void);
};
export const TradeTicketMobile = ({ tradeFormData, instrument, onCloseTradeClick }: TradeTicketMobileProps) => {
   return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{ display: "flex", padding: "10px 10px 2px 10px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{ display: "flex", fontWeight: 600, letterSpacing: "1.5px" }}
              alignItems="center"
              gap="4px"
              justifyContent="center"
            >
              <Icon />
              <span className="trade-title-text">TRADE</span>
            </Box>
            <Box>
              <button type="button" style={{ background: "none", border: "none" }} onClick={onCloseTradeClick}>
                <CloseIcon />
              </button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <TradeTicketDrawer
        workspaceId={0}
        tradeFormData={tradeFormData}
        isMobile
        instrument={instrument}
        onCloseTradeClick={onCloseTradeClick}
      />
    </>
  );
};
export default TradeTicketMobile;
