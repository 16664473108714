import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { AccountType, Mode_Type } from "../../../../types";
import { useState } from "react";
import { buttonStyles, labelStyles, modeStyles } from "./style";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";

interface ModeProps {
  mode: Mode_Type;
  onModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onModeReset: () => void;
  isDesktop?: boolean;
}

const Mode = ({ mode, onModeChange, onModeReset, isDesktop }: ModeProps) => {
  const [checked, setChecked] = useState<Mode_Type | null>(mode);
  const currentUser = useGetCurrentUser();

  const hasChallengeAccount = currentUser?.accounts?.some(
    (account: any) => account.accountType === AccountType.Challenge
  );

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onModeChange(event);
    setChecked(event.target.value as Mode_Type);
  };

  return (
    <FormControl sx={{ display: "flex" }}>
      {!isDesktop && (
        <FormLabel id="demo-radio-buttons-group-label" sx={{ margin: "10px 0 0 10px", letterSpacing: '0.5px' }}>
          Mode
        </FormLabel>
      )}
      <RadioGroup name="mode" value={mode} onChange={handleModeChange} data-testid="theme-toggle">
        <div
          className="mobile-mode"
          style={{
            backgroundColor: checked === Mode_Type.PRACTICE_MODE ? "var(--Blue-Blue-6, #EBF9FF)" : "transparent",
          }}
        >
          <FormControlLabel
            value={Mode_Type.PRACTICE_MODE}
            control={<Radio />}
            label={Mode_Type.PRACTICE_MODE}
            labelPlacement="end"
            sx={{
              ...modeStyles,
              "& .MuiFormControlLabel-label": labelStyles,
            }}
          />
          <Button
            sx={{...buttonStyles, textTransform: 'none'}}
            onClick={() => {
              onModeReset();
              setChecked(null);
            }}
          >
            {isDesktop ? "Reset" : "RESET"}
          </Button>
        </div>
        <div
          className="mobile-mode"
          style={{
            backgroundColor: checked === Mode_Type.CHALLENGE_MODE ? "var(--Blue-Blue-6, #EBF9FF)" : "transparent",
          }}
        >
          <FormControlLabel
            value={Mode_Type.CHALLENGE_MODE}
            control={<Radio />}
            label={Mode_Type.CHALLENGE_MODE}
            labelPlacement="end"
            sx={{
              ...modeStyles,
              "& .MuiFormControlLabel-label": labelStyles,
            }}
            disabled={!hasChallengeAccount}
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default Mode;
