import { Typography, Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

export const LoadingText = styled(Typography)`
  margin-top: 16px;
  font-family: "Averta-Regular";
  font-size: 20px;
  color: #3a464f;
  font-weight: 600;
`;
