import React from "react";
import { Modal, Box, Grid, Typography, Button } from "@mui/material";
import { AppleIcon, CloseIcon, PlayStoreIcon } from "../../../../../images/Icons";
import { AppDownloadPromptModalStyles } from "./styles";
import { saveToLS } from "../../../../../storage";
import { APP_DOWNLOAD_MODAL } from "../../../../../types";

interface AppDownloadPromptModalProps {
  open: boolean;
  onClose: () => void;
}

const AppDownloadPromptModal: React.FC<AppDownloadPromptModalProps> = ({ open, onClose }) => {
  const handleDontShowAgain = () => {
    saveToLS(APP_DOWNLOAD_MODAL, "true");
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={AppDownloadPromptModalStyles.modalContainer}>
        <Box sx={AppDownloadPromptModalStyles.closeIconContainer}>
          <Button onClick={onClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="h6" sx={AppDownloadPromptModalStyles.title}>
          Want to manage your Watchlists on your Mobile device?
        </Typography>
        <Typography variant="body2" sx={AppDownloadPromptModalStyles.subtitle}>
          Download our CME Mobile App today
        </Typography>
        <Grid container spacing={2} justifyContent="center" width={"300px"}>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <PlayStoreIcon sx={{ width: "130px", height: "42px" }} />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <AppleIcon sx={{ width: "130px", height: "42px" }} />
          </Grid>
        </Grid>
        <Typography sx={AppDownloadPromptModalStyles.dontShowAgain} onClick={handleDontShowAgain}>
          Don't show again
        </Typography>
      </Box>
    </Modal>
  );
};

export default AppDownloadPromptModal;
