import { GridColDef } from "@mui/x-data-grid";
import { dataGridStyles } from "../../shared/DataGrid";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { DataGridPro, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useEffect, useRef } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import { Box } from "@mui/material";

const dragIconStyles = { color: "#081D378F", height: "20px", width: "20px" };

const StyledGridOverlay = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay({ message, notExists }: { message: string; notExists: boolean }) {
  return (
    <StyledGridOverlay>
      <Box style={{ color: notExists ? "red" : "#5A6874" }}>{notExists ? "Watchlist does not exist" : message}</Box>
    </StyledGridOverlay>
  );
}

const WatchlistDataGrid = ({
  watchlistRows,
  columns,
  handleRowOrderChange,
  onDragStart,
  notExists,
}: {
  watchlistRows: Instrument[];
  columns: GridColDef[];
  handleRowOrderChange: (params: GridRowOrderChangeParams) => void;
  onDragStart: (params: any) => void;
  notExists: boolean;
}) => {
  const tableRef = useRef<any>(null);
  useEffect(() => {
    let unsubscribeRowDragStart: any;
    let unsubscribeRowDragEnd: any;
    if (tableRef?.current) {
      unsubscribeRowDragStart = tableRef?.current?.subscribeEvent("rowDragStart", () => {
        onDragStart(true);
      });
      unsubscribeRowDragEnd = tableRef?.current?.subscribeEvent("rowDragEnd", () => {
        onDragStart(false);
      });
    }
    return () => {
      unsubscribeRowDragStart();
      unsubscribeRowDragEnd();
    };
  }, [onDragStart, tableRef]);

  const getRowClassName = (params: any) => {
    return params.row.cqgSymbol === null ? "highlight-row" : "";
  };

  return (
    <div style={{ position: "absolute", top: 0, bottom: 0, width: "100%", height:'100%' }}>
      <DataGridPro
        rowHeight={36}
        rows={watchlistRows}
        apiRef={tableRef}
        columns={columns}
        sx={dataGridStyles}
        getRowClassName={getRowClassName}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        hideFooterPagination
        onRowOrderChange={handleRowOrderChange}
        slots={{
          rowReorderIcon: (props) => <DragIndicatorIcon {...props} sx={dragIconStyles} />,
          noRowsOverlay: () => <CustomNoRowsOverlay message={"No rows"} notExists={notExists} />,
          noResultsOverlay: () => <CustomNoRowsOverlay message={"No rows"} notExists={notExists} />,
        }}
      />
    </div>
  );
};

export default WatchlistDataGrid;
