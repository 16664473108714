import { Box, Button, Typography } from "@mui/material";
import { InstrumentExpiredScreenStyles } from "./InstrumentExpiredScreenStyles";
 
const InstrumentExpiredScreen = () => {
  return (
    <Box sx={InstrumentExpiredScreenStyles.container}>
      <Button disabled={true} variant="outlined" sx={InstrumentExpiredScreenStyles.tag}>
        EXPIRED
      </Button>
      <Typography sx={InstrumentExpiredScreenStyles.title}>
        This product is expired,
        please select a new one
      </Typography>
    </Box>
  );
};

export default InstrumentExpiredScreen;