export const educationtitle = {
  fontFamily: "Averta-Regular",
  fontWeight: 600,
  fontSize: "16px",
  whiteSpace: "nowrap",
  padding: 0,
  margin: 0,
  color: "#081D37",
  alignSelf: "center",
};

export const catalogButtonStyles = {
  color: "#00426D",
  fontWeight: "600",
  fontFamily: "Averta-Regular",
  borderColor: "#006EB6",
  fontSize: "11px",
  height: "30px",
};

export const boxContainerStyles = {
  padding: "16px",
  backgroundColor: "white",
  height: "100%",
  paddingTop: "5px",
};

export const boxHeaderStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const callIconStyles = {
  width: "12px",
  height: "12px",
  cursor: "pointer",
};

export const callIconButtonStyles = {
  width: "15px",
  height: "15px",
  color: "#5A6874",
};
