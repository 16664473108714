import { ChevronLeft, ExpandMore } from "@mui/icons-material";
import useFundsInfo from "../dashboard/hooks/useFundsInfo";
import IconButton from "@mui/material/IconButton";
import { useCallback, useState } from "react";
import AccountInfoSlider from "./AccountInfoSlider";
import { Mode_Type } from "../../../../types";
import { TrophyIcon } from "../../../../images/Icons";

const FundsInfo = ({
  accountInfoVisible,
  handleCloseAccInfo,
  onMenuItemClick,
  mode
}: {
  accountInfoVisible: boolean;
  handleCloseAccInfo: () => void;
  onMenuItemClick: (component: JSX.Element | null) => void;
  mode: Mode_Type
}) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const { formattedPurchasingPower, formattedPositionMargin, formattedProfitLoss, formattedAvailableFunds } =
    useFundsInfo();

  const openMarketMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorMenu(null);
  }, []);

  return (
    <>
      <div className="header-right">
        {mode === Mode_Type.CHALLENGE_MODE &&
          <div className="info-item">
            <TrophyIcon />
          </div>}
        <div className="info-item">
          <span>{mode === Mode_Type.PRACTICE_MODE ? "PRACTICE FUNDS" : "CHALLENGE FUNDS"}</span>
          <strong>{formattedPurchasingPower}</strong>
        </div>
        <div className="info-item">
          <span>{mode === Mode_Type.PRACTICE_MODE ? "NET P/L" : "PROFIT/LOSS"}</span>
          <strong>{formattedProfitLoss}</strong>
        </div>
        <div className="info-item">
          <span>MARGIN</span>
          <strong>{formattedPositionMargin}</strong>
        </div>
        <div className="info-item">
          <span>AVAILABLE</span>
          <strong>{formattedAvailableFunds}</strong>
        </div>
        {mode === Mode_Type.CHALLENGE_MODE &&
          <div className="info-item">
            <span>RANKING</span>
            <strong>-</strong>
          </div>}
        <IconButton aria-label="marketDropdown" onClick={(event) => openMarketMenu(event)} sx={{ padding: "2px" }}>
          {anchorMenu ? <ExpandMore /> : <ChevronLeft />}
        </IconButton>
      </div>
      {anchorMenu && (
        <AccountInfoSlider
          accountInfoVisible={accountInfoVisible}
          handleCloseAccInfo={handleCloseAccInfo}
          onMenuItemClick={onMenuItemClick}
          isDesktop
          handleCloseMenu={handleCloseMenu}
          anchorMenu={anchorMenu}
        />
      )}
    </>
  );
};

export default FundsInfo;
