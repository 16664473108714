import { ENV } from "../env";

const BASE_URL = ENV.APP_BASE_URL;
const ROOT_URL =  BASE_URL || "/";

export const privateRoutes = [

  // {
  //   path: "/widgets/chart",
  //   loader: () => import ("../components/mobileView/Chart/Chart"),
  // },
  {
    path: ROOT_URL,
    loader: () => import("../components/home/Home"),
    permissionRequired: null,
    exact: true,
  },
];

export const publicRoutes = [
  {
    path: BASE_URL + "/auth/signin",
    loader: () => import("../components/auth/SigninPage"),
  },
  {
    path: BASE_URL + "/widgets/position",
    loader: () => import("../components/PositionsWidget"),
  },
  {
    path: BASE_URL + "/web",
    loader: () => import("../components/Web"),
  },
  {
    path: BASE_URL + "/widgets/chart/:symbol",
    loader: () => import("../components/mobileView/Chart/Chart"),
  },
  {
    path: BASE_URL + "/widgets/orders",
    loader: () => import("../components/mobileView/Order/OrderGrid"),
  },
  {
    path: BASE_URL + "/widgets/positions",
    loader: () => import("../components/mobileView/Position/PositionGrid"),
  },
  {
    path: BASE_URL + "/widgets/account",
    loader: () => import("../components/mobileView/AccountDetail/AccountDetail"),
  },
  {
    path: BASE_URL + "/widgets/trade_plan",
    loader: () => import("../components/mobileView/TradePlan/TradePlanMobile"),
  },
  {
    path: BASE_URL + "/widgets/price_ladder/:symbol",
    loader: () => import("../components/mobileView/PriceLadder/PriceLadderView"),
  },
  {
    path: BASE_URL + "/widgets/reset_practice_account",
    loader: () => import("../components/mobileView/ResetPracticeAccount"),
  },
  {
    path: BASE_URL + "/widgets/trade_log",
    loader: () => import("../components/mobileView/AccountDetail/TradeLogMobile"),
  }
];

