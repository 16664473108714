import { useEffect, useCallback } from "react";
import "moment-timezone";
import moment from "moment";

/**
 * Custom Hook to trigger a callback at a specific time in a given timezone.
 * This version will execute the callback every time the target time is reached.
 * @param targetTime - The time (HH:mm) to trigger the callback (e.g., "00:00" for midnight).
 * @param timeZone - The timezone in which the target time should be checked (e.g., "America/Chicago").
 * @param onTrigger - A callback function to execute at the target time.
 */
const useTimeTrigger = (targetTime: string, timeZone: string, onTrigger: () => void) => {
  const checkTime = useCallback(() => {
    const now = moment().tz(timeZone);
    const currentTime = now.format("HH:mm");
    if (currentTime === targetTime) {
      console.log(`Triggering action at ${targetTime} in ${timeZone}`);

      // Execute the provided callback function
      onTrigger();
    }
  }, [targetTime, timeZone, onTrigger]);

  useEffect(() => {
    // Check immediately on mount, then set interval to check every minute
    checkTime();

    const interval = setInterval(checkTime, 60000); // Check every minute
    return () => clearInterval(interval); // Cleanup on unmount
  }, [checkTime]);

  return null; // No state to return, just triggers actions
};

export default useTimeTrigger;
