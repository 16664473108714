import {styled} from '@mui/material/styles';

export const WatchlistName = styled('button')({
    padding: '6px 12px',
    background: 'none',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    color: '#112B4A',
    fontSize: '16px',
    fontFamily: 'Averta-Regular',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
        background: '#EBF9FF',
    },
    '&.active': {
        background: '#EBF9FF',
    },
});
export const SectionTitle = styled("h5")({
    fontSize: '14px',
    paddingLeft: '10px',
    fontWeight: '500',
    marginBottom: '5px',
    color: '#5A6874',
    fontFamily: 'Averta-Regular',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
});
