import { Box } from '@mui/material';
import {styled} from '@mui/material/styles';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Pro = styled('div')({
  width: 'calc(100% - 24px)',
  marginLeft: '24px',
});
export const Text = styled('p')({
  margin: 0,
  fontSize: '12px',
  fontFamily: 'Averta-Regular',
});
export const ContractText = styled(Text)({
  fontWeight: 600,
  marginLeft: '8px',
  marginRight: '8px',
  color: '#081D37',
});
export const Tag = styled('span')({
  fontSize: '12px',
  color: '#112B4A',
  backgroundColor: '#DDCEE4',
  borderRadius: '6px',
  padding: '2px 3px',
  marginLeft: '8px',
});
export const ProductBox = styled(Box)({
  display: 'flex',
  width: '100%',
  marginBottom: '8px',
});
export const Amount = styled(Text)({
  marginRight: '8px',
  fontWeight: 300,
  color: '#081D37',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontFamily: 'Averta-Regular',
});
type ContainerProps = {
  disabled?: boolean;
};
export const Container = styled('div')<ContainerProps>(({ disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #EBEDEE',
  marginBottom: '8px',
  padding: '0px 10px',
  flexWrap: 'wrap',
  opacity: disabled ? 0.5 : 1,
}));
export const InstrumentSelector = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});
export const InstrumentName = styled('p')({
  fontSize: '14px',
  color: '#081D37',
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  padding: 0,
  margin: 0,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  marginLeft: '-20px',
});
export const InstrumentProducts = styled('p')({
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#081D37',
  fontFamily: 'Averta-Regular',
  fontWeight: 300,
  padding: 0,
  margin: 0,
});
export const Button = styled('button')({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  '&:focus': {
    outline: 'none',
  },
});

export const FeaturedContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '8px',
  // margin: '2px 16px',
  padding: '6px 4px',
});
export const Heading = styled('h4')({
  fontWeight: 400,
  fontSize: '14px',
  color: '#000000',
  lineHeight: '24px',
  margin: '0px 0px 0px',
  display: 'flex',
  alignItems: 'center',
  'svg': {
    marginLeft: '4px',
  }
});
export const ActionButton = styled('button')({
  background: '#006EB6',
  color: '#FFFFFF',
  textTransform: 'uppercase',
  border: 'none',
  fontFamily: 'Averta-Regular',
  borderRadius: '4px',
  padding: '8px 12px',
  boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)',
  fontWeight: 600,
  marginRight: 8,
  fontSize: '11px',
  cursor: 'pointer',
  "&:disabled": {
    background: '#EBEDEE',
    color: '#C2CACE',
    opacity: '0.5',
    cursor: 'auto',
  }
});
export const ClearSelectionButton = styled('button')({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '0',
  margin: '0',
  fontFamily: 'Averta-Regular',
  color: '#0195E7',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
});
export const InfoOutlinedIconStyled = styled(InfoOutlinedIcon)({
  height: '16px',
  width: '16px',
});
