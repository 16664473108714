import React from "react";
import { DockviewReact, themeLight } from "dockview";
import { components } from "./utils";
import RightHeaderControls from "./RightHeaderControls";
import tabComponents from "./tab-components/tabComponents";

interface DockviewContentProps {
  onReady: (event: any) => void;
  dockviewRef: React.MutableRefObject<null>;
}

const DockviewContent: React.FC<DockviewContentProps> = ({ onReady, dockviewRef }) => {
  return (
    <DockviewReact
      components={components}
      tabComponents={tabComponents}
      rightHeaderActionsComponent={RightHeaderControls}
      onReady={onReady}
      className="dockview-theme-light"
      defaultRenderer="always"
      floatingGroupBounds="boundedWithinViewport"
      ref={dockviewRef}
      dndEdges={{
        size: { value: 60, type: "pixels" },
        activationSize: { value: 1, type: "percentage" },
      }}
      theme={themeLight}
    />
  );
};

export default DockviewContent;
