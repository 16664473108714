import React, { useState } from 'react';
import { StyledToggleButtonGroup, StyledToggleButton, StyledTextTypography } from './Styled';
import './header.css'
const ThemeToggle = () => {
  const [alignment, setAlignment] = useState<string>('Light');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>, 
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <div className="theme-toggle-container">
      <StyledTextTypography variant="body2">
        THEME
      </StyledTextTypography>

      <StyledToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        <StyledToggleButton value="Light" alignment={alignment}>
          Light
        </StyledToggleButton>
        <StyledToggleButton value="Dark" alignment={alignment}>
          Dark
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </div>
  );
};

export default ThemeToggle;
