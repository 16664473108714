import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { AssetProductsDictionary, InstrumentExt, Product, ProductWithContracts } from "./types";
import { Instrument } from "./cqg-api/models/Instrument";
import { InstrumentsManager } from "./cqg-api/services/InstrumentsManager";
import * as MarketData from "./cqg-api/proto/market_data_2";

interface AvailableProductsContextProps {
  products: Product[];
  assetProductsDictionary: AssetProductsDictionary;
  productsMapById: Record<string, ProductWithContracts>;
  monthlyContractsByIdMap: Record<string, Instrument>;
  monthlyContractsByDisplayNameMap: Record<string, InstrumentExt>;
  productMapByCMESymbol: Record<string, Product>;
  availableloading?: boolean;
}

const initialContextValue: AvailableProductsContextProps = {
  products: [],
  assetProductsDictionary: {},
  productsMapById: {},
  monthlyContractsByIdMap: {},
  monthlyContractsByDisplayNameMap: {},
  productMapByCMESymbol: {},
};

export const AvailableProductsContext = createContext<AvailableProductsContextProps>(initialContextValue);

export const AvailableProductsProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AvailableProductsContext.Provider value={initialContextValue}>
      {children}
    </AvailableProductsContext.Provider>
  );
};

export const TestAvailableProductsProvider = ({ children, realTimeMarketData }: { children: ReactNode, realTimeMarketData: MarketData.RealTimeMarketData[] }) => {
  useEffect(() => {
    InstrumentsManager.processRealTimeMarketData(realTimeMarketData);
    return () => { }
  }, []);
  
  return (
    <AvailableProductsContext.Provider value={initialContextValue}>
      {children}
    </AvailableProductsContext.Provider>
  );
};

export const useAvailableProductsContext = () => {
  return useContext(AvailableProductsContext);
}
