import { AppGlobal } from "../../AppGlobal";
import { ENV } from "../../env";
import { getCookie } from "../../utils/utils";

export default class PermissionChecker {
  currentUser;

  constructor(currentUser: any) {
    this.currentUser = currentUser;
  }

  get isAuthenticated() {
    const cmeToken = getCookie("cmeToken");
    const userId = getCookie("userId");
    const isHostCME = ENV.IS_HOST_CME;

    if (isHostCME) {
      // Check if CME login details are available
      // If found, compare the provided userId and token (from cookies) with the stored login details
      // If don't match, clear all the stored data to force process login details as current state in invalid.
      const cmeLoginDetails = AppGlobal.getCMELoginDetails();
      if (cmeLoginDetails) {

        if (
          (userId === cmeLoginDetails.userId && cmeToken === cmeLoginDetails.cmeToken) // If CME userId and token are same as stored
          && (Boolean(this.currentUser) && Boolean(this.currentUser?.accessToken)) // And if user is already logged in
        ) {
          return true;
        }
        else {
          AppGlobal.clearAll();
          return false;
        }
      }
    }

    return Boolean(this.currentUser) && Boolean(this.currentUser?.accessToken);
  }
}
