import { useEffect, useRef, useId, useState, useCallback, useMemo } from "react";
import { cqgUDFDataFeed } from '../../../cqg-api/services/CQGUDFDataFeed';
import { useChartConfig, generateMultiChartLayout, chartLayout } from "./chartConfig";
import { WidgetAction, WidgetType } from "../../../../src/types";
import { useDispatch } from "react-redux";
import workspacesActions from "../../../../src/redux/workspaces/workspacesActions";
import { setTradeTicketValue } from "../../../../src/redux/products/chartWidget";
import { useRunningMonth } from "../../../hooks/api/useRunningMonth";
import * as Order from "../../../cqg-api/proto/order_2"
import { useOrdersList } from "../../../cqg-api/hooks/ServiceHooks";
import useOrderLines from "./useOrderLines";
import ConfirmCancelDialog from "../../shared/ConfirmCancelDialog";
import { cancelOrders } from "../orders/actions";

const TradingChart = (props) => {
  const chartContainerRef = useRef();
  const [tvWidget, setTvWidget] = useState(null);
  const { isContractExists, getRunningMonthContract, monthlyContractsByDisplayNameMap } = useRunningMonth();
  const { ordersList } = useOrdersList();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrderCancelDialog, setOpenOrderCancelDialog] = useState(false);

  const handleConfirmCancelOrder = useCallback(() => {
    if (selectedOrder) {
      cancelOrders([selectedOrder]);
      setOpenOrderCancelDialog(false);
    }
  }, [selectedOrder]);

  const symbols = useMemo(() => {
    const symbols = [];

    if(props.isMobileView) {
      return props.symbols;
    }

    props.symbols?.map(sym => {
      const isExists = isContractExists(sym);
      if (!isExists) {
        symbols.push(getRunningMonthContract(sym));
      } else {
        symbols.push(sym);
      }
    })

    return symbols;
  }, [props.symbols, monthlyContractsByDisplayNameMap])

  const containerId = useId();
  const dispatch = useDispatch();

  const workspaceId = props.workspaceId;

  const openOrderTicket = useCallback((order, instrument, chartvalue=null) => {
    if (workspaceId) {
      const chartWidgetValue = chartvalue ? chartvalue : {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        widgetData: {
          action: WidgetAction.CreateOrder,
          data: {
            size: order.qty,
            side: order.side,
            limitPrice: order.price,
            orderType: Order.Order_OrderType.ORDER_TYPE_MKT,
          },
        },
      };

      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: instrument,
        }),
      );

      dispatch(setTradeTicketValue(chartWidgetValue));
    }
  }, [workspaceId]);

  const chartConfig = useChartConfig(cqgUDFDataFeed, symbols[0], containerId, openOrderTicket, props.widget);

  const createTradingwidget = useCallback(async () => {
    const widget = new window.TradingView.widget(chartConfig);
    setTvWidget(widget);
  }, [cqgUDFDataFeed, symbols, containerId, openOrderTicket]);

  const { generateOrderLine } = useOrderLines(
    tvWidget,
    ordersList,
    setSelectedOrder,
    setOpenOrderCancelDialog,
    openOrderTicket,
    symbols
  );

  useEffect(() => {
    createTradingwidget();
  }, [symbols])

  useEffect(() => {
    let saveInterval = null;
    if (tvWidget) {
      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const size = symbols.length;
  
          if (size === 7 || size > 8) {
            console.log(`Trading chart does not support ${size} layout.`);
          } else {
            tvWidget.setLayout(chartLayout(size));
            generateMultiChartLayout(tvWidget, size, symbols)
          }

        });
        generateOrderLine()
      });
    }

    return () => {
      if (tvWidget)
        tvWidget.remove();

      clearInterval(saveInterval);
    }
  }, [tvWidget])

  return (
    <>
      <div
        style={{ width: "100%", height: "100%" }}
        id={"TVChartContainer" + containerId}
        ref={chartContainerRef}
        className={"TVChartContainer"}
      />
      {openOrderCancelDialog && selectedOrder && (
        <ConfirmCancelDialog
          open={openOrderCancelDialog}
          onClose={() => setOpenOrderCancelDialog(false)}
          onConfirm={handleConfirmCancelOrder}
          title="Confirm Order Cancellation"
          message="Are you sure you want to cancel this order?"
        />
      )}
    </>
  );
};

export default TradingChart;