import { Slide } from "@mui/material";
import "./ProfileSection.css";
import Mode from "./Mode";
import Footer from "./Footer";
import Pages from "./Pages";
import UserInfo from "./UserInfo";
import DigitalTime from "./DigitalTime/DigitalTime";
import { useState } from "react";
import { SimulatorController } from "../../../../cqg-api/controllers/SimulatorController";
import authActions from "../../../../redux/auth/authActions";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";
import { useAuthenticateUser, useGetCurrentUser } from "../../../../hooks/api/authentication";
import { useDispatch } from 'react-redux';
import Notification from '../../../shared/notification/Notification';
import { Mode_Type } from "../../../../types";
import { AppGlobal } from "../../../../AppGlobal";

interface ProfileSectionProps {
  isMobileProfileViewVisible: boolean;
  onMenuItemClick: (component: JSX.Element | null) => void;
  handleModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mode: Mode_Type;
}

const ProfileSection = ({ isMobileProfileViewVisible, onMenuItemClick, handleModeChange, mode }: ProfileSectionProps) => {
  const currentUser = useGetCurrentUser();
  const dispatch = useDispatch();
  const { getUserDetails } = useAuthenticateUser();
  const [showResetPracticeAccountDialog, setShowResetPracticeAccountDialog] = useState(false);

  const handleModeReset = () => {
    setShowResetPracticeAccountDialog(true);
  };

  const resetPracticeAccount = async () => {
    SimulatorController.resetPracticeAccount(currentUser.id).then(async (resp) => {
      console.log('Reset practice account done!');
      await getUserDetails(currentUser.id, (response: any) => {
        AppGlobal.removeCurrentUser();
        dispatch(authActions.dispatchLoginSuccess(response));
        setShowResetPracticeAccountDialog(false);
        window.location.reload();
      });
    }).catch((e) => {
      setShowResetPracticeAccountDialog(false);
      Notification.error(`Unable to reset your account. Please contact support.`);
      setShowResetPracticeAccountDialog(false);
    });
  }

  return (
    <>
      <Slide direction="up" in={isMobileProfileViewVisible} mountOnEnter unmountOnExit>
        <div className="profile-slider-container">
          <UserInfo />
          <Mode mode={mode} onModeChange={handleModeChange} onModeReset={handleModeReset} />
          <hr />
          <Pages onMenuItemClick={onMenuItemClick} mode={mode} />
          <hr />
          <Footer />
          <DigitalTime />
        </div>
      </Slide>
      <ConfirmCancelDialog
        open={showResetPracticeAccountDialog}
        onClose={() => setShowResetPracticeAccountDialog(false)}
        onConfirm={resetPracticeAccount}
        loading={false}
        title="Reset Account"
        message={
          <span>
            Resetting your account will delete your trade history. Are you sure you want to reset your account?
          </span>
        }
        cancelText="Cancel"
        confirmText="Reset"
      />
    </>
  );
};

export default ProfileSection;

