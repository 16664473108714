import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { OrderStatus_Status } from "../../../cqg-api/proto/common/shared_1";
import { WidgetAction, WidgetType } from '../../../types';
import { OrderChain_OrderType } from '../../../cqg-api/proto/traderouting_1';

const getOrderPriceForOrderLine = (order) => {
  if (order.orderType === OrderChain_OrderType.STP) {
    return order.stopPrice;
  } else {
    return order.limitPrice;
  }
};

const updateOrderPrice = (order, price) => {
  if (order.orderType === OrderChain_OrderType.STP) {
    order.stopPrice = price
  } else {
    order.limitPrice = price;
  }
};

const useOrderLines = (tvWidget, ordersList, setSelectedOrder, setOpenOrderCancelDialog, openOrderTicket, symbols) => {
  const dispatch = useDispatch();
  const [orderLines, setOrderLines] = useState([]);
  const marker = useRef(false);

  useEffect(() => {
    if (!!ordersList.length) {
      const orders = ordersList.filter(
        (order) =>
          order.status === OrderStatus_Status.CANCELLED ||
          order.status === OrderStatus_Status.FILLED ||
          order.status === OrderStatus_Status.REJECTED,
      );
      removeMarker(orders);
      
      if(marker.current) {
        const workingOrders = ordersList.filter(
          (order) =>
            order.status === OrderStatus_Status.WORKING ||
            order.status === OrderStatus_Status.PARTIALLY_MATCHED,
        );
  
        const newOrders = workingOrders.filter(order => 
          !orderLines.some(line => order.chainOrderId === line.orderId)
        );
        const newOrderLines = [];
  
        newOrders.map(order => {
          if( symbols.includes(order.displayContract)) {
            const orderLine = createOrderLine(order);
            newOrderLines.push(orderLine);
          }
        })
  
        if (newOrderLines.length > 0) {
          setOrderLines((prevLines) => [...prevLines, ...newOrderLines]);
        }
      }      
    }
  }, [ordersList]);

  const openTicket = useCallback(
    (order, instrument) => {
      const chartWidgetValue = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        widgetData: {
          action: WidgetAction.AmendOrder,
          data: order,
        },
      };

      openOrderTicket(order, instrument, chartWidgetValue);
    },
    [dispatch],
  );

  const createOrderLine = useCallback(
    (order) => {
      const orderLine = tvWidget.chart().createOrderLine();

      orderLine.onCancel(() => {
        console.log("Order cancelled");
        setSelectedOrder(order);
        setOpenOrderCancelDialog(true);
      });

      orderLine.onModify(() => {
        console.log("Order modified");
        const instrument = order.instrument;
        openTicket(order, instrument);
      });

      orderLine.onMove(function () {
        const rawPrice = this.getPrice();
        updateOrderPrice(order, rawPrice);
        const instrument = order.instrument;
        openTicket(order, instrument);
      });

      const color = order.displaySide === "SELL" ? "rgb(203, 6, 6)" : "rgb(0, 85, 138)";
      orderLine.setText(order.orderType === 3 ? `STOP ${order.displaySide}` : `${order.displaySide} LIMIT`);
      orderLine.setLineColor(color);
      orderLine.setBodyBorderColor(color);
      orderLine.setBodyTextColor(color);
      orderLine.setQuantityBorderColor(color);
      orderLine.setQuantityBackgroundColor(color);
      orderLine.setCancelButtonBorderColor(color);
      orderLine.setCancelButtonIconColor(color);

      orderLine.setLineLength(3);
      orderLine.setTooltip("Drag to adjust order");
      orderLine.setQuantity(order.displaySize);

      const linePrice = getOrderPriceForOrderLine(order);
      orderLine.setPrice(linePrice);

      orderLine.orderId = order.chainOrderId;

      return orderLine;
    },
    [tvWidget, setSelectedOrder, setOpenOrderCancelDialog, openTicket],
  );

  const removeMarker = useCallback((orders) => {
    if (!!orders.length && !!orderLines.length) {
      const lines = orderLines.filter(line => orders.some(order => line.orderId === order.chainOrderId));
      lines.map(line => {
        if(line && !!line._line) {
          line.remove();
        }
      })
    }
  })

  const generateOrderLine = useCallback(() => {
    if (ordersList && ordersList.length > 0) {
      const newOrderLines = [];

      ordersList.forEach((order) => {
        if (order.status === OrderStatus_Status.WORKING || order.status === OrderStatus_Status.PARTIALLY_MATCHED) {
          if (symbols.includes(order.displayContract)){
            const orderLine = createOrderLine(order);
            newOrderLines.push(orderLine);
          }
        } 
      });

      if (newOrderLines.length > 0) {
        setOrderLines((prevLines) => [...prevLines, ...newOrderLines]);
      }
    } else {
      setOrderLines([]);
    }

    marker.current = true
  }, [ordersList, orderLines, createOrderLine]);

  return {
    orderLines,
    generateOrderLine,
  };
};

export default useOrderLines;