import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipArrowStyles, tooltipStyles } from "./menuItemDrawerStyles";

export const InfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: tooltipArrowStyles,
  [`& .${tooltipClasses.tooltip}`]: tooltipStyles,
}));

export default InfoTooltip;
