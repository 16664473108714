import { IDockviewPanelHeaderProps } from "dockview";
import { PanelCrossIcon, StarIcon } from "../../../images/Icons";
import "./tabComponents.css";

const tabComponents = {
  featuredWatchlist: (props: IDockviewPanelHeaderProps<any>) => {
    return (
      <div className="tab-component-dock-container">
        <StarIcon className="tab-component-star-icon" />
        <div className="dv-default-tab-content tab-component-dock--title">{props.api.title}</div>
        <span className="tab-component-close-action" onClick={() => props.api.close()}>
          <PanelCrossIcon />
        </span>
      </div>
    );
  },
};

export default tabComponents;
