import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  challengeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px",
    gap: "24px",
    // width: "592px",
    // height: "189px",
    borderRadius: "4px",
    border: "1px solid #EBEDEE",
  },

  imageSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "40px",
    width: "200px",
    height: "173px",
    position: "relative",
  },

  rectangle: {
    width: "200px",
    height: "173px",
    backgroundColor: "#EBF9FF",
  },

  contentSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    width: "376px",
  },

  publicText: {
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "14px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: "#000000",
  },

  challengeTitle: {
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "25px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
    height: "25px",
  },

  challengeDescription: {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
    height: "59px",
  },

  dateSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0px 0px",
    gap: "8px",
    width: "352px",
    height: "38px",
  },

  dateFrame: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    width: "87px",
    height: "26px",
  },

  date: {
    fontFamily: "Averta-Regular",
    fontWeight: 700,
    fontSize: "9px",
    lineHeight: "11px",
    color: "#25323C",
  },

  dateText: {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "9px",
    lineHeight: "11px",
    color: "#25323C",
    whiteSpace: "nowrap",
  },

  actionsSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
    gap: "8px",
    width: "352px",
  },

  viewDetailsButton: {
    width: "136px",
    height: "30px",
    fontSize: "11px",
    fontWeight: 600,
    color: "#006EB6",
    borderRadius: "2px",
    border: "none",
    whiteSpace: "nowrap",
    textTransform: "none",
  },

  ContainedButton: {
    // width: '56px',
    height: "30px",
    backgroundColor: "#006EB6",
    color: "#FFFFFF",
    fontSize: "11px",
    fontWeight: 600,
    textTransform: "uppercase",
    borderRadius: "2px",
  },
}));

export default useStyles;
