import {styled} from '@mui/material/styles';

export const WatchlistSelector = styled('div')({
  backgroundColor: "#EBEDEE",
  color: '#25323C',
  padding: '10px',
  border: '1px solid #BDC3C7',
  margin: '8px',
  fontSize:' 14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
export const Text = styled('p')({
  margin: 0,
  fontSize: '12px',
  fontFamily: 'Averta-Regular',
});
export const ProductName = styled('span')({
  minHeight: 17,
})
export const Title = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
});