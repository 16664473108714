import StarIcon from "@mui/icons-material/Star";
import TableView from ".//TableView";
import { Title, WatchlistSelector } from "./styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MobileWatchlistDrawer from "./MobileWatchlistDrawer";
import { useMobileWatchlist } from "./useMobileWatchlist";
import { useEffect, useState } from "react";
import { APP_DOWNLOAD_MODAL } from "../../../types";
import InstrumentModal from "../availableProducts/mobile/marketModal/MarketMobileModal";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { subscribeToContracts } from "../../../utils/subscriptions";
import AppDownloadPromptModal from "./MobileWatchlistDrawer/AppDownloadModal/AppDownloadPromptModal";
import MobileWatchlistLoader from "./MobileWatchlistLoader";
import { getFromLS } from "../../../storage";
import { ContractWithProduct } from "../../featuredWatchlist/types";


const MobileWatchlist = () => {
  const {
    loadingMyWatchlist,
    selectedWatchlist,
    handleSelectWatchlist,
    isSelectedIsFeatured,
    watchlistSelector,
    handleSelectFeaturedWatchlist,
    watchlistData,
    setWatchlistSelector,
  } = useMobileWatchlist();

   const [isAppDownloadModalOpen, setIsAppDownloadModalOpen] = useState(false);
  const [selectedInstrument, setSelectedInstrument] = useState<ContractWithProduct | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewTradeTicket, setViewTradeTicket] = useState(false);

const rows = watchlistData as ContractWithProduct[];
const handleOpenModal = (instrument: ContractWithProduct) => {
  const contractIds = Array.isArray(instrument?.monthlyContracts)
    ? instrument?.monthlyContracts
        .map((contract: Instrument) => contract.contractId)
        .filter((contractId): contractId is number => contractId !== null)
    : [];
    if (contractIds.length > 0) {
      subscribeToContracts(contractIds);
      setSelectedInstrument(instrument);
      setModalOpen(true);
    }
};


  const openTradeWindow = () => {
    setViewTradeTicket(true);
  };

  useEffect(() => {
    const hideModal = getFromLS(APP_DOWNLOAD_MODAL);

    if (!hideModal) {
      const timer = setTimeout(() => {
        setIsAppDownloadModalOpen(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleCloseAppDownloadModal = () => {
    setIsAppDownloadModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const onCloseTradeClick = () => setViewTradeTicket(false);

  if (loadingMyWatchlist) {
    return <MobileWatchlistLoader />;
  }
  if (!selectedWatchlist?.products) {
    return null;
  }

  return (
    <>
      <WatchlistSelector onClick={() => setWatchlistSelector(true)}>
        <Title>
          {isSelectedIsFeatured && <StarIcon fontSize="small" />}
          {selectedWatchlist.name}
        </Title>
        <KeyboardArrowDownIcon />
      </WatchlistSelector>
      <TableView rows={rows} handleOpenModal={handleOpenModal} />
      {selectedInstrument && (
        <InstrumentModal
          open={modalOpen}
          onClose={handleCloseModal}
          openTradeWindow={openTradeWindow}
          instrumentName={selectedInstrument?.cmeSymbolName}
          viewTradeTicket={viewTradeTicket}
          onCloseTradeClick={onCloseTradeClick}
          contract={Array.isArray(selectedInstrument?.monthlyContracts) ? selectedInstrument?.monthlyContracts : []}
          selectedInstrument={selectedInstrument}
        />
      )}


      <MobileWatchlistDrawer
        open={watchlistSelector}
        onClose={() => setWatchlistSelector(false)}
        selectFeaturedWatchlist={handleSelectFeaturedWatchlist}
        selectWatchlist={handleSelectWatchlist}
        selectedWatchlist={selectedWatchlist}
      />

      {isAppDownloadModalOpen && (
        <AppDownloadPromptModal open={isAppDownloadModalOpen} onClose={handleCloseAppDownloadModal} />
      )}
    </>
  );
};

export default MobileWatchlist;
