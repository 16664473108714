import { WidgetType } from "../../../../../types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { WatchListMode } from "./types";

const Header = ({
  widgetType,
  watchlistMode,
  backHandler,
}: {
  widgetType: WidgetType | undefined;
  watchlistMode: WatchListMode;
  backHandler: () => void;
}) => {
  switch (widgetType) {
    case WidgetType.Watchlist:
      return (
        <div className="menu-drawer-header-watchlist">
          {watchlistMode !== WatchListMode.Edit_User_Panel &&
            (watchlistMode === WatchListMode.Creation || watchlistMode === WatchListMode.Edit) && (
              <ArrowBackIcon className="menu-drawer-back-button" onClick={backHandler} />
            )}
          <div>
            {watchlistMode === WatchListMode.Creation
              ? "CREATE "
              : watchlistMode === WatchListMode.Edit || watchlistMode === WatchListMode.Edit_User_Panel
                ? "MANAGE "
                : ""}
            WATCHLIST
          </div>
        </div>
      );
    case WidgetType.Chart:
      return <div>NEW CHART</div>;
    case WidgetType.PriceLadder:
      return <div>NEW PRICE LADDER</div>;
    case WidgetType.Options:
      return <div>New Options Grid</div>;
    default:
      return <></>;
  }
};

export default Header;
