// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationTitle {
  font-size: 1rem;
  font-family: Averta-Regular;
  color: #081D37DE;

}
.notificationText {
  font-size: 0.75rem;
  font-family: Averta-Regular;
  color: #081D3799;
}
.notificationContent {
  display: flex;
  gap: 2px;
  font-family: Averta-Regular;
  flex-direction: column;
}

.Toastify__close-button {
  color: #081D37DE !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/notification/notification.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,2BAA2B;EAC3B,gBAAgB;;AAElB;AACA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,QAAQ;EACR,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".notificationTitle {\n  font-size: 1rem;\n  font-family: Averta-Regular;\n  color: #081D37DE;\n\n}\n.notificationText {\n  font-size: 0.75rem;\n  font-family: Averta-Regular;\n  color: #081D3799;\n}\n.notificationContent {\n  display: flex;\n  gap: 2px;\n  font-family: Averta-Regular;\n  flex-direction: column;\n}\n\n.Toastify__close-button {\n  color: #081D37DE !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
