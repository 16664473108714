import { Box, Checkbox } from "@mui/material";
import { Amount, ContractText, ProductBox, Tag, Text } from "./styles";
import { useEffect, useState } from "react";
import InfoTooltip from "../home/components/SideMenu/menu-item-drawer/InfoTooltip";
import { DisplayUtil } from "../../cqg-api/utils/DisplayUtil";
import { ContractWithProduct, IContract } from "./types";
import clsx from "clsx";

type ProductProps = {
  product: ContractWithProduct;
  selected: boolean;
  updateSelected: (productCode: IContract, selected: boolean) => void;
  instrumentName: string;
};
const wordLimit = 23;
function truncateText(text: string, limit = wordLimit) {
  if (text.length > limit) {
    return text.slice(0, limit) + "...";
  }
  return text;
}
export const ProductItem = ({ product, selected, updateSelected, instrumentName }: ProductProps) => {
  const [isChecked, setIsChecked] = useState(selected);
  useEffect(() => {
    setIsChecked(selected);
  }, [selected]);
  const updateSelectedProduct = () => {
    setIsChecked(!isChecked);
    if (product?.contractCode) {
      updateSelected(
        {
          product,
          watchlistName: instrumentName,
        },
        !isChecked,
      );
    }
  };
  return (
    <ProductBox justifyContent="space-between" alignItems="center">
      <Box sx={{ display: "flex", width: "100%" }} alignItems="center">
        <Checkbox size="small" sx={{ padding: 0, margin: 0 }} checked={isChecked} onClick={updateSelectedProduct} />
        <ContractText
          className={clsx({
            "product-not-available": !product.isAvailableProduct,
          })}
        >
          {product?.contractCode}
        </ContractText>
        {product.productName.length > wordLimit ? (
          <InfoTooltip title={product.productName} placement="top" arrow>
            <Text
              className={clsx({
                "product-not-available": !product.isAvailableProduct,
              })}
            >
              {truncateText(product.productName)}
            </Text>
          </InfoTooltip>
        ) : (
          <Text  className={clsx({
            "product-not-available": !product.isAvailableProduct,
          })}>{product.productName}</Text>
        )}
        <Tag
          className={clsx({
            "product-not-available": !product.isAvailableProduct,
            "product-not-available--tag": !product.isAvailableProduct,
          })}
        >
          FM
        </Tag>
      </Box>
      <Amount>{DisplayUtil.toDisplayPrice(product?.lastPrice as number, product)}</Amount>
    </ProductBox>
  );
};
