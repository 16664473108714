import React, { useCallback, useEffect, useState } from "react";
import { Switch } from "@mui/material";
import "./sidebar.css";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";
import TooltipWithIcon from "../../../shared/TooltipWithIcon";
import { useOneClickTrade } from "../../../../hooks/api/workspace";

interface OneClickProps {
  isOpen?: boolean;
}

const OneClick: React.FC<OneClickProps> = ({ isOpen }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isOneClickEnabled, setOneClickEnabled] = useState<boolean | null>(null);
  const { getOneClickTradeAsync, setOneClickTradeAsync } = useOneClickTrade();

  const fetchStatus = useCallback(async () => {
    if (isOneClickEnabled === null) {
      const status = await getOneClickTradeAsync();
      setOneClickEnabled(status);
    }
  }, [isOneClickEnabled, getOneClickTradeAsync]);

  const updateStatus = async (status: boolean) => {
    await setOneClickTradeAsync(status);
    setOneClickEnabled(status);
  };

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const handleToggleChange = () => {
    if (isOneClickEnabled !== null) {
      if (!isOneClickEnabled) {
        setPopupOpen(true);
      } else {
        updateStatus(false);
      }
    }
  };

  const handleAccept = () => { 
    updateStatus(true); 
    setPopupOpen(false); 
  };

  const handleReject = () => { 
    setPopupOpen(false); 
  };

  if (isOneClickEnabled === null) return null;

  return (
    <>
      <div className="switch-tooltip-container">
        <Switch
          className="toggle-switch"
          checked={isOneClickEnabled}
          onChange={handleToggleChange}
          sx={{
            '& .MuiSwitch-track': {
              backgroundColor: 'var(--Grey-Grey-4, #C2CACE)',
              opacity: 1,
            },
          }}
        />
        <span className="toggle-label">1-Click</span>
        <TooltipWithIcon
          tooltipText="1-click trading enables users to execute a trade with a single click without displaying a secondary confirmation of the trade. Order submissions are based on settings and
selections the user configures prior to execution."
          isHeader
        />
      </div>
      {isPopupOpen && (
        <ConfirmCancelDialog
          open={isPopupOpen}
          onClose={handleReject}
          onConfirm={handleAccept}
          title="Confirm 1-Click Activation"
          message="Activating 1-click trading will suppress all confirmation messages. All orders will be submitted directly into the market."
          cancelText="Reject"
          confirmText="Accept"
        />
      )}
    </>
  );
};

export default OneClick;
