import { useCallback, useState } from "react";
import { EconodayEvents, EconodayEvent } from "../../types";
import axios, { AxiosError } from "axios";
import Notification from "../../components/shared/notification/Notification";

export const useFetchEconodayEvents = () => {
  const [events, setEvents] = useState<EconodayEvents[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const loadEconodayEvents = useCallback(async (
    start?: string,
    end?: string
  ) => {
    try {
      const params = {
        start_date:start,
        end_date:end
      }
      const response = await axios.get<EconodayEvents[]>("econoday_events", {params});
      setEvents(response.data);
    } catch (error: any) {
      setError(error);
      setEvents([]);
      Notification.error(`Could not load econoday events, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { events, error, loaded, loadEconodayEvents };
};

export const useFetchEconodayEvent = () => {
  const [event, setEvent] = useState<EconodayEvent | null>();
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const loadEconodayEvent = useCallback(async (
    econodayEventId: number
  ) => {
    try {
      const response = await axios.get<EconodayEvent>(`econoday_events/${econodayEventId}`);
      setEvent(response.data);
    } catch (error: any) {
      setError(error);
      setEvent(null);
      Notification.error(`Could not load econoday event, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { event, error, loaded, loadEconodayEvent };
};
