export const chipBaseStyles = {
  margin: "4px",
  minHeight: "24px",
  maxHeight: "24px",
  padding: "0px",
  gap: "0px",
  fontFamily: "Averta-Regular",
  fontSize: "12px",
};
export const actionButtonStyles = {
  width: "100%",
  fontFamily: "Averta-Regular",
  fontSize: "11px",
  fontWeight: 700,
  lineHeight: "22px",
  letterSpacing: "0",
};
