import { Box, Button, Modal } from "@mui/material";
import "./PriceLadder.css";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { Instrument } from "../../../cqg-api/models/Instrument";
import {
  OrderSide,
  OrderType,
  SELECTED_MOBILE_WIDGET_INSTRUMENT,
  Widget,
  WidgetAction,
  WidgetType,
} from "../../../types";
import SelectedContractInfo from "../../TradeTicketDrawer/SelectedContractInfo";
import { subscribeToInstrumentsDepths } from "../../../utils/subscriptions";
import { capitalizeKeys, mapInstrumentData } from "../../../utils/utils";
import { buyMarketButtonStyles, sellMarketButtonStyles } from "./PriceLadderStyles";
import PriceLadderBook from "./PriceLadderBook/PriceLadderBook";
import PriceLadderFooter from "./PriceLadderFooter";
import useScrollHandlers from "./hooks/useScrollHandler";
import TimeInForce from "../../TradeTicketDrawer/components/TimeInForce";
import FlattenAllPositions from "./FlattenAllPositions";
import CancelAllOrders from "./CancelAllOrders";
import PositionsCount from "./PostionsCount";
import SelectContractMobile from "./SelectContractMobile";
import SelectContract from "./SelectContract";
import OrderSettingsImage from "./OrderSettings";
import { ocoInitial } from "./types";
import { CQGEnvironment } from "../../../cqg-api/services/CQGEnvironment";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { makeOrder } from "../../../cqg-api/controllers/tradingTicketController";
import Notification from "../../shared/notification/Notification";
import { useAvailableProductsContext } from "../../../AvailableProductsProvider";
import OrderSettingsDialog from "./OrderSettingsDialog/OrderSettingsDialog";
import { Account } from "../../../cqg-api/models/Account";
import { IFormInput, TradeFormData } from "../../TradeTicketDrawer/types";
import Quantity from "../../TradeTicketDrawer/components/Quantity";
import { createOrderStateObject, getPrice } from "../../TradeTicketDrawer/utils";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import { clearTradeTicketValue, setPanelDataValue, setWidgetDataValue } from "../../../redux/products/chartWidget";
import { useDispatch } from "react-redux";
import { useMarketData } from "../../../MarketDataProvider";
import { getFromLS } from "../../../storage";
import { useOneClickTrade } from "../../../hooks/api/workspace";
import useOpenTradeTicker from "./hooks/useOpenTradeTicker";
import useResizer from "./hooks/useResizer";
import { orderTypeValue } from "./utils";
import OrderSettingsDialogMobile from "./mobile/OrderSettingsDialogMobile";
import TradeTicketMobile from "../../TradeTicketDrawer/TradeTicketMobile";
import { mobileTradeTicketStyles } from "../availableProducts/mobile/mobileTradeTicketStyles";
import { CQGConstants } from "../../../cqg-api/constants/CQGConstants";
import { OrderChain_Side } from "../../../cqg-api/proto/traderouting_1";

interface PriceLadderProps {
  workspaceId: number;
  widget: Widget;
  isMobile?: boolean;
}
const PriceLadder = ({ workspaceId, widget, isMobile }: PriceLadderProps) => {
  const dispatch = useDispatch();
  const { getOneClickTradeAsync } = useOneClickTrade();
  const [openOrderSettings, setOpenOrderSettings] = useState(false);
  const [oco, setOco] = useState(ocoInitial);
  const [viewTradeTicket, setViewTradeTicket] = useState(false);
  const [externalData, setExternalData] = useState<TradeFormData>();
  const onCloseTradeClick = () => setViewTradeTicket(false);
  const [instrument, setInstrument] = useState<Instrument>();

  const { products, monthlyContractsByDisplayNameMap } = useAvailableProductsContext();

  const onClose = () => {
    setOpenOrderSettings(false);
  };

  const { centerDivRef, priceLadderBookRef, scrollUpHandler, scrollDownHandler, scrollToCenterHandler } =
    useScrollHandlers();
  const userSelectedInstrument = isMobile && getFromLS(SELECTED_MOBILE_WIDGET_INSTRUMENT)?.[WidgetType.PriceLadder];
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm<IFormInput>({
    mode: "all",
    defaultValues: {
      contract: userSelectedInstrument ?? widget.contractIds?.[0],
      timeInForce: 1,
      side: OrderSide.Buy,
      orderType: OrderType.Lmt,
      quantity: 1,
      limitPrice: 0,
      stopPrice: 0,
      // rawLimitPrice:0,
      rawStopPrice: 0,
      oco: ocoInitial,
    },
  });

  const selectedSymbol = watch("contract");
  const quantityValue = watch("quantity");
  const timeInForce = watch("timeInForce");

  const { panelRef, isSmallPanel } = useResponsivePanel(350);

  const monthlyContracts = useMemo(() => {
    if (!monthlyContractsByDisplayNameMap || Object.keys(monthlyContractsByDisplayNameMap).length === 0) {
      return [];
    }

    let symbolDisplayName = selectedSymbol;
    let contracts: any = monthlyContractsByDisplayNameMap[symbolDisplayName];

    // If no contract returned, then it may be closed or no longer valid. Extract list from the base symbol.
    if (!contracts) {
      // Extract the base contract.
      const cmeBaseSymbol = symbolDisplayName.slice(0, -2);
      let cqgBaseSumbol = "";

      for (const key in products) {
        const prod = products[key];
        if (prod.cmeSymbol === cmeBaseSymbol) {
          cqgBaseSumbol = prod.cqgSymbol;
          break;
        }
      }

      contracts = monthlyContractsByDisplayNameMap[cqgBaseSumbol];
    }

    return contracts?.monthlyContracts ?? [];
  }, [selectedSymbol, monthlyContractsByDisplayNameMap]);

  const selectedContract = useMemo(() => {
    if (monthlyContracts?.length == 0) return null;

    let contract = monthlyContracts.find((contract: Instrument) => contract.displayName === selectedSymbol);

    // If no contract returned, then it may be closed or no longer valid, then return the first contract.
    if (!contract) {
      contract = monthlyContracts[0];
      setValue("contract", contract.displayName);
    }

    return contract;
  }, [monthlyContracts, selectedSymbol]);

  const [contractRealTimeData, setContractRealTimeData] = useState<Instrument>({ ...selectedContract });

  const { realTimeMarketData } = useMarketData();

  useEffect(() => {
    if (selectedContract) {
      setContractRealTimeData({ ...selectedContract });
      subscribeToInstrumentsDepths([selectedContract.contractId] as number[]);
    }
  }, [selectedContract]);

  useEffect(() => {
    if (selectedContract) {
      setContractRealTimeData(mapInstrumentData(selectedContract, realTimeMarketData));
    }
  }, [realTimeMarketData, selectedContract]);

  const onOrderSettingsImageClick = () => {
    setOpenOrderSettings(true);
  };

  const placeOrder = (order: OrderState, account: Account) => {
    CQGEnvironment.Instance.cqgService.placeOrder(order, account);
  };

  const { openTradeTicket } = useOpenTradeTicker(workspaceId, watch("oco"), quantityValue, timeInForce);

  const createOrderHandler = async (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    if (isMobile) {
      handleOpenMobileTradeTicket(price, orderSide, orderType);
      return;
    }
    const isOneClickEnabled = await getOneClickTradeAsync();
    if (!isOneClickEnabled) {
      openTradeTicket(selectedContract, orderType, orderSide, null);
      return;
    }
    try {
      // Extract account and data values
      const accountId = CQGEnvironment.cqgAccountAuthInfo?.accountId as number;
      const accounts = CQGEnvironment.Instance.accountsManager.getAccount(accountId);
      const data = getValues();
      const buildOrderState = createOrderStateObject(
        data,
        null,
        selectedContract,
        orderSide,
        selectedContract?.correctPriceScale,
      );
      // need to simplify profitLossStrategy for Trade Ticket and Price Ladder
      const profitLossStrategy = {
        profit: {
          price: getPrice(data.oco?.[orderSide]?.takeProfit, true, price, selectedContract, orderSide),
        },
        loss: {
          price: getPrice(data.oco?.[orderSide]?.stopLoss, false, price, selectedContract, orderSide),
        },
      };
      const profitLossStrategyValue =
        orderType === OrderType.Mkt ? buildOrderState.profitLossStrategy : profitLossStrategy;

      // Create order
      const orderState = {
        ...buildOrderState,
        side: orderSide,
        orderType: orderTypeValue(orderType, orderSide, price, contractRealTimeData),
        limitPrice: price,
        stopPrice: price,
        strikePrice: price,
        rawLimitPrice: price,
        rawStopPrice: price,
        profitLossStrategy: profitLossStrategyValue,
      };
      const newOrder = makeOrder(orderState);

      // Attempt to place the order
      await placeOrder(newOrder as OrderState, accounts);
    } catch (error) {
      console.error("Order placement failed:", error);
      Notification.error(`Order could not be placed`);
    }
  };

  const placeMarkerOrder = (orderSide: OrderSide) => {
    createOrderHandler(orderSide, OrderType.Mkt, null);
  };

  const onSaveOcoSettings = (data: any) => {
    setOco(data);
    const dataWithTitle = {
      data: data,
      widgetName: widget.id,
      contractMonth: selectedSymbol,
    };
    dispatch(setWidgetDataValue(dataWithTitle));
    onClose();
  };

  useEffect(() => {
    if (openOrderSettings) {
      setValue("oco", oco);
    }
  }, [oco, openOrderSettings, setValue]);

  const validOco = () => {
    if (
      oco?.[OrderSide.Buy].takeProfit.isSelected ||
      oco?.[OrderSide.Buy].stopLoss.isSelected ||
      oco?.[OrderSide.Sell].takeProfit.isSelected ||
      oco?.[OrderSide.Sell].stopLoss.isSelected
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (widget?.widgetData?.contractMonth === selectedSymbol) {
      setOco(capitalizeKeys(widget.widgetData?.data) as typeof ocoInitial);
    } else {
      setOco(ocoInitial);
    }
  }, [widget?.widgetData, selectedSymbol]);

  useEffect(() => {
    if (!isMobile) {
      const panelData = {
        widgetId: widget.id,
        title: selectedSymbol,
      };
      dispatch(setPanelDataValue(panelData));
    }
  }, [dispatch, isMobile, selectedSymbol, widget?.id]);

  const widgetHeight = widget?.panelHeight;
  const layoutHeight = Number(widget?.layoutHeight);
  useResizer({ widgetHeight, layoutHeight });

  const handleOpenMobileTradeTicket = (
    price: number | null,
    orderSide?: OrderSide,
    orderType?: OrderType,
    widgetAction?: WidgetAction,
    order?: OrderState,
  ) => {
    dispatch(clearTradeTicketValue());
    let newOrder;

    if (widgetAction === WidgetAction.AmendOrder && order) {
      newOrder = order.clone();
      newOrder.limitPrice = price;
      newOrder.displayLimitPrice = price;
      newOrder.stopPrice = price;
      newOrder.displayStopPrice = price;
      setExternalData({
        widgetData: {
          action: WidgetAction.AmendOrder,
          data: newOrder,
        },
      });
      setInstrument(order.instrument);
    } else {
      if (orderType && orderSide) {
        newOrder = {
          ...contractRealTimeData,
          orderType: CQGConstants.getOrderTypeByString(
            orderTypeValue(orderType, orderSide, price, contractRealTimeData),
          ),
          side: orderSide === OrderSide.Buy ? OrderChain_Side.BUY : OrderChain_Side.SELL,
          size: quantityValue,
          oco: oco,
          limitPrice: price,
          stopPrice: price,
          timeInForce,
        };
        setExternalData({
          widgetData: {
            action: WidgetAction.CreateOrder,
            data: newOrder,
          },
        });
      }
      setInstrument(contractRealTimeData);
    }
    setViewTradeTicket(true);
  };

  return (
    <>
      <div ref={panelRef} className="price-ladder-container">
        <div className="price-ladder-top">
          {isMobile ? (
            <SelectContractMobile
              widgetType={WidgetType.PriceLadder}
              updateValue={(displayName: string) => setValue("contract", displayName)}
              value={watch("contract")}
            />
          ) : (
            <SelectContract control={control} name="contract" contracts={monthlyContracts} />
          )}
          <PositionsCount contract={selectedContract}></PositionsCount>
          <OrderSettingsImage onClick={onOrderSettingsImageClick} validOco={validOco} />
        </div>

        <div className="price-ladder-top">
          <div className="price-ladder-width">
            <TimeInForce control={control} />
          </div>

          <FlattenAllPositions contract={selectedContract}></FlattenAllPositions>

          <CancelAllOrders contract={selectedContract}></CancelAllOrders>
        </div>

        <div className="price-ladder-top">
          <div className="price-ladder-width">
            <Button variant="contained" sx={buyMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Buy)}>
              BUY MKT
            </Button>
          </div>
          <Quantity isSmallPanel={isSmallPanel} control={control} setValue={setValue} errors={errors} />
          <div className="price-ladder-width">
            <Button variant="contained" sx={sellMarketButtonStyles} onClick={() => placeMarkerOrder(OrderSide.Sell)}>
              SELL MKT
            </Button>
          </div>
        </div>

        <div style={{ margin: "10px" }}>
          <SelectedContractInfo selectedContract={contractRealTimeData} isPriceLadder />
        </div>

        <PriceLadderBook
          centerDivRef={centerDivRef}
          priceLadderBookRef={priceLadderBookRef}
          quantityValue={quantityValue}
          realTimeInstrument={contractRealTimeData}
          createOrderHandler={createOrderHandler}
          contract={selectedContract}
          oco={oco}
          getOneClickTradeAsync={getOneClickTradeAsync}
          openTradeTicket={openTradeTicket}
          isMobileView={isMobile}
          onOpenMobileTradeTicket={handleOpenMobileTradeTicket}
          watch={watch}
          setValue={setValue}
        />

        <PriceLadderFooter
          scrollUpHandler={scrollUpHandler}
          scrollToCenterHandler={scrollToCenterHandler}
          scrollDownHandler={scrollDownHandler}
          contract={selectedContract}
        />
      </div>
      {openOrderSettings && !isMobile && (
        <OrderSettingsDialog
          open={openOrderSettings}
          onClose={onClose}
          control={control}
          selectedContract={contractRealTimeData}
          watch={watch}
          selectedInstrument={selectedContract}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          onSaveOcoSettings={onSaveOcoSettings}
          clearErrors={clearErrors}
        />
      )}
      {openOrderSettings && isMobile && (
        <OrderSettingsDialogMobile
          open={openOrderSettings}
          onClose={onClose}
          control={control}
          selectedContract={contractRealTimeData}
          watch={watch}
          selectedInstrument={selectedContract}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          onSaveOcoSettings={onSaveOcoSettings}
          clearErrors={clearErrors}
        />
      )}
      {viewTradeTicket && (
        <Modal open={viewTradeTicket} onClose={onCloseTradeClick}>
          <Box sx={mobileTradeTicketStyles}>
            <TradeTicketMobile
              tradeFormData={externalData}
              instrument={instrument}
              onCloseTradeClick={onCloseTradeClick}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default PriceLadder;
