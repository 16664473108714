import axios, { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { Workspace } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import { useGetCurrentUser } from "./authentication";
import { getEmptyWorkspace, getUserCreatedFlagWorkspace, getWorkspaceData } from "../../app-data/defaultWorkspace";


const url = "member_workspaces";

export const useFetchWorkspaces = () => {
  const [workSpaces, setWorkSpaces] = useState<Workspace[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const currentUser = useGetCurrentUser();
  const { createWorkspaceAsync } = useCreateWorkspace();
  const { updateWorkspaceAsync } = useUpdateWorkspace();
  let createOrderTimeout: NodeJS.Timeout;

  const loadWorkSpaces = useCallback(async () => {
    try {
      if(!currentUser.id){
        console.error("UserID not found");
        return; 
      }
      const response = await axios.get<Workspace[]>(`get_member_workspaces/${currentUser.id}`);

      if (response?.data?.length > 0) {
        const workspaces = response?.data.filter(ws => ws.title !== "--FLAG-USER-CREATED-WORKSPACE--")
        setWorkSpaces(workspaces);
        return;
      }

      const createDefaultWorkspace = async () => {
        // Add this flag workspace to the user's workspace list
        await createWorkspaceAsync(getUserCreatedFlagWorkspace(), (workspace: Workspace) => { });

        // Create default workspace
        const emptyWorkspace = getEmptyWorkspace();
        createWorkspaceAsync(emptyWorkspace, (workspace: Workspace) => {
          workspace.data = getWorkspaceData(workspace.id!);

          updateWorkspaceAsync(workspace, (updatedWorkspace: Workspace) => {
            setWorkSpaces([workspace])
          });
        });
      };

      // Initially this hook may run multiple times
      clearTimeout(createOrderTimeout);
      createOrderTimeout = setTimeout(() => {
        createDefaultWorkspace();
      }, 1000);

    } catch (error: any) {
      setError(error);
      setWorkSpaces([]);
      Notification.error(`Could not load workspaces, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, [currentUser.id]);

  return { workSpaces, error, loaded, loadWorkSpaces, setWorkSpaces };
};

export const useFetchWorkspaceById = () => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const fetchWorkspaceById = useCallback(async (id: string) => {
    try {
      setLoaded(true);
      const response = await axios.get<Workspace>(`${url}/${id}`);
      setWorkspace(response.data);
    } catch (error: any) {
      setError(error);
      alert("error..");
    } finally {
      setLoaded(false);
    }
  }, []);

  return { fetchWorkspaceById, loaded, error, workspace };
};

export const useCreateWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [createWorkspacedLoaded, setCreateWorkspaceLoaded] = useState(true);
  const currentUser = useGetCurrentUser();

  const createWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      if(!currentUser.id){
        console.error("UserID not found");
        return; 
      }
      try {
        setCreateWorkspaceLoaded(false);
        const response = await axios.post(`member_workspaces?member_id=${currentUser.id}`, { ...workspace });
        onSuccess?.(response?.data);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not create workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setCreateWorkspaceLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { createWorkspaceAsync, error, createWorkspacedLoaded };
};

export const useUpdateWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [updateWorkspaceLoaded, setUpdateWorkspaceLoaded] = useState(true);
  const currentUser = useGetCurrentUser();

  const updateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setUpdateWorkspaceLoaded(false);
        const response: Workspace = await axios.put(`${url}/${workspace.id}`, {
          data: workspace.data,
        });
        // Notification.success("Workspace updated successfully");
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [],
  );

  const updateDefaultWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      if (!currentUser.id) {
        console.error("userID not found");
        return;
      }
      try {
        setUpdateWorkspaceLoaded(false);
        const response = await axios.put(`set_member_default_workspace/${currentUser.id}`, {
          workspace_id: workspace.id,
        });
        onSuccess?.(response?.data);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { updateWorkspaceAsync, updateDefaultWorkspaceAsync, error, updateWorkspaceLoaded };
};

export const useWorkspaceUpdater = () => {
  const [error, setError] = useState<Error | null>(null);
  const [updateWorkspaceLoaded, setUpdateWorkspaceLoaded] = useState(true);

  const updateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setUpdateWorkspaceLoaded(false);
        const response: Workspace = await axios.put(`${url}/${workspace.id}`, {
          title: workspace.title,
        });
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        let errMsg = error?.response?.data?.message || error?.message;
        Notification.error(`Could not update workspace: ${errMsg}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWorkspaceLoaded(true);
      }
    },
    [],
  );

  return { updateWorkspaceAsync, error, updateWorkspaceLoaded };
};

export const useDeleteWorkspace = () => {
  const [error, setError] = useState<Error>();
  const [deleteWorkspaceLoading, setDeleteWorkspaceLoading] = useState(false);

  const deleteWorkspaceAsync = useCallback(
    async (workspaceId: number, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setDeleteWorkspaceLoading(true);
        const response: Workspace = await axios.delete(`${url}/${workspaceId}`);
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not remove workspace, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setDeleteWorkspaceLoading(false);
      }
    },
    [],
  );

  return { deleteWorkspaceAsync, error, deleteWorkspaceLoading };
};

export const useSelectedWorkspace = () => {
  const [error, setError] = useState<Error>();

  const updateSelectedWorkspaceAsync = useCallback(
    async (id: number | undefined, onSuccess?: (response: any) => void, onFail?: () => void) => {
      try {
        const response: Workspace = await axios.put(`set_selected_workspace`, {
          selected_workspace: id,
        });
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        setError(error);
        onFail?.();
      }
    },
    [],
  );

  return { updateSelectedWorkspaceAsync, error };
};

export const useCreateDuplicateWorkspace = () => {
  const [error, setError] = useState<Error | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const createDuplicateWorkspaceAsync = useCallback(
    async (workspace: Workspace, onSuccess?: (response: Workspace) => void, onFail?: () => void) => {
      try {
        setIsCreating(true);
        const response = await axios.post('set_duplicate_workspace', {
          title: workspace.title,
          workspaceId: workspace.id
        });
        onSuccess?.(response?.data);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          Notification.error(`Could not create workspace: ${error.response?.data?.message || error.message}`);
        } else {
          Notification.error(`Unexpected error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
        }
        setError(error as Error);
        onFail?.();
      } finally {
        setIsCreating(false);
      }
    },
    []
  );

  return { createDuplicateWorkspaceAsync, error, isCreating };
};

export const useOneClickTrade = () => {
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [loaded, setLoaded] = useState(false);
  const currentUser = useGetCurrentUser();

  const getOneClickTradeAsync = useCallback(async () => {
    try {
      const response = await axios.get(`get_one_click_trades?member_id=${currentUser?.id}`);
      return response.data.oneClickTradeEnabled;
    } catch (error: any) {
      setError(error);
      Notification.error(`Could not fetch one-click trade status, ${error?.message}`);
    }
  }, [currentUser?.id]);

  const setOneClickTradeAsync = useCallback(
    async (oneClickTradeEnabled: boolean) => {
      try {
        const response = await axios.post(`set_one_click_trades?member_id=${currentUser?.id}`, {
          one_click_trade_enabled: oneClickTradeEnabled,
        });

        if (oneClickTradeEnabled) {
          Notification.success(`One-click trade status updated successfully`);
        }

        return response.data;
      } catch (error: any) {
        setError(error);
        Notification.error(`Could not update one-click trade status, ${error?.message}`);
      } finally {
        setLoaded(true);
      }
    },
    [currentUser?.id],
  );

  return { error, loaded, getOneClickTradeAsync, setOneClickTradeAsync };
};
