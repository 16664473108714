import "../widgets/widgets.css";
import MenuItem from "../../../../common/userinfo/Menutem";
import { Settings, Create, LiveHelp, SvgIconComponent } from "@mui/icons-material";
import TradePlan from "../../tradePlan/TradePlan";
import { DashboardTrophyIcon, DiscoverIcon, EducationIcon, TradeLogIcon } from "../../../../../images/Icons";
import TradeLogComponent from "../../TradeLog/TradeLogComponent";
import Education from "../../education/Education";
import Discover from "../../Discover/Discover";

interface MenuItemType {
  text: string;
  icon?: SvgIconComponent | string | React.ComponentType<any>;
  disabled: boolean;
}

const toolsItems: MenuItemType[] = [
  { text: "Education", icon: EducationIcon, disabled: false },
  { text: "Trade Plan", icon: Create, disabled: false },
  { text: "Trade Log", icon: TradeLogIcon, disabled: false },
  { text: "Settings", icon: Settings, disabled: true },
  { text: "Help", icon: LiveHelp, disabled: true },
];

const challengeItems: MenuItemType[] = [
  { text: "Discover", icon: DiscoverIcon, disabled: true },
  { text: "Dashboard", icon: DashboardTrophyIcon, disabled: true },
];

interface ToolsListProps {
  onMenuItemClick: (component: JSX.Element | null, index: number | null) => void;
  enableMenuItems: () => void;
  activeTabIndex: number | null;
  items: MenuItemType[];
}

const ToolsList = ({ onMenuItemClick, enableMenuItems, activeTabIndex, items }: ToolsListProps) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li
          key={index}
          onClick={() => {
            if (item.disabled) return;

            let component: JSX.Element | null = null;
            if (item.text === "Trade Plan") {
              component = <TradePlan onHandleClose={enableMenuItems} />;
            } else if (item.text === "Trade Log") {
              component = <TradeLogComponent onHandleClose={enableMenuItems} />;
            } else if (item.text === "Education") {
              component = <Education onHandleClose={enableMenuItems} />;
            } else if (item.text === "Discover") {
              component = <Discover onHandleClose={enableMenuItems}/>;
            }
            onMenuItemClick(component, index);
          }}
          style={{
            ...(activeTabIndex === index ? { borderRight: "2px solid var(--primary-main-blue, #006EB6)" } : {}),
            cursor: item.disabled ? "not-allowed" : "pointer", 
          }}
        >
          <MenuItem text={item.text} icon={item.icon} isActive={activeTabIndex === index} />
        </li>
      ))}
    </ul>
  );
};

interface ToolsNavProps {
  onMenuItemClick: (component: JSX.Element | null, index: number | null) => void;
  enableMenuItems: () => void;
  activeTabIndex: number | null;
}

const ToolsNav = ({ onMenuItemClick, enableMenuItems, activeTabIndex }: ToolsNavProps) => (
  <>
    <div className="productsNav">
      <h3 className="heading">CHALLENGE</h3>
      <ToolsList
        onMenuItemClick={onMenuItemClick}
        enableMenuItems={enableMenuItems}
        activeTabIndex={activeTabIndex}
        items={challengeItems}
      />
    </div>
    <div className="productsNav">
      <h3 className="heading">TOOLS</h3>
      <ToolsList
        onMenuItemClick={onMenuItemClick}
        enableMenuItems={enableMenuItems}
        activeTabIndex={activeTabIndex}
        items={toolsItems}
      />
    </div>
  </>
);

export default ToolsNav;
