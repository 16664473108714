import { useEffect } from 'react';

interface UseResizerProps {
  widgetHeight: number | undefined;
  layoutHeight: number;
}

const useResizer = ({ widgetHeight, layoutHeight }: UseResizerProps) => {
  useEffect(() => {
    const targets = document.querySelectorAll(".dv-resize-container");
    const overlay = document.querySelector(".dv-render-overlay") as HTMLElement;

    targets.forEach(target => {
      (target as HTMLElement).style.maxHeight = `${layoutHeight - 10}px`;
    });

    if (overlay) {
      overlay.style.maxHeight = `${layoutHeight}px`;
    }
  }, [layoutHeight]);
};

export default useResizer;