// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watchlist-table--header {
  background-color: #eeeeee;
  height: 36px !important;
}

.watchlist-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}

.watchlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.watchlist-grid-panel {
  flex: 3 1;
  position: relative;
}

.watchlist-header--actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.highlight-row {
  background-color: #EBEDEE !important;
  >div{
    color: #25323C80 !important;
  }
}
.highlight-row:hover {
  opacity: 0.5;
}

.highlight-row:hover::after{
    content: 'Product not in Simulator';
}

.highlight-row::after{
  content: '';
  position: absolute;
  right: 0;
  color: #000000;
  text-align: end;
  font-family: Averta-Regular;
  font-weight: bolder;
  padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/watchlist/Watchlist.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,oCAAoC;EACpC;IACE,2BAA2B;EAC7B;AACF;AACA;EACE,YAAY;AACd;;AAEA;IACI,mCAAmC;AACvC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,cAAc;EACd,eAAe;EACf,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".watchlist-table--header {\n  background-color: #eeeeee;\n  height: 36px !important;\n}\n\n.watchlist-container {\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n  width: 100%;\n}\n\n.watchlist-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0 10px;\n}\n\n.watchlist-grid-panel {\n  flex: 3 1;\n  position: relative;\n}\n\n.watchlist-header--actions {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.highlight-row {\n  background-color: #EBEDEE !important;\n  >div{\n    color: #25323C80 !important;\n  }\n}\n.highlight-row:hover {\n  opacity: 0.5;\n}\n\n.highlight-row:hover::after{\n    content: 'Product not in Simulator';\n}\n\n.highlight-row::after{\n  content: '';\n  position: absolute;\n  right: 0;\n  color: #000000;\n  text-align: end;\n  font-family: Averta-Regular;\n  font-weight: bolder;\n  padding-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
