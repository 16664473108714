import { UserWatchlist, NewUserWatchlist, UpdateUserWatchlist } from "../models/userWatchlist";
import { MessageApiResponse } from "../models/common";
import { apiFeaturedClient as apiClient } from "./apiClient";

const createUserWatchlist = async (data: NewUserWatchlist): Promise<UserWatchlist> => {
  const resp = await apiClient.post<UserWatchlist>("/user-watchlist", data);
  return resp.data;
};

const getUserWatchlists = async (): Promise<UserWatchlist[]> => {
  const resp = await apiClient.get<UserWatchlist[]>("/user-watchlist");
  return resp.data;
};

const getUserWatchlistById = async (watchlistId: number): Promise<UserWatchlist> => {
  const resp = await apiClient.get<UserWatchlist>(`/user-watchlist/${watchlistId}`);
  return resp.data;
};

const updateUserWatchlist = async (watchlistId: number, data: UpdateUserWatchlist): Promise<MessageApiResponse> => {
  const resp = await apiClient.post<MessageApiResponse>(`/user-watchlist/${watchlistId}/PUT`, data);
  return resp.data;
};

const deleteUserWatchlist = async (watchlistId: number): Promise<MessageApiResponse> => {
  const resp = await apiClient.post<MessageApiResponse>(`/user-watchlist/${watchlistId}/DELETE`);
  return resp.data;
};

const followFeaturedWatchlist = async (watchlistId: number): Promise<MessageApiResponse> => {
  const resp = await apiClient.post<MessageApiResponse>(`/user-watchlist/${watchlistId}/follow`);
  return resp.data;
};

const unfollowFeaturedWatchlist = async (watchlistId: number): Promise<MessageApiResponse> => {
  const resp = await apiClient.post<MessageApiResponse>(`/user-watchlist/${watchlistId}/unfollow`);
  return resp.data;
};

export const UserWatchlistService = {
  getUserWatchlists: getUserWatchlists,
  getUserWatchlistById: getUserWatchlistById,
  createUserWatchlist: createUserWatchlist,
  updateUserWatchlist: updateUserWatchlist,
  deleteUserWatchlist: deleteUserWatchlist,
  followFeaturedWatchlist: followFeaturedWatchlist,
  unfollowFeaturedWatchlist: unfollowFeaturedWatchlist,
};
