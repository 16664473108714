import { CryptoHelper } from "./CryptoHelper";
import { getFromSS, removeFromSS, saveToSS } from "./storage";

const globalHash = new Map<string, any>();

export class AppGlobal {
  static getCurrentUser = () => {
    const userDetails = globalHash.get("currentUser") || getFromSS("currentUser");
    return userDetails;
    // const userDetailsObj = CryptoHelper.decrypt(userDetails);
    // return userDetailsObj;
  };

  static setCurrentUser = (currentUser: any) => {
    // const encrypted = AES.encrypt(JSON.stringify(currentUser), ENCRYPTION_KEY).toString();
    globalHash.set("currentUser", currentUser);
    saveToSS("currentUser", currentUser);
  };

  static removeCurrentUser = () => {
    globalHash.delete("currentUser");
    removeFromSS("currentUser");
  }

  static clearAll = () => {
    globalHash.clear();
    sessionStorage.clear();
  }

  static setCMELoginDetails = (cmeToken: string, userId: string) => {
    const cmeLoginDetails = {
      cmeToken,
      userId
    }

    globalHash.set("cmeLoginDetails", cmeLoginDetails);
    saveToSS("cmeLoginDetails", cmeLoginDetails);
  }

  static getCMELoginDetails = () => {
    const cmeLoginDetails = globalHash.get("cmeLoginDetails") || getFromSS("cmeLoginDetails");
    return cmeLoginDetails;
  }

  static removeCMELoginDetails = () => {
    globalHash.delete("cmeLoginDetails");
    removeFromSS("cmeLoginDetails");
  }
}
