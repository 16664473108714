import React, { useCallback } from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import { ChartIcon, OptionWidIcon, PriceLadderGreyIcon } from "../../images/Icons";
import { setChartWidgetValue } from "../../redux/products/chartWidget";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { Widget, WidgetType } from "../../types";

interface CustomMenuProps {
  anchorMenu: HTMLElement | null;
  handleCloseMenu: () => void;
  paramRowData: any;
}

const typographyStyles={
  color: "#081D37",
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.17px",
  fontFamily: "Averta-Regular",
}

const CustomMenu: React.FC<CustomMenuProps> = ({ anchorMenu, handleCloseMenu, paramRowData }) => {
  const dispatch = useDispatch();
 
  

  const handleOpenWidget = useCallback(
    (widgetType: WidgetType, key: number) => {
      let widgetValue: Widget;
      if (!paramRowData) return;
      const contractDisplayName = paramRowData.displayName;
       widgetValue = {
        contractIds: [contractDisplayName],
        id: uuidv4(),
        key,
        title: widgetType,
        widgetName: widgetType,
        widgetType: widgetType,
      };
      if(widgetType === WidgetType.Chart) {
        widgetValue= {
          key: 3,
          title: WidgetType.Chart,
          widgetType: `${paramRowData.displayName} Chart`,
          contractIds: [paramRowData.displayName],
        };
      }
      dispatch(setChartWidgetValue(widgetValue));
      handleCloseMenu();
    },
    [dispatch, handleCloseMenu, paramRowData],
  );

  return (
    <Menu
      anchorEl={anchorMenu}
      open={Boolean(anchorMenu)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiMenu-paper": {
          boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.05)",
          width: "180px",
        },
      }}
    >
      <MenuItem onClick={() => handleOpenWidget(WidgetType.PriceLadder, 4)}>
        <PriceLadderGreyIcon />
        <Typography
          sx={{
            ...typographyStyles,
            marginLeft: "14px",
          }}
        >
          Price Ladder
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => handleOpenWidget(WidgetType.Options, 10)}>
      <span style={{marginLeft: "-3px"}}>
        <OptionWidIcon />
        </span>
        <Typography
          sx={{...typographyStyles, marginLeft: "10px"}}
        >
          Options
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => handleOpenWidget(WidgetType.Chart, 10)}>
      <span style={{marginLeft: "2px"}}>
        <ChartIcon />
      </span>
        <Typography
          sx={{...typographyStyles,marginLeft: "16px"}}
        >
          Chart
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default CustomMenu;
