import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import {
  modalTableCellLabelStyles,
  modalTableCellValuesStyles,
  modalTableContainerStyles,
  modalTableRowStyles,
  modalTableTypographyStyles,
} from "../../marketStyles";

const ModalTable = ({
  rowData,
}: {
  rowData: {
    labels: string[];
    values: (number | JSX.Element | null | undefined)[];
  }[];
}) => {
  return (
    <TableContainer component={Paper} sx={modalTableContainerStyles}>
      <Table aria-label="instrument data">
        <TableBody>
          {rowData.map((row, index) => (
            <>
              <TableRow sx={{ ...modalTableRowStyles, backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#f5f5f5" }}>
                {row?.labels.map((label, i) => (
                  <TableCell sx={modalTableCellLabelStyles} key={`label-${i}`} colSpan={i === 1 ? 2 : 1}>
                    <Typography variant="body1" sx={{ ...modalTableTypographyStyles, color: "rgba(8, 29, 55, 0.87)" }}>
                      {label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={modalTableRowStyles}>
                {row?.values.map((value, i) => (
                  <TableCell
                    sx={modalTableCellValuesStyles}
                    key={`value-${i}`}
                    colSpan={i === 1 ? 2 : 1}
                  >
                    <Typography variant="body2" sx={{ ...modalTableTypographyStyles, color: "rgba(8, 29, 55, 0.6)" }}>
                      {value}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModalTable;
