import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Home from "../components/home/Home";
import PermissionChecker from "../redux/auth/PermissionChecker";
import WidgetHome from "../components/mobileView/WidgetHome";
import { ENV } from "../env";
import { useDispatch } from "react-redux";
import authActions from "../redux/auth/authActions";

const BASE_URL = ENV.APP_BASE_URL;

function PrivateRoute({
  component: Component,
  currentUser,
  ...rest
}: any) {
  const location = useLocation();
  const dispatch = useDispatch();

  const isWidgetsPath = location.pathname.indexOf('/widgets') > -1;
  return (
    <Route
      {...rest}
      render={(props: any) => {
        const permissionChecker = new PermissionChecker(currentUser);

        if (!permissionChecker.isAuthenticated) {
          // If not authenticated the remove entry from store.
          // TODO: I dont like this line here. It doesn't fit in flow.
          // dispatch(authActions.AUTH_LOGOUT);

          return (
            <Redirect
              to={{
                pathname: BASE_URL + "/auth/signin",
                state: { from: location },
              }}
            />
          );
        }
        if (isWidgetsPath) {
          return (
            <WidgetHome {...props}>
              <Component {...props} />
            </WidgetHome>
          );
        }
        return (
          <Home {...props}>
            <Component {...props} />
          </Home>
        );
      }}
    />
  );
}

export default PrivateRoute;
