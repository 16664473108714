import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styled";
import PublicChallengeCard from "./components/PublicChallenge/PublicChallengeCard";
import { titleStyle } from "./components/TradingChallenge/style";

const PublicChallenges: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.publicChallengesContainer}>
      <Typography sx={titleStyle}>Public Challenges Available</Typography>
      <PublicChallengeCard />
    </Box>
  );
};

export default PublicChallenges;
