import { useEffect, useMemo, useState } from "react";
import DataGridTable from "../../shared/DataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useFetchEconodayEvents } from "../../../hooks/api/useEconodayCalender";
import moment from "moment";
import "./index.css"
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import EventDialog from "./EventDialog";
import { EconodayEvents, TIME_ZONE } from "../../../types";
import { getImpact } from "./util";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import mobile from "is-mobile";
import "moment-timezone";
import EconomicDatePicker from "./components/EconomicDatePicker";

const EconodayCalendar = (props: { workspaceId: number }) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
    const { events, loadEconodayEvents } = useFetchEconodayEvents();

    const [open, setOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<EconodayEvents | null>(null);

    useEffect(() => {
        const today = dayjs().format('YYYY-MM-DD');
        loadEconodayEvents(today);
    }, [loadEconodayEvents]);

    const onHandleEventDetail = (event: EconodayEvents) => {
        setOpen(true);
        setEvent(event);
    };

    const isClickable = (event: EconodayEvents) => {
        return !!event.definition || !!event.description || !!event.econodayEventValues.length;
    }

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: "country",
                headerName: "Country",
                flex: 1,
                minWidth: 75,
                sortable: true,
                headerClassName: "positions-table--header",
                renderCell: (params: GridRenderCellParams) => {
                    return <><img alt="" className={`flag flag-${params.row.country.toLowerCase()}`} src="/public/images/blank.gif"/><span className="country">{params.row.country}</span></>
                }
            },
            {
                field: "releasedOn",
                headerName: "Date",
                flex: 1,
                minWidth: 75,
                sortable: true,
                headerClassName: "positions-table--header",
                renderCell: (params: GridRenderCellParams) => moment(params.row.releasedOn).tz(TIME_ZONE).format("MMM DD | hh:mm A")
            },
            {
                field: "name",
                headerName: "Event",
                flex: 1,
                minWidth: 75,
                sortable: true,
                headerClassName: "positions-table--header",
                renderCell: (params: GridRenderCellParams) => isClickable(params.row) ?
                                <Link underline="none" href="#" style={{ color: "#006EB6" }} onClick={() => onHandleEventDetail(params.row)}>{params.row.name}</Link>
                                : <>{params.row.name}</>
            },
            {
                field: "impact",
                headerName: "Impact",
                flex: 1,
                minWidth: 75,
                sortable: true,
                headerClassName: "positions-table--header",
                renderCell: (params: GridRenderCellParams) => getImpact(params.row.eventAttribute)
            },
        ],
        [],
    );

    return (
        <>
            <div className="widget-parent-container">
                <div className="draggableCancelSelector widget-grid-container positions-container">
                    <div className="watchlist-header">
                       <EconomicDatePicker isMobileView={isMobileView} loadEconodayEvents={loadEconodayEvents} />
                    </div>
                    <div className="positions-grid-panel economic-grid-container">
                        <DataGridTable columns={columns} rows={events} noDataMessage="No Data" isRowHeightDyanmic={true} disableSorting={true} disableRowHover={true} enableRowAlternatingColors={true}/>
                    </div>
                </div>
            </div>
            {open && 
              <EventDialog open={open} onClose={() => setOpen(false)} eventId={event?.id} isMobileView={isMobileView} />
            }
        </>
    );
};

export default EconodayCalendar;
