import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Averta-Regular, sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  textAlign: 'left',
  color: "#081D37DE"
}));

export const typographyStyles = {
  fontFamily: 'Averta-Regular',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '1.5px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: 'var(--primary-main-blue, #006EB6)',
};

export const headingTypographyStyles = {
  fontFamily: 'Averta-Regular', 
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24.5px',
  textAlign: 'left',
  textUnderlinePosition: 'from-font',
  textDecorationSkipInk: 'none',
  color: 'var(--text-primary, #081D37)',
};

export const menuStyle = {
  "& .MuiMenu-paper": {
    boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.05)",
    width: "346px",
    height: "392px",
    padding: "16px 24px",
    marginTop: "10px",
    border: "1px solid #0000001F"
  },
}

export const StyledTextTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Averta-Regular',
  fontWeight: 700,
  fontSize: '9px',
  lineHeight: '11.12px',
  letterSpacing: '0.4px',
  marginBottom: '4px',
  color: "#25323C"
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px',
  width: '97%',
  height: '30px',
  border: '1px solid #C2CACE',
  borderRadius: '6px',
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme, alignment }: { theme?: any, alignment: string }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  gap: '4px',
  width: '100%',
  height: '100%',
  backgroundColor: alignment === theme ? '#006EB6' : '#FFFFFF',
  color: alignment === theme ? '#FFFFFF' : '#25323C',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '11px',
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#006EB6',
    borderRadius: '4px',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#006EB6',
  },
  '&:hover': {
    backgroundColor: '#f1f1f1',
  },
}));