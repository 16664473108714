import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import { getFeaturedWatchlist, getWatchlistLocal } from "../../../../redux/featuredWatchlist/featuredWatchlistSelectors";
import { SectionTitle, WatchlistName } from "./styles";
import { FeaturedWatchlist, UserWatchlist } from "../../../../cme-watchlist-api";

type MobileWatchlistDrawerProps = {
  open: boolean;
  onClose: () => void;
  selectWatchlist: (id: number) => void;
  selectFeaturedWatchlist: (id: number) => void;
  selectedWatchlist: UserWatchlist | FeaturedWatchlist;
};
export default function MobileWatchlistDrawer({
  open, onClose, selectFeaturedWatchlist, selectWatchlist, selectedWatchlist,
}: MobileWatchlistDrawerProps) {
  const myWatchlists = useSelector(getWatchlistLocal);
  const featuredWatchlistLocal = useSelector(getFeaturedWatchlist);
  const toggleDrawer = () => () => {
    onClose();
  };
  const getActiveWatchlist = (watchlistId: number) => {
    return selectedWatchlist.watchlistId === watchlistId ? "active" : '';
  };
  const list = () => (
    <Box role="presentation" onKeyDown={toggleDrawer()} sx={{ marginBottom: '10px' }}>
      <SectionTitle>Your Watchlists</SectionTitle>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {myWatchlists.map((watchlist: UserWatchlist) => {
          const isActive = getActiveWatchlist(watchlist?.watchlistId);
          return (
          <WatchlistName
            className={isActive ? "active" : ''}
            onClick={() => selectWatchlist(watchlist.watchlistId)}
            key={watchlist.watchlistId}
          >
            <span>{watchlist.name}</span>
            {isActive && <CheckIcon sx={{fill:'#016FB6'}} />}
          </WatchlistName>
        )})}
      </Box>
      <SectionTitle><StarIcon fontSize="small" /> <span>Featured Watchlists</span></SectionTitle>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {featuredWatchlistLocal.map((watchlist: FeaturedWatchlist) => {
          const isActive = getActiveWatchlist(watchlist?.watchlistId);
          return (
          <WatchlistName
            className={isActive ? "active" : ''}
            onClick={() => selectFeaturedWatchlist(watchlist.watchlistId)}
            key={watchlist.watchlistId}>
              <span>{watchlist.name}</span>
              {isActive && <CheckIcon sx={{fill:'#016FB6'}}/>}
          </WatchlistName>
          )}
        )}
      </Box>
    </Box>
  );

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      {list()}
    </Drawer>
  );
}
