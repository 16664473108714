import React from "react";
import Logo from "../../Logo";
import { Container, LoadingText } from "./Styled";
import { ScreenLoader } from "./ScreenLoaderIcon";
import { Mode_Type } from "../../../../types";

interface LoadingScreenProps {
  mode: Mode_Type;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ mode }) => {
  return (
    <Container>
      <ScreenLoader/>
      <LoadingText variant="h6">
        Switching to {mode}
      </LoadingText>
      <Logo />
    </Container>
  );
};

export default LoadingScreen;