import AES from "crypto-js/aes";
import Crypto from "crypto-js";
import { User } from "./types";
import { AppGlobal } from "./AppGlobal";

export const saveToLS = (key: string, value: any) => {
  if (global.localStorage) {
    global.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getFromLS = (key: string) => {
  var ls;
  if (global.localStorage) {
    try {
      const value = global.localStorage.getItem(key);
      if (!value) return null;
      ls = JSON.parse(value) || null;
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls;
};

export const saveToSS = (key: string, value: any) => {
  if (global.sessionStorage) {
    global.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const getFromSS = (key: string) => {
  var ss;
  if (global.sessionStorage) {
    try {
      const value = global.sessionStorage.getItem(key);
      if (!value) return null;
      ss = JSON.parse(value) || null;
    } catch (e) {
      /*Ignore*/
    }
  }
  return ss;
};

export const removeFromSS = (key: string) => {
  if (global.sessionStorage) {
    global.sessionStorage.removeItem(key);
  }
};
