const prefix = "FEATURED_WATCHLIST";

export const featuredWatchlistActions = {
    FEATURED_WATCHLIST_SET_SUCCESS: `${prefix}_SET_SUCCESS`,
    WATCHLIST_SET_SUCCESS: `WATCHLIST_SET_SUCCESS`,
    WATCHLIST_ADD_PRODUCTS: `WATCHLIST_ADD_PRODUCTS`,
    WATCHLIST_DELETE_PRODUCTS: `WATCHLIST_DELETE_SUCCESS`,
    WATCHLIST_ADD_NEW: `WATCHLIST_ADD_NEW`,
    FEATURED_WATCHLIST_IN_DOCKVIEW_SET: `${prefix}_IN_DOCKVIEW_SET`,
    FEATURED_WATCHLIST_IN_DOCKVIEW_ADD: `${prefix}_IN_DOCKVIEW_ADD`,
    FEATURED_WATCHLIST_IN_DOCKVIEW_REMOVE: `${prefix}_IN_DOCKVIEW_REMOVE`,
    FEATURED_WATCHLIST_SAVE_SELECTED_PRODUCTS: `${prefix}_SAVE_SELECTED_PRODUCTS`,
    dispatchSetFeaturedWatchlist:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.FEATURED_WATCHLIST_SET_SUCCESS,
        payload: data,
      });
    },
    dispatchSetWatchlist:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.WATCHLIST_SET_SUCCESS,
        payload: data,
      });
    },
    dispatchAddProductsToWatchlist:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.WATCHLIST_ADD_PRODUCTS,
        payload: data,
      });
    },
    dispatchDeleteProductsFromWatchlist:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.WATCHLIST_DELETE_PRODUCTS,
        payload: data,
      });
    },
    dispatchAddNewWatchlist:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.WATCHLIST_ADD_NEW,
        payload: data,
      });
    },
    setFeaturedWatchlistInDockView:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.FEATURED_WATCHLIST_IN_DOCKVIEW_SET,
        payload: data,
      });
    },
    addFeaturedWatchlistInDockView:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.FEATURED_WATCHLIST_IN_DOCKVIEW_ADD,
        payload: data
      });
    },
    removeFeaturedWatchlistInDockView:
    (data) =>
    async (dispatch) => {
      dispatch({
        type: featuredWatchlistActions.FEATURED_WATCHLIST_IN_DOCKVIEW_REMOVE,
        payload: data
      });
    },
    saveSelectedProducts:
    (data) =>
      async (dispatch) => {
        dispatch({
          type: featuredWatchlistActions.FEATURED_WATCHLIST_SAVE_SELECTED_PRODUCTS,
          payload: data
        });
      }
};

export default featuredWatchlistActions;
