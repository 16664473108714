import { SELECTED_MOBILE_WIDGET_INSTRUMENT, Widget, WidgetType } from "../../../types";
import DataGridTable from "../../shared/DataGrid";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./Options.css";
import { AvailableProductsContext } from "../../../AvailableProductsProvider";
import { MapInstrumentMarketData2 } from "../../../utils/utils";
import { useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import useHeaderGridColumns from "./hooks/useHeaderGridColumns";
import MobileHeader from "./mobile/MobileHeader";
import { getFromLS } from "../../../storage";
import CustomMenu from "../../shared/CustomMenu";
import { useDispatch } from "react-redux";
import { setPanelDataValue } from "../../../redux/products/chartWidget";

interface HeaderDatagridProps {
  widget: Widget;
  setSelectedInstrument: any;
  selectedInstrument?: any;
  isMobile?: boolean;
}

const HeaderDatagrid = ({ widget, setSelectedInstrument, selectedInstrument, isMobile }: HeaderDatagridProps) => {
  const dispatch = useDispatch();
  const { realTimeMarketData } = useRealTimeMarketData();
  const userSelectedInstrument = isMobile && getFromLS(SELECTED_MOBILE_WIDGET_INSTRUMENT)?.[WidgetType.Options];

  const [gridData, setGridData] = useState<any[]>([]);
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);

  useEffect(() => {
    if (widget.contractIds?.length && monthlyContractsByDisplayNameMap) {
      const contract = monthlyContractsByDisplayNameMap[userSelectedInstrument ?? widget.contractIds?.[0]];
      setSelectedInstrument(contract);
      const monthlyContract = contract?.monthlyContracts?.find(
        (monthlyContract) => monthlyContract.contractId === contract.contractId,
      );
      setGridData([{ ...contract, id: Math.random(), monthlyContract }]);
    }
  }, [monthlyContractsByDisplayNameMap, setSelectedInstrument, userSelectedInstrument, widget.contractIds]);

  useEffect(() => {
    if (gridData?.length) {
      setGridData(MapInstrumentMarketData2(gridData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    if (selectedInstrument?.displayName && !isMobile) {
      const panelData = {
        widgetId: widget.id,
        title: selectedInstrument.displayName,
      };
      dispatch(setPanelDataValue(panelData));
    }
  }, [dispatch, isMobile, selectedInstrument?.displayName, widget.id]);

  const handleRowStateChange = useCallback(
    (id: string, contract: any) => {
      if (typeof contract === "string") {
        const newContract = monthlyContractsByDisplayNameMap[contract];
        setSelectedInstrument(newContract);
      } else {
        setSelectedInstrument(contract);
      }
      setGridData((prevData) =>
        prevData.map((data) =>
          data.cqgSymbol === contract.cqgSymbol
            ? { ...contract, id: Math.random(), monthlyContract: contract, monthlyContracts: data.monthlyContracts }
            : data,
        ),
      );
    },
    [monthlyContractsByDisplayNameMap, setSelectedInstrument],
  );

  const { columns, anchorMenu, paramRowData, handleCloseMenu } = useHeaderGridColumns(handleRowStateChange);

  const getNoDataMessage = useMemo(() => {
    return "No data available";
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileHeader
          rows={gridData}
          selectedInstrument={selectedInstrument}
          handleRowStateChange={handleRowStateChange}
        />
      ) : (
        <DataGridTable
          columns={columns}
          rows={gridData}
          noDataMessage={getNoDataMessage}
          hideVerticalScroll={true}
          isOptionsRow={true}
          disableRowHover={true}
        />
      )}
      {anchorMenu && (
        <CustomMenu anchorMenu={anchorMenu} handleCloseMenu={handleCloseMenu} paramRowData={paramRowData} />
      )}
    </>
  );
};

export default HeaderDatagrid;
