import { Close } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { Mode_Type, USER_MODE } from "../../../../../types";
import { getFromLS } from "../../../../../storage";
import { useMemo } from "react";

const Header = ({ onHandleClose, isMobileView, isMobileClient }: { onHandleClose: () => void; isMobileView: boolean, isMobileClient?: boolean }) => {
  const mode = useMemo(()=> getFromLS(USER_MODE),[]);
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ justifyContent: "space-between" }}
      className={clsx({
        "trade-plan-header-mobile": isMobileView && !isMobileClient,
      })}
    >
      <Typography variant="h6" fontSize={16} fontWeight={600} lineHeight={"28px"} fontFamily={"Averta-Regular"}>
        My Trade Plan ({ mode === Mode_Type.PRACTICE_MODE ? "Practice Account" : "Challenge Account"})
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        {!isMobileView && (
          <Button
            type="submit"
            variant="contained"
            sx={{ height: "24px", width: "55px", fontSize: "12px", background: "#006EB6" }}
          >
            Save
          </Button>
        )}
        {!isMobileClient && <IconButton onClick={onHandleClose} sx={{ marginTop: "-8px" }}>
          <Close />
        </IconButton>}
      </Stack>
    </Stack>
  );
};

export default Header;
