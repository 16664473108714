import { Box, Typography } from "@mui/material";
import { AccountType, Mode_Type } from "../../../../types";

export default function TradeLogHeader({ isMobileView, initialMode }: { isMobileView: boolean; initialMode: AccountType }) {
  return (
    <Box
      className="box-container-trade-log"
      sx={{
        width: "calc(100% - 50px)",
        maxWidth: { md: "100%", lg: "760px" },
      }}
    >
      {!isMobileView && (
        <Typography
          variant="subtitle1"
          className="subtitle-trade-log"
          sx={{
            fontFamily: "Averta-Regular",
            fontWeight: 600,
            fontSize: "11px",
            lineHeight: "17.16px",
            letterSpacing: "1px",
          }}
        >
          {initialMode === AccountType.Simulator ? "Practice Simulator" : "Futures Challenge "} Trade Log
        </Typography>
      )}
      <Typography
        variant="body1"
        className="body-text-trade-log"
        sx={{
          maxWidth: { xs: "100%", sm: "712px" },
          fontFamily: "Averta-Regular",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "17.16px",
          letterSpacing: "0.17px",
        }}
      >
        If your goal is to make money trading futures over the long haul, then embrace what the Trade Log offers—an
        easy, simple-to-review written record of each round-turn trade you make that will help you gauge how your
        choices affect your trading performance.
      </Typography>
      <Typography
        variant="body1"
        className="body-text-trade-log"
        sx={{
          maxWidth: { xs: "100%", sm: "712px" },
          fontFamily: "Averta-Regular",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "17.16px",
          letterSpacing: "0.17px",
        }}
      >
        Expand the line for each leg in the table to enter notes on your trading approach, stop use, trend and
        risk/reward ratio as well as anything else about why you decided to make this trade at this time.
      </Typography>
    </Box>
  );
}
