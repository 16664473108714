import './TreeView.css';

interface TreeViewHeadingProps {
  isMobileView?: boolean;
}

const TreeViewHeading = ({ isMobileView }: TreeViewHeadingProps) => {
  const label2Class = isMobileView ? "label2-mobile" : "label2-desktop";
  const label3Class = isMobileView ? "label3-mobile" : "label3-desktop";

  return (
    <div className="tree-view-heading" onClick={(e) => e.stopPropagation()}>
      <div className='label1'>Contract</div>
      <div className={label2Class}>Change</div>
      <div className={label3Class}>Volume</div>
    </div>
  );
};

export default TreeViewHeading;
