import axios from "axios";
import { useCallback, useState } from "react";
import { Mode_Type, User, USER_MODE } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../redux/auth/authSelector";
import { getFromLS } from "../../storage";
import { ENV } from "../../env";
import { getCookie } from "../../utils/utils";
import { Account, AccountType } from "./types";
import { AppGlobal } from "../../AppGlobal";
import authActions from "../../redux/auth/authActions";

export const useAuthenticateUser = () => {
  const [error, setError] = useState<Error>();
  const [authenticateUserLoaded, setAuthenticateUserLoaded] = useState(false);

  const authenticateUser = useCallback(
    async (login: string, password: string, onSuccess?: (response: User) => void, onFail?: () => void) => {
      try {
        setAuthenticateUserLoaded(true);
        const response = await axios.post(ENV.USER_LOGIN_API_URL + "/users/sign-in", {
          cms_user: {
            login: login,
            password: password,
          },
        });
        if (response?.data.authToken) {
          onSuccess?.(response.data);
        } else {
          Notification.error("username or password is not correct.");
        }
      } catch (error: any) {
        console.error(error);
        Notification.error(`Authentication failed, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setAuthenticateUserLoaded(false);
      }
    },
    [],
  );

  const whoAmI = useCallback(
    async (onSuccess?: (response: User) => void, onFail?: (err: string) => void) => {
      try {
        setAuthenticateUserLoaded(true);
        // TODO: This is quick fix to avoid url caching.
        const userId = getCookie("userId");
        let uri = `${ENV.APP_API_URL}/users/who_am_i?user_id=${userId}&t=${new Date().getTime()}`;
        const response = await axios.get(uri, { withCredentials: true });
        if (response?.data?.authToken) {
          onSuccess?.(response.data);
        }

        if (response.data === 'User not found') {
          onFail?.(response.data);
        }
      } catch (error: any) {
        console.error(error);
        setError(error);
        onFail?.(error?.message);
      } finally {
        setAuthenticateUserLoaded(false);
      }
    },
    [],
  );

  const getUserDetails = useCallback(
    async (userId: string, onSuccess?: (response: User) => void, onFail?: () => void) => {
      try {
        setAuthenticateUserLoaded(true);
        let uri = ENV.USER_LOGIN_API_URL + '/users/get_user_detail/' + userId;
        const response = await axios.get(uri, { withCredentials: true });
        if (response?.data?.authToken) {
          onSuccess?.(response.data);
        }
      } catch (error: any) {
        console.error(error);
        onFail?.();
      }
    }, [],
  );

  return { authenticateUser, error, authenticateUserLoaded, whoAmI, getUserDetails };
};

export const useGetCurrentUser = () => {
  return useSelector(authSelectors.currentUser) || AppGlobal.getCurrentUser();
};

export const useLogout = async () => {
  const dispatch = useDispatch();
  await dispatch(authActions.dispatchLogout());
};

export const useUserDetails = () => {
  const currentUser = useGetCurrentUser();
  const getAccountByMode = (mode: Mode_Type) => {
    if (mode === Mode_Type.PRACTICE_MODE) {
      const account: Account = currentUser.accounts.find((acc: Account) => acc.accountType === AccountType.Simulator);
      return account;
    } else {
      const account: Account = currentUser.accounts.find((acc: Account) => acc.accountType === AccountType.Challenge);
      return account;
    }
  }

  const getAccountByAppMode = () => {
    const appMode = getFromLS(USER_MODE)
    const account: Account = getAccountByMode(appMode);
    return account;
  }

  return { getAccountByMode, getAccountByAppMode }
}
