import InstrumentExpiredScreen from "./InstrumentExpiredScreen";
import ModalTable from "./ModalTable";

export const MarketMobileModalContent = ({
  isInstrumentExpired,
  rowData,
}: {
  isInstrumentExpired: boolean;
  rowData: {
    labels: string[];
    values: (number | JSX.Element | null | undefined)[];
  }[];
}) => {
  if (isInstrumentExpired) {
    return <InstrumentExpiredScreen />;
  }

  return (
    <>
      <ModalTable rowData={rowData} />
      <hr style={{ borderColor: "rgba(0, 0, 0, 0.12)" }} />
    </>
  );
};
