import { produce } from "immer";
import actions from "./featuredWatchlistActions";

const initialData = {
    featuredWatchlist: [],
    watchlistLocal: [],
    featuredWatchlistInDockView: [],
    savedProducts: [],
  };
export const featuredWatchlist = (state = initialData, { type, payload }) => {
    if (type === actions.FEATURED_WATCHLIST_SET_SUCCESS) {
      return produce(state, (draft) => {
        draft.featuredWatchlist = payload;
      }
      );
    }
    if (type === actions.WATCHLIST_SET_SUCCESS) {
      return produce(state, (draft) => {
        draft.watchlistLocal = payload;
      }
      );
    }
    if (type === actions.WATCHLIST_ADD_PRODUCTS) {
      return produce(state, (draft) => {
        const index = draft.watchlistLocal.findIndex(item => item.watchlistId === payload.watchlistId);
        if (index !== -1) {
          draft.watchlistLocal[index] = payload;
        } else {
          draft.watchlistLocal.push(payload);
        }
      });
    }
    if (type === actions.WATCHLIST_DELETE_PRODUCTS) {
      return produce(state, (draft) => {
        draft.watchlistLocal = draft.watchlistLocal.filter(item => item.watchlistId !== payload);
      });
    }
    if (type === actions.WATCHLIST_ADD_NEW) {
      return produce(state, (draft) => {
        draft.watchlistLocal.push(payload);
      });
    }
    if (type === actions.FEATURED_WATCHLIST_IN_DOCKVIEW_SET) {
      return produce(state, (draft) => {
        draft.featuredWatchlistInDockView = payload;
      });
    }
    if (type === actions.FEATURED_WATCHLIST_IN_DOCKVIEW_ADD) {
      return produce(state, (draft) => {
        draft.featuredWatchlistInDockView.push(payload);
      });
    }
    if (type === actions.FEATURED_WATCHLIST_IN_DOCKVIEW_REMOVE) {
      return produce(state, (draft) => {
        draft.featuredWatchlistInDockView = draft.featuredWatchlistInDockView.filter(item => item.title !== payload);
      }
      );
    }
    if (type === actions.FEATURED_WATCHLIST_SAVE_SELECTED_PRODUCTS) {
      return produce(state, (draft) => {
        draft.savedProducts = payload;
      });
    }
    return state;
};
