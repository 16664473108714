import { WatchlistType, Widget, WidgetType } from "../../../types";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product";
import { Settings } from "@mui/icons-material";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { MapTreeData } from "../../../utils/utils";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { subscribeToContracts, unsubscribeContractIds } from "../../../utils/subscriptions";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { useDispatch, useSelector } from "react-redux";
import watchlistSelectors from "../../../redux/watchlist/watchlistSelector";
import WatchlistDataGrid from "./WatchlistDataGrid";
import LoadingComponent from "../../shared/LoadingComponent";
import "./Watchlist.css";
import FilterProducts from "../availableProducts/FilterProducts";
import { Button, IconButton } from "@mui/material";
import watchlistActions from "../../../redux/watchlist/watchlistActions";
import SelectContract from "../availableProducts/SelectContract";
import { Contract } from "../availableProducts/types";
import { v4 as uuidv4 } from "uuid";
import RenderWatchlistCell from "./RenderWatchlistCell";
import { CopyIcon, DeleteIcon, MenuMoreVertIcon } from "../../../images/Icons";
import RenderColorCell from "../availableProducts/RenderColorCell";
import { setSelectedInstrument, setTradeTicketValue } from "../../../redux/products/chartWidget";
import { TimeUtil } from "../../../cqg-api/utils/TimeUtil";
import workspacesActions from "../../../redux/workspaces/workspacesActions";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { AvailableProductsContext } from "../../../AvailableProductsProvider";
import TradeButton from "../../shared/TradeButton";
import CustomMenu from "../../shared/CustomMenu";
import { useMarketData } from "../../../MarketDataProvider";
import { WatchListMode } from "../../home/components/SideMenu/menu-item-drawer/types";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { updateRowPosition } from "./utils";
import { CMEWatchlistAPI, FeaturedWatchlist, UserWatchlist, UserWatchlistService } from "../../../cme-watchlist-api";
import { productMapper } from "../../home/components/SideMenu/menu-item-drawer/utils";
import Notification from "../../shared/notification/Notification";
import { useFetchCMEProducts } from "../../../hooks/api/cmeProduct";

const editWatchlistButtonStyles = {
  height: "27.25px",
  marginTop: "8px",
  color: "#006EB6",
  borderColor: "#006EB6",
  fontWeight: "700",
  borderRadius: "4px",
  fontFamily: "Averta-regular",
  fontSize: "12px",
};

interface RowState {
  [key: string]: Contract;
}

const Watchlist = (props: {
  workspaceId: number;
  workspaceClassName?: string;
  widget: Widget;
  isPopupWindow?: boolean;
}) => {
  const dispatch = useDispatch();
  const { widget } = props;

  const { loadProducts, loaded } = useFetchProducts();
  const { loadCMEProducts, cmeProducts } = useFetchCMEProducts();
  useEffect(() => {
    loadCMEProducts();
  }, [loadCMEProducts]);
  const [watchlist, setWatchlist] = useState<UserWatchlist | FeaturedWatchlist>();
  const { getUserWatchlistById, updateUserWatchlist } = UserWatchlistService;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<any>();
  const { realTimeMarketData } = useMarketData();

  const { monthlyContractsByDisplayNameMap, availableloading, productsMapById } = useContext(AvailableProductsContext);
  const [selectedFilter, setSelectedFilter] = useState("Volume");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const panelRef = useRef(null);
  const open = Boolean(anchorEl);
  const [isDragging, setIsDragging] = useState(false);
  const [notExists, setNotExists] = useState(false);
  const [panelSize, setPanelSize] = useState({ width: 0 });
  const isSmallPanelFilter = panelSize.width < 600;

  const updateLoading = useSelector(watchlistSelectors.getUpdateLoading);
  const watchlistWidget = useSelector(watchlistSelectors.getWidget);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const getWatchlist = useCallback(async () => {
    try {
      setLoading(true);
      if (widget.watchlistType === WatchlistType.User && widget.widgetId) {
        const watchlistItems = await getUserWatchlistById(widget.widgetId);
        setWatchlist(watchlistItems);
      } else {
        if (widget.widgetId) {
          const id =
            widget.widgetId.toString() === "0000"
              ? process.env.REACT_APP_CME_DEFAULT_WORKSPACE_WATCHLIST_ID ||
                window.REACT_APP_CME_DEFAULT_WORKSPACE_WATCHLIST_ID
              : widget.widgetId;
          const watchlistItems = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(id);
          setWatchlist(watchlistItems);
        }
      }
    } catch (e) {
      console.error("Error", e);
      Notification.error("Watchlist does not exist");
      setNotExists(true);
    } finally {
      setLoading(false);
    }
  }, [getUserWatchlistById, widget.watchlistType, widget.widgetId]);

  useEffect(() => {
    if (!widget.widgetId) {
      console.error("Watchalist id not found.");
      return;
    }
    getWatchlist();
  }, [getUserWatchlistById, getWatchlist, widget.watchlistType, widget.widgetId]);

  useEffect(() => {
    const contractIds = watchlist?.products
      ?.map((contract) => monthlyContractsByDisplayNameMap[contract.contractCode]?.contractId)
      .filter((contractId): contractId is number => contractId !== undefined && contractId !== null);

    if (contractIds) subscribeToContracts(contractIds);

    return () => {
      if (contractIds?.length) {
        unsubscribeContractIds(contractIds);
      }
    };
  }, [monthlyContractsByDisplayNameMap, watchlist?.products]);

  const [watchlistData, setWatchlistData] = useState<Instrument[]>([]);


  useEffect(() => {
    const hasMonthlyContracts = Object.keys(monthlyContractsByDisplayNameMap).length;
    if (!hasMonthlyContracts || !watchlist?.products) {
      setWatchlistData([]);
      return;
    }
    if (watchlistData?.length) {
      return;
    }

    const instrumentsWithId = watchlist?.products
      ?.map((product) => {
        const contract = monthlyContractsByDisplayNameMap[product.contractCode];
        if (contract) return { ...contract, id: contract?.contractId };
        const instrument = new Instrument(null);
        instrument.displayName = product.contractCode;
        instrument.cmeSymbolName = product.productName;
        instrument.cqgSymbol = null;
        return { ...instrument, id: uuidv4() };
      })
      .filter(Boolean);
    if (instrumentsWithId) setWatchlistData(instrumentsWithId);
    // knowingly removed watchlist.length dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyContractsByDisplayNameMap, watchlist?.products]);

  useEffect(() => {
    if (watchlistData.length && !isDragging) {
      setWatchlistData(MapTreeData(watchlistData, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData, isDragging]);

  const [rowState, setRowState] = useState<RowState>({});

  const handleRowSelectedContractChange = useCallback(
    (id: string, contract: Contract) => {
      // Update row state
      setRowState((prevState) => ({
        ...prevState,
        [id]: contract,
      }));

      // Map updated contracts based on the selected contract's display name
      const updatedContracts = watchlistData.map((row: any) =>
        row.id === id
          ? {
              ...monthlyContractsByDisplayNameMap[contract.displayName],
              id: uuidv4(),
            }
          : row,
      );

      // Update the watchlist data state
      setWatchlistData(updatedContracts);

      // Subscribe to instrument based on contractId
      subscribeToContracts([contract.contractId]);
    },
    [monthlyContractsByDisplayNameMap, watchlistData],
  );


  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const [paramRowData, setParamRowData] = useState<null | any>(null);

  const openWatchlistMenu = useCallback((event: React.MouseEvent<HTMLElement>, params: any) => {
    setAnchorMenu(event.currentTarget);
    setParamRowData(params?.row);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorMenu(null);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "cmeSymbolName",
        headerName: "Name",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
      },
      {
        field: "monthlyContracts",
        headerName: "Contract",
        flex: 1,
        minWidth: 150,
        sortable: true,
        sortingOrder: ["asc", "desc"],
        headerClassName: "market-table--header",
        renderCell: (params: GridRenderCellParams) => {
          const rowId = params.row.id;
          const selectedContract = rowState[rowId as string] || params.row;
          const isExpired = TimeUtil.isContractExpired(params.row.last_trading_date);

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <SelectContract
                selectedState={selectedContract}
                id={rowId}
                contracts={params.value}
                onSelect={(id: string, contract: Contract) => handleRowSelectedContractChange(id, contract)}
              />
              {isExpired && params.row.cqgymbol && (
                <span style={{ marginLeft: "8px", color: "#C62828", fontSize: "12px" }}>EXPIRED</span>
              )}
            </div>
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        renderCell: (params: GridRenderCellParams) => {
          return <RenderColorCell value={DisplayUtil.toDisplayPrice(params?.row.lastPrice, params.row)} />;
        },
      },
      {
        field: "labelPriceNetChange",
        headerName: "Change",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelOpen",
        headerName: "Open",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelHigh",
        headerName: "High",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelLow",
        headerName: "Low",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        renderCell: RenderWatchlistCell,
      },
      {
        field: "labelTotalVolume",
        headerName: "Volume",
        flex: 1,
        minWidth: 75,
        headerClassName: "watchlist-table--header",
        sortable: true,
        sortingOrder: ["asc", "desc"],
      },
      {
        field: "selected",
        headerName: "Actions",
        headerClassName: "market-table--header",
        flex: 1,
        minWidth: 140,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          if (!params.row.cqgSymbol) {
            return;
          }
          const isExpired = TimeUtil.isContractExpired(params.row.last_trading_date);

          if (isExpired) {
            return (
              <IconButton
                onClick={() => {
                  setOpenConfirmDialog(true);
                  setDeleteRowId(params?.row?.displayName);
                }}
                style={{
                  gap: 4,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                disableRipple
              >
                <DeleteIcon sx={{ fontSize: 15 }} />
                <span
                  style={{
                    fontFamily: "Averta-Regular",
                    fontSize: "11px",
                    fontWeight: 400,
                    color: "#006EB6",
                  }}
                >
                  Remove
                </span>
              </IconButton>
            );
          }
          return (
            <span className="starIcon">
              <TradeButton
                enabled={true}
                handleClick={() => {
                  // setSelectedContract(params.row.monthlyContracts);
                  const tradeTicketWidgetValue: Widget = {
                    key: 3,
                    title: WidgetType.TradeTicket,
                    widgetType: "Sarab Chart",
                  };
                  dispatch(
                    workspacesActions.dispatchSetWorkspace({ workspaceId: props.workspaceId, instrument: params.row }),
                  );
                  dispatch(setTradeTicketValue(tradeTicketWidgetValue));
                  dispatch(
                    setSelectedInstrument({
                      instrument: params.row.monthlyContracts,
                      selected: params.row ?? params.row.monthlyContracts[0],
                    }),
                  );
                  // setOpenDrawer(true);
                }}
                label="TRADE"
              ></TradeButton>
              <IconButton onClick={(event) => openWatchlistMenu(event, params)} sx={{ padding: "5px 10px" }}>
                <MenuMoreVertIcon />
              </IconButton>
            </span>
          );
        },
      },
    ],
    [dispatch, openWatchlistMenu, handleRowSelectedContractChange, props.workspaceId, rowState],
  );

  const handleCloseDialog = () => {
    setDeleteRowId("");
    setOpenConfirmDialog(false);
  };

  const onDeleteProduct = async (e?: any, rowId?: number) => {
    const rowIdToDelete = rowId ?? deleteRowId;

    try {
      // Filter out the product to delete and map remaining display names to a string
      const contractIds: any = watchlistData
        .filter(({ displayName }) => displayName !== rowIdToDelete.toString())
        .map(({ displayName }) => displayName);
      if (widget.widgetId) {
        await updateUserWatchlist(widget?.widgetId, {
          coverImage: "watchlists-masthead.jpg",
          description: "abc",
          watchlistId: widget.widgetId,
          name: widget.widgetName as string,
          products: productMapper(contractIds, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts),
        });
        const watchlistItems = await getUserWatchlistById(widget.widgetId);

        setWatchlist(watchlistItems);
      }
    } catch (e) {
      console.error("Error", e);
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  useEffect(() => {
    const updateList = async () => {
      if (updateLoading && widget.widgetId) {
        try {
          if (widget.watchlistType === WatchlistType.User && widget.widgetId) {
            const watchlistItems = await getUserWatchlistById(widget.widgetId);
            setWatchlist(watchlistItems);
          }
          setLoading(true);
          dispatch(watchlistActions.dispatchUpdateWatchlistLoading(false));
          setWatchlistData([]);
        } catch (e) {
          Notification.error("Something went wrong");
        } finally {
          setLoading(false);
        }
      }
    };
    updateList();
  }, [
    dispatch,
    getUserWatchlistById,
    updateLoading,
    watchlistWidget?.widgetId,
    widget.watchlistType,
    widget?.widgetId,
  ]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setPanelSize({ width: entry.contentRect.width });
      }
    });
    const currentPanel = panelRef.current;
    if (currentPanel) {
      observer.observe(currentPanel);
    }

    return () => {
      if (currentPanel) {
        observer.unobserve(currentPanel);
      }
    };
  }, []);

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditWatchlistClick = () => {
    const selectedContractIds = Array.from(new Set(watchlistData.map((item) => item.displayName)));
    dispatch(
      watchlistActions.dispatchUpdateWatchlist({
        widget,
        selectedTreeItems: selectedContractIds,
        watchlistMode: WatchListMode.Edit_User_Panel,
      }),
    );
    dispatch(watchlistActions.dispatchEditWatchlist(true));
  };

  const handleDuplicateWatchlistClick = () => {
    const selectedContractIds = Array.from(new Set(watchlistData.map((item) => item.displayName)));
    dispatch(
      watchlistActions.dispatchUpdateWatchlist({
        widget,
        selectedTreeItems: selectedContractIds,
        watchlistMode: WatchListMode.Creation,
      }),
    );
    dispatch(watchlistActions.dispatchEditWatchlist(true));
  };

  const handleRowOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const newRows = updateRowPosition(params.oldIndex, params.targetIndex, watchlistData);
      setWatchlistData(newRows);
    },
    [watchlistData],
  );

  return (
    <>
      <div ref={panelRef} className="widget-parent-container">
        {(loading || availableloading) && <LoadingComponent withOverlay />}
        <div className="draggableCancelSelector widget-grid-container watchlist-container">
          <div className="watchlist-header">
            <div>
              {widget.watchlistType === WatchlistType.User && (
                <>
                  <Button
                    disabled={!loaded || notExists || loading}
                    onClick={() => handleEditWatchlistClick()}
                    sx={editWatchlistButtonStyles}
                    variant="text"
                    startIcon={<Settings style={{ height: "14px", width: "14px" }} />}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={!loaded || notExists || loading}
                    onClick={() => handleDuplicateWatchlistClick()}
                    sx={editWatchlistButtonStyles}
                    variant="text"
                    startIcon={<CopyIcon style={{ height: "14px", width: "14px" }} />}
                  >
                    Save a Copy
                  </Button>
                </>
              )}
              {widget.watchlistType === WatchlistType.Featured && (
                <Button
                  disabled={!loaded || loading}
                  onClick={() => handleDuplicateWatchlistClick()}
                  sx={editWatchlistButtonStyles}
                  variant="text"
                  startIcon={<CopyIcon style={{ color: "#006EB6", height: "14px", width: "14px" }} />}
                >
                  Save a Copy
                </Button>
              )}
            </div>
            <div className="watchlist-header--actions">
              <FilterProducts
                selectedAssetType={selectedFilter}
                handleFilterIconClick={handleFilterIconClick}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                setSelectedFilter={setSelectedFilter}
                isSmallPanelFilter={isSmallPanelFilter}
              />
            </div>
          </div>
          <div className="watchlist-grid-panel">
            <WatchlistDataGrid
              watchlistRows={watchlistData}
              columns={columns}
              handleRowOrderChange={handleRowOrderChange}
              onDragStart={(val: boolean) => setIsDragging(val)}
              notExists={notExists}
            />
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={openConfirmDialog}
        handleClose={handleCloseDialog}
        onDelete={onDeleteProduct}
        loading={loading}
        confirmText={"Are you sure you want to remove"}
      />
      {anchorMenu && (
        <CustomMenu anchorMenu={anchorMenu} handleCloseMenu={handleCloseMenu} paramRowData={paramRowData} />
      )}
    </>
  );
};

export default Watchlist;
