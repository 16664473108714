import { TextField, Typography } from "@mui/material";
import TreeViewContainerDescription from "./TreeViewContainerDescription";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TreeView from "./TreeView";
import { TreeViewMode, WidgetType } from "../../types";
import TreeViewChip from "./TreeViewChip";
import { getFilteredAssets, useFilteredAssetKeys, } from "./hooks/useFilteredAssets";
import * as _ from "../../vendors/underscore-esm";
import { useAvailableProductsContext } from "../../AvailableProductsProvider";
import searchImage from './search-icon.png';
import { useMarketData } from "../../MarketDataProvider";

const TreeViewContainer = ({
  widgetType,
  selectedTreeItems,
  onChangeTreeItems,
  errorMessage,
  isMobileView,
  value,
  isTreeExpanded,
  unavailableProductsList
}: {
  widgetType: WidgetType;
  selectedTreeItems: string[];
  onChangeTreeItems: (selectedItems: string[]) => void;
  errorMessage: string | null;
  isMobileView?: boolean;
  value?: string;
  isTreeExpanded?: boolean;
  unavailableProductsList?: { productId: number; contractCode: string }[];
}) => {
  const { realTimeMarketData } = useMarketData();
  const [searchQuery, setSearchQuery] = React.useState("");
  const { assetProductsDictionary, monthlyContractsByDisplayNameMap } = useAvailableProductsContext();
  const [filteredTreeViewDataSource, setFilteredTreeViewDataSource] = useState(getFilteredAssets(assetProductsDictionary, searchQuery));

  const filteredAssetKeys = useFilteredAssetKeys(assetProductsDictionary, selectedTreeItems, isMobileView, value, monthlyContractsByDisplayNameMap, widgetType);
  const [isFilteringApplied , setIsFilteringApplied ] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [hasSearchQueryChanged, setHasSearchQueryChanged] = useState(false);

  const assets = useMemo(() => {
    return Object.keys(filteredTreeViewDataSource ?? {}).sort();
  }, [filteredTreeViewDataSource]);

  useEffect(() => {
    if (searchQuery.trim() && hasSearchQueryChanged) {
      const allItemIds: string[] = assets.reduce((ids: string[], asset: string) => {
        const productIds = filteredTreeViewDataSource[asset]?.map((product) => product.id) || [];
        return [...ids, asset, ...productIds];
      }, []);
      setExpandedItems(allItemIds);
    } else if (hasSearchQueryChanged) {
      setExpandedItems([]);
    }
    setHasSearchQueryChanged(false);
  }, [searchQuery, assets, filteredTreeViewDataSource, hasSearchQueryChanged]);

  useEffect(() => {
    const filteredTreeViewDataSource = getFilteredAssets(assetProductsDictionary, searchQuery);
    setFilteredTreeViewDataSource(filteredTreeViewDataSource);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  useEffect(() => {
    setExpandedItems([]);
  },[widgetType])

  useEffect(() => {
    if (isMobileView && selectedTreeItems[0] === value) {
      setExpandedItems(filteredAssetKeys);
    } else {
      if(filteredAssetKeys.length && !isFilteringApplied && widgetType === WidgetType.Watchlist && isTreeExpanded) {
        setIsFilteringApplied(true);
        setExpandedItems(filteredAssetKeys);
      }
    }
  }, [searchQuery, assets, filteredTreeViewDataSource, isMobileView, filteredAssetKeys, selectedTreeItems, value, isFilteringApplied, widgetType, isTreeExpanded]);

  const handleDelete = React.useCallback(
    (item: string) => {
      // {/* TODO: based on Mode */}
      const filteredTreeViewItems =
        widgetType === WidgetType.PriceLadder
          ? []
          : selectedTreeItems.filter((treeItem) => treeItem !== item);
      onChangeTreeItems(filteredTreeViewItems);
    },
    [onChangeTreeItems, selectedTreeItems, widgetType],
  );

  const onSelect = (selectedItems: string[]) => {
    onChangeTreeItems(selectedItems);
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, itemIds: string[]) => {
    setExpandedItems(itemIds);
  }, []);

  return (
    <>
      <div className="widget-dialog-description">
        {/* TODO: pass from menu drawer */}
        <TreeViewContainerDescription widgetType={widgetType} />
      </div>
      <div className="widget-dialog-search">
        <TextField
          id="title"
          label={<><img src={searchImage} alt="Search" /> Search</>}
          size="small"
          sx={{ width: "100%" }}
          className="basic-info-title-field"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value)
            setHasSearchQueryChanged(true);
          }}
        />
      </div>
      {!isMobileView && 
        <div className="selected-items-container">
          {/* TODO: need to remove widget type in chip */}
          <TreeViewChip selectedTreeItems={selectedTreeItems} handleDelete={handleDelete} widgetType={widgetType} isEnabled={!!_.size(monthlyContractsByDisplayNameMap)} isMobileView={isMobileView} unavailableProductsList={unavailableProductsList} />
        </div>
      }
      {errorMessage && (
        <Typography color="error" variant="caption" sx={{ marginBottom: 1, marginLeft: "24px" }}>
          {errorMessage}
        </Typography>
      )}
      <div className="widget-dialog-menu" data-testid="tree-view">
        <TreeView
          mode={(widgetType === WidgetType.PriceLadder|| widgetType === WidgetType.Options) ? TreeViewMode.SINGLE : TreeViewMode.MULTIPLE}
          selectedItems={selectedTreeItems}
          filteredTreeViewDataSource={filteredTreeViewDataSource}
          expandedItems={expandedItems}
          onSelect={onSelect}
          onExpandedItemsChange={handleExpandedItemsChange}
          isMobileView={isMobileView}
        />
      </div>
    </>
  );
};

export default TreeViewContainer;
