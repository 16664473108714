import { ContractWithProduct, ProductsListProps } from "./types";
import { Pro } from "./styles";
import { ProductItem } from "./ProductItem";
import { useMarketData } from "../../MarketDataProvider";
import { useContext, useEffect, useState } from "react";
import { subscribeToContracts, unsubscribeInstuments } from "../../utils/subscriptions";
import { MapFeaturedData } from "../../utils/utils";
import { AvailableProductsContext } from "../../AvailableProductsProvider";
import { Instrument } from "../../cqg-api/models/Instrument";

export const ProductsList = ({ products, selected, instrumentName, updateSelected, selectedContracts }: ProductsListProps) => {
  const { realTimeMarketData } = useMarketData();
  const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);

  const [contractDataList, setContractDataList] = useState<ContractWithProduct[]>([]);
  const [featuredProducts, setFeaturedProducts] = useState<ContractWithProduct[]>([]);

  useEffect(() => {
    if (!products?.length) return;

    const mappedContracts = products.reduce((acc: any, product) => {
      const contractDetails = product.contractCode && monthlyContractsByDisplayNameMap[product.contractCode];
      acc.push({
        ...contractDetails,
        ...product,
      });

      return acc;
    }, []);

    setContractDataList(mappedContracts);
  }, [monthlyContractsByDisplayNameMap, products]);

  useEffect(() => {
    const validContractData = contractDataList?.filter((contract: any) => contract.contractId);
    const contractIds: number[] = validContractData?.map((contract: any) => contract.contractId);

    if (contractIds?.length) {
      subscribeToContracts(contractIds);
    }

    return () => {
      if (contractIds?.length) {
        unsubscribeInstuments(validContractData as Instrument[]);
      }
    };
  }, [contractDataList]);

  useEffect(() => {
    if (contractDataList && contractDataList.length) {
      setFeaturedProducts(MapFeaturedData(contractDataList, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  if (!products) {
    return null;
  }

  return (
    <Pro>
      {featuredProducts?.map((product: ContractWithProduct) => (
        <ProductItem
          key={product.productId}
          product={product}
          selected={!!selectedContracts.find((contract) => contract.product.contractCode === product.contractCode)}
          updateSelected={updateSelected}
          instrumentName={instrumentName}
        />
      ))}
    </Pro>
  );
};
