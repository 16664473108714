import { Modal, Box } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import ContractChip from "./ContractChip";
import ModalFooter from "./ModalFooter";
import { modalBoxContainstyles } from "../../marketStyles";
import ModalHeader from "./ModalHeader";
import { DisplayUtil } from "../../../../../cqg-api/utils/DisplayUtil";
import { useRealTimeMarketData } from "../../../../../cqg-api/hooks/ServiceHooks";
import { mapContractData } from "../../../../../utils/utils";
import { TimeUtil } from "../../../../../cqg-api/utils/TimeUtil";
import { MarketMobileModalContent } from "./MarketMobileModalContent";
import TradeTicketMobile from "../../../../TradeTicketDrawer/TradeTicketMobile";
import { mobileTradeTicketStyles } from "../mobileTradeTicketStyles";
import { Instrument } from "../../../../../cqg-api/models/Instrument";
import { ContractWithProduct } from "../../../../featuredWatchlist/types";
 interface MarketMobileModalProps {
  open: boolean;
  onClose: () => void;
  instrumentName?: string | null;
  contract: Instrument[];
  openTradeWindow: () => void;
  onCloseTradeClick?: () => void;
  viewTradeTicket?:boolean;
  selectedInstrument? : ContractWithProduct;
}

const MarketMobileModal = ({ open, onClose, instrumentName, contract, openTradeWindow,onCloseTradeClick,viewTradeTicket,selectedInstrument }: MarketMobileModalProps) => {
  const { realTimeMarketData } = useRealTimeMarketData();
  const [selectedContract, setSelectedContract] = useState<Instrument | null>(null);

  const handleContractSelect = (selected: Instrument) => {
    setSelectedContract(selected);
  };

  useEffect(() => {
    if (!open || !contract?.length) return;
  
    const matchingContract = contract.find(contractItem => contractItem.displayName === selectedInstrument?.displayName) || contract[0];
    setSelectedContract(matchingContract);
  }, [contract, open, selectedInstrument]);
  

  useEffect(() => {
    if (selectedContract) {
      setSelectedContract(mapContractData(selectedContract, realTimeMarketData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData]);

  const changeCell = (row: any) => {
    const color = row?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    const sign = row?.labelPriceNetChange > 0 ? "+" : "";
    if (row?.labelPriceNetChange) {
      return (
        <div style={{ color }}>
          <span>
            {`${sign}${row?.labelPriceNetChange?.toFixed(2)} (${sign}${row?.labelPriceNetChangePercent?.toFixed(2)}%)`}
          </span>
        </div>
      );
    }

    return <span></span>;
  };
  const yesterdaySettlement = useMemo(
    () =>
      parseFloat(
        DisplayUtil.toDisplayPrice(selectedContract?.yesterdaySettlement ?? 0, selectedContract as any)?.toString() ||
          "",
      ) || 0,
    [selectedContract],
  );

  const rowData = useMemo(
    () => [
      {
        labels: ["Last Price", "Change", "Volume"],
        values: [
          DisplayUtil.toDisplayPrice(selectedContract?.lastPrice as number, selectedContract as any),
          changeCell(selectedContract),
          selectedContract?.labelTotalVolume,
        ],
      },
      {
        labels: ["Prior Settle", "High", "Low"],
        values: [yesterdaySettlement, selectedContract?.labelHigh, selectedContract?.labelLow],
      },
    ],
    [yesterdaySettlement, selectedContract],
  );
 
  const isInstrumentExpired = useMemo(() => {
    return TimeUtil.isContractExpired(selectedContract?.last_trading_date ?? 0);
  }, [selectedContract?.last_trading_date]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalBoxContainstyles}>
        <ModalHeader instrumentName={instrumentName} onClose={onClose} />
        <ContractChip
          contract={contract}
          selectedContract={selectedContract}
          handleContractSelect={handleContractSelect}
          isInstrumentExpired={isInstrumentExpired}
        />
        {selectedContract && (
          <>
            <MarketMobileModalContent isInstrumentExpired={isInstrumentExpired} rowData={rowData} />
            <ModalFooter
              openTradeWindow={openTradeWindow}
              selectedContract={selectedContract}
              isInstrumentExpired={isInstrumentExpired}
            />
          </>
        )}
        {viewTradeTicket && (
                <Modal open={viewTradeTicket} onClose={onCloseTradeClick}>
                  <Box sx={mobileTradeTicketStyles}>
                    <TradeTicketMobile instrument={selectedContract} onCloseTradeClick={onCloseTradeClick} />
                  </Box>
                </Modal>
              )}
      </Box>
    </Modal>
  );
};

export default MarketMobileModal;
