import { Account, AccountType, Mode_Type, User } from "../types";

export const getStreamingAccount = (currentUser: User, mode: Mode_Type) => {
  // Ensure `currentUser.accounts` is always an array
  const accounts = Array.isArray(currentUser?.accounts) ? currentUser.accounts : [];

  if (accounts.length === 0) return null;

  // Return the single account if there's only one
  if (accounts.length === 1) return accounts[0];

  // Find the simulator account
  let simulatorAccount: Account | undefined;
  if( mode === Mode_Type.CHALLENGE_MODE ) {
    simulatorAccount = accounts.find((account: Account) => account?.accountType === AccountType.Challenge);
  } else {
    simulatorAccount = accounts.find((account: Account) => account?.accountType === AccountType.Simulator);
  }
  if (!simulatorAccount) console.error("No simulator account found, streaming may not work");
  return simulatorAccount;
};

export const getUserFromAuthReponse = (data: any) => {
  return {
    accessToken: data.authToken,
    id: data.id,
    accounts: data.accounts,
    selectedWorkspace: data.selectedWorkspace,
    firstName: data.firstName,
    lastName: data.lastName,
  };
};
