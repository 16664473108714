export const InstrumentExpiredScreenStyles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  
    title: {
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "Averta-Regular !important",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000000",
      marginTop: "10px",
      marginBottom: "10px",
      maxWidth: "190px",
      margin:"auto"
    },
  
    tag: {
      backgroundColor: "#FFFFFF !important",
      border: "0.5px solid #FF003D !important",
      color: "#FF003D !important",
      padding: "4px !important",
      fontSize: "12px",
      fontWeight: 600,
      marginTop: "10px",
    },
  };