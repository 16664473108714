import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { WatchlistType, Widget, WidgetType, Workspace } from "../../../../../types";
import { useState, useMemo } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import TreeViewContainer from "../../../../tree-view/TreeViewContainer";
import "../../../../tree-view/TreeViewContainer.css";
import TreeViewContainerButtonTitle from "../../../../tree-view/TreeViewContainerButtonTitle";
import WatchlistManager from "./WatchlistManager";
import { WatchListMode } from "./types";
import DrawerComponent from "@mui/material/Drawer";
import watchlistActions from "../../../../../redux/watchlist/watchlistActions";
import { useDispatch, useSelector } from "react-redux";
import { AvailableProductsContext } from "../../../../../AvailableProductsProvider";
import { v4 as uuidv4 } from "uuid";
import { actionButtonStyles, drawerStyle } from "./menuItemDrawerStyles";
import EditConfirmation from "./EditConfirmation";
import { areArraysIdentical, productMapper, updateWidgetName } from "./utils";
import clsx from "clsx";
import {
  CMEWatchlistAPI,
  FeaturedWatchlist,
  Product,
  UserWatchlist,
  UserWatchlistService,
} from "../../../../../cme-watchlist-api";
import ErrorSuccess from "./ErrorSuccess";
import { useProductList, useUnavailableProductsList} from "./hooks/useProductList";
import { MarketDataProvider } from "../../../../../MarketDataProvider";
import { setPanelDataValue } from "../../../../../redux/products/chartWidget";
import Notification from "../../../../shared/notification/Notification";
import { getFeaturedWatchlist, getUserSavedProducts, getWatchlistLocal } from "../../../../../redux/featuredWatchlist/featuredWatchlistSelectors";
import featuredWatchlistActions from "../../../../../redux/featuredWatchlist/featuredWatchlistActions";
import { useFetchCMEProducts } from "../../../../../hooks/api/cmeProduct";
import LoadingComponent from "../../../../shared/LoadingComponent";

type MenuDrawerProps = {
  onClose: () => void;
  onAddToWorkspaceRequest: (widget: Widget) => void;
  widgetToAdd: Widget;
  open: boolean;
  activeWorkspace?: Workspace;
  selectedTreeItems?: string[];
  getWatchlistMode?: WatchListMode;
};

const MenuItemDrawer: React.FC<MenuDrawerProps> = (props) => {
  const dispatch = useDispatch();
  const { widgetToAdd, onClose, onAddToWorkspaceRequest, open } = props;
  const widgetType = widgetToAdd.widgetType as WidgetType;
  const [watchlistTypeGlobal, setWatchlistTypeGlobal] = useState<WatchlistType>(WatchlistType.User);
  const [selectedTreeItems, setSelectedTreeItems] = useState<string[]>([]);
  const [unavailableProductsList, setUnavailableProductsList] = useState<{ productId: number; contractCode: string }[]>([]);
  const { productsMapById, monthlyContractsByDisplayNameMap, productMapByCMESymbol } = React.useContext(AvailableProductsContext);
  const { createUserWatchlist, getUserWatchlists, deleteUserWatchlist, updateUserWatchlist, getUserWatchlistById } =
    UserWatchlistService;
  const { getProductsList } = useProductList(monthlyContractsByDisplayNameMap);
  const { getUnavailableProductsList } = useUnavailableProductsList(monthlyContractsByDisplayNameMap);
  const savedProducts = useSelector(getUserSavedProducts);
  const { loadCMEProducts, cmeProducts } = useFetchCMEProducts();
  React.useEffect(() => {
    loadCMEProducts();
  }, [loadCMEProducts]);

  React.useEffect(() => {
    setSelectedTreeItems([]);
    setIsTreeExpanded(false);
  },[widgetType])

  // TODO: watchlist error handling
  const [hasWatchlistError, setHasWatchlistError] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updateSucess, setUpdateSucess] = useState(false);
  const [watchlistName, setWatchlistName] = useState("");
  const [watchlistMode, setWatchListMode] = useState<WatchListMode>(WatchListMode.ViewList);
  const [featureWatchlist, setFeatureWatchlists] = useState<FeaturedWatchlist[]>([]);
  const [loading, setLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [loadingMyWatchlist, setLoadingMyWatchlist] = useState(false);
  const featuredWatchlistLocal = useSelector(getFeaturedWatchlist);
  const [loadingEditMyWatchlist, setLoadingEditMyWatchlist] = useState(false);
  const [isTreeExpanded, setIsTreeExpanded] = useState(false);
  const myWatchlists = useSelector(getWatchlistLocal);
  const widgetDialogContainerRef = React.useRef<HTMLDivElement>(null);

  const handleEditWatchlist = React.useCallback(async () => {
    if (widgetToAdd.widgetId) {
      try {
        setIsEditLoading(true);
        let watchlistItems;
        if(widgetToAdd.watchlistType === WatchlistType.User) {
          watchlistItems = await getUserWatchlistById(widgetToAdd.widgetId);
        } else {
          const widgetId =
          widgetToAdd.widgetId.toString() === "0000"
              ? process.env.REACT_APP_CME_DEFAULT_WORKSPACE_WATCHLIST_ID ||
                window.REACT_APP_CME_DEFAULT_WORKSPACE_WATCHLIST_ID
              : widgetToAdd.widgetId;
          watchlistItems = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(widgetId);
        }
        if (watchlistItems.products) {
          setSelectedTreeItems(watchlistItems.products.map(contracts => contracts.contractCode));
          dispatch(featuredWatchlistActions.saveSelectedProducts(watchlistItems.products));
          setUnavailableProductsList(getUnavailableProductsList(watchlistItems.products) as any);
          setEditSelectedWatchlist(watchlistItems);
          dispatch(featuredWatchlistActions.dispatchAddProductsToWatchlist(watchlistItems));
        }
        setIsTreeExpanded(true);
      } catch (e) {
        Notification.error("Something went wrong");
      } finally {
        setIsEditLoading(false);
      }
    }
  }, [widgetToAdd.widgetId, widgetToAdd.watchlistType, getUserWatchlistById, getUnavailableProductsList, dispatch]);

  React.useEffect(() => {
    setWatchlistName("");
    setWatchListMode(WatchListMode.ViewList);
    setHasWatchlistError(false);
    if (props.selectedTreeItems?.length && props.getWatchlistMode === WatchListMode.Edit_User_Panel) {
      setWatchListMode(WatchListMode.Edit_User_Panel);
    }
    if (props.selectedTreeItems?.length && props.getWatchlistMode === WatchListMode.Creation) {
      setWatchListMode(WatchListMode.Creation);
    }
    handleEditWatchlist();
    setWatchlistName(widgetToAdd.widgetName ?? "");
  }, [handleEditWatchlist, props.getWatchlistMode, props.selectedTreeItems, widgetToAdd]);

  React.useEffect(() => {
    const getFeaturedWatchlist = async () => {
      if (widgetToAdd.widgetType !== WidgetType.Watchlist) return;
      const getFeaturedWatchlistApi = async () => {
        const data = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlists();
        const updatedData = await Promise.all(
          data.map(async (featuredWatchlist) => {
            const watchlistData = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(
              featuredWatchlist.watchlistId,
            );
            featuredWatchlist.products = watchlistData.products;
            return featuredWatchlist;
          }),
        );
        return updatedData;
      };
      const response = await getFeaturedWatchlistApi();
      const responseWithProductAvailable = response.map(watchlistItem => {
        return {
            ...watchlistItem,
            products: watchlistItem.products &&  watchlistItem.products.map(product => ({
                ...product,
                isAvailableProduct: productMapByCMESymbol[product.productCode] ? true : false
            }))
        };
      });

      dispatch(featuredWatchlistActions.dispatchSetFeaturedWatchlist(responseWithProductAvailable));
      setFeatureWatchlists(responseWithProductAvailable);
    };
    featuredWatchlistLocal.length === 0 ? getFeaturedWatchlist() : setFeatureWatchlists(featuredWatchlistLocal);
  }, [dispatch, featuredWatchlistLocal, productMapByCMESymbol, widgetToAdd.widgetType]);

  const handleWatchlistNameChange = (watchlistName: string) => {
    setWatchlistName(watchlistName);
  };

  const handleChangeWatchErrorState = (isErrorState: boolean) => {
    setHasWatchlistError(isErrorState);
  };

  const handleChangeTreeItems = (selectedItems: string[]) => {
    setSelectedTreeItems(selectedItems);
    if (isUpdated) {
      setIsUpdated(false);
      setUpdateSucess(false);
    }
  };

  const selectedMonthlyContracts = useMemo(() => {
    const productIds = Object.keys(productsMapById);
    return selectedTreeItems.filter((item) => !productIds.includes(item.toString()));
  }, [productsMapById, selectedTreeItems]);

  const treeViewErrorMessage = useMemo(() => {
    const stringCount = selectedMonthlyContracts?.filter((item) => typeof item === "string").length;
    if (stringCount === 7 && widgetType === WidgetType.Chart) {
      return "Display of 7 charts is not supported";
    } else if (stringCount > 8 && widgetType === WidgetType.Chart) {
      return "You have selected maximum number of charts supported";
    }
    return null;
  }, [widgetType, selectedMonthlyContracts]);
  const handleActionButton = async () => {
    if (!widgetToAdd) {
      throw new Error("Widget not found");
    }

    // TODO: need to handle this by api only
    if (watchlistMode === WatchListMode.Edit) {
      setLoading(true);
      try {
        if (editSelectedWatchlist) {
          const selectedWatchlist = myWatchlists.find((w) => w.watchlistId === editSelectedWatchlist.watchlistId);
          await updateUserWatchlist(editSelectedWatchlist?.watchlistId, {
            coverImage: editSelectedWatchlist.coverImage,
            description: editSelectedWatchlist.description,
            watchlistId: editSelectedWatchlist.watchlistId,
            name: watchlistName,
            products: productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts),
          });
          dispatch(featuredWatchlistActions.dispatchAddProductsToWatchlist({...selectedWatchlist,name: watchlistName, products: productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts)}));
          setIsUpdated(true);
          setUpdateSucess(true);
        }
      } catch (e) {
        console.log("Something went wrong", e);
        Notification.error("Something went wrong");
      } finally {
        setLoading(false);
      }
      return;
    }
    const widget = {
      ...widgetToAdd,
      contractIds: selectedMonthlyContracts || [],
      ...(widgetToAdd.widgetId && { widgetId: widgetToAdd.widgetId }),
      id: uuidv4(),
      ...((watchlistTypeGlobal === WatchlistType.User || watchlistTypeGlobal === WatchlistType.Featured) && {
        watchlistType: watchlistTypeGlobal,
      }),
    };

    if (
      widget.widgetType === WidgetType.PriceLadder ||
      widget.widgetType === WidgetType.Options ||
      widget.widgetType === WidgetType.Chart
    ) {
      const selectedContractKey = selectedMonthlyContracts?.[0];
      const contract = monthlyContractsByDisplayNameMap?.[selectedContractKey];
      if (contract) {
        widget.widgetName = updateWidgetName(contract.displayName, widget.widgetType);
      }
    }

    if (widget.widgetType !== WidgetType.Watchlist) {
      setLoading(true);
      try {
        await onAddToWorkspaceRequest(widget);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
      return;
    }

    if (widget.widgetId && watchlistMode !== WatchListMode.Creation && editSelectedWatchlist) {
      try {
        setLoading(true);
        await updateUserWatchlist(editSelectedWatchlist?.watchlistId, {
          coverImage: editSelectedWatchlist.coverImage,
          description: editSelectedWatchlist.description,
          watchlistId: editSelectedWatchlist.watchlistId,
          name: watchlistName,
          products: productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts),
        });
        const panelData = {
          widgetId: widget.widgetId,
          title: watchlistName,
          widgetType: WidgetType.Watchlist,
        };
        dispatch(featuredWatchlistActions.dispatchAddProductsToWatchlist({...editSelectedWatchlist, name: watchlistName, products: productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts)}));
        dispatch(setPanelDataValue(panelData));
        dispatch(watchlistActions.dispatchUpdateWatchlistLoading(true));
        onDrawerCloseClick();
      } catch (e) {
        console.error(e);
        Notification.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    } else {
      // TODO: need to verify
      const isduplicateName = myWatchlists?.findIndex((watchlist) => watchlist.name === watchlistName);
      const isduplicateFeaturedName = featureWatchlist?.findIndex((watchlist) => watchlist.name === watchlistName);
      if (isduplicateName !== -1 || isduplicateFeaturedName !== -1) {
        setHasWatchlistError(true);
        return;
      }
      try {
        setLoading(true);
        const items = productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts);
        const data = {
          name: watchlistName,
          description: "abc",
          coverImage: "watchlists-masthead.jpg",
          products: items,
        };
        const userWatchlist = await createUserWatchlist(data as any);
        widget.widgetId = userWatchlist.watchlistId;
        widget.widgetName = watchlistName;
        onAddToWorkspaceRequest(widget);
        dispatch(featuredWatchlistActions.dispatchAddNewWatchlist(userWatchlist));
      } catch (e) {
        console.error(e);
        Notification.error("Something went wrong");
      } finally {
        setLoading(false);
      }
      return;
    }
  };

  const shouldRenderTreeView =
    widgetType === WidgetType.Watchlist &&
    (watchlistMode === WatchListMode.ViewList || watchlistMode === WatchListMode.UnKnown)
      ? false
      : true;

  // Determine if the action button should be disabled
  const isActionButtonDisabled = useMemo(() => {
    // disable if no tree items are selected
    if (watchlistName.trim() === "" && widgetType === WidgetType.Watchlist) {
      return selectedTreeItems.length === 0 || watchlistName.trim() === "";
    }
    return selectedTreeItems.length === 0;
  }, [selectedTreeItems.length, watchlistName, widgetType]);

  const handleWatchListModeChange = React.useCallback((watchlistMode: WatchListMode, wType?: WatchlistType) => {
    if (wType) {
      setWatchlistTypeGlobal(wType);
    }
    setWatchListMode(watchlistMode);
    if(wType === WatchlistType.User) {
      setIsTreeExpanded(false);
      setSelectedTreeItems([]);
    }
    if (watchlistMode === WatchListMode.ViewList) {
      setSelectedTreeItems([]);
    }
  }, []);

  const onDrawerCloseClick = () => {
    if (watchlistMode === WatchListMode.Edit) {
      dispatch(watchlistActions.dispatchEditWatchlist(false));
    }
    onClose();
  };

  const [isEditConfirmation, setIsEditConfirmation] = useState(false);

  const handleEditConfirmation = () => {
    dispatch(watchlistActions.dispatchEditWatchlist(false));
    setEditSelectedWatchlist(undefined);
    setSelectedTreeItems([]);
    setWatchlistName("");
    setHasWatchlistError(false);
    setWatchListMode(WatchListMode.ViewList);
    setIsEditConfirmation(false);
    setUpdateSucess(false);
    setLoadingEditMyWatchlist(false);
    setIsTreeExpanded(false);
  };

  React.useEffect(() => {
    if (widgetToAdd.widgetType !== WidgetType.Watchlist) return;
    const getUserDefinedWatchlists = async () => {
      try {
        setLoadingMyWatchlist(true);
        const UserWatchlists = await getUserWatchlists();
        dispatch(featuredWatchlistActions.dispatchSetWatchlist(UserWatchlists));
      } catch (e) {
        console.error(e);
        Notification.error("Something went wrong");
      } finally {
        setLoadingMyWatchlist(false);
      }
    };
    if (myWatchlists.length === 0) {
      getUserDefinedWatchlists();
    }
  }, [dispatch, getUserWatchlists, myWatchlists.length, widgetToAdd.widgetType]);

  const handleLeftActionButton = async () => {
    if (watchlistMode === WatchListMode.Edit) {
      if (isUpdated) {
        handleEditConfirmation();
        return;
      }
      if (
        !areArraysIdentical(
          getProductsList(editSelectedWatchlist?.products as Product[]) ?? props.selectedTreeItems ?? [],
          selectedTreeItems,
        )
      ) {
        setIsEditConfirmation(true);
        return;
      }

      handleEditConfirmation();
      return;
    }
    if (watchlistMode === WatchListMode.Creation) {
      const data = {
        name: watchlistName,
        description: "abc",
        coverImage: "watchlists-masthead.jpg",
        products: productMapper(selectedTreeItems, monthlyContractsByDisplayNameMap, productsMapById, cmeProducts, savedProducts),
      };
      try {
        setLoading(true);
        const isduplicateName = myWatchlists?.findIndex((watchlist) => watchlist.name === watchlistName);
        const isduplicateFeaturedName = featureWatchlist?.findIndex((watchlist) => watchlist.name === watchlistName);
        if (isduplicateName !== -1 || isduplicateFeaturedName !== -1) {
          setHasWatchlistError(true);
          return;
        }
        await createUserWatchlist(data as any);
        const UserWatchlists = await getUserWatchlists();
        dispatch(featuredWatchlistActions.dispatchSetWatchlist(UserWatchlists));
        setWatchListMode(WatchListMode.ViewList);
        setWatchlistName("");
        setSelectedTreeItems([]);
        setHasWatchlistError(false);
      } catch (e) {
        console.error("Something went wrong", e);
        Notification.error("Something went wrong");
      } finally {
        setLoading(false);
      }
      return;
    }
    onClose();
  };

  const backHandler = () => {
    if (
      !isUpdated &&
      !areArraysIdentical(
        getProductsList(editSelectedWatchlist?.products as Product[]) ?? props.selectedTreeItems ?? [],
        selectedTreeItems,
      )
    ) {
      setIsEditConfirmation(true);
      return;
    }

    handleEditConfirmation();
  };

  const [editSelectedWatchlist, setEditSelectedWatchlist] = useState<UserWatchlist | FeaturedWatchlist |undefined>(undefined);

  const onEditWatchlistClick = async (watchlist: UserWatchlist) => {
    try {
      setLoadingEditMyWatchlist(true);
      const selectedWatchlist = myWatchlists.find((w) => w.watchlistId === watchlist.watchlistId);
      if (selectedWatchlist && selectedWatchlist.products.length > 0) {
        setSelectedTreeItems(getProductsList(selectedWatchlist.products) as any);
        setEditSelectedWatchlist(selectedWatchlist);
        setWatchlistName(selectedWatchlist.name);
        setWatchListMode(WatchListMode.Edit);
        setIsTreeExpanded(true);
        return;
      }
      const watchlistItems = await getUserWatchlistById(watchlist.watchlistId);
      if (watchlistItems.products) {
        setSelectedTreeItems(getProductsList(watchlistItems.products) as any);
        setEditSelectedWatchlist(watchlistItems);
        setWatchlistName(watchlist.name);
        setWatchListMode(WatchListMode.Edit);
        setIsTreeExpanded(true);
        dispatch(featuredWatchlistActions.dispatchAddProductsToWatchlist(watchlistItems));
      }
      setLoadingEditMyWatchlist(false);
    } catch (e) {
      Notification.error("Something went wrong");
    setLoadingEditMyWatchlist(false);
    }
  };

  const onAddToWorkspaceClick = async (widget: Widget) => {
    try {
      const isEmpty = (products?: any[]) => !products || products.length === 0;
      if (widget.widgetId) {
        if (widget.watchlistType === WatchlistType.User) {
          const watchlistItems = await getUserWatchlistById(widget.widgetId);
          if (isEmpty(watchlistItems.products)) {
            return Notification.error("This watchlist is empty");
          }
          return onAddToWorkspaceRequest(widget);
        }

        if (widget.watchlistType === WatchlistType.Featured) {
          const watchlistData = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(widget.widgetId);
          if (isEmpty(watchlistData.products)) {
            return Notification.error("This watchlist is empty");
          }
          return onAddToWorkspaceRequest(widget);
        }
      }
    } catch (e) {
      Notification.error("Something went wrong");
    }
  };

  const onCopyWatchlistClick = async (watchlist: UserWatchlist) => {
    try {
      const watchlistItems = await getUserWatchlistById(watchlist.watchlistId);
      if (watchlistItems.products) {
        setSelectedTreeItems(getProductsList(watchlistItems.products) as any);
        setEditSelectedWatchlist(watchlistItems);
        setWatchlistName(watchlist.name);
        setWatchListMode(WatchListMode.Creation);
        setIsTreeExpanded(true);
      }
    } catch (e) {
      Notification.error("Something went wrong");
    }
  };

  React.useEffect(() => {
    if (WatchListMode.Creation === watchlistMode || WatchListMode.Edit === watchlistMode) {
      if (widgetDialogContainerRef.current) {
        widgetDialogContainerRef.current.scrollTop = 0; 
      }
    }
  }, [watchlistMode]);

  return (
    <>
      <DrawerComponent sx={drawerStyle} open={open} onClose={onDrawerCloseClick}>
        {isEditLoading && <LoadingComponent />}
        <div>
          <div
            className={clsx({
              "widget-dialog-container": true,
              "widget-dialog-container--isEditConfirmation": isEditConfirmation,
            })}
            ref={widgetDialogContainerRef}
          >
            <div className="widget-dialog-header">
              <Header widgetType={widgetType} watchlistMode={watchlistMode} backHandler={backHandler} />
              <CloseIcon className="widget-dialog-remove" onClick={onDrawerCloseClick} />
            </div>

            <ErrorSuccess
              hasWatchlistError={hasWatchlistError}
              updateSucess={updateSucess}
              setHasWatchlistError={setHasWatchlistError}
              setUpdateSucess={setUpdateSucess}
            />

            {widgetType === WidgetType.Watchlist && (
              <WatchlistManager
                onAddToWorkspaceClick={onAddToWorkspaceClick}
                watchlistMode={watchlistMode}
                onWatchListModeChange={handleWatchListModeChange}
                onWatchlistNameChange={handleWatchlistNameChange}
                watchlistName={watchlistName}
                onEditWatchlistClick={onEditWatchlistClick}
                myWatchlists={myWatchlists}
                deleteUserWatchlist={deleteUserWatchlist}
                onCopyWatchlistClick={onCopyWatchlistClick}
                featuredWatchlist={featureWatchlist}
                setSelectedTreeItems={setSelectedTreeItems}
                loadingMyWatchlist={loadingMyWatchlist}
                loadingEditMyWatchlist={loadingEditMyWatchlist}
              />
            )}

            {shouldRenderTreeView && (
              <>
                <MarketDataProvider>
                  <TreeViewContainer
                    widgetType={widgetType}
                    selectedTreeItems={selectedTreeItems}
                    onChangeTreeItems={handleChangeTreeItems}
                    errorMessage={treeViewErrorMessage}
                    data-testid="tree-view"
                    isTreeExpanded={isTreeExpanded}
                    unavailableProductsList={unavailableProductsList}
                  />
                </MarketDataProvider>

                <div className="widget-dialog-actions">
                  <Button
                    sx={{
                      ...actionButtonStyles,
                      border: "1px solid #006EB6",
                    }}
                    onClick={handleLeftActionButton}
                    variant="outlined"
                    disabled={(isActionButtonDisabled && watchlistMode === WatchListMode.Creation) || loading}
                  >
                    {widgetType === WidgetType.Watchlist && watchlistMode === WatchListMode.Creation
                      ? "SAVE WATCHLIST"
                      : widgetType === WidgetType.Watchlist && watchlistMode === WatchListMode.Edit
                        ? "BACK"
                        : "Cancel"}
                  </Button>

                  <Button
                    onClick={handleActionButton}
                    sx={{ ...actionButtonStyles, color: "#fff" }}
                    variant="contained"
                    disabled={isActionButtonDisabled || !!treeViewErrorMessage || isUpdated || loading}
                  >
                    <TreeViewContainerButtonTitle
                      widgetType={widgetType}
                      isWatchlistEditMode={
                        watchlistMode === WatchListMode.Edit || watchlistMode === WatchListMode.Edit_User_Panel
                      }
                    />
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {isEditConfirmation && (
          <EditConfirmation
            setIsEditConfirmation={setIsEditConfirmation}
            handleEditConfirmation={handleEditConfirmation}
          />
        )}
      </DrawerComponent>
    </>
  );
};

export default MenuItemDrawer;
