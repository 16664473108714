import { useCallback, useState } from "react";
import { CMEProduct } from "../../types";
import { AxiosError } from "axios";
import Notification from "../../components/shared/notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import productsActions from "../../redux/products/productActions";
import { getCMEProducts } from "../../redux/products/productSelector";
import { apiFeaturedClient } from "../../cme-watchlist-api/api/apiClient";

export const useFetchCMEProducts = () => {
  const savedProducts = useSelector(getCMEProducts);
  const [cmeProducts, setCMEProducts] = useState<Record<string, CMEProduct>>(savedProducts);
  const dispatch = useDispatch();
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const loadCMEProducts = useCallback(async () => {
    try {
      if (savedProducts.length < 1) {
        const response = await apiFeaturedClient.get<any>("/product-slate");
        const data = response.data.products.reduce((acc: any, item: any) => {
          acc[item.prodCode] = item;
          return acc;
        }, {});
        setCMEProducts(data);
        dispatch(productsActions.dispatchSetCMEProduct(data));
      }
    } catch (error: any) {
      setError(error);
      setCMEProducts({});
      Notification.error(`Could not load cmeProducts, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, [dispatch, savedProducts.length]);

  return { cmeProducts, error, loaded, loadCMEProducts, setCMEProducts };
};
