import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import CustomLoadable from "../components/shared/CustomLoadable";
import { privateRoutes, publicRoutes } from "./routes";
import PublicRoute from "./PublicRoute";
import { useGetCurrentUser } from "../hooks/api/authentication";
import { ENV } from "../env";

function RoutesComponent(props: any) {
  const BASE_URL = ENV.APP_BASE_URL;
  const ROOT_URL = BASE_URL || "/";

  const currentUser = useGetCurrentUser();
  return (
    <Switch>
      {privateRoutes.map((route: any) => (
        <PrivateRoute
          key={route.path}
          path={route.path}
          currentUser={currentUser}
          component={CustomLoadable({
            loader: route.loader,
          })}
          exact={Boolean(route.exact)}
        />
      ))}
      {publicRoutes.map((route: any) => (
        <PublicRoute
          key={route.path}
          path={route.path}
          currentUser={currentUser}
          component={CustomLoadable({
            loader: route.loader,
          })}
          exact={Boolean(route.exact)}
        />
      ))}
    </Switch>
  );
}

export default RoutesComponent;
