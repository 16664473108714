import { StrikePriceOptionsController } from "../../../../cqg-api/controllers/StrikePriceOptionsController";
import clsx from "clsx";
import { StrikePriceIcon } from "../../../../images/Icons";
import { Box, Modal } from "@mui/material";
import { useCallback, useState } from "react";
import TradeTicketMobile from "../../../TradeTicketDrawer/TradeTicketMobile";
import { InstrumentsManager } from "../../../../cqg-api/services/InstrumentsManager";
import { mobileTradeTicketStyles } from "../../availableProducts/mobile/mobileTradeTicketStyles";
import { WidgetAction } from "../../../../types";
import * as Order from "../../../../cqg-api/proto/order_2";
import { TradeFormData } from "../../../TradeTicketDrawer/types";

const MobileOptionsTable = ({
  rows,
  controller,
  selectedInstrument,
}: {
  rows: any;
  controller: StrikePriceOptionsController | null;
  selectedInstrument: any;
}) => {
  const [viewTradeTicket, setViewTradeTicket] = useState(false);
  const [instrument, setInstrument] = useState<any>();
  const [externalData, setExternalData] = useState<TradeFormData>();
  const onCloseTradeClick = () => setViewTradeTicket(false);
  const openTradeTicketHandler = useCallback(
    (row: any, putCall?: "P" | "C") => {
      const instrument = {
        // TODO: need to verify options getInstrument
        ...InstrumentsManager.getInstrument(row.id),
        cqgSymbol: selectedInstrument?.cqgSymbol,
        displayName: selectedInstrument?.displayName,
        strikePrice: row.strikePrice,
      };
      setExternalData({
        action: WidgetAction.Options,
        widgetData: {
          data: {
            ...row,
            putCallValue: putCall,
            orderType: Order.Order_OrderType.ORDER_TYPE_LMT,
          },
        },
      });
      setInstrument(instrument);
      setViewTradeTicket(true);
    },
    [selectedInstrument?.cqgSymbol, selectedInstrument?.displayName],
  );
  return (
    <>
      <table className="mobile-options-grid-table">
        <tr>
          <th className="mobile-options-grid--calls" colSpan={3}>
            CALLS
          </th>
          <th className="mobile-options-grid--strikePrice">
            <StrikePriceIcon />
          </th>
          <th className="mobile-options-grid--puts" colSpan={3}>
            PUTS
          </th>
        </tr>
        <tr className="mobile-options-grid--sub-row">
          <td>IMP VOL</td>
          <td>BID</td>
          <td>OFFER</td>
          <td className="mobile-options-grid--sub-row-strike">STRIKE</td>
          <td>BID</td>
          <td>OFFER</td>
          <td>IMP VOL</td>
        </tr>
        {rows?.map((row: any) => {
          return (
            <tr className="mobile-options-grid--data-row">
              <td className="mobile-options-grid--data-imp-vol">{row?.callsImpVol}</td>
              <td onClick={() => openTradeTicketHandler(row, "C")}>
                <div
                  className={clsx({
                    "mobile-options-grid--data-bid": true,
                    "mobile-options-grid--data--border-none": !row?.callsBid,
                  })}
                >
                  {row?.callsBid}
                </div>
              </td>
              <td onClick={() => openTradeTicketHandler(row, "C")}>
                <div
                  className={clsx({
                    "mobile-options-grid--data-offer": true,
                    "mobile-options-grid--data--border-none": !row?.callsOffer,
                  })}
                >
                  {row?.callsOffer}
                </div>
              </td>
              <td
                className={clsx({
                  "mobile-options-grid--data-strikePrice": true,
                  "mobile-options-grid--data-strikePrice--atm": row?.strikePrice === controller?.atmStrike,
                })}
                onClick={() => openTradeTicketHandler(row)}
              >
                {row?.strikePrice}
              </td>
              <td onClick={() => openTradeTicketHandler(row, "P")}>
                <div
                  className={clsx({
                    "mobile-options-grid--data-bid": true,
                    "mobile-options-grid--data--border-none": !row?.putsBid,
                  })}
                >
                  {row?.putsBid}
                </div>
              </td>
              <td onClick={() => openTradeTicketHandler(row, "P")}>
                <div
                  className={clsx({
                    "mobile-options-grid--data-offer": true,
                    "mobile-options-grid--data--border-none": !row?.putsOffer,
                  })}
                >
                  {row?.putsOffer}
                </div>
              </td>
              <td className="mobile-options-grid--data-imp-vol">{row?.putsImpVol}</td>
            </tr>
          );
        })}
      </table>
      {rows?.length === 0 && controller?.loadError && (
        <div className="mobile-options-grid-no-options">This product does not have an options contract</div>
      )}
      {viewTradeTicket && (
        <Modal open={viewTradeTicket} onClose={onCloseTradeClick}>
          <Box sx={mobileTradeTicketStyles}>
            <TradeTicketMobile
              tradeFormData={externalData}
              instrument={instrument}
              onCloseTradeClick={onCloseTradeClick}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default MobileOptionsTable;
