import { useMemo } from "react";
import { Product } from "../../../../../../cme-watchlist-api";
import { InstrumentExt } from "../../../../../../types";

export const useProductList = (monthlyContractsByDisplayNameMap: Record<string, InstrumentExt>) => {
  const getProductsList = useMemo(
    () =>
      (watchlistItems: Product[]) => {
        const productsMap: Record<string, string[]> = {};

        watchlistItems?.forEach(({ productId, contractCode }) => {
          const contract = monthlyContractsByDisplayNameMap[contractCode];
          if (!contract) return;
          if (!productsMap[contract.productId]) {
            productsMap[contract.productId] = [];
          }
          productsMap[contract.productId].push(contractCode);
        });

        return Object.entries(productsMap).flatMap(([productId, contractCodes]) => [
          Number(productId),
          ...contractCodes,
        ]);
      },
    [monthlyContractsByDisplayNameMap],
  );

  return { getProductsList };
};

export const useUnavailableProductsList = (monthlyContractsByDisplayNameMap: Record<string, InstrumentExt>) => {
  const getUnavailableProductsList = useMemo(
    () =>
      (watchlistItems: Product[]) => {
        const useUnavailableProducts: { productId: number; contractCode: string }[] = [];

        watchlistItems?.forEach(({ productId, contractCode }) => {
          const contract = monthlyContractsByDisplayNameMap[contractCode];
          if (!contract) {
            useUnavailableProducts.push({ productId, contractCode });
          }
        });

        return useUnavailableProducts;
      },
    [monthlyContractsByDisplayNameMap],
  );

  return { getUnavailableProductsList };
};
