import axios from "axios";
import { TabFilter } from "./types";

// TODO : Need to remove
const BASE_URL =
  "https://beta.cmegroup.com/content/cmegroup/en/education/courses/jcr:content/main-content-section/section_copy/section-elements/search_sort_filter_d.ssfajax.0.0.3_0.0_";

export const apiEducationClient = axios.create({
  baseURL: "http://localhost:3005",
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

// TODO: videos are hardcoded
export const videos = {
  [TabFilter.NewToFutures]: [
    {
      title: "Definition of a Futures Contract",
      videoId: "5228649991001",
    },
    {
      title: "Contract Specifications",
      videoId: "6029615970001",
    },
    {
      title: "Understanding Contract Codes",
      videoId: "5249762821001",
    },
    {
      title: "Margin: Know What's Needed",
      videoId: "5170576929001",
    },
    {
      title: "Understanding Futures Expiration and Contract Roll",
      videoId: "5367342669001",
    },
    {
      title: "Calculating Futures Profit or Loss",
      videoId: "5609041418001",
    },
    {
      title: "Futures Order Types",
      videoId: "5724175969001",
    },
    {
      title: "Physical Delivery vs. Cash Settlement",
      videoId: "6030331732001",
    },
  ],
  [TabFilter.Options]: [
    {
      title: "What is an Option?",
      videoId: "5118785780001",
    },
    {
      title: "Explaining Call Options (short and long)",
      videoId: "5228649991001",
    },
    {
      title: "Explaining Put Options (short and long)",
      videoId: "5201595203001",
    },
    {
      title: "Bull Spread",
      videoId: "5649113054001",
    },
    {
      title: "Bear Spread",
      videoId: "5639631553001",
    },
    {
      title: "Straddles",
      videoId: "5549733736001",
    },
    {
      title: "Strangles",
      videoId: "5558258567001",
    },
    {
      title: "Calculating Options Moneyness and Intrinsic Value",
      videoId: "5201755840001",
    },
  ],
  [TabFilter.Stategies]: [
    {
      title: "Utilizing Stop Orders",
      videoId: "6022194009001",
    },
    {
      title: "Support and Resistance",
      videoId: "5632254774001",
    },
    {
      title: "Rolling an Equity Position Using Spreads",
      videoId: "5028254056001",
    },
    {
      title: "Bull Spread",
      videoId: "5649113054001",
    },
    {
      title: "Bear Spread",
      videoId: "5639631553001",
    },
    {
      // videoId not avaiable
      title: "Straddles",
      videoId: "5549733736001",
    },
    {
      title: "Strangles",
      videoId: "5558258567001",
    },
    {
      title: "Hedging with the Micro E-mini futures",
      videoId: "6029181581001",
    },
  ],
  [TabFilter.RiskManagement]: [
    {
      title: "Position & Risk Management",
      videoId: "5724673633001",
    },
    {
      title: "Alpha/Beta and Portable Alpha",
      videoId: "5535672689001",
    },
    {
      title: "What Does Risk Actually Mean?",
      videoId: "5977817408001",
    },
    {
      title: "Utilizing Stop Orders",
      videoId: "6022194009001",
    },
    {
      title: "Managing Futures Risk",
      videoId: "6022195806001",
    },
    {
      title: "Calculating Futures Profit or Loss",
      videoId: "5609041418001",
    },
    {
      title: "Option hedging with Micro WTI Crude Oil futures",
      videoId: "6256323080001",
    },
    {
      title: "Support and Resistance",
      videoId: "5632254774001",
    },
  ],
  [TabFilter.Financials]: [
    {
      title: "Trading SOFR Futures",
      videoId: "5759002405001",
    },
    {
      title: "E-mini S&P 500 Product Overview",
      videoId: "4903744656001",
    },
    {
      title: "E-Mini Nasdaq 100 Overview",
      videoId: "4944273099001",
    },
    {
      title: "Micro Bitcoin Futures Product Overview",
      videoId: "6243440994001",
    },
    {
      title: "Micro E-mini Equity Index Futures Products Overview",
      videoId: "6011747871001",
    },
    {
      title: "Japanese Yen Product Overview",
      videoId: "5309408947001",
    },
    {
      title: "Euro FX Product Overview",
      videoId: "5071506953001",
    },
    {
      title: "Treasuries Product Overview",
      videoId: "4931408470001",
    },
  ],
  [TabFilter.Commodities]: [
    {
      title: "Gold Product Overview",
      videoId: "4933058935001",
    },
    {
      title: "Micro Ag futures product overview",
      videoId: "6369351494112",
    },
    {
      title: "Learn about the 1:1 Crack Spread",
      videoId: "5514719494001",
    },
    {
      title: "Corn Product Overview",
      videoId: "5332603485001",
    },
    {
      title: "Cash Flow in Metals Futures vs Forwards",
      videoId: "5591176596001",
    },
    {
      title: "Understanding Commodity Storage",
      videoId: "5581103231001",
    },
    {
      title: "What is Contango and Backwardation",
      videoId: "5478030662001",
    },
    {
      title: "Gold & Silver Ratio Spread",
      videoId: "5641693035001",
    },
  ],
};
