import React from "react";
import { Button } from "@mui/material";
import useStyles from "../PublicChallenge/style";

interface ContainedButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
}

const ContainedButton: React.FC<ContainedButtonProps> = ({ text, onClick, className }) => {
  const classes = useStyles();

  return (
    <Button variant="contained" className={`${classes.ContainedButton} ${className}`} onClick={onClick}>
      {text}
    </Button>
  );
};

export default ContainedButton;
