import { useEffect, useState } from "react";
import "./DigitalTime.css";
import moment from "moment";
import "moment-timezone";
import { Mode_Type, TIME_ZONE } from "../../../../../types";
import { useRealTimeMarketData } from "../../../../../cqg-api/hooks/ServiceHooks";
import { Instrument } from "../../../../../cqg-api/models/Instrument";

const DATE_TIME_FORMAT = "hh:mm:ss a [CST] MM/DD/YYYY";

interface DigitalTimeProps {
  isDesktop?: boolean;
  isMobileAccountView?: boolean;
  mode?: string;
}

const DigitalTime = ({ isDesktop = false, isMobileAccountView = false, mode }: DigitalTimeProps) => {
  moment.tz.setDefault("UTC");

  const { realTimeMarketData } = useRealTimeMarketData();
  const [latestInstrument, setLatestInstrument] = useState<Instrument | null>(null);
  const [dateTime, setDateTime] = useState<string | null>(null);

  useEffect(() => {
    if (mode === Mode_Type.CHALLENGE_MODE) {
      const currentTime = moment().tz(TIME_ZONE).format(DATE_TIME_FORMAT);
      setDateTime(currentTime);
      const interval = setInterval(() => {
        const newTime = moment().tz(TIME_ZONE).format(DATE_TIME_FORMAT);
        setDateTime(newTime);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [mode]);

  useEffect(() => {
    if (!realTimeMarketData || realTimeMarketData.length === 0) return;

    const instrumentWithLatestTimestamp = realTimeMarketData.reduce((latest, instrument) => {
      const instrumentTimestamp = instrument.lastUpdateTimestamp?.getTime();
      const latestTimestamp = latest?.lastUpdateTimestamp?.getTime();

      return !latestTimestamp || (instrumentTimestamp && instrumentTimestamp > latestTimestamp) ? instrument : latest;
    }, null as Instrument | null);

    setLatestInstrument(instrumentWithLatestTimestamp);
  }, [realTimeMarketData]);

  useEffect(() => {
    if (mode === Mode_Type.CHALLENGE_MODE || !latestInstrument?.lastUpdateTimestamp) return;

    const startTime = moment(latestInstrument.lastUpdateTimestamp).tz(TIME_ZONE);

    const updateTime = () => {
      setDateTime(startTime.add(1, "second").format(DATE_TIME_FORMAT));
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [latestInstrument]);

  const splitTime = (time: string) => {
    const [timePart, day, period, date] = time.split(" ");
    const [hh, mm, ss] = timePart.split(":");
    return { hh, mm, ss, day, period, date };
  };

  if (!dateTime) return null;

  const { hh, mm, ss, day, period, date } = splitTime(dateTime);

  return (
    <div className={isMobileAccountView ? "digital-time-acc-container" : "digital-time-container"}>
      <div className={isDesktop ? "digital-time-desktop" : isMobileAccountView ? "digital-time-acc-desktop" : "digital-time"}>
        <span>{hh}</span>
        <span className="colon">:</span>
        <span>{mm}</span>
        <span className="colon">:</span>
        <span>{ss}</span>
        <span style={{ width: "auto", whiteSpace: 'nowrap', paddingLeft: "4px" }}>{` ${day} ${period} ${date}`}</span>
      </div>
    </div>
  );
};

export default DigitalTime;
