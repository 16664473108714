import { GridSortModel } from "@mui/x-data-grid";
import { Instrument } from "../../../cqg-api/models/Instrument";

export const sortRows = (rows: any, sortModel: GridSortModel) => {
  if (sortModel.length === 0) return rows;
  const { field, sort } = sortModel[0];
  return [...rows].sort((a, b) => {
    const aValue = a[field];
    const bValue = b[field];
    if (aValue < bValue) return sort === "asc" ? -1 : 1;
    if (aValue > bValue) return sort === "asc" ? 1 : -1;
    return 0;
  });
};

export const updateRowPosition = (initialIndex: number, newIndex: number, rows: Instrument[]) => {
  const rowsClone = [...rows];
  const row = rowsClone.splice(initialIndex, 1)[0];
  rowsClone.splice(newIndex, 0, row);
  return rowsClone;
};
