import { DatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { datePickerDayStyles, datePickerStyles } from "../style";
import { InputAdornment } from "@mui/material";
import { DatePickerIcon } from "../../../../images/Icons";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import ActionsBar from "./ActionsBar";

interface EconomicDatePickerProps {
  isMobileView: boolean;
  loadEconodayEvents: (start?: string, end?: string) => Promise<void>;
}

const EconomicDatePicker = ({ isMobileView, loadEconodayEvents }: EconomicDatePickerProps) => {
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [changeDate, setChangeDate] = useState<Dayjs>(dayjs());
  const onChangeDate = (date: Dayjs | null) => {
    if (date) {
      setChangeDate(date);
    }
  };

  const onApplyDate = (onAccept: () => void) => {
    const formatedDate = changeDate?.format("YYYY-MM-DD");
    loadEconodayEvents(formatedDate);
    setDate(changeDate);
    onAccept();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["DateTimePicker"]}
        sx={{
          width: isMobileView ? "100%" : undefined,
        }}
      >
        {isMobileView ? (
          <MobileDatePicker
            value={date}
            onChange={(e) => onChangeDate(e)}
            sx={{
              ...datePickerStyles,
              ".MuiOutlinedInput-input": {
                padding: "9px 10px",
              },
            }}
            className="economic-mobile-date-picker"
            slotProps={{
              textField: {
                fullWidth: true,
                InputProps: {
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        marginLeft: "12px",
                      }}
                      position="end"
                    >
                      <DatePickerIcon />
                    </InputAdornment>
                  ),
                },
              },
              day: {
                sx: datePickerDayStyles,
              },
            }}
            slots={{
              actionBar: ({ onCancel, onAccept }) => (
                <ActionsBar onCancel={onCancel} onApplyDate={onApplyDate} onAccept={onAccept} />
              ),
            }}
            closeOnSelect={false}
            format="DD MMM YYYY"
          />
        ) : (
          <DatePicker
            views={["year", "month", "day"]}
            onChange={(e) => onChangeDate(e)}
            value={date}
            sx={datePickerStyles}
            slots={{
              openPickerIcon: DatePickerIcon,
              actionBar: ({ onCancel, onAccept }) => (
                <ActionsBar onCancel={onCancel} onApplyDate={onApplyDate} onAccept={onAccept} />
              ),
            }}
            format="DD MMM YYYY"
            slotProps={{
              day: {
                sx: datePickerDayStyles,
              },
            }}
            closeOnSelect={false}
          />
        )}
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default EconomicDatePicker;
