import { styled } from '@mui/material/styles';
import ListItem from './ListItem';
import { FeaturedWatchlist } from '../../cme-watchlist-api';
import { RadioGroup } from "@mui/material";
import { IContract } from './types';

export const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

type FeaturedListProps = {
  keyValue: number;
  list: FeaturedWatchlist[];
  onSelectWatchlist: (watchListId: { watchListId: number, watchlistName: string, productsCount: number}) => void;
  selectedWatchlist?: { watchListId: number, watchlistName: string} | null;
  selectedContracts: IContract[];
  updateSelectedContracts: (contractCodes: IContract[]) => void;
  updateSelectedProduct: (productCode: IContract, selected: boolean) => void;
};
const getname = (list: FeaturedWatchlist[], value: number) => {
  const watchlistToUpdate =  list.find(watchlist => watchlist.watchlistId == value);
  return {
    watchlistName: watchlistToUpdate?.name ?? '',
    productsCount: watchlistToUpdate?.productsCount ?? 0
  };
}
const FeaturedList = ({
  keyValue,
  list,
  onSelectWatchlist,
  selectedWatchlist,
  updateSelectedContracts,
  updateSelectedProduct,
  selectedContracts,
}: FeaturedListProps) => {
  const updateWatchlist = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectWatchlist({
      ...getname(list, parseInt(e.target.value)),
      watchListId: parseInt(e.target.value),
    });
  }
  return (
  <List>
    <RadioGroup key={keyValue} value={selectedWatchlist?.watchListId ?? ''} onChange={updateWatchlist}>
    {list.map((watchlist) => (
        <ListItem
          key={watchlist.watchlistId}
          watchlist={watchlist}
          selectedWatchlist={selectedWatchlist}
          updateSelectedContracts={updateSelectedContracts}
          updateSelectedProduct={updateSelectedProduct}
          selectedContracts={selectedContracts}
        />
      ))}
    </RadioGroup>
  </List>
)};

export default FeaturedList;
