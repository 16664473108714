// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayWrapper {
    position: relative;
    display: inline-block;
}
  
.menuOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
}

.spinner {
    color: #000;
    font-size: 16px;
}
  `, "",{"version":3,"sources":["webpack://./src/components/home/components/SideMenu/widgets/Overlay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".overlayWrapper {\n    position: relative;\n    display: inline-block;\n}\n  \n.menuOverlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: transparent;\n    z-index: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    pointer-events: all;\n    cursor: pointer;\n}\n\n.spinner {\n    color: #000;\n    font-size: 16px;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
