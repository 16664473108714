import { mobileTabs } from "../../components/home/mobileTabs";
import actions from "./layoutActions";

const initialData = {
  width: 0,
  defaultLayout: [],
  onLayoutChanged: null,
  selectedWorkspace: [],
  selectActiveTab: mobileTabs[0],
};

export const layoutReducer = (state = initialData, { type, payload }) => {
  if (type === actions.UPDATE_LAYOUT) {
    return {
      ...state,
      width: payload.width,
      defaultLayout: payload.defaultLayout,
      onLayoutChanged: payload.onLayoutChanged,
      selectedWorkspace: payload.selectedWorkspace,
      selectActiveTab: payload.selectActiveTab,
    };
  }

  if (type === actions.UPDATE_MOBILE_ACTIVE_TAB) {
    return {
      ...state,
      selectActiveTab: payload,
    };
  }

  return state;
};
