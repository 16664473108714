import { Instrument } from "../../../../cqg-api/models/Instrument";
import { DisplayUtil } from "../../../../cqg-api/utils/DisplayUtil";
import { InstrumentMarketData } from "../../../../types";
import RenderColorCell from "../RenderColorCell";

interface TableViewProps {
  rows: InstrumentMarketData[];
  handleOpenModal: (instrument: InstrumentMarketData & { monthlyContracts?: Instrument }) => void;
}

const TableView = ({ rows, handleOpenModal }: TableViewProps) => {
  const getContractName = (instrument: any) => {
    return (
      <span className="contract-name">
        {`${instrument?.monthlyContracts[0]?.displayName} - ${instrument?.monthlyContracts[0]?.month} ${instrument?.monthlyContracts[0]?.title.substr(instrument?.monthlyContracts[0]?.title.length - 2)}`}
      </span>
    );
  };

  const changeCell = (row: InstrumentMarketData & { monthlyContracts?: Instrument }) => {
    const stateOfRow = row?.monthlyContract;

    const color = stateOfRow?.labelPriceNetChange && stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    const sign = stateOfRow?.labelPriceNetChangePercent && stateOfRow?.labelPriceNetChangePercent > 0 ? "+" : "";

    if (!stateOfRow?.labelPriceNetChange) {
      return (
        <div>
          <div>-</div>
          <div>-</div>
        </div>
      );
    }

    if (stateOfRow?.labelPriceNetChange) {
      return (
        <div style={{ color }}>
          <div>{`${sign}${stateOfRow?.labelPriceNetChange?.toFixed(2)}`}</div>
          <div>{`(${sign}${stateOfRow?.labelPriceNetChangePercent?.toFixed(2)}%)`}</div>
        </div>
      );
    }
  };

  return (
    <div className="simple-table">
      {rows.map((row) => (
        <div key={row.id} className="flex w-full justify-between row py-2" onClick={() => handleOpenModal(row)}>
          <div className="tableDataMarket">
            <div className="column column1">
              <span className="text-sm market-mobile-table-font">{row?.name}</span>
              <span className="text-xs">{getContractName(row)}</span>
            </div>
            <div className="column column2">
              <span className="text-xs market-mobile-table-font">
                {row?.monthlyContract ? (
                  <RenderColorCell
                    value={DisplayUtil.toDisplayPrice(row?.monthlyContract?.lastPrice as number, row?.monthlyContract)}
                  />
                ) : (
                  <span>-</span>
                )}
              </span>
              <span className="text-xs market-mobile-table-font">
                {row?.monthlyContract ? row?.monthlyContract?.labelTotalVolume : <span>-</span>}
              </span>
            </div>
            <div className="column column3">
              <span className="text-xs sm-info">{changeCell(row)}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableView;
