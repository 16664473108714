export const AppDownloadPromptModalStyles = {
    modalContainer: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      bgcolor: "background.paper",
      paddingTop: 0,
      border: "1px solid white",
      height: "195px",
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 15px 0px 15px',
      gap: 2,
      background: '#FFFFFF',
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.2)',
      alignItems: "center",

    },
    closeIconContainer: {
      position: "absolute",
      top: "10px",
      right: "0px",
      cursor: "pointer",
    },
    title: {
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "Averta-Regular",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000000"
    },
    subtitle: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontFamily: "Averta-Regular",
      fontSize: "11px",
      lineHeight: "20px",
      color: "#000000"
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      gap: 1,
      width: 140,
      height: 40,
      border: "1px solid #000000",
      borderRadius: "6px",
      textTransform: "none"
    },
    icon: {
      width: 20,
      height: 24,
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
    },
    buttonLabel: {
      fontSize: "10px",
      textTransform: "uppercase",
      color: "#000",
    },
    buttonTitle: {
      fontSize: "14px",
      fontWeight: 500,
      whiteSpace: "nowrap",
    },
    dontShowAgain: {
      display: "flex",
      justifyContent: "center",
      // marginTop: "auto",
      color: "#006EB6",
      fontWeight: 600,
      fontSize: "11px",
      alignItems: "center",
    },
  };