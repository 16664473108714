import { CMESymbolService } from "../services/CMESymbolService";
import { SymbolResolver } from "../services/SymbolResolver";
import { CMEInstrument } from "../models/CMEInstrument";
import axios from "axios";
import { ENV } from "../../env";

export class SimulatorController {
  private static restrictedAssetClasses: string[] = [];
  private static restrictedSymbols: string[] = [];

  static onRestrictedSymbolsChange = (newValue: any, oldValue: any) => {
    this.restrictedAssetClasses = [];
    CMESymbolService.getAssetClasses().then((assetClasses: string[]) => {
      assetClasses.forEach((assetClass: string) => {
        CMESymbolService.getInstrumentsForAssetClass(assetClass).then(
          (instruments: { [key: string]: CMEInstrument }) => {
            let allowed = false;

            for (let key in instruments) {
              let inst = instruments[key] as CMEInstrument;
              if (!this.isRestrictedSymbol(inst.cme_symbol)) {
                allowed = true;
                break;
              }
            }

            if (!allowed) {
              this.restrictedAssetClasses.push(assetClass);
              // this.restrictedAssetClasses = _.clone(this.restrictedAssetClasses);
            }
          },
        );
      });
    });
  };

  static lodInstruments = () => {
    this.restrictedAssetClasses = [];
    CMESymbolService.getAssetClasses().then((assetClasses: string[]) => {
      assetClasses.forEach((assetClass: string) => {
        CMESymbolService.getInstrumentsForAssetClass(assetClass).then(
          (instruments: { [key: string]: CMEInstrument }) => {
            let allowed = false;

            for (let key in instruments) {
              let inst = instruments[key] as CMEInstrument;
              SymbolResolver.resolveSymbols(inst.cme_symbol);
              if (!this.isRestrictedSymbol(inst.cme_symbol)) {
                allowed = true;
                // break;
              }
            }

            if (!allowed) {
              this.restrictedAssetClasses.push(assetClass);
              // this.restrictedAssetClasses = _.clone(this.restrictedAssetClasses);
            }
          },
        );
      });
    });
  };

  static isRestrictedSymbol = (symbol: string) => {
    return this.restrictedSymbols.includes(symbol);
  };

  static resetPracticeAccount = async (memberId: string) => {
    const response = await axios.post(ENV.USER_LOGIN_API_URL + "/trading_tools/simulator/member/" + memberId + "/reset_practice_account", {});
    return response;
  };
}
