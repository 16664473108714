// import { createSelector } from 'reselect';

const selectRaw = (state) => state.layout;

const selectActiveTab = (state) => state.layout.selectActiveTab; 
// const selectMenuVisible = createSelector(
//   [selectRaw],
//   (layout) => Boolean(layout.menuVisible),
// );

const layoutSelectors = {
  selectRaw,
  selectActiveTab,
};

export default layoutSelectors;
