import { Widget } from "../../../types";
import BrightcovePlayer from "./BrightcovePlayer";

interface CommentaryProps {
  workspaceId: number;
  widget: Widget;
}

const Commentary = (props: CommentaryProps) => {
  return <BrightcovePlayer />;
};

export default Commentary;
