import { FeaturedWatchlistService } from "./api/featuredWatchlist";
import { UserWatchlistService } from "./api/userWatchlist";

export * from "./api/featuredWatchlist";
export * from "./api/userWatchlist";
export * from "./models/featuredWatchlist";
export * from "./models/userWatchlist";
export * from "./models/common";

export const CMEWatchlistAPI = {
  UserWatchlist: UserWatchlistService,
  FeaturedWatchlist: FeaturedWatchlistService,
};
