import { getFromLS } from "../../storage";
import { SELECTED_MOBILE_WIDGET_INSTRUMENT, WidgetType } from "../../types";

const selectedInstrument = getFromLS(SELECTED_MOBILE_WIDGET_INSTRUMENT) || {};
const contract = selectedInstrument[WidgetType.Chart] || "ZCH5";
export const mobileTabs = [
  {
    key: 1,
    title: "Markets",
    widgetType: "Markets",
    isActive: true,
    displayName: "Markets",
  },
  {
    key: 3,
    title: "Chart",
    widgetType: "Chart",
    contractIds: [contract],
    isActive: true,
    displayName: "Chart",
  },
  {
    key: 7,
    title: "Options",
    widgetType: "Options",
    contractIds: ["6JH5"],
    isActive: true,
    displayName: "Options",
  },
  {
    key: 4,
    title: "Price Ladder",
    widgetType: "Price Ladder",
    contractIds: ["GCG5"],
    isActive: true,
    displayName: "Price Ladder",
  },
  {
    key: 2,
    title: "Watchlists",
    widgetType: "Watchlists",
    widgetId: 2337,
    isActive: true,
    displayName: "Watchlists",
  },
  {
    key: 5,
    title: "Positions",
    widgetType: "Positions",
    isActive: true,
    displayName: "Positions",
  },
  {
    key: 6,
    title: "Orders",
    widgetType: "Orders",
    isActive: true,
    displayName: "Orders",
  },
  {
    key: 8,
    title: "News",
    widgetType: "News",
    isActive: false,
    displayName: "News",
  },
  {
    key: 9,
    title: "Calendar",
    widgetType: "Calendar",
    isActive: true,
    displayName: "Calendar",
  },
];
