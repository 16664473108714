import { Chip } from "@mui/material";
import { Contract } from "../../types";
import { ContractChipStyles } from "../../marketStyles";
import { convertSymbolToYear } from "../../../../tree-view/utils/convertSymbolToDate";
import clsx from "clsx"; 
import { Instrument } from "../../../../../cqg-api/models/Instrument";
interface ContractChipProps {
  contract: Instrument[];
  selectedContract: Instrument | null;
  handleContractSelect: (selected: Instrument) => void;
  isInstrumentExpired: boolean;
}

const ContractChip = ({ contract, selectedContract, handleContractSelect ,isInstrumentExpired}: ContractChipProps) => {
  const getContractName = (contract: Instrument) => {
    return `${contract?.displayName} - ${convertSymbolToYear(contract?.displayName, contract?.month as string)}`;
  };

  const getChipClassNames = (isSelected: boolean) => {
    const baseClass = "contract-chip";  

    if (isInstrumentExpired) {
      return clsx(baseClass, {
        "contract-chip-expired": isSelected,
        "contract-chip-expired-unselected": !isSelected,
      });
    }

    return clsx(baseClass, {
      "contract-chip-selected": isSelected,
      "contract-chip-unselected": !isSelected,
    });
  };

  return (
    <div className="contract-chip-container">
      {contract?.map((contractItem) => {
        const isSelected = selectedContract?.contractId === contractItem?.contractId;
        const chipClassNames = getChipClassNames(isSelected);
        return (
          <Chip
            key={contractItem?.contractId}
            label={getContractName(contractItem)}
            onClick={() => handleContractSelect(contractItem)}
            sx={ContractChipStyles}
            className={chipClassNames}
          />
        );
      })}
    </div>
  );
};

export default ContractChip;
