import React from 'react';
import { TextField } from '@mui/material';
import {useStyles} from './style';

interface CustomTextFieldProps {
  placeholder?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  className?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  placeholder = 'Enter text',
  variant = 'outlined',
  value = '',
  onChange,
  fullWidth = true,
  className,
}) => {
  const classes = useStyles();

  return (
    <TextField
      className={`${classes.inputField} ${className}`}
      variant={variant}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth}
    />
  );
};

export default CustomTextField;
