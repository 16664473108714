export const dataGridStyles = {
  "& .MuiDataGrid-cell": {
    fontSize: "11px",
    fontFamily: "Averta-regular",
    textAlign: "center",
    color: "#25323C",
  },
  "& .MuiDataGrid-columnHeader": {
    fontSize: "12px",
    fontFamily: "Averta-regular",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontFamily: "Averta-Regular",
    fontSize: "9px",
    fontWeight: 400,
    letterSpacing: "0.17px",
  },
  ".MuiDataGrid-columnSeparator": {
    visibility: "visible !important",
    opacity: "1 !important",
  },
  ".MuiDataGrid-columnSeparator--resizable:hover": {
    color: "rgb(0 0 0 / 40%)",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    opacity: "0 !important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    border: "none !important",
    fontFamily: "Averta-Regular",
    fontSize: "9px"
  },
  "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical": {
    top: "43px",
  },
  "& .MuiDataGrid-row--borderBottom": {
    backgroundColor: "#f6f6f6"
  },
};

export const contractSelectStyles = {
  height: "32px",
  background: "#EBEDEE",
  color: "#25323C",
  fontFamily: "Averta-Regular",
  fontSize: "11px"
};

export const menuItemStyles = {
  fontSize: "14px",
  fontFamily: "Averta-Regular",
  display: "flex",
  justifyContent: "space-between",
  minHeight: "32px",
}
