import React from 'react';
import { Divider } from '@mui/material';
import { StyledTypography } from "./Styled";

const MarketInfo = () => (
  <>
    <div className="market-text-display">
      <StyledTypography variant="body2">
        Market Data and Simulation Provided by CQG
      </StyledTypography>
      <StyledTypography variant="body2">
        Options Premium is tracked in the Margin Requirements field.
      </StyledTypography>
      <StyledTypography variant="body2">
        Options Settlement occurs each evening, around 5:30pm (CT), and it will update your account balance with the day’s profits and losses.
      </StyledTypography>
      <StyledTypography variant="body2">
        *Open positions will remain in the Open Position table, only closed positions will be displayed in the trade history.
      </StyledTypography>
    </div>
    <Divider />
    <div className="market-text-display">
      <StyledTypography variant="body2">
        The data and output from this tool does not constitute investment advice and is not a personal recommendation from CME Group.
      </StyledTypography>
      <StyledTypography variant="body2">
        Any investment activities undertaken using this tool will be at the sole risk of the relevant investor.
      </StyledTypography>
    </div>
  </>
);

export default MarketInfo;
