import React from 'react';
import './Overlay.css';

function OverlayWrapper(props: { children: any, isDisabled: boolean, onClick?: () => void}) {
  const {children, isDisabled, onClick} = props;
  return (
    <div className="overlayWrapper">
      <div className="content">
         {children}
       </div>
      {isDisabled && <div className="menuOverlay" onClick={onClick}/>}
    </div>
  );
}

export default OverlayWrapper;
