import { useCallback } from "react";
import { cancelOrders } from "../../widgets/orders/actions";
import { OrderState } from "../../../cqg-api/models/OrderState";

interface ModalFooterProps {
  data: any;
  onClose: () => void;
  handleAmmendOrder: (order: OrderState) => void;
}

const ModalFooter = ({ data, onClose, handleAmmendOrder }: ModalFooterProps) => {
  const handleConfirmCancelOrder = useCallback(() => {
    cancelOrders([data?.order]);
    onClose();
  }, [data?.order, onClose]);
  return (
    <div className="flex w-full justify-between p-2 ActionsButtons">
      <button onClick={() => handleAmmendOrder(data.order)} className="mobile-action-button cancel-all-orders">AMEND</button>
      <button onClick={handleConfirmCancelOrder} className={`mobile-action-button cancel-all-orders active`}>
        CANCEL
      </button>
    </div>
  );
};

export default ModalFooter;
