import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DigitalTime from '../../mobile/profile/DigitalTime/DigitalTime';
import OneClick from '../SideMenu/OneClick';
import { ChevronLeft } from '@mui/icons-material';
import Mode from '../../mobile/profile/Mode';
import { Mode_Type } from '../../../../types';
import { LogoutIcon } from '../../../../images/Icons';
import ThemeToggle from './ThemeToggle';
import { SimulatorController } from '../../../../cqg-api/controllers/SimulatorController';
import { useAuthenticateUser, useGetCurrentUser } from '../../../../hooks/api/authentication';
import ConfirmCancelDialog from '../../../shared/ConfirmCancelDialog';
import Notification from '../../../shared/notification/Notification';
import { useDispatch } from 'react-redux';
import authActions from '../../../../redux/auth/authActions';
import { AppGlobal } from '../../../../AppGlobal';

interface ProfileSectionProps {
  userName: string;
  profileInitial: string;
  open: boolean;
  anchorShow: HTMLElement | null;
  handleAccountClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  handleLogoutClick: () => void;
  handleModeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mode: Mode_Type;
}

const ProfileSection = ({
  userName,
  profileInitial,
  open,
  anchorShow,
  handleAccountClick,
  handleClose,
  handleLogoutClick,
  handleModeChange,
  mode
}:ProfileSectionProps) => {
  const { getUserDetails } = useAuthenticateUser();
  const dispatch = useDispatch();
  const [showResetPracticeAccountDialog, setShowResetPracticeAccountDialog] = useState(false);
  const currentUser = useGetCurrentUser();

  const handleModeReset = () => {
    setShowResetPracticeAccountDialog(true);
  };

  const resetPracticeAccount = async () => {
    SimulatorController.resetPracticeAccount(currentUser.id).then(async (resp) => {
      console.log('Reset practice account done!');
      await getUserDetails(currentUser.id, (response: any) => {
        AppGlobal.removeCurrentUser();
        dispatch(authActions.dispatchLoginSuccess(response));
        setShowResetPracticeAccountDialog(false);
        window.location.reload();
      });
    }).catch((e) => {
      setShowResetPracticeAccountDialog(false);
      Notification.error(`Unable to reset your account. Please contact support.`);
      setShowResetPracticeAccountDialog(false);
    });
  }

  return(
  <div className="profile-section">
    <DigitalTime isDesktop={true} mode={mode}/>
    <OneClick isOpen={true} />
    <div className="profile-icon">{profileInitial}</div>
    <div className="profile-text">
      <div className="profile-sec-top" onClick={handleAccountClick}>
        <span className="profile-name">{userName}</span>
        <IconButton className="dropdown-button" sx={{ padding: 0, fontSize: 'inherit', '& svg': { fontSize: '1.3rem' } }}>
          {open ? <ExpandMoreIcon /> : <ChevronLeft />}
        </IconButton>
      </div>
      <div className="profile-sec-bottom">
        <span>{ mode === Mode_Type.PRACTICE_MODE ? "Practice Mode" : "Challenge Mode"}</span>
      </div>
    </div>
    <Menu
      anchorEl={anchorShow}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ style: { width: '40ch', marginTop: '10px' } }}
      className="menu-paper"
    >
      <Mode mode={mode} onModeChange={handleModeChange} onModeReset={handleModeReset} isDesktop={true}/>
      <hr className="divider-menu" />
      <ThemeToggle/>
      <MenuItem onClick={handleLogoutClick} sx={{ padding: '12px' }}>
        <span className="menu-item-text"><LogoutIcon/> Logout</span>
      </MenuItem>
    </Menu>

    <ConfirmCancelDialog
      open={showResetPracticeAccountDialog}
      onClose={() => setShowResetPracticeAccountDialog(false)}
      onConfirm={resetPracticeAccount}
      loading={false}
      title="Reset Account"
      message={
        <span>
          Resetting your account will delete your trade history. Are you sure you want to reset your account?
        </span>
      }
      cancelText="Cancel"
      confirmText="Reset"
    />
  </div>
);
}

export default ProfileSection;
