import "./PriceLadder.css";
import { useState } from "react";
import { WidgetType } from "../../../types";
import MobileTreeViewModal from "../../tree-view/mobile/MobileTreeViewModal";
import { PriceLadderSearchIcon } from "../../../images/Icons";

const SelectContract = (props: {
  value: string;
  updateValue: (displayName: string) => void;
  widgetType: WidgetType;
}) => {
  const { value, updateValue, widgetType } = props;
  const [showTreeView, setShowTreeView] = useState(false);
  
  const openProductSelection = () => {
    setShowTreeView(true);
  };

  return (
    <div className="price-ladder-contract-select">
      <div onClick={openProductSelection} className="price-ladder-contract-select-container">
        <div className="price-ladder-contract-label">
          <PriceLadderSearchIcon/>{value}
        </div>
      </div>
      {showTreeView && (
        <MobileTreeViewModal
          value={value}
          updateValue={updateValue}
          widgetType={widgetType}
          showTreeView={showTreeView}
          setShowTreeView={setShowTreeView}
        />
      )}
    </div>
  );
};

export default SelectContract;
