import React, { useEffect } from "react";
import "./BrightcovePlayer.css";
import useResponsivePanel from "../../../hooks/useResponsivePanel";
import clsx from "clsx";
import Notification from "../../shared/notification/Notification";

const BrightcovePlayer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    try {
      script.src = "https://players.brightcove.net/49919183001/d7IxE9yBU_default/index.min.js";
      // https://players.brightcove.net/49919183001/experience_673ba1302c74bdef40b380d6/live.js
      script.async = true;
      document.body.appendChild(script);
    } catch (e) {
      Notification.error("Something went wrong");
    }
    return () => {
      document.body.removeChild(script); // Cleanup
    };
  }, []);

  const { panelRef, isSmallPanel } = useResponsivePanel(600);

  return (
    <div className="video-wrapper-brightcove">
      <div className="video-wrapper">
        <div
          className={clsx({
            "video-wrapper--container": true,
            "video-wrapper--container-small": isSmallPanel,
          })}
          ref={panelRef}
        >
          <video
            data-account="49919183001"
            data-player="d7IxE9yBU"
            data-embed="default"
            className="video-js"
            controls
            data-playlist-id="1825678285028125559"
            id="featured-videos"
          ></video>
          <div
            className={clsx({
              "vjs-playlist": true,
              bcPlaylistContainer: true,
              loaded: true,
              "vjs-playlist-horizontal": true,
              "vjs-csspointerevents": true,
              "vjs-mouse": true,
              "vjs-playlist-horizontal--small": isSmallPanel,
            })}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BrightcovePlayer;
