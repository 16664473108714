export const modeStyles = {
  marginLeft: "0px",
};

export const labelStyles = {
  fontFamily: "Averta-Regular",
  fontWeight: "400",
  letterSpacing: "0.5px",
  fontSize: "13px",
  color: "#25323C",
};

export const buttonStyles = {
  fontFamily: "Averta-Regular",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "normal",
  letterSpacing: "0%",
  color: "var(--Blue-Blue-4, #006EB6)",
  position: "relative",
  display: "inline-block",
  padding: "8px 0px",
  minWidth: "40px",
  textTransform: "none",
  marginRight: "12px",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "0%",
    height: "2px",
    backgroundColor: "var(--Blue-Blue-4, #006EB6)",
    transition: "width 0.3s ease",
  },
  "&:hover::after": {
    width: "100%",
  },
};
