 import { Instrument } from "../../../cqg-api/models/Instrument";
import { DisplayUtil } from "../../../cqg-api/utils/DisplayUtil";
import { Product } from "../../../cme-watchlist-api/models/common";
 import RenderColorCell from "../availableProducts/RenderColorCell";
import { ProductName } from "./styles";
import { ContractWithProduct } from "../../featuredWatchlist/types";

interface TableViewProps {
  rows: ContractWithProduct[];
  handleOpenModal: (instrument:ContractWithProduct) => void;
}

const TableView = ({ rows, handleOpenModal }: TableViewProps) => {
  console.log(rows)
   const getContractName = (instrument: any) => {
    if (!instrument?.monthlyContracts || instrument?.monthlyContracts?.length === 0) {
     return <span className="contract-name">{`${instrument?.contractCode||''} - ${instrument?.expirationMonth?.slice(0, 1).toUpperCase() + instrument?.expirationMonth?.slice(1, 3).toLowerCase()||''} ${instrument?.expirationMonth?.slice(-2)||''}`}</span>;
    }
    return (
      <span className="contract-name">
      {`${instrument?.displayName||''} - ${instrument?.month ||''} ${instrument?.title.substr(instrument?.title.length - 2)||''}`}
      </span>
    );
  };

  const changeCell = (row: (Instrument & Product)) => {
    const stateOfRow = row;

    const color = stateOfRow?.labelPriceNetChange && stateOfRow?.labelPriceNetChange < 0 ? "#C62828" : "#00695C";
    const sign = stateOfRow?.labelPriceNetChangePercent && stateOfRow?.labelPriceNetChangePercent > 0 ? "+" : "";

      return (
        <div style={{ color }}>
          <div>{stateOfRow?.labelPriceNetChange ?`${sign}${stateOfRow.labelPriceNetChange.toFixed(2)}`: "-"}</div>
          <div>{stateOfRow?.labelPriceNetChangePercent ?`(${sign}${stateOfRow.labelPriceNetChangePercent.toFixed(2)}%)`:"-"}</div>
        </div>
      ); 
  };

  return (
    <div className="simple-table">
      {rows.map((row) => (
        <div key={row?.displayName} className={`flex w-full justify-between row py-2 ${!row.lastPrice ? "watchlist-table-no-product":""}`} onClick={() => handleOpenModal(row)}>
          <div className="tableDataMarket">
            <div className="column column1">
          <ProductName className="text-sm market-mobile-table-font">{row?.productName||""}</ProductName>
              <span className="text-xs">{getContractName(row)}</span>
            </div>
            <div className="column column2">
              <span className="text-xs market-mobile-table-font">
                {row && row?.lastPrice ? (
                  <RenderColorCell
                    value={DisplayUtil.toDisplayPrices(row?.lastPrice as number, row)}
                  />
                ) : (
                  <span>-</span>
                )}
              </span>
              <span className="text-xs market-mobile-table-font">
                {row?.labelTotalVolume ? row?.labelTotalVolume : <span>-</span>}
              </span>
            </div>
            <div className="column column3">
              <span className="text-xs sm-info">{changeCell(row)}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableView;
