import { useEffect, useMemo } from "react";
import { Mode_Type, Product, User } from "../../types";
import { subscribeToInstruments, unsubscribeInstuments } from "../../utils/subscriptions";
import { Instrument } from "../../cqg-api/models/Instrument";
import { getStreamingAccount } from "../../utils/user";
import { CQGEnvironment } from "../../cqg-api/services/CQGEnvironment";

export const useSubscribeProducts = (products: Product[]) => {
  useEffect(() => {
    let resolvedInstruments: Instrument[] = [];
    subscribeToInstruments(products).then((symbols) => {
      resolvedInstruments = symbols;
    });
    return () => {
      unsubscribeInstuments(resolvedInstruments);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
};

const useInitiateCQGEnvironment = (currentUser: User, mode: Mode_Type) => {
  const cqgAccount = useMemo(() => {
    return getStreamingAccount(currentUser, mode);
  }, [currentUser, mode]);

  useEffect(() => {
    if (cqgAccount) {
      CQGEnvironment.setCQGAccountAuthInfo(cqgAccount);
    }
  }, [cqgAccount, currentUser]);

  return cqgAccount;
};

export default useInitiateCQGEnvironment;
