export interface Facets {
  count: number;
  tagId: string;
}

export interface Result {
  text: string;
  url: string;
}

export interface EducationData {
  currentPage: number;
  facets: Facets;
  hasMore: boolean;
  results: Result[];
  totalPages: number;
  totalResults: number;
}

export enum TabFilter {
  NewToFutures = "New to Futures",
  Options = "Options",
  Stategies = "Stategies",
  RiskManagement = "Risk Management",
  Financials = "Financials",
  Commodities = "Commodities",
  // GettingStarted = "Getting Started",
  // MarketFundamentals = "Market Fundamentals",
  // ProductInformation = "Product Information",
  // StrategiesANDTechniques = "Strategies & Techniques",
}
