import { useState } from "react";
import { Box, Button, Divider, Grid, Modal } from "@mui/material";
import TreeViewContainer from "../../tree-view/TreeViewContainer";
import { SELECTED_MOBILE_WIDGET_INSTRUMENT, WidgetType } from "../../../types";
import { CloseIcon } from "../../../images/Icons";
import "./MobileTreeViewModal.css";
import { saveToLS, getFromLS } from "../../../storage";
import { actionButtonStyles } from "./styled";

const MobileTreeViewModal = (props: {
  value: string;
  updateValue: (displayName: string) => void;
  widgetType: WidgetType;
  showTreeView: boolean;
  setShowTreeView: any;
}) => {
  const { value, updateValue, widgetType, showTreeView, setShowTreeView } = props;
  const [selectedTreeItems, setSelectedTreeItems] = useState<string[]>([value]);
  const handleChangeTreeItems = (selectedItems: string[]) => {
    setSelectedTreeItems(selectedItems);
  };
  const updateContract = () => {
    updateValue(selectedTreeItems[0]);
    saveToLS(SELECTED_MOBILE_WIDGET_INSTRUMENT, {
      ...getFromLS(SELECTED_MOBILE_WIDGET_INSTRUMENT),
      [widgetType]: selectedTreeItems[0],
    });
  };
  return (
    <Modal open={showTreeView} onClose={() => setShowTreeView(false)}>
      <Box className="mobile-tree-view-modal-content" sx={{ bgcolor: "background.paper" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", padding: "24px 24px 12px" }} justifyContent="space-between" alignItems="center">
              <Box
                sx={{ display: "flex", fontWeight: 600, letterSpacing: "1.5px" }}
                alignItems="center"
                gap="4px"
                justifyContent="center"
              >
                <span className="trade-title-text">Edit Product</span>
              </Box>
              <Box>
                <button
                  type="button"
                  style={{ background: "none", border: "none" }}
                  onClick={() => setShowTreeView(false)}
                >
                  <CloseIcon />
                </button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{marginBottom: "8px"}}/>
        <TreeViewContainer
          widgetType={widgetType}
          selectedTreeItems={selectedTreeItems}
          onChangeTreeItems={handleChangeTreeItems}
          errorMessage={""}
          data-testid="tree-view"
          isMobileView
          value={value}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="mobile-tree-view-buttons" justifyContent="end" alignItems="center">
              <Box sx={{ display: "flex", letterSpacing: "1.5px" }} alignItems="center" gap="4px" justifyContent="end" width="100%" height= "41px">
                <Button
                  sx={{
                    ...actionButtonStyles,
                    border: "1px solid #006EB6",
                    color: "#00426D"
                  }}
                  variant="outlined"
                  onClick={() => {
                    setShowTreeView(false);
                    setSelectedTreeItems([value]);
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ ...actionButtonStyles,  }}
                  disabled={selectedTreeItems?.length === 0}
                  onClick={() => {
                    updateContract();
                    setShowTreeView(false);
                  }}
                >
                  UPDATE
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MobileTreeViewModal;
