import React, { useEffect } from "react";
import Notification from "../../../shared/notification/Notification";

const BrightCoveVideo = ({
  video,
}: {
  video: {
    title: string;
    videoId: string;
  };
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    try {
      script.src =
        "https://players.brightcove.net/49919183001/46f29b5c-718a-4f1f-aabc-37aadea1426c_default/index.min.js";
      script.async = true;
      document.body.appendChild(script);
    } catch (e) {
      Notification.error("Something went wrong");
    }
    return () => {
      document.body.removeChild(script); // Cleanup
    };
  }, []);

  return (
    <div key={video.videoId}>
      <div className="video-title-text">{video.title}</div>
      <div className="brightcove-video-wrapper-container">
        <video
          data-account="49919183001"
          data-player="46f29b5c-718a-4f1f-aabc-37aadea1426c"
          data-embed="default"
          className="video-js"
          controls
          data-video-id={video.videoId}
          id="brightcovePlayer"
        ></video>
      </div>
    </div>
  );
};

export default BrightCoveVideo;
