import React from "react";
import { Box, Typography, Button } from "@mui/material";
import useStyles from "./style";
import { buttonStyles } from "../../../../mobile/profile/style";
import { titleStyle } from "../TradingChallenge/style";
import CustomButton from "../shared/CustomButton";
import ContainedButton from "../shared/ContainedButton";

const PublicChallengeCard: React.FC = () => {
  const classes = useStyles();
  const handleViewDetails = () => {
    console.log("View Details clicked");
  };

  const handleJoin = () => {
    console.log("Join clicked");
  };
  return (
    <Box className={classes.challengeContainer}>
      <Box className={classes.imageSection}>
        <Box className={classes.rectangle}></Box>
      </Box>

      <Box className={classes.contentSection}>
        <Typography variant="h6" className={classes.publicText}>
          Public
        </Typography>
        <Typography sx={titleStyle}>Challenge Title Here</Typography>
        <Typography className={classes.challengeDescription}>
          Lorem ipsum dolor sit amet consectetur. Id nulla libero sagittis facilisis pulvinar. Morbi nunc dignissim
          metus odio dolor lacus faucibus nunc consequat.
        </Typography>

        <Box className={classes.dateSection}>
          <Box className={classes.dateFrame}>
            <Typography className={classes.date}>Start Date</Typography>
            <Typography className={classes.dateText}>2/10/2025 8:15am CT</Typography>
          </Box>
          <Box className={classes.dateFrame}>
            <Typography className={classes.date}>End Date</Typography>
            <Typography className={classes.dateText}>2/28/2025 3:15pm CT</Typography>
          </Box>
          <CustomButton onClick={handleViewDetails} text="View Details" />
          <ContainedButton onClick={handleJoin} text="JOIN" />
        </Box>
      </Box>
    </Box>
  );
};

export default PublicChallengeCard;
