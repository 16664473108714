import {
  Divider,
  Typography,
  Slide,
  Menu,
} from "@mui/material";
import FundsInfo from "../../mobile/profile/FundInfo";
import DigitalTime from "../../mobile/profile/DigitalTime/DigitalTime";
import { headingTypographyStyles, menuStyle, typographyStyles } from "./Styled";
import TradeLogComponent from "../TradeLog/TradeLogComponent";
import TradeHistoryDownload from "../../mobile/profile/TradeHistoryDownload";
import MarketInfo from "./MarketInfo";

const AccountInfoSlider = ({
  accountInfoVisible,
  handleCloseAccInfo,
  onMenuItemClick,
  isDesktop,
  handleCloseMenu,
  anchorMenu,
}: {
  accountInfoVisible: boolean;
  handleCloseAccInfo?: () => void;
  onMenuItemClick?: (component: JSX.Element | null) => void;
  isDesktop?: boolean;
  handleCloseMenu?: () => void;
  anchorMenu?: HTMLElement | null;
}) => {
  const handlePageClick = (text: string) => {
    let component = null;
    if (text === "Trade Log") {
      component = <TradeLogComponent onHandleClose={enableMenuItems} />;
    }
    if(!!onMenuItemClick) onMenuItemClick(component);
  };

  const enableMenuItems = () => {
    if(!!onMenuItemClick) onMenuItemClick(null);
  };

  return isDesktop ? (
    <Menu
      anchorEl={anchorMenu}
      open={Boolean(anchorMenu)}
      onClose={handleCloseMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={menuStyle}
    >
      <div style={{display: 'flex', gap: "12px", flexDirection: "column"}}>
        <MarketInfo />
        <TradeHistoryDownload />
      </div>
    </Menu>
  ) : (
    <Slide direction="up" in={accountInfoVisible} mountOnEnter unmountOnExit>
      <div className="mobile-acc-container">
        <Typography variant="h6" color="initial" sx={headingTypographyStyles}>
          ACCOUNT BALANCE
        </Typography>
        <FundsInfo />
        <DigitalTime isMobileAccountView={true} />
        <Divider />
        {onMenuItemClick && 
          <div className="link-container">
            <Typography
              variant="body1"
              color="primary"
              sx={typographyStyles}
              onClick={() => handlePageClick("Trade Log")}
            >
              VIEW YOUR TRADE LOG
            </Typography>
          </div>
        }
        <MarketInfo />
      </div>
    </Slide>
  );
};

export default AccountInfoSlider;
