import { Drawer } from "@mui/material";
import Header from "./Header";
import Widgets from "./widgets/Widgets";
import ToolsNav from "./tools";
import { useState } from "react";
import OverlayWrapper from "./widgets/OverlayWrapper";

const SideMenuItems = (props: any) => {
  const {
    isOpen,
    onToggle,
    onAddWidgetClick,
    onMenuItemClick,
    drawerOpen,
    handleDrawer,
    loadWorkspaceOnToolsSelection,
    showMenuItemDrawer,
  } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

  const onHandleMenuItemClick = (component: JSX.Element | null, index: number | null) => {
    onMenuItemClick(component);
    setActiveTabIndex(index);
    setIsDisabled(true);
    loadWorkspaceOnToolsSelection(true);
    showMenuItemDrawer(false);
  };

  const enableMenuItems = () => {
    setIsDisabled(false);
    setActiveTabIndex(null);
    onMenuItemClick(null);
  };
 
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onToggle}
      variant="persistent"
      sx={{
        width: isOpen ? 180 : 0,
        height: "calc(100vh)",
        transition: "width 0.1s ease",
        "& .MuiDrawer-paper": {
          width: isOpen ? 179 : 0,
          top: 2,
          position: "relative",
          overflow: "hidden",
        },
      }}
    >
      <>
        <OverlayWrapper isDisabled={isDisabled} onClick={enableMenuItems}>
          <Header onToggle={onToggle} />
          <Widgets handleDrawer={handleDrawer} drawerOpen={drawerOpen} onAddWidgetClick={onAddWidgetClick} />
        </OverlayWrapper>
        <ToolsNav
          onMenuItemClick={(component, index) => onHandleMenuItemClick(component, index)}
          enableMenuItems={enableMenuItems}
          activeTabIndex={activeTabIndex}
        />
      </>
    </Drawer>
  );
};

export default SideMenuItems;
