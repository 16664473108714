import React, { useState } from 'react';
import "../../components/widgets//orders/Orders.css";
import './orders.css';
import OrderDetails from './orderModal/OrderModal';
import clsx from 'clsx';
import { OrderRow, WidgetAction } from '../../types';
import { formatNumber } from '../../utils/getPrice';
import { OrderStatus_Status } from '../../cqg-api/proto/common/shared_1';
import { capitalizeFirstLetter, getOrderStatusLabel } from '../widgets/orders/actions';
import useOrders from './useOrders';
import { convertSymbolToYear, YearFormat } from '../tree-view/utils/convertSymbolToDate';
import { TradeFormData } from '../TradeTicketDrawer/types';
import { Instrument } from '../../cqg-api/models/Instrument';
import { Box, Modal } from '@mui/material';
import TradeTicketMobile from '../TradeTicketDrawer/TradeTicketMobile';
import { mobileTradeTicketStyles } from '../widgets/availableProducts/mobile/mobileTradeTicketStyles';
import { OrderState } from '../../cqg-api/models/OrderState';

type OrdersListProps = {
  dataRows: OrderRow[];
}

const OrdersList = ({ dataRows }: OrdersListProps) => {
  const [viewTradeTicket, setViewTradeTicket] = useState(false);
  const [externalData, setExternalData] = useState<TradeFormData>();
  const onCloseTradeClick = () => setViewTradeTicket(false);
  const [instrument, setInstrument] = useState<Instrument>();
  const { 
    showOrderDetails,
    closeOrderDetails,
    openSelectedDialog,
    selectedOrder,
  } = useOrders();
  const getSide = (side: string) => {
    if (side === "BUY") {
      return <span className='side buy'>B</span>
    }
    return <span className='side sell'>S</span>
  }
  const handleShowOrderDetails = (selectedOrder: OrderRow) => {
    if (selectedOrder.status === OrderStatus_Status.WORKING) {
      showOrderDetails(selectedOrder);
    }
  }

  const handleAmmendOrder = (order: OrderState) => {
    setViewTradeTicket(true);
    setExternalData({
      widgetData: {
        data: order,
        action: WidgetAction.AmendOrder
      },
    })
    setInstrument(order.instrument);
    closeOrderDetails();
  }
  return (
    <>
      {dataRows.length === 0 ? (
        <div className="no-orders-wrapper">
          <div className="no-orders">No Orders to display</div>
        </div>
      ) : (
        dataRows.map((row: OrderRow) => (
          <div
            key={row.id}
            className='flex w-full justify-between row py-2'
            onClick={() => handleShowOrderDetails(row)}
          >
            <div className='flex flex-col orderSide'>
              <div className='text-sm contract'>{getSide(row.side)}</div>
            </div>
            <div className='tableData-order'>
              <span className='text-sm'>{row.contract}</span>
              <span className='text-xs justify-end'>{row.strike === 0 ? "" : row.strike}</span>
              <span className='text-xs justify-end'>{row.qty}</span>
              <span
                className={clsx(
                  "justify-end",
                  "orderStatusValue",
                  {
                    "order-status-value--expired":
                      row.status === OrderStatus_Status.EXPIRED ||
                      row.status === OrderStatus_Status.REJECTED ||
                      row.status === OrderStatus_Status.CANCELLED,
                    "order-status-value--working": row.status === OrderStatus_Status.WORKING,
                  }
                )}
              >
                {capitalizeFirstLetter(getOrderStatusLabel(OrderStatus_Status, row.status))}
              </span>
              {row.symbol && (
                <span className='text-xs sm-info'>
                  {row.symbol} - {convertSymbolToYear(row.symbol, row.month, YearFormat.SHORT)}
                </span>
              )}
              <span className='text-xs justify-end'>{row.cp}</span>
              <span className='text-xs justify-end'>{row.type}</span>
              <span className='text-xs justify-end'>{formatNumber(parseFloat(row.limitPX ?? row.fillPX))}</span>
            </div>
          </div>
        ))
      )}
      <OrderDetails
        handleAmmendOrder={handleAmmendOrder}
        data={selectedOrder}
        open={openSelectedDialog}
        onClose={closeOrderDetails}
      />
      {viewTradeTicket && (
        <Modal open={viewTradeTicket} onClose={onCloseTradeClick}>
          <Box sx={mobileTradeTicketStyles}>
            <TradeTicketMobile
              tradeFormData={externalData}
              instrument={instrument}
              onCloseTradeClick={onCloseTradeClick}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default OrdersList;
