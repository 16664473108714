import { useEffect, useState } from "react";
import { WidgetType } from "../../../../types";
import MobileTreeViewModal from "../../../tree-view/mobile/MobileTreeViewModal";
import { PriceLadderSearchIcon } from "../../../../images/Icons";
import { Instrument } from "../../../../cqg-api/models/Instrument";

const InstrumentSelect = ({
  selectedInstrument,
  handleRowStateChange,
}: {
  selectedInstrument: Instrument;
  handleRowStateChange: (id: string, contract: any) => void;
}) => {
  const [value, setValue] = useState("");
  const [showTreeView, setShowTreeView] = useState(false);
  const openProductSelection = () => {
    setShowTreeView(true);
  };

  useEffect(() => {
    setValue(selectedInstrument?.displayName ?? "");
  }, [selectedInstrument?.displayName]);

  const updateValue = (displayName: string): void => {
    handleRowStateChange("", displayName);
  };

  return (
    <>
      <div onClick={openProductSelection} className="price-ladder-contract-select-container">
        <div className="price-ladder-contract-label">
          <PriceLadderSearchIcon />
          {value}
        </div>
      </div>
      {showTreeView && (
        <MobileTreeViewModal
          value={selectedInstrument?.displayName ?? ""}
          updateValue={updateValue}
          widgetType={WidgetType.Options}
          showTreeView={showTreeView}
          setShowTreeView={setShowTreeView}
        />
      )}
    </>
  );
};

export default InstrumentSelect;
