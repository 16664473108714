import { BootstrapTooltip } from "../../../shared/BootstrapTooltip";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { menuIconStyles } from "../PriceLadderStyles";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { PriceLadderIcon } from "../../../../images/Icons";
import { useMemo } from "react";
import clsx from "clsx";
import { OrderSide, OrderType, WidgetAction } from "../../../../types";
import { Depth } from "../types";
import { ordersPlacedOnDepth } from "../utils";

interface OrderContentProps {
  orderClass: string;
  cancelStyles: {
    height: string;
    width: string;
    cursor: string;
    color: string;
  };
  placedOrders: OrderState[];
  onCancelClick: (orders: OrderState[]) => void;
  isDragging: boolean;
  isMobileView?: boolean;
  onOpenMobileTradeTicket: (
    price: number | null,
    orderSide?: OrderSide,
    orderType?: OrderType,
    widgetAction?: WidgetAction,
    order?: OrderState,
  ) => void;
  depth: Depth;
  orderSide: OrderSide;
}

const OrderChip = ({
  orderClass,
  cancelStyles,
  placedOrders,
  onCancelClick,
  isDragging,
  isMobileView,
  onOpenMobileTradeTicket,
  depth,
  orderSide,
}: OrderContentProps) => {
  const totalOrdersQty = useMemo(
    () => placedOrders.reduce((sum, order: OrderState) => sum + (order.size || 0), 0),
    [placedOrders],
  );

  const handleOpenMobileTradeTicket = () => {
    const matchingOrders = ordersPlacedOnDepth(placedOrders, depth, orderSide);
    const order = matchingOrders[0];
    onOpenMobileTradeTicket(depth.displayPrice, undefined, undefined, WidgetAction.AmendOrder, order);
  };

  return (
    <span
      className={clsx({ [orderClass]: true, "price-ladder-order-pill": isMobileView })}
      onClick={isMobileView ? handleOpenMobileTradeTicket : undefined}
    >
      <>
        {isDragging ? (
          <PriceLadderIcon style={menuIconStyles} className="price-ladder-pointer" />
        ) : (
          <BootstrapTooltip title="Drag to adjust" placement="top">
            <span>
              <PriceLadderIcon style={menuIconStyles} className="price-ladder-pointer" />
            </span>
          </BootstrapTooltip>
        )}
      </>

      <span>{totalOrdersQty}</span>
      {!isMobileView && (
        <>
          <BootstrapTooltip title="Cancel order" placement="top">
            <CancelRoundedIcon style={cancelStyles} onClick={() => onCancelClick(placedOrders)} />
          </BootstrapTooltip>
        </>
      )}
    </span>
  );
};

export default OrderChip;
