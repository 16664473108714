import * as React from "react";
import { useEffect } from "react";
import { WatchListMode } from "./types";
import MyWatchlists from "./MyWatchlists";
import { useDeleteWatchlist, useFetchMyWatchlists } from "../../../../../hooks/api/watchlist";
import { Box, CircularProgress, TextField } from "@mui/material";
import { FeaturedWatchlist, MessageApiResponse, UserWatchlist } from "../../../../../cme-watchlist-api";
import FeaturedWatchlists from "../../../../featuredWatchlist/Index";
import { MarketDataProvider } from "../../../../../MarketDataProvider";
import { WatchlistType } from "../../../../../types";
import { useDispatch } from "react-redux";
import featuredWatchlistActions from "../../../../../redux/featuredWatchlist/featuredWatchlistActions";

type WatchlistManagerProps = {
  onAddToWorkspaceClick: any;
  watchlistMode: WatchListMode;
  onWatchListModeChange: (watchlistMode: WatchListMode, watchlistType?: WatchlistType) => void;
  onWatchlistNameChange: (watchlistName: string) => void;
  watchlistName: string;
  onEditWatchlistClick: (watchlist: any) => void;
  myWatchlists: UserWatchlist[];
  deleteUserWatchlist: (watchlistId: number) => Promise<MessageApiResponse>;
  onCopyWatchlistClick: (watchlist: UserWatchlist) => void;
  featuredWatchlist: FeaturedWatchlist[];
  setSelectedTreeItems: (selectedTreeItems: string[]) => void;
  loadingMyWatchlist: boolean;
  loadingEditMyWatchlist: boolean;
};

const WatchlistManager: React.FC<WatchlistManagerProps> = ({
  onAddToWorkspaceClick,
  watchlistMode,
  onWatchListModeChange,
  onWatchlistNameChange,
  watchlistName,
  onEditWatchlistClick,
  myWatchlists,
  deleteUserWatchlist,
  onCopyWatchlistClick,
  featuredWatchlist,
  setSelectedTreeItems,
  loadingMyWatchlist,
  loadingEditMyWatchlist,
}) => {
  const { myWatchlistdLoaded } = useFetchMyWatchlists();

  const { deleteWatchlistdLoaded } = useDeleteWatchlist();
  const dispatch = useDispatch();

  useEffect(() => {
    if (watchlistMode === WatchListMode.Edit) return;
    if (myWatchlistdLoaded && !myWatchlists.length) onWatchListModeChange(WatchListMode.Creation);
    if (myWatchlistdLoaded && myWatchlists.length) onWatchListModeChange(WatchListMode.ViewList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWatchlistdLoaded, myWatchlists.length]);

  const handleOnCreateNewWatchlistClick = (wType?: WatchlistType) => {
    onWatchListModeChange(WatchListMode.Creation, wType);
  }

  return (
    <>
      <div className="widget-dialog-description">
        {(() => {
          switch (watchlistMode) {
            case WatchListMode.ViewList:
              return (
                <>
                  <MyWatchlists
                    watchlists={myWatchlists}
                    deleteUserWatchlist={deleteUserWatchlist}
                    onCreateNewWatchlistClick={handleOnCreateNewWatchlistClick}
                    onAddToWorkspaceClick={onAddToWorkspaceClick}
                    onCopyWatchlistClick={onCopyWatchlistClick}
                    deleteWatchlistdLoaded={deleteWatchlistdLoaded}
                    onEditWatchlistClick={onEditWatchlistClick}
                    loadingMyWatchlist={loadingMyWatchlist}
                    loadingEditMyWatchlist={loadingEditMyWatchlist}
                  />
                  {featuredWatchlist.length > 0 ? (
                    <MarketDataProvider>
                      <FeaturedWatchlists
                        setSelectedTreeItems={setSelectedTreeItems}
                        onWatchlistNameChange={onWatchlistNameChange}
                        watchlist={featuredWatchlist}
                        addToWorkspace={onAddToWorkspaceClick}
                        onCreateNewWatchlistClick={handleOnCreateNewWatchlistClick}
                      />
                    </MarketDataProvider>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              );

            case WatchListMode.Creation:
            case WatchListMode.Edit:
            case WatchListMode.Edit_User_Panel:
              return (
                <>
                  <div style={{ marginBottom: "8px" }}>Watchlist name</div>
                  <div>
                    <TextField
                      id="title"
                      label="Enter Watchlist Name"
                      size="small"
                      sx={{ width: "100%", marginBottom: "8px" }}
                      className="basic-info-title-field"
                      value={watchlistName}
                      onChange={(e) => onWatchlistNameChange(e.target.value)}
                    />
                  </div>
                </>
              );
          }
        })()}
      </div>
    </>
  );
};

export default WatchlistManager;
