import axios from "axios";
import { ENV } from "../../env";

// TODO: FOR DEBUGGING PURPOSES ONLY. USE FROM CONFIG.
const API_BASE_URL = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL;
const API_FEATURED_BASE_URL = ENV.CME_WATCHLIST_API_URL;

const createClient = (baseURL: string) => {
  let docCookie = document.cookie;

  let headers = {
    "Content-Type": "application/json",
    accept: "*/*",
    withCredentials: true,
    cookies: docCookie,
  };

  return axios.create({
    baseURL: baseURL,
    headers: headers,
  });
};

const apiClient = createClient(API_BASE_URL);

export const apiFeaturedClient = axios.create({
  baseURL: API_FEATURED_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

export default apiClient;
