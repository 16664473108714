import { useEffect, useMemo, useState } from "react";
import { AssetProductsDictionary, InstrumentExt, ProductWithContracts, WidgetType } from "../../../types";

export const useFilteredAssets = (
  assetProductsDictionary: AssetProductsDictionary,
  searchQuery: string,
): AssetProductsDictionary => {
  return useMemo(() => {
    // Return the full object if the search query is empty
    if (searchQuery.trim() === "") {
      return assetProductsDictionary;
    }

    const inputSearchTextLower = searchQuery.toLowerCase();
    const filteredAssets: AssetProductsDictionary = {};

    for (const asset in assetProductsDictionary) {
      const products = assetProductsDictionary[asset].filter((item: ProductWithContracts) => {
        const nameInLowerCase = item.name?.toLowerCase() ?? "";

        // Check if the item matches the search query
        const matchesItem = nameInLowerCase.includes(inputSearchTextLower);

        // Check if any monthly contracts match the search query
        const matchesContracts = item.monthlyContracts?.some((contract) =>
          contract.displayName?.toLowerCase().includes(inputSearchTextLower),
        );

        return matchesItem || matchesContracts;
      });

      // Only keep assets that have matching products
      if (products.length > 0) {
        filteredAssets[asset] = products;
      }
    }

    return filteredAssets;
  }, [assetProductsDictionary, searchQuery]);
};

export const getFilteredAssets = (assetProductsDictionary: AssetProductsDictionary, searchQuery: string,): AssetProductsDictionary => {
  // Return the full object if the search query is empty
  if (searchQuery.trim() === "") {
    return assetProductsDictionary;
  }

  const inputSearchTextLower = searchQuery.toLowerCase();
  const filteredAssets: AssetProductsDictionary = {};

  for (const asset in assetProductsDictionary) {
    const products = assetProductsDictionary[asset].filter((item: ProductWithContracts) => {
      const nameInLowerCase = item.name?.toLowerCase() ?? "";

      // Check if the item matches the search query
      const matchesItem = nameInLowerCase.includes(inputSearchTextLower);

      // Check if any monthly contracts match the search query
      const matchesContracts = item.monthlyContracts?.some((contract) =>
        contract.displayName?.toLowerCase().includes(inputSearchTextLower),
      );

      return matchesItem || matchesContracts;
    });

    // Only keep assets that have matching products
    if (products.length > 0) {
      filteredAssets[asset] = products;
    }
  }

  return filteredAssets;
};

export function useFilteredAssetKeys(
  assetProductsDictionary: AssetProductsDictionary,
  selectedTreeItems: string[],
  isMobileView?: boolean,
  value?: string,
  monthlyContractsByDisplayNameMap?: Record<string, InstrumentExt>,
  widgetType?: WidgetType,
): string[] {
  const [filteredAssetKeys, setFilteredAssetKeys] = useState<string[]>([]);

  useEffect(() => {
    if (isMobileView && selectedTreeItems[0] === value) {
      const inputSearchTextLower = selectedTreeItems[0].toLowerCase();
      const filteredAssets: AssetProductsDictionary = {};

      for (const asset in assetProductsDictionary) {
        const products = assetProductsDictionary[asset].filter((item) => {
          const nameInLowerCase = item.name?.toLowerCase() ?? "";

          // Check if the item matches the search query
          const matchesItem = nameInLowerCase.includes(inputSearchTextLower);
          // Check if any monthly contracts match the search query
          const matchesContracts = item.monthlyContracts?.some((contract) =>
            contract.displayName?.toLowerCase().includes(inputSearchTextLower)
          );

          return matchesItem || matchesContracts;
        });

        if (products.length > 0) {
          filteredAssets[asset] = products;
        }
      }
      const searchPrefix = inputSearchTextLower.slice(0, 2);
      const idsFromSymbols = Object.values(filteredAssets).flatMap((products) =>
        products.filter((item) => item.cmeSymbol.toLowerCase().startsWith(searchPrefix)).map((item) => item.id.toString())
      );

      setFilteredAssetKeys([...Object.keys(filteredAssets), ...idsFromSymbols]);
    } else {
      if (monthlyContractsByDisplayNameMap && widgetType === WidgetType.Watchlist && filteredAssetKeys.length === 0) {
        const assetContractsMap: Record<string, string[]> = {}; // Stores asset class names with product IDs (ensuring uniqueness manually)
      
        selectedTreeItems
          ?.filter((item) => typeof item === "string")
          ?.forEach((selectedItem) => {
            const contractDetails = monthlyContractsByDisplayNameMap[selectedItem];
      
            if (contractDetails?.assetClassName) {
              if (!assetContractsMap[contractDetails.assetClassName]) {
                assetContractsMap[contractDetails.assetClassName] = [];
              }
      
              // Add contractId only if it's not already present
              if (!assetContractsMap[contractDetails.assetClassName].includes(contractDetails.productId.toString())) {
                assetContractsMap[contractDetails.assetClassName].push(contractDetails.productId.toString());
              }
            }
          });
      
        // Flatten values and remove duplicates manually
        const allProductIds: string[] = [];
        Object.values(assetContractsMap).forEach((contracts) => {
          contracts.forEach((contractId) => {
            if (!allProductIds.includes(contractId)) {
              allProductIds.push(contractId);
            }
          });
        });
        const combinedAssetKeys = [...allProductIds, ...Object.keys(assetContractsMap)];
        if (combinedAssetKeys.length) {
          setFilteredAssetKeys(combinedAssetKeys);
        }
      }
      
    }
  }, [assetProductsDictionary, isMobileView, selectedTreeItems, value, monthlyContractsByDisplayNameMap, filteredAssetKeys.length, widgetType]);

  return filteredAssetKeys;
}
