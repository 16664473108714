export const drawerStyle = {
  "& .MuiModal-modal": {
    zIndex: 10,
  },
  "& .MuiModal-backdrop": {
    left: 180,
    top: 83,
  },
  "& .MuiDrawer-paper": {
    width: 420,
    boxSizing: "border-box",
    backgroundColor: "#f4f4f4",
    left: 180,
    top: 83,
    boxShadow: "none",
    background: "#fff",
    border: "1px solid var(--divider, #0000001F)",
  },
};

export const actionButtonStyles = {
  width: "186px",
  color: "#006EB6",
  fontFamily: "Averta-Regular",
  fontSize: "11px",
  fontWeight: 700,
  lineHeight: "26px",
  letterSpacing: "0",
};

export const tooltipArrowStyles = {
  color: "#fff",
  fontSize: "16px",
  "&:before": {
    border: "1px solid #00000040",
  },
};

export const tooltipStyles = {
  maxWidth: "207px",
  minWidth: "100px",
  textAlign: "center",
  backgroundColor: "#fff",
  color: "#5A6874",
  boxShadow: "0px 0px 4px 0px #00000040",
  padding: "6px",
  fontSize: "10px",
  fontFamily: "Averta-Regular",
  fontWeight: 400,
  lineHeight: "14px",
  borderRadius: "4px",
};

export const radioButtonStyles = {
  width: "16px",
  height: "16px",
  "& .MuiRadio-root": {
    width: "16px",
    height: "16px",
  },
  "& .MuiSvgIcon-root": {
    width: "16px",
    height: "16px",
  },
};

export const confirmButtonStyle = {
  width: "12px",
  height: "22px",
  minWidth: "38px",
  lineHeight: "0",
  fontSize: "10px",
};

export const buttonStyles = {
  background: "#006EB6",
  fontSize: "11px",
  fontWeight: "600",
  fontFamily: "Averta-Regular",
  lineHeight: "13.6px",
  height: "30px",
  letterSpacing: "0",
  marginTop: "20px",
  minWidth: "118px",
};
