import { makeStyles } from "@mui/styles";

export const buttonStyles = {
  fontFamily: "Averta-Regular",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "normal",
  letterSpacing: "0%",
  color: "var(--Blue-Blue-4, #006EB6)",
  position: "relative",
  display: "inline-block",
  padding: "8px 0px",
  minWidth: "90px",
  textTransform: "none",
  marginRight: "12px",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "0%",
    height: "2px",
    backgroundColor: "var(--Blue-Blue-4, #006EB6)",
    transition: "width 0.3s ease",
  },
  "&:hover::after": {
    width: "100%",
  },
};

export const useStyles = makeStyles((theme) => ({
  inputField: {
    width: "576px",
    height: "30px",
    backgroundColor: "#EBEDEE",
    "& .MuiOutlinedInput-input": {
      padding: "4px",
    },
  },

  checkboxLabel: {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#25323C",
    "& .MuiFormControlLabel-label": {
      fontSize: "11px",
    },
  },
}));
