import { useCallback, useState } from "react";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import useOrderCancel from "../hooks/useOrderCancel";
import { cancelOrders } from "../../orders/actions";
import LadderTable from "./LadderTable";
import { OrderState } from "../../../../cqg-api/models/OrderState";
import { Depth, StopLoss, TakeProfit } from "../types";
import { OrderSide, OrderType, WidgetAction } from "../../../../types";
import ConfirmCancelDialog from "../../../shared/ConfirmCancelDialog";
import { CQGEnvironment } from "../../../../cqg-api/services/CQGEnvironment";
import { ordersPlacedOnDepth } from "../utils";
import { useOrdersData } from "../../../../OrdersDataProvider";
import { orderChain_SideFromJSON } from "../../../../cqg-api/proto/traderouting_1";
import ConfirmTradeDialog from "../../../TradeTicketDrawer/ConfirmTradeDialog";
import { useTradeProps } from "../../../TradeTicketDrawer/hooks/useTradeProps";
import { TradeTicketTab } from "../../../../types";
import { IFormInput } from "../../../TradeTicketDrawer/types";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";

interface PriceLadderBookProps {
  centerDivRef: React.MutableRefObject<any>;
  priceLadderBookRef: React.MutableRefObject<any>;
  quantityValue: number;
  realTimeInstrument: Instrument;
  createOrderHandler: (dorderSide: OrderSide, orderType: OrderType, price: number | null) => Promise<void>;
  contract: Instrument;
  oco: {
    BUY: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
    SELL: {
      takeProfit: TakeProfit;
      stopLoss: StopLoss;
    };
  };
  getOneClickTradeAsync: () => Promise<any>;
  openTradeTicket: (
    params: any,
    orderType: OrderType,
    orderSide: OrderSide,
    price: number | null,
    action?: WidgetAction,
  ) => void;
  isMobileView?: boolean;
  onOpenMobileTradeTicket: (
    price: number | null,
    orderSide?: OrderSide,
    orderType?: OrderType,
    widgetAction?: WidgetAction,
    order?: OrderState,
  ) => void;
  watch: UseFormWatch<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

const PriceLadderBook = ({
  centerDivRef,
  priceLadderBookRef,
  quantityValue,
  realTimeInstrument,
  contract,
  createOrderHandler,
  oco,
  getOneClickTradeAsync,
  openTradeTicket,
  isMobileView,
  onOpenMobileTradeTicket,
  watch,
  setValue,
}: PriceLadderBookProps) => {
  const { ordersList } = useOrdersData();
  const [draggedRowDepth, setDraggedRowDepth] = useState<Depth | null>(null);
  const { isDialogOpen, requestCancel, confirmCancel, closeDialog } = useOrderCancel();
  const [showConfirmTradeDialog, setShowConfirmTradeDialog] = useState(false);
  const [newDepth, setNewDepth] = useState<Depth>();
  const [dropedOrderSide, setDropedOrderSide] = useState<OrderSide>();
  const [matchingOrders, setMatchingOrder] = useState<OrderState[]>([]);

  const handleCancelOrder = (orders: OrderState[]) => {
    cancelOrders(orders);
  };

  const handleCancelClick = (orders: OrderState[]) => {
    requestCancel(() => handleCancelOrder(orders));
  };

  const createOrderHandlerClick = async (orderSide: OrderSide, orderType: OrderType, price: number | null) => {
    const isOneClickEnabled = await getOneClickTradeAsync();
    if (!isOneClickEnabled && !isMobileView) {
      openTradeTicket(realTimeInstrument, orderType, orderSide, price);
      return;
    }
    createOrderHandler(orderSide, orderType, price);
  };

  const handleDragStart = useCallback((dragStartDepth: Depth, index: number) => {
    setDraggedRowDepth(dragStartDepth);
  }, []);

  const modifyOrdersWithNewPrice = useCallback((orders: OrderState[], newPrice: number) => {
    orders.forEach((order) => {
      try {
        if (order.stopPrice) {
          Object.assign(order, { displayStopPrice: newPrice, stopPrice: newPrice });
        } else {
          Object.assign(order, { displayLimitPrice: newPrice, limitPrice: newPrice });
        }
        CQGEnvironment.Instance.cqgService.modifyOrder(order);
      } catch (error) {
        console.error("Failed to modify order:", { error, order });
      }
    });
  }, []);

  const confirmOrder = useCallback(() => {
    if (!newDepth || !dropedOrderSide || !matchingOrders) return;

    modifyOrdersWithNewPrice(matchingOrders, Number(newDepth.displayPrice));

    setShowConfirmTradeDialog(false);
    setDraggedRowDepth(null);
  }, [dropedOrderSide, matchingOrders, newDepth, modifyOrdersWithNewPrice]);

  const handleDrop = useCallback(
    async (newDepth: Depth, orderSide: OrderSide) => {
      if (!draggedRowDepth || draggedRowDepth.displayPrice === newDepth.displayPrice) return;

      const matchingOrders = ordersPlacedOnDepth(ordersList, draggedRowDepth, orderSide);
      if (!matchingOrders.length || matchingOrders[0].side !== orderChain_SideFromJSON(orderSide)) {
        console.warn("No valid matching order found for modification.");
        setDraggedRowDepth(null);
        return;
      }

      const isOneClickEnabled = await getOneClickTradeAsync();
      if (isOneClickEnabled && !isMobileView) {
        modifyOrdersWithNewPrice(matchingOrders, Number(newDepth.displayPrice));
        return;
      }

      setMatchingOrder(matchingOrders);
      setDropedOrderSide(orderSide);
      setNewDepth(newDepth);
      setValue("limitPrice", newDepth.displayPrice);
      setShowConfirmTradeDialog(true);
    },
    [draggedRowDepth, getOneClickTradeAsync, isMobileView, ordersList, setValue, modifyOrdersWithNewPrice],
  );

  return (
    <>
      <LadderTable
        priceLadderBookRef={priceLadderBookRef}
        instrumentWithMarketData={realTimeInstrument}
        centerDivRef={centerDivRef}
        quantityValue={quantityValue}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
        createOrderHandlerClick={createOrderHandlerClick}
        onCancelClick={handleCancelClick}
        oco={oco}
        contract={contract}
        isMobileView={isMobileView}
        onOpenMobileTradeTicket={onOpenMobileTradeTicket}
      />

      <ConfirmCancelDialog
        open={isDialogOpen}
        onClose={() => closeDialog()}
        onConfirm={confirmCancel}
        loading={false}
        title="Confirm Order Cancellation"
        message="Are you sure you want to cancel this orders?"
      />
      <ConfirmTradeDialog
        open={showConfirmTradeDialog}
        onClose={() => setShowConfirmTradeDialog(false)}
        cancel={() => setShowConfirmTradeDialog(false)}
        confirm={() => confirmOrder()}
        title={"Confirm Order Amend"}
        trade={useTradeProps({
          watch,
          activeTab: TradeTicketTab.Futures,
          selectedStrike: null,
          selectedContract: realTimeInstrument,
          selectedInstrument: contract,
        })}
      />
    </>
  );
};

export default PriceLadderBook;
