import moment from "moment";
import { Product } from "../../../../../cme-watchlist-api";
import { TimeUtil } from "../../../../../cqg-api/utils/TimeUtil";
import { CMEProduct, InstrumentExt, ProductWithContracts, WidgetType } from "../../../../../types";
import { convertSymbolToDateOnlyFullYear, monthYear } from "../../../../tree-view/utils/convertSymbolToDate";

export const areArraysIdentical = (list1: string[], list2: string[]) => {
  if (list1.length !== list2.length) {
    return false;
  }
  for (let i = 0; i < list1.length; i++) {
    if (list1[i] !== list2[i]) {
      return false;
    }
  }
  return true;
};

export const productMapper = (
  contractIds: string[],
  monthlyContractsByDisplayNameMap: Record<string, InstrumentExt>,
  productsMapById: Record<string, ProductWithContracts>,
  cmeProducts: Record<string, CMEProduct>,
  userSavedproducts?: Product[]
): Product[] => {
  const data = contractIds
    .map((contractId) => {
      if (typeof contractId === "string") {
        const contract = monthlyContractsByDisplayNameMap[contractId];
        // as some contracts not avaialble in our demo
        if (!contract) {
          const savedContract = userSavedproducts?.find((product) => product.contractCode === contractId) as any;
          return {
            productId: savedContract?.productId,
            productName: savedContract?.productName,
            productCode: savedContract?.productCode,
            contractCode: contractId,
            frontMonth: false,
            uri: "",
            sortOrder: 0,
            expirationMonth: savedContract?.expirationMonth,
            exchangeCode: "XCME",
            expirationDate: savedContract?.expirationDate?.replaceAll("-",""),
            type: "C",
            chart: {...savedContract?.chart},
          };
        };
        const product = productsMapById[contract.productId];
        const { yearChar } = convertSymbolToDateOnlyFullYear(contractId, contract.month as string);
        return {
          productId: cmeProducts[product.cmeSymbol]?.id,
          productName: product.name,
          productCode: product.cmeSymbol,
          contractCode: contractId,
          // need to verify frontMonth
          frontMonth: false,
          uri: "",
          sortOrder: 0,
          expirationMonth: `${contract.month} 202${yearChar}`,
          exchangeCode: "XCME",
          expirationDate: moment(TimeUtil.toUtcDate(contract.last_trading_date as number)).format("YYYYMMDD"),
          type: "C",
          chart: {
            code: product.cmeSymbol,
            title: `Chart+-+${contract.month}+202${yearChar}+${product.shortName}`,
            venue: "0",
            monthYear: monthYear(contractId).monthChar + monthYear(contractId).yearChar,
            year: "202" + yearChar,
          },
        };
      }
      return undefined;
    })
    .filter(Boolean);
  return data as any;
};

export const updateWidgetName = (displayName: string | undefined, widgetType: WidgetType) => {
  const widgetTypeMapping: any = {
    [WidgetType.PriceLadder]: "DOM",
    [WidgetType.Chart]: "Chart",
    [WidgetType.Options]: "OPT",
  };

  return `${displayName} ${widgetTypeMapping[widgetType]}`;
};
