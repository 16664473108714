import { FeaturedWatchlist, NewFeaturedWatchlist, UpdateFeaturedWatchlist } from "../models/featuredWatchlist";
import { MessageApiResponse } from "../models/common";
import { apiFeaturedClient } from "./apiClient"; 

const createFeaturedWatchlist = async (data: NewFeaturedWatchlist): Promise<FeaturedWatchlist> => {
  const resp = await apiFeaturedClient.post<FeaturedWatchlist>("/featured-watchlist", data);
  return resp.data;
};

const getFeaturedWatchlists = async (): Promise<FeaturedWatchlist[]> => {
  const resp = await apiFeaturedClient.get<FeaturedWatchlist[]>("/featured-watchlist");
  return resp.data;
};

const getFeaturedWatchlistById = async (watchlistId: number): Promise<FeaturedWatchlist> => {
  const resp = await apiFeaturedClient.get<FeaturedWatchlist>(`/featured-watchlist/${watchlistId}`);
  return resp.data;
};

const updateFeaturedWatchlist = async (watchlistId: number, data: UpdateFeaturedWatchlist): Promise<MessageApiResponse> => {
  const resp = await apiFeaturedClient.put<MessageApiResponse>(`/featured-watchlist/${watchlistId}`, data);
  return resp.data;
};

export const FeaturedWatchlistService = {
  getFeaturedWatchlists: getFeaturedWatchlists,
  getFeaturedWatchlistById: getFeaturedWatchlistById,
  createFeaturedWatchlist: createFeaturedWatchlist,
  updateFeaturedWatchlist: updateFeaturedWatchlist,
};
