import Button from "@mui/material/Button";
import { Control, FieldErrors, UseFormClearErrors, UseFormGetValues, UseFormWatch } from "react-hook-form";
import { Instrument } from "../../../../cqg-api/models/Instrument";
import { Box, Grid, Modal } from "@mui/material";
import SideController from "../../../TradeTicketDrawer/components/SideController";
import { gridItemStyles } from "../../../TradeTicketDrawer/styles";
import TakeProfitandLoss from "../OrderSettingsDialog/TakeProfitAndLoss/TakeProfitandLoss";
import { useEffect, useMemo, useState } from "react";
import { IFormInput } from "../../../TradeTicketDrawer/types";
import { mobileTradeTicketStyles } from "../../availableProducts/mobile/mobileTradeTicketStyles";
import CloseIcon from "@mui/icons-material/Close";
import "./OrderSettingsDialogMobile.css";
import { ocoMobileActionButtonStyles } from "../PriceLadderStyles";

interface OrderSettingsDialogMobileProps {
  open: boolean;
  onClose: () => void;
  control: Control<IFormInput, any>;
  selectedContract: Instrument;
  watch: UseFormWatch<IFormInput>;
  selectedInstrument: Instrument;
  setValue: any;
  errors: FieldErrors<IFormInput>;
  getValues: UseFormGetValues<IFormInput>;
  onSaveOcoSettings: any;
  clearErrors?: UseFormClearErrors<IFormInput>;
}

const OrderSettingsDialogMobile = ({
  open,
  onClose,
  control,
  selectedContract,
  watch,
  selectedInstrument,
  setValue,
  errors,
  getValues,
  onSaveOcoSettings,
  clearErrors,
}: OrderSettingsDialogMobileProps) => {
  const [instrument, setInstrument] = useState<Instrument>(selectedInstrument);

  useEffect(() => {
    setInstrument(selectedInstrument);
    if (clearErrors) {
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveOcoSettingsClick = () => {
    onSaveOcoSettings(getValues()?.oco);
  };

  const saveButtonDisabled = useMemo(
    () => Boolean(errors?.oco?.BUY) || Boolean(errors?.oco?.SELL),
    [errors?.oco?.BUY, errors?.oco?.SELL],
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={mobileTradeTicketStyles}>
        <div className="oco-mobile-heading">
          <div className="oco-mobile-heading--text">Settings</div>
          <CloseIcon onClick={onClose} className="oco-mobile-close" />
        </div>
        <hr />
        <Grid item xs={6} sx={{ ...gridItemStyles, marginBottom: "16px" }}>
          <SideController control={control} side={errors.side} setValue={setValue} />
        </Grid>
        <Grid container spacing={2}>
          <TakeProfitandLoss
            control={control}
            setValue={setValue}
            watch={watch}
            selectedContract={instrument}
            errors={errors}
            clearErrors={clearErrors}
          />
        </Grid>
        <div className="oco-mobile-action">
          <Button sx={ocoMobileActionButtonStyles} variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{
              ...ocoMobileActionButtonStyles,
              color: "#fff",
            }}
            variant="contained"
            disabled={saveButtonDisabled}
            onClick={() => onSaveOcoSettingsClick()}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default OrderSettingsDialogMobile;
