import React from "react";
import clsx from "clsx";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./style.css";
import { BodyHeader, DiscoverContainer, DiscoverHeader } from "./styled";
import ChallengeCard from "./ChallengeCard";
import PublicChallenges from "./PublicChallenges";
import TradingChallengeCard from "./components/TradingChallenge/TradingChallengeCard";
import ContactUsCard from "./components/TradingChallenge/ContactUs";
interface DiscoverProps {
  onHandleClose: () => void;
}

const Discover: React.FC<DiscoverProps> = ({ onHandleClose }) => {
  return (
    <div
      className={clsx({
        "fluid-discover": true,
      })}
    >
      <DiscoverContainer>
        <DiscoverHeader>
          <Typography>Discover</Typography>
          <IconButton onClick={onHandleClose}>
            <Close />
          </IconButton>
        </DiscoverHeader>
        <BodyHeader>
          <ChallengeCard />
          <PublicChallenges />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <TradingChallengeCard />
            <ContactUsCard />
          </Box>
        </BodyHeader>
      </DiscoverContainer>
    </div>
  );
};

export default Discover;
