import { FormControl, Select, styled } from "@mui/material";

export const inputStyles = {
  height: "36px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& input": {
      padding: "6.5px 6px",
      border: "none",
      textAlign: "center",
      fontSize: "14px",
      minWidth: "0px",
      fontfamily: "Averta-Regular",
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
    "& fieldset": {
      border: "none",
    },
  },
};

export const StyledSelect = styled(Select)({
  height: "36px",
  borderRadius: "4px",
  backgroundColor: "#fff",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#d1d1d1",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2",
  },
});

export const StyledFormControl = styled(FormControl)({});

export const flattenButtonStyles = { fontSize: '12px' ,width: "100%",minWidth: "78px", height: "36px", color: "#006EB6", fontWeight: 600, fontFamily: "Averta-Regular", letterSpacing: "1.5px", padding: "6px 0px" };

export const cancelAllButtonStyles = { color: "#006EB6", fontWeight: 600,
  //  minWidth: "115px" 
  width: "100%",
  fontFamily: "Averta-Regular",
  letterSpacing: "1.5px",
  padding: "6px 4px",
  whiteSpace: "nowrap",
  fontSize: "12px",
  minWidth: '63px'
  };

export const buyMarketButtonStyles = {
  background: "#4CAF50",
  minWidth: "95px",
  width: "100%",
  padding: "6px 4px",
  whiteSpace: "nowrap",
  fontSize: "12px",
  "&:hover": { background: "#4CAF50" },
  fontFamily: "Averta-Regular",
  fontWeight: 600,
  letterSpacing: "1.5px",
};
export const sellMarketButtonStyles = {
  background: "#EF5350",
  minWidth: "95px",
  width: "100%",
  padding: "6px 4px",
  whiteSpace: "nowrap",
  fontSize: "12px",
  "&:hover": { background: "#EF5350" },
  fontFamily: "Averta-Regular",
  fontWeight: 600,
  letterSpacing: "1.5px",
};

export const cancelRoundedBuyStyles = { height: "14px", width: "14px", cursor: "pointer", color: "#2E7D32" };

export const cancelRoundedSellStyles = { height: "14px", width: "14px", color: "red", cursor: "pointer" };

export const menuIconStyles = { height: "8px", width: "12px", color: "#081D378F" };

export const settingsIconStyles = (isValidOco: boolean) => ({
  color: isValidOco ? "#006EB6" : "rgba(8, 29, 55,0.56)",
  cursor: "pointer",
});


export const ocoMobileActionButtonStyles = {
  color: "rgba(8, 29, 55, 0.87)",
  fontFamily: "Averta-Regular",
  letterSpacing: "1.5px",
  height: "41px",
  width: "100%",
}