import { Box, Chip } from "@mui/material";
import { WidgetType } from "../../types";
import CloseIcon from "@mui/icons-material/Close";
import { chipBaseStyles } from "./mobile/styled";

const TreeViewChip = ({
  selectedTreeItems,
  handleDelete,
  widgetType,
  isEnabled,
  isMobileView,
  unavailableProductsList,
}: {
  selectedTreeItems: string[];
  handleDelete: (item: string) => void;
  widgetType?: WidgetType;
  isEnabled?: boolean;
  isMobileView?: boolean;
  unavailableProductsList?: { productId: number; contractCode: string }[];
}) => {
  const isPriceLadder = widgetType === WidgetType.PriceLadder;
  const isMobile = isMobileView === true;

  const isItemEnabled = (item: string) => {
    if (unavailableProductsList && unavailableProductsList.length > 0) {
      return !unavailableProductsList.some((missingItem) => missingItem.contractCode === item);
    }
    return isEnabled;
  };

  const getChipStyles = () => {
    if (isPriceLadder && isMobile) {
      return {
        backgroundColor: "white",
        border: "1px solid var(--Grey-Grey-4, #C2CACE)",
        color: "var(--Grey-Grey-2, #25323C)",
      };
    }
    if (isPriceLadder) {
      return {
        backgroundColor: "var(--primary-Gray3, #5A6874)",
        border: "1px solid transparent",
        color: "white",
      };
    }
    if (isMobile) {
      return {
        backgroundColor: "white",
        border: "1px solid var(--Grey-Grey-4, #C2CACE)",
        color: "var(--Grey-Grey-2, #25323C)",
      };
    }
    return {
      backgroundColor: "white",
      border: "1px solid var(--primary-main-blue, #006EB6)",
      color: "var(--primary-main-blue, #006EB6)",
    };
  };

  const getDeleteIconStyles = () => {
    if (isPriceLadder && isMobile) {
      return {
        backgroundColor: "white",
        color: "#5A6874",
      };
    }
    if (isPriceLadder) {
      return {
        backgroundColor: "white",
        color: "#5A6874",
      };
    }
    if (isMobile) {
      return {
        backgroundColor: "white",
        color: "black",
      };
    }
    return {
      backgroundColor: "var(--primary-main-blue, #006EB6)",
      color: "white",
    };
  };

  return (
    <>
      {selectedTreeItems
        .filter((item) => typeof item === "string")
        .map((item) => {
          const chipStyles = getChipStyles();
          const deleteIconStyles = getDeleteIconStyles();

          return (
            <Chip
              key={item}
              label={item}
              onDelete={() => handleDelete(item)}
              disabled={!isItemEnabled(item)}
              deleteIcon={
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: deleteIconStyles.backgroundColor,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon
                    data-testid="CloseIcon"
                    sx={{
                      color: deleteIconStyles.color,
                      fontSize: "10px",
                    }}
                  />
                </Box>
              }
              sx={{
                ...chipBaseStyles,
                border: chipStyles.border,
                backgroundColor: chipStyles.backgroundColor,
                color: chipStyles.color,
              }}
            />
          );
        })}
    </>
  );
};

export default TreeViewChip;
