import { useCallback, useState } from "react";
import { Mode_Type, USER_MODE, Watchlist } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import axios from "axios";
import { useGetCurrentUser } from "./authentication";
import { TradePlan } from "../../components/home/components/tradePlan/TradePlanType";
import { getFromLS } from "../../storage";
import { ENV } from "../../env";

export const useFetchMyTradePlan = () => {
  const [error, setError] = useState<Error>();
  const [myTradePlanLoaded, setMyTradePlanLoaded] = useState<boolean>();
  const [myTradePlan, setMyTradePlan] = useState<TradePlan>();
  const currentUser = useGetCurrentUser();
  const appMode = getFromLS(USER_MODE)
  const is_practice = appMode === Mode_Type.PRACTICE_MODE;

  const loadMyTradePlanAsync = useCallback(
    async (onSuccess?: (response: Watchlist) => void, onFail?: () => void) => {
      if(!currentUser.id){
        console.error("UserID not found");
        return; 
      }
      try {
        setMyTradePlanLoaded(false);
        let uri = `${ENV.APP_API_URL}/get_member_trade_plan`;
        const response = await axios.get(uri, {
          params: { member_id: currentUser.id, is_practice: is_practice }
        });        
        setMyTradePlan(response.data);
        onSuccess?.(response.data)
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not fetch trade plan, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setMyTradePlanLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { loadMyTradePlanAsync, error, myTradePlanLoaded, myTradePlan };
};

export const useUpdateTradePlan = () => {
    const [error, setError] = useState<Error>();
    const [updateTradePlanLoaded, setUpdateTradePlanLoaded] = useState(true);
    const currentUser = useGetCurrentUser();
    const appMode = getFromLS(USER_MODE)
    const is_practice = appMode === Mode_Type.PRACTICE_MODE;
    const updateTradePlanAsync = useCallback(
      async (
        tradePlan: any,
        onSuccess?: () => void,
        onFail?: () => void,
      ) => {
        try {
          setUpdateTradePlanLoaded(false);
          await axios.post(`trade_plan`, {
            tradePlan: tradePlan,
            memberId: currentUser.id,
            is_practice: is_practice
          });
          onSuccess?.()
          Notification.success("Trade Plan updated successfully");
        } catch (error: any) {
          console.error(error);
          Notification.error(`Could not update trade plan, ${error?.message}`);
          setError(error);
          onFail?.();
        } finally {
          setUpdateTradePlanLoaded(true);
        }
      },
      [],
    );
  
    return { updateTradePlanAsync, error, updateTradePlanLoaded };
  };
