import actions from './productActions';

const initialData = {
  loadingInit: true,
  loading: false,
  products: [],
  cmeProducts: [],
};

export const products = (state = initialData, { type, payload }) => {
  if (type === actions.PRODUCT_GET_SUCCESS) {
    return state.products;
  }
  if (type === actions.PRODUCT_SET_SUCCESS) {
    return {...state, products: payload};
  }
  if (type === actions.CME_PRODUCT_GET_SUCCESS) {
    return state.cmeProducts;
  }
  if (type === actions.CME_PRODUCT_SET_SUCCESS) {
    return {...state, cmeProducts: payload};
  }
  return state;
};
