import { SvgIconProps } from "@mui/material";
import React from "react";

const loaderStyle = {
  animation: "rotateLoader 2s linear infinite",
};

export const ScreenLoader: React.FC<SvgIconProps> = ({ width = 50, height = 50, ...props }) => {
  return (
    <svg
      {...props}
      style={loaderStyle}
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 2V10M22.5 34V42M8.36 7.86L14.02 13.52M30.98 30.48L36.64 36.14M2.5 22H10.5M34.5 22H42.5M8.36 36.14L14.02 30.48M30.98 13.52L36.64 7.86"
        stroke="#081D37"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const styleSheet = document.styleSheets[0];
const keyframes = `
  @keyframes rotateLoader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
