import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";
import ContainedButton from "../shared/ContainedButton";
import CustomTextField from "../shared/CustomTextField";
import CustomCheckboxWithLabel from "../shared/CustomCheckboxWithLabel";

const PromoCodeCard: React.FC = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.content} sx={{ gap: "24px" }}>
        <Typography className={classes.description}>
          These trading challenges are customized for small groups as access is limited. If you have been given an
          access code for a private trading challenge, please enter it below.
        </Typography>

        <Box className={classes.promoCodeContainer}>
          <CustomTextField
            placeholder="Enter your code here"
            value={inputValue}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
          <Box className={classes.checkboxSection}>
            <CustomCheckboxWithLabel
              label="I have read and agree to the Trading Simulator User Agreements"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </Box>
          <ContainedButton onClick={() => {}} text="SUBMIT" />
        </Box>
      </Box>
    </Box>
  );
};

export default PromoCodeCard;
