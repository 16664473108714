import actions from "./authActions";

const initialData = {
  loadingInit: true,
  loading: false,
};

export const auth = (state = initialData, { type, payload }) => {
  if (type === actions.AUTH_INIT_SUCCESS) {
    return {
      ...state,
      loadingInit: false,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...state,
      loadingInit: false,
    };
  }

  if (type === actions.AUTH_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.AUTH_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.AUTH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      errorMessage: null,
      loading: false,
    };
  }

  if (type === actions.AUTH_LOGOUT) {
    return {
      ...state,
      currentUser: null,
      errorMessage: null,
      loading: false,
    };
  }

  return state;
};
