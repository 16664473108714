import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useStyles } from "./styled";

const ChallengeCard: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.imageSection}>
        <Box className={classes.rectangle}>
          <IconButton className={classes.playButton}>
            <PlayArrowIcon />
          </IconButton>
          <Box className={classes.vector} />
        </Box>
      </Box>
      <Box className={classes.textSection}>
        <Typography variant="h4" className={classes.title}>
          Introduction to Challenges
        </Typography>
        <Typography variant="body2" className={classes.description}>
          Experience trading futures and options in a realistic yet risk-free environment. Learn what it’s like trading
          the world’s leading derivatives products using live market data in our simulated environment. Track your
          performance and see if you can outperform your peers.
        </Typography>
      </Box>
    </Box>
  );
};

export default ChallengeCard;
