import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { useGetCurrentUser } from "../../../../hooks/api/authentication";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "4px 12px",
    backgroundColor: "#E5F6FD",
    borderRadius: "5px",
  },
  titleText: {
    fontFamily: "Averta-Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "160%",
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: "rgba(8, 29, 55, 0.87)",
  },
  descriptionText: {
    fontFamily: "Averta-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "143%",
    letterSpacing: "0.17px",
    color: "rgba(8, 29, 55, 0.87)",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 0px",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "84px",
    height: "16px",
    borderRadius: "4px",
    fontFamily: "Averta-Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: "#006EB6",
  },
  divider: {
    width: "100%",
    margin: "8px 0",
  },
  linkStyles: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontFamily: "Averta-Regular",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    border: "none",
    "&:hover": { border: "none", backgroundColor: "transparent" },
  }
};

const TradeHistoryDownload = () => {
  const apiUrl = process.env.REACT_APP_API_URL || window.REACT_APP_API_URL;
  const currentUser = useGetCurrentUser();

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "8px 0px" }}>
        <Typography sx={styles.titleText}>Trade History</Typography>
        <Typography sx={styles.descriptionText}>Download your trading history*</Typography>
      </Box>
      <Link href={`${apiUrl}/trading_tools/simulator/member/${currentUser?.id}/download_trade_log.csv`} sx={styles.linkStyles}>
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.button}>DOWNLOAD</Button>
        </Box>
      </Link>
    </Box>
  );
};

export default TradeHistoryDownload;
