import { useCallback, useMemo } from "react";
import { CQGConstants } from "../../../cqg-api/constants/CQGConstants";
import { OrderType } from "../../../types";
import { ocoInitial } from "../../widgets/priceLadder/types";
import { getDefaultQty, getDefaultSide, getDefaultTIF } from "../utils";
import { OrderState } from "../../../cqg-api/models/OrderState";
import { TradeFormData } from "../types";

const useCreateDefaultFormValues = (
  defaultContract: any,
  orderData: any,
  amendOrder: any,
  widgetData: any,
  tradeFormData?: TradeFormData,
) => {
  const getDefaultOrderType = useCallback(() => {
    if (widgetData?.data) {
      const order = widgetData?.data as OrderState;
      return CQGConstants.getOrderType(order.orderType);
    }
    if (!!orderData && orderData.orderType) {
      return CQGConstants.getOrderType(orderData.orderType);
    }
    return OrderType.Mkt;
  }, [orderData, widgetData?.data]);

  return useMemo(
    () => ({
      contract: defaultContract?.displayName,
      side: getDefaultSide(amendOrder, orderData),
      orderType: getDefaultOrderType(),
      timeInForce: widgetData?.data?.timeInForce ? widgetData?.data?.timeInForce : getDefaultTIF(amendOrder),
      quantity: getDefaultQty(amendOrder, orderData),
      limitPrice: tradeFormData ? widgetData?.data?.limitPrice : (defaultContract?.lastPrice ?? 0),
      stopPrice: tradeFormData ? widgetData?.data?.stopPrice : (defaultContract?.lastPrice ?? 0),
      rawLimitPrice: tradeFormData ? widgetData?.data?.limitPrice : (defaultContract?.lastPrice ?? 0),
      rawStopPrice: tradeFormData ? widgetData?.data?.stopPrice : (defaultContract?.lastPrice ?? 0),
      putCall: undefined,
      strikePrice: "",
      oco: widgetData?.data?.oco ?? ocoInitial,
    }),
    [
      defaultContract?.displayName,
      defaultContract?.lastPrice,
      amendOrder,
      orderData,
      getDefaultOrderType,
      tradeFormData,
      widgetData?.data?.timeInForce,
      widgetData?.data?.limitPrice,
      widgetData?.data?.stopPrice,
      widgetData?.data?.oco,
    ],
  );
};

export default useCreateDefaultFormValues;
