import { CQGApi } from "../cqg-api/CGQApi";
import { Instrument } from "../cqg-api/models/Instrument";
import { MarketDataSubscription_Level } from "../cqg-api/proto/market_data_2";
import { Product } from "../types";

const LEVEL = MarketDataSubscription_Level.LEVEL_TRADES_BBA_VOLUMES;

export const subscribeToInstruments = async (products: Product[]): Promise<Instrument[]> => {
  if (products.length === 0) return [];
  let cqgApi = CQGApi.Instance;

  const cqgSymbols = products.map((product) => {
    return product.cqgSymbol;
  });
  const instruments: Instrument[] = [];
  cqgSymbols.forEach(async (symbol) => {
    const resolvedInstruments = await cqgApi.resolveCQGSymbols(symbol);
    instruments.push(...resolvedInstruments);
    resolvedInstruments.forEach((inst: Instrument) => {
      cqgApi.subscribeToMarketData([inst.contractId!], LEVEL);
      // console.log("subscribed", inst.contractId, inst.title);
    });
  });
  return instruments;
};

export const subscribeToContracts = async (contractIds: number[], level = LEVEL) => {
  let cqgApi = CQGApi.Instance;
  if (contractIds.length === 0) return [];
  contractIds.forEach((contractId) => {
    cqgApi.subscribeToMarketData([contractId], level);
  });
};

export const unsubscribeContracts = (contracts: number[]) => {
  if (contracts.length === 0) return;
  let cqgApi = CQGApi.Instance;
  contracts.forEach((contract) => {
    // console.log("Unsubscribing instrument:", instrument.contractId, instrument.title);
    cqgApi.unSubscribeFromMarketData([contract!], LEVEL);
  });
};

export const subscribeToInstrumentsDepths = async (contractIds: number[]) => {
  subscribeToContracts(contractIds, MarketDataSubscription_Level.LEVEL_TRADES_BBA_DOM);
};

export const unsubscribeInstuments = (instruments: Instrument[]) => {
  if (instruments.length === 0) return;
  let cqgApi = CQGApi.Instance;
  instruments.forEach((instrument) => {
    // console.log("Unsubscribing instrument:", instrument.contractId, instrument.title);
    cqgApi.unSubscribeFromMarketData([instrument.contractId!], LEVEL);
  });
};

export const unsubscribeContractIds = (contractIds: number[]) => {
  if (contractIds.length === 0) return;
  let cqgApi = CQGApi.Instance;
  contractIds.forEach((contractId) => {
    // console.log("Unsubscribing instrument:", instrument.contractId, instrument.title);
    cqgApi.unSubscribeFromMarketData([contractId!], LEVEL);
  });
};

export const unsubscribeInstrumentsList = async (instruments: Instrument[]) => {
  if (instruments.length === 0) return [];
  let cqgApi = CQGApi.Instance;

  const cqgSymbols = instruments.map((instrument) => instrument.cqgSymbol).filter((symbol) => symbol);

  cqgSymbols.forEach(async (symbol) => {
    const resolvedInstruments = await cqgApi.resolveCQGSymbols(symbol || "");
    // console.log("Unsubscribing instrument:", resolvedInstruments[0].contractId, resolvedInstruments[0].title);
    cqgApi.unSubscribeFromMarketData([resolvedInstruments?.[0].contractId!], LEVEL);
  });
};
