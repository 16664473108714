import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useStyles } from "./style";

interface CustomCheckboxWithLabelProps {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: object;
  className?: string;
}

const CustomCheckboxWithLabel: React.FC<CustomCheckboxWithLabelProps> = ({
  label,
  checked,
  onChange,
  sx = {},
  className,
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: "16px",
            },
            "& .MuiTypography-root": {
              fontSize: "11px",
            },
            ...sx,
          }}
        />
      }
      label={label}
      className={`${classes.checkboxLabel} ${className}`}
    />
  );
};

export default CustomCheckboxWithLabel;
