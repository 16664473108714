import React from "react";
import { Box, Typography } from "@mui/material";
import { containerStyle, titleStyle, useStyles } from "./style";
import ViewDetailsButton from "../shared/CustomButton";

const ContactUsCard: React.FC = () => {
  const classes = useStyles();
  const handleViewDetails = () => {
    console.log("View Details");
  };
  return (
    <Box sx={containerStyle}>
      <Typography sx={titleStyle}>Contact Us About a Trading Challenge</Typography>
      <Box className={classes.card}>
        <Box className={classes.content} sx={{ gap: "8px" }}>
          <Typography className={classes.title}>
            Design your own Private Trading Challenge will contracts roll?{" "}
          </Typography>
          <Typography className={classes.description}>
            Create a custom trading challenge using live market data for your private group ‒ classrooms, trading desks,
            new hires, interns, or other organizations. At no cost to you, we will design a unique challenge environment
            specific to your learning objectives. There is no better way to learn about the derivatives market than
            through hands-on experience in a simulated environment.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <ViewDetailsButton onClick={handleViewDetails} text="View Details" />
            <ViewDetailsButton onClick={handleViewDetails} text="Request a Private Challenge" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsCard;
