import { useMediaQuery } from "@mui/material";
import { CMELogoDesktopIcon, CMELogoIcon } from "../../images/Icons";
import { useTheme } from "@mui/material/styles";
import mobile from "is-mobile";

const Logo = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();

  return (
    <div className={isMobileView ? "logo-mobile" : "logo"}>
      {!isMobileView ? (
        <div className="logo">
          <CMELogoDesktopIcon />
          <div className="logo-content">CME Group</div>
        </div>
      ) : (
        <CMELogoIcon />
      )}
    </div>
  );
};

export default Logo;
