import { SvgIconComponent } from "@mui/icons-material";
import { SerializedDockview } from "dockview";
import ReactGridLayout from "react-grid-layout";
import { Instrument } from "./cqg-api/models/Instrument";
import { ContractMetadata } from "./cqg-api/proto/metadata_2";
import { IContract } from "./components/featuredWatchlist/types";

export type DemoGridLayoutProps = {
  width?: number;
  defaultLayout: ReactGridLayout.Layout[];
  onLayoutChanged: (layout: ReactGridLayout.Layout[]) => void;
  selectedWorkspace?: Workspace;
};

export type WorkspaceData = {
  title?: string;
  description?: string;
  serializedDockview?: SerializedDockview;
  widgets: Widget[];
};

export type Workspace = {
  title: string;
  data?: SerializedDockview;
  description?: string;
  id?: number;
  memberId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isDefault?: boolean;
};
export type DefaultWorkspaceData = {
  workspace: ReactGridLayout.Layout[];
  workspaceId: number;
};
export type DefaultWorkspace = DefaultWorkspaceData | null;

export type Widget = {
  id?: string;
  title: string;
  key: number;
  icon?: SvgIconComponent | string;
  widgetType: WidgetType | string;
  watchlistType?: WatchlistType;
  widgetId?: number;
  workspaceId?: number;
  contractIds?: string[];
  widgetName?: string;
  widgetData?: {
    action: WidgetAction,
    data: any,
    contractMonth?: any
  };
  expired?: boolean;
  panelHeight?: number;
  layoutHeight?: number;
};

export enum WatchlistType  {
  User,
  Featured
}

export type User = {
  accessToken?: string;
  name?: string;
  id?: string;
  accounts: Account[];
  firstName?: string;
  lastName?: string;
};

export enum AccountType {
  Challenge = "challenge",
  Simulator = "simulator",
}

export type Account = {
  accountId: number;
  accountType: AccountType;
  password: string;
  username: string;
};

export type Product = {
  id: string;
  cmeSymbol: string;
  description: string;
  name: string;
  tickSize: string;
  shortName: string;
  assetClass?: any;
  cqgSymbol: string;
};

export type CMEProduct = {
  id: number;
  guid: string;
  prodCode: string;
  prodGroup: string;
  name: string;
  clearing: string;
  globex: string;
  floor: string;
  floorTraded: boolean;
  globexTraded: boolean;
  cpc: string;
  venues: string;
  cleared: string;
  exch: string;
  tags: string | null;
  style: string | null;
  url: string;
  cat: string;
  subCat: string;
  group: string;
  subGroup: string;
  vol: string;
  globexVol: string;
  cpcVol: string;
  floorVol: string;
  oi: string;
};

export type ProductWithContracts = Product & {
  monthlyContracts?: Instrument[];
};

export type Watchlist = {
  id: number;
  workspaceId: number;
  associatedProducts: string;
  title: string;
};

export type InstrumentMarketData = Product & {
  askPrice?: number | string | null;
  askQty?: number | string | null;
  bidPrice?: number | string | null;
  bidQty?: number | string | null;
  lastPrice?: number | string | null;
  lastQty?: number | string | null;
  change?: number | null;
  changePercentage?: string | null;
  price?: number | string | null;
  title?: string | null;
  tradeVolume?: number | string | null;
  monthlyContract?: Instrument | undefined;
  labelTotalVolume?: number | string | null;
  yesterdaySettlement?: number | null;
  productName?: string;
};

export enum WidgetType {
  AccountInfo = "Account Info",
  Watchlist = "Watchlists",
  AvailableProducts = "Markets",
  Orders = "Orders",
  Positions = "Positions",
  EnergyChart = "Energy chart",
  BarChart = "Bar chart",
  LineChart = "Line chart",
  DoubleBarChart = "Donut chart",
  TradeLogs = "Performance",
  Chart = "Chart",
  Performance = "Performance",
  Commentary = "Commentary",
  PriceLadder = "Price Ladder",
  TradeTicket = "Trade Ticket",
  Options = "Options",
  EconodayCalendar = "Calendar",
}

export enum WidgetAction {
  AmendOrder = "AmendOrder",
  CreateOrder = "CreateOrder",
  PositionTrade = "PositionTrade",
  Options = "Options"
}

export type DockerviewPanelProps = {
  title?: string;
  widget: Widget;
  workspace?: Workspace;
  workspaceId?: number;
};

export const WORKSPACES = "WORKSPACES";

export type AssetProductsDictionary = {
  [assetClassName: string]: ProductWithContracts[];
};

export enum TreeViewMode {
  SINGLE = "single",
  MULTIPLE = "multiple",
}

export const CONTRACT_ID_NAME = "displayName";

export enum positionsFilter {
  All = "All",
  Open = "Open",
  Closed = "Closed",
}

export enum OrderSide {
  Buy = "BUY",
  Sell = "SELL",
}

export enum OrderType {
  Mkt = "MKT",
  Lmt = "LMT",
  Stp = "STP",
  Stl = "STL",
  Cross = "CROSS",
  Unrecognized = "UNRECOGNIZED",
}

export enum PositionDisplayEnum {
  LongShort,
  PlusMinus,
}
export enum PositionDisplay {
  Long = "Long",
  Short = "Short",
}

export type TradeLogProps = {
  capitalRiskPerTrade: string,
  pctCapitalRiskPerTrade: string,
  pctCapitalToMargin: string,
  approach: string,
  trend: string,
  ratio: string,
  useStopLoss: string,
  tradePlanSymbols: TradePlanSymbolType[],
  approachFundamentalAnalysis: [],
  approachTechnicalType: string,
  approachTechnicalChartType: string,
  approachTechnicalIndicator: [],
  approachTechnicalIndicatorOther: string;
  comments: string;
}

export type SymbolType = { symbol: string; symbolId: number | string; name: string };

export type TradePlanSymbolType = {
    symbolId?: string; cmeSymbolId: number; cmeSymbol: SymbolType 
};

export enum TradeTicketTab {
  Futures = 'futures',
  Options = 'options'
}

export enum Order_Type {
  MARKET = "MARKET",
  LIMIT = "LIMIT",
  STOP = "STOP",
};
export type Row = {
  id: string;
  contract: string;
  symbol: string;
  month: string;
  buys: number;
  sells: number;
  cp: string;
  direction: string;
  strike: number;
  relizedPL: string;
  unrealizedPL: string;
  averagePX: string;
  realizedPL: string;
  size: number;
}
export enum Mode_Type {
  PRACTICE_MODE = "Practice Mode",
  CHALLENGE_MODE = "Challenge Mode",
}

export type OrderRow = {
  id: string;
  contract: string;
  status: number;
  side: string;
  symbol: string;
  month: string;
  buys: string;
  cp: string;
  direction: string;
  type: string;
  strike: number;
  relizedPL: string;
  unrealizedPL: string;
  averagePX: string;
  realizedPL: string;
  qty: string;
  fillPX: string;
  limitPX: string;
}
export enum OrderOption {
  Call = "CALL",
  Put = "PUT",
}

export type EconodayEvents = {
  id: number;
  name: string;
  country?: string;
  releasedOn: Date;
  eventAttribute: number,
  description: string;
  definition: string;
  econodayEventValues: Array<{
    valueName: string;
    actual: number | null;
    previous: string;
    revised: string | null;
    prefix: string | null;
    suffix: string | null;
  }>;
}

export type EconodayEvent = {
  id: number
  name: string
  modifydate: string
  country: string
  eventCode: any
  displayNameId: string
  definition: string
  description: string
  eventId: number
  releasedFor: string
  releasedOn: string
  frequency: number
  interactive_url: any
  eventAttribute: number
  econodayEventValues: EconodayEventValue[]
}
export type EconodayEventValue = {
  id: number
  econodayEvent_id: number
  valueName: string
  valueUid?: number
  displayValueId: number
  consensus?: string
  consensusrangefrom?: string
  consensusrangeto?: string
  actual?: string
  previous?: string
  revised: any
  prefix: any
  suffix: string
  created_at: string
  updated_at: string
}

export class InstrumentExt extends Instrument {
  productId: string;
  monthlyContracts: Instrument[];

  constructor(contractMetadata: ContractMetadata , productId: string) {
    super(contractMetadata);
    this.productId = productId;
    this.monthlyContracts = [];
  }
}

export const TIME_ZONE = "America/Chicago";

export const SELECTED_MOBILE_WIDGET_INSTRUMENT = "selected.mobile.widget.instrument";

export const USER_MODE = "userMode";

export const APP_DOWNLOAD_MODAL ="appDownloadModalSeen"

export const MY_WATCHLIST = "myWatchlist"