import { MenuItem, Select } from "@mui/material";
import { checkIconStyles, menuStyles, selectStyles } from "../../../widgets/availableProducts/marketStyles";
import CheckIcon from "@mui/icons-material/Check";
import { TabFilter } from "./types";
import { useMemo } from "react";

const Tabs = ({
  isMobile,
  isSmallPanel,
  activeTab,
  onTabChange,
}: {
  isMobile: boolean;
  isSmallPanel: boolean;
  activeTab: string;
  onTabChange: (tab: TabFilter) => void;
}) => {
  const tabs = useMemo(() => Object.values(TabFilter), []);
  const handleTabChange = (tab: TabFilter) => {
    onTabChange(tab);
  };
  return (
    <div className="education-type-wrapper" style={{ flex: isMobile ? 1 : undefined }}>
      {isSmallPanel || isMobile ? (
        <Select
          sx={{
            ...selectStyles,
            backgroundColor: "#EBEDEE",
            color: "#25323C",
            minWidth: isMobile ? "100%" : "196px",
            height: isMobile ? "30px" : isSmallPanel ? "30.3px" : undefined,
            "& .MuiSelect-select": {
              paddingLeft: isMobile ? "14px" : undefined,
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: isMobile ? "100%" : undefined,
              },
            },
          }}
          renderValue={(selected) => selected}
          value={activeTab}
          onChange={(event) => handleTabChange(event.target.value as TabFilter)}
        >
          {tabs.map((tab) => (
            <MenuItem key={tab} value={tab} sx={menuStyles}>
              <span>{tab}</span>
              {isMobile && tab === activeTab && <CheckIcon style={checkIconStyles} />}
            </MenuItem>
          ))}
        </Select>
      ) : (
        tabs.map((tab) => {
          const isActive = activeTab === tab ? "education-type-active" : "";
          return (
            <button onClick={() => handleTabChange(tab)} className={`education-type ${isActive}`} key={tab}>
              {tab}
            </button>
          );
        })
      )}
    </div>
  );
};

export default Tabs;
