import { SvgIconComponent } from "@mui/icons-material";
import { SerializedDockview } from "dockview";
import ReactGridLayout from "react-grid-layout";

export type DemoGridLayoutProps = {
  width?: number;
  defaultLayout: ReactGridLayout.Layout[];
  onLayoutChanged: (layout: ReactGridLayout.Layout[]) => void;
  selectedWorkspace?: Workspace;
};

export type WorkspaceData = {
  title?: string;
  description?: string;
  serializedDockview?: SerializedDockview;
  widgets: Widget[];
};

export type Workspace = {
  title: string;
  data?: SerializedDockview;
  description?: string;
  id?: number;
  memberId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isDefault?: boolean;
};
export type DefaultWorkspaceData = {
  workspace: ReactGridLayout.Layout[];
  workspaceId: number;
};
export type DefaultWorkspace = DefaultWorkspaceData | null;

export type Widget = {
  title: string;
  key: number;
  icon?: SvgIconComponent | string;
  WidgetType: WidgetType;
  widgetId?: number;
  workspaceId?: number;
};

export type User = {
  accessToken?: string;
  name?: string;
  id?: string;
  accounts: Account[];
  firstName?: string;
  lastName?: string;
};

export enum AccountType {
  Challenge = "challenge",
  Simulator = "simulator",
}

export type Account = {
  accountId: number;
  accountType: AccountType;
  password: string;
  username: string;
};

export type Product = {
  id: string;
  cmeSymbol: string;
  description: string;
  name: string;
  tickSize: string;
  shortName: string;
  assetClass?: any;
  cqgSymbol: string;
};

export type ProductWithContracts = Product & {
  monthlyContracts?: Instrument[];
};

export type Watchlist = {
  id: number;
  workspaceId: number;
  associatedProducts: string;
};

export type InstrumentMarketData = Product & {
  askPrice?: number | string | null;
  askQty?: number | string | null;
  bidPrice?: number | string | null;
  bidQty?: number | string | null;
  lastPrice?: number | string | null;
  lastQty?: number | string | null;
  change?: number | null;
  changePercentage?: string | null;
  price?: number | string | null;
  title?: string | null;
  tradeVolume?: number | string | null;
};

export enum WidgetType {
  AccountInfo = "Account Info",
  Watchlist = "Watchlist",
  AvailableProducts = "Available Products",
  Orders = "Orders",
  Positions = "Positions",
  EnergyChart = "Energy chart",
  BarChart = "Bar chart",
  LineChart = "Line chart",
  DoubleBarChart = "Donut chart",
  TradeLogs = "Trade Logs",
  Chart = "Chart",
}

export type DockerviewPanelProps = {
  title?: string;
  widget: Widget;
  workspace: Workspace;
};

export const WORKSPACES = "WORKSPACES";

export type AssetProductsDictionary = {
  [assetClassName: string]: ProductWithContracts[];
};

export enum TreeViewMode {
  SINGLE = "single",
  MULTIPLE = "multiple",
}
export interface TradeLog {
  id: number;
  cqgAccountId: number;
  cmeSymbolId: number;
  memberId: number;
  realizedProfitLoss: string;
  buyOrderId: number;
  sellOrderId: number;
  tradeTime: string;
  qty: number;
  created_at: string;
  updated_at: string;
  instrumentId: number;
  cmeSymbol?: CmeSymbol | null;
  instrument: Instrument;
  buyOrder?: BuyOrderOrSellOrder;
  sellOrder?: BuyOrderOrSellOrder;
}
export interface CmeSymbol {
  cmeSymbol: string;
  tickSize: string;
}
export interface Instrument {
  contractSymbol: string | undefined;
  month: string | null | undefined;
  strikePrice?: number | null | undefined;
  putCall?: string | null;
  isOption?: () => boolean;
  cmeSymbol: string;
  displayName: string;
}
export interface BuyOrderOrSellOrder {
  id?: number;
  statusUtcTime?: string;
  avgFillPrice?: string;
  side: number;
  comments?: string | null;
  tradeLogStopLoss?: string | null;
  tradeLogApproach?: string | null;
  tradeLogTrend?: string | null;
  tradeLogRatio?: string | null;
  orderType: number | null;
  duration: number | null;
  limitPrice: string;
  stopPrice: string;
  tradePlanApproachFundamentalAnalysis?: string[] | null;
  tradePlanTechnicalType?: string | null;
  tradePlanTechnicalChartType?: string | null;
  tradePlanTechnicalIndicator?: string[] | null;
  tradePlanTechnicalIndicatorOther?: string | null;
}

export interface TradeFormValues {
  id?: number;
  side: string;
  quantity: number;
  type: string;
  limitPrice: string;
  stopPrice: string;
  timeInForce: string;
  useStopLoss: string;
  trend: string;
  ratio: string;
  approach: string;
  approachFundamentalAnalysis: string[];
  comments: string;
  approachTechnicalChartType?: string;
  approachTechnicalIndicator?: string[];
  approachTechnicalIndicatorOther?: string;
  approachTechnicalType?: string;
}
