import { Button, Typography, ButtonProps } from '@mui/material';

interface TradeButtonProps extends ButtonProps {
  enabled: boolean;
  handleClick: () => void;
  label: string;
}

const TradeButton = ({
  enabled,
  handleClick,
  label = 'TRADE',
  sx = {},
  ...rest
}: TradeButtonProps) => {
  return (
    <Button
      disabled={!enabled}
      onClick={handleClick}
      sx={{
        boxSizing: 'border-box',
        padding: '4px 8px',
        minWidth: '46px',
        height: '19px',
        border: '1px solid #3CC8FF',
        borderRadius: '2px',
        boxShadow: "0px 0px 3px 0px #00000026",
        textAlign: 'center',
        '&:hover': {
          backgroundColor: 'var(--Blue-Blue-4, #006EB6)',
          '& .MuiTypography-root': {
            color: '#FFFFFF',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'Averta-Regular',
          fontWeight: '600',
          fontSize: '9px',
          lineHeight: '11px',
          textTransform: 'uppercase',
          color: '#5A6874',
          letterSpacing: '0.5px',
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default TradeButton;
