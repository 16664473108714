import HeaderTable from "./HeaderTable";
import InstrumentSelect from "./InstrumentSelect";

const MobileHeader = ({
  rows,
  selectedInstrument,
  handleRowStateChange,
}: {
  rows: any[];
  selectedInstrument: any;
  handleRowStateChange: (id: string, contract: any) => void;
}) => {
  return (
    <>
      <div className="mobile-options-header-container">
        <div className="mobile-options-header-container--instrument">
          <InstrumentSelect selectedInstrument={selectedInstrument} handleRowStateChange={handleRowStateChange} />
        </div>
      </div>
      <HeaderTable rows={rows} />
    </>
  );
};

export default MobileHeader;
