import { Button } from "@mui/material";
import { actionButtonStyles } from "./menuItemDrawerStyles";

const EditConfirmation = ({
  setIsEditConfirmation,
  handleEditConfirmation,
}: {
  setIsEditConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditConfirmation: () => void;
}) => {
  return (
    <div className="menu-edit-confirmation">
      <div className="menu-edit-confirmation--description">You have unsaved changes</div>
      <div className="menu-edit-confirmation--description">made on this Watchlist?</div>
      <div className="menu-edit-confirmation--description">Are you sure you want to leave?</div>
      <div className="menu-edit-confirmation--action-button">
        <Button
          sx={{
            ...actionButtonStyles,
            border: "1px solid #006EB6",
            color: '#006EB6'
          }}
          variant="outlined"
          onClick={() => {
            handleEditConfirmation();
          }}
        >
          LEAVE WITHOUT SAVING
        </Button>

        <Button
          onClick={() => setIsEditConfirmation(false)}
          sx={{ ...actionButtonStyles, color: "#fff", background: "#006EB6" }}
          variant="contained"
        >
          STAY & CONTINUE
        </Button>
      </div>
    </div>
  );
};

export default EditConfirmation;
