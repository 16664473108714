import { useEffect, useState } from "react";
import { useFetchProducts } from "../../../hooks/api/product"; // Assume this hook is already defined
import { InstrumentLoadService } from "../../../cqg-api/services/InstrumentLoadService";
import { AssetProductsDictionary, Product, ProductWithContracts } from "../../../types";
import { Instrument } from "../../../cqg-api/models/Instrument";

const useAvailableProducts = () => {
  const { products, loadProducts } = useFetchProducts();
  const [assetProductsDictionary, setAssetProductsDictionary] = useState<AssetProductsDictionary>({});
  const [productsMapById, setProductsMapById] = useState<Record<string, ProductWithContracts>>({});
  const [monthlyContractsByIdMap, setMonthlyContractsByIdMap] = useState<Record<string, Instrument>>({});
  const [monthlyContractsByDisplayNameMap, setMonthlyContractsByDisplayNameMap] = useState<
    Record<string, Instrument & { productId: string }>
  >({});
  const [productMapByCMESymbol,setProductMapByCMESymbol] = useState<Record<string, Product>>()
  const [availableloading, setAvailableLoading] = useState(false);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    const productMapByCMESymbol = products.reduce((productMap: Record<string, Product>, product: Product) => {
      if (product.cmeSymbol) {
        productMap[product.cmeSymbol] = product;
      }
      return productMap;
    }, {});
    setProductMapByCMESymbol(productMapByCMESymbol);
  }, [products]);

  useEffect(() => {
    const loadContracts = async () => {
      if (products) {
        const productsByAssetClass: AssetProductsDictionary = {};

        try {
          // Create an array of promises
          setAvailableLoading(true);
          const promises = products.map(async (product: Product) => {
            const monthlyContracts = await InstrumentLoadService.loadMonthsFromCqgRoot(product.cqgSymbol, 4);
            return { ...product, monthlyContracts }; // Return the updated product
          });

          // Wait for all promises to resolve
          const productsWithContracts = await Promise.all(promises);

          // Group products by asset class
          productsWithContracts.forEach((productWithContracts: ProductWithContracts) => {
            const assetClassName = productWithContracts?.assetClass?.name;
            if (assetClassName) {
              productsByAssetClass[assetClassName] = productsByAssetClass[assetClassName] || [];
              productsByAssetClass[assetClassName].push(productWithContracts);
            }
          });
  
          const assetClassesOrdering = Object.keys(productsByAssetClass)
            .map(assetClassName => {
              const assetClass = productsByAssetClass[assetClassName][0]?.assetClass;
              return {
                name: assetClass?.name,
                ordering: assetClass?.ordering || 0,
              };
            })
            .sort((a, b) => a.ordering - b.ordering);
  
          const sortedAssetClasses = assetClassesOrdering.map(assetClass => assetClass.name);
  
          const sortedProductsByAssetClass: AssetProductsDictionary = {};
          sortedAssetClasses.forEach(assetClassName => {
            if (productsByAssetClass[assetClassName]) {
              sortedProductsByAssetClass[assetClassName] = productsByAssetClass[assetClassName];
            }
          });
  
          setAssetProductsDictionary(sortedProductsByAssetClass);
          setAvailableLoading(false);
        } catch (error) {
          console.error("Error loading contracts:", error);
        }
      }
    };

    loadContracts();
  }, [products]);

  useEffect(() => {
    const productMapById: Record<string, ProductWithContracts> = Object.values(assetProductsDictionary)
      .flatMap((products) => products)
      .reduce(
        (map, product) => {
          map[product.id] = product;
          return map;
        },
        {} as Record<string, ProductWithContracts>,
      );
    setProductsMapById(productMapById);
  }, [assetProductsDictionary]);

  useEffect(() => {
    const monthlyContractsByIdMap = Object.values(assetProductsDictionary)
      .flatMap((products) => products) // Flatten the products arrays
      .flatMap((product) => product.monthlyContracts || []) // Flatten all monthlyContracts arrays
      .filter((contract) => contract) // Filter out null/undefined contracts
      .reduce(
        (map, contract) => {
          map[contract.contractId as any] = contract; // Use contractId as the key in the map
          return map;
        },
        {} as { [contractId: string]: Instrument },
      );
    setMonthlyContractsByIdMap(monthlyContractsByIdMap);
  }, [assetProductsDictionary]);

  useEffect(() => {
    const monthlyContractsByDisplayNameMap = Object.values(assetProductsDictionary)
      .flatMap((products) => products) // Flatten the products arrays
      .flatMap((product) =>
        product.monthlyContracts
          ? product.monthlyContracts.map((monthlyContract) => ({
              ...monthlyContract,
              monthlyContracts: product.monthlyContracts,
              productId: product.id,
            }))
          : [],
      ) // Flatten all monthlyContracts arrays
      .filter((contract) => contract) // Filter out null/undefined contracts
      .reduce(
        (map, contract) => {
          map[contract.displayName as string] = contract;
          map[contract.cqgSymbol as string] = contract;
          return map;
        },
        {} as { [contractId: string]: Instrument & { productId: string } },
      );

    setMonthlyContractsByDisplayNameMap(monthlyContractsByDisplayNameMap);
  }, [assetProductsDictionary]);

  return {
    availableProducts: {
      assetProductsDictionary,
      products,
      productsMapById,
      monthlyContractsByIdMap,
      monthlyContractsByDisplayNameMap,
      availableloading,
      productMapByCMESymbol
    },
  };
};

export default useAvailableProducts;
