import React from "react";
import { Tooltip } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { OneClickIcon } from "../../images/Icons";
import clsx from "clsx";

const useStyles = makeStyles({
  tooltip: {
    padding: "4px 8px",
    gap: 0,
    borderRadius: "4px",
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    lineHeight: "14px",
    boxShadow: "0px 0px 2px 0px #0000004D",
    marginTop: "16px",
  },
  whiteBG: {
    backgroundColor: "white",
    color: "black",
    width: "240px",
    fontSize: "10px",
  },
  coloredBG: {
    backgroundColor: "#607D8B",
    width: "218px",
    fontSize: "13px",
  },
  infoIcon: {
    width: '12px',
    height: '12px',
    color: '#081D374D',
    marginBottom: '4px',
    marginLeft: '4px',
  },
  iconContainer: {
    position: "relative",
    display: "inline-block",
    marginTop: "3px",
  },
  arrowWhite: {
    "&::before": {
      backgroundColor: "white",
    },
  },
  arrowColored: {
    "&::before": {
      backgroundColor: "#607D8B",
    },
  },
});

const TooltipWithIcon = ({ tooltipText, isHeader = false }: { tooltipText: string; isHeader?: boolean }) => {
  const classes = useStyles();

  const tooltipClass = isHeader ? classes.whiteBG : classes.coloredBG;
  const arrowClass = isHeader ? classes.arrowWhite : classes.arrowColored;
  const Icon = isHeader ? OneClickIcon : InfoRounded;

  return (
    <Tooltip
      title={tooltipText}
      placement="top"
      arrow
      classes={{
        tooltip: clsx(classes.tooltip, tooltipClass),
        arrow: arrowClass,
      }}
    >
      <span className={classes.iconContainer}>
        <Icon fontSize="small" className={isHeader ? undefined : classes.infoIcon} />
      </span>
    </Tooltip>
  );
};

export default TooltipWithIcon;
