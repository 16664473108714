const pe = process.env;
const getAppBaseUrl = () => {
  const  envBaseURL = pe.PUBLIC_URL ||  "/trading_simulator";
  return envBaseURL;
}

const getUserLoginApiUrl = () => {
  const isHostCME = window.location.host.indexOf("cmegroup.com") >= 0;
  if (isHostCME) {
    let baseUrl = getAppBaseUrl();
    let uri: string = `${window.location.origin}${baseUrl}/api`;
    return uri;
  }

  return (pe.REACT_APP_USER_LOGIN_API_URL || window.REACT_APP_USER_LOGIN_API_URL || "https://education-demo.connamara.com");
};

let env = {
  IS_HOST_CME: window.location.host.indexOf("cmegroup.com") >= 0,
  APP_BASE_URL: getAppBaseUrl(),
  USER_LOGIN_API_URL: getUserLoginApiUrl(),
  APP_API_URL: getUserLoginApiUrl(),
  CME_WATCHLIST_API_URL: pe.REACT_APP_CME_WATCHLIST_API_URL || window.REACT_APP_CME_WATCHLIST_API_URL || "https://beta.cmegroup.com/services",
  CME_LOGOUT_URL: pe.REACT_APP_CME_LOGOUT_URL || "https://beta.cmegroup.com/libs/cmegroup/security/logout",
};

export const ENV = env;
