import { SxProps, Theme } from "@mui/system";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    width: "592px",
    height: "208px",
    border: "1px solid #EBEDEE",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 0,
  },

  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px 8px",
    width: "592px",
    height: "208px",
    flex: "none",
    order: 0,
    flexGrow: 0,
  },
  title: {
    height: "40px",
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
  },
  description: {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
  },
  promoCodeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
  },
  inputField: {
    height: "30px",
    backgroundColor: "#EBEDEE",
    "& .MuiOutlinedInput-input": {
      padding: "4px",
    },
  },

  submitButton: {
    height: "30px",
    backgroundColor: "#006EB6",
    borderRadius: "2px",
    textTransform: "uppercase",
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#FFFFFF",
  },

  checkboxSection: {
    height: "24px",
    display: "flex",
  },
}));

export const containerStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "16px",
  height: "249px",
};

export const titleStyle: SxProps<Theme> = {
  height: "25px",
  fontFamily: "Averta-Regular",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "25px",
  display: "flex",
  alignItems: "center",
  color: "#25323C",
};
