import React, { useEffect, useRef } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Contract } from "./types";
import ContractMenuItem from "./ContractList";
import { subscribeToContracts, unsubscribeContracts } from "../../../utils/subscriptions";
import { monthMapCapitalize } from "../../tree-view/utils/convertSymbolToDate";

interface SelectContractProps {
  contracts: Contract[];
  onSelect: (id: string, contract: Contract) => void;
  id: string;
  selectedState: Contract;
}

const SelectContract: React.FC<SelectContractProps> = ({ contracts, onSelect, id, selectedState }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedContract, setSelectedContract] = React.useState(selectedState);
  const prevStateRef = useRef<Contract>();

  useEffect(() => {
    if (selectedContract) {
      prevStateRef.current = selectedContract;
      subscribeToContracts([selectedContract.contractId]);
    }
    return () => {
      if (prevStateRef.current) unsubscribeContracts([prevStateRef.current.contractId]);
    };
  }, [selectedContract]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(!contracts) return;
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOnSelect = (contract: Contract) => {
    handleCloseMenu();
    if (contract) {
      setSelectedContract(contract);
      onSelect(id, contract);
    }
  };
  const getContractName = (contract: Contract) => {
    const indexOfSelectedMonth = Object.values(contract.MONTH_CODES).findIndex((month) => month === contract.month);
    const monthCode = Object.keys(contract.MONTH_CODES)[indexOfSelectedMonth];
    return `${monthCode} - ${contract.month.toUpperCase()} ${contract.title.substr(contract.title.length - 2)}`;
  };

  const sortedContracts = React.useMemo(() => {
    if (!Array.isArray(contracts)) return [];
    
    return [...contracts].sort(
      (currentContract, comparedContract) =>
        Object.values(monthMapCapitalize).indexOf(currentContract.month) -
        Object.values(monthMapCapitalize).indexOf(comparedContract.month)
    );
  }, [contracts]);
  

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 0px",
          minWidth: "90px",
          alignItems: "center",
          fontFamily: "Averta-Regular",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "26px",
          letterSpacing: "1px",
          textAlign: "left",
          cursor: !contracts ? "not-allowed" : "pointer",
        }}
      >
        <span style={{ color: contracts ? "#1976d2" : "#25323C80" }}>
          {selectedContract ? selectedContract.displayName : ""}
        </span>
        {contracts && <KeyboardArrowDownIcon />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {sortedContracts?.map((contract) => (
          <ContractMenuItem
            key={`${contract.id}-${contract.month}`}
            contract={contract}
            selectedContract={selectedContract}
            onSelect={handleOnSelect}
            getContractName={getContractName}
          />
        ))}
      </Menu>
    </div>
  );
};

export default SelectContract;
