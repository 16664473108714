import { DockviewApi } from "dockview";
import { updateWidgetName } from "../home/components/SideMenu/menu-item-drawer/utils";
import { WidgetType } from "../../types";

interface WidgetDataValue {
  widgetName: string;
  data: any;
  contractMonth?: string;
  widgetId?: number;
  title?: string;
  widgetType?: WidgetType;
}
export class PanelHelper {
  static panelDataUpdate = (dockviewApi: DockviewApi | undefined, widgetDataValue: WidgetDataValue | null) => {
    if (!widgetDataValue || !dockviewApi) return;

    const targetPanel = dockviewApi.panels.find((panel) => panel.params?.widget.id === widgetDataValue.widgetName);

    if (targetPanel && targetPanel.params) {
      targetPanel.api.updateParameters({
        widget: {
          ...targetPanel.params.widget,
          widgetData: {
            ...targetPanel.params.widget.widgetData,
            data: widgetDataValue.data,
            ...(widgetDataValue.contractMonth && { contractMonth: widgetDataValue.contractMonth }),
          },
        },
        workspaceId: targetPanel.params.workspaceId,
      });
    } else {
      console.log(`Panel title "${widgetDataValue.widgetName}" not found`);
    }
  };
  static panelTitleUpdate = (dockviewApi: DockviewApi | undefined, widgetDataValue: WidgetDataValue | null) => {
    if (!widgetDataValue || !dockviewApi) return;

    const targetPanel = dockviewApi.panels.find((panel) =>
      widgetDataValue.widgetType === WidgetType.Watchlist
        ? panel.params?.widget.widgetId === widgetDataValue.widgetId
        : panel.params?.widget.id === widgetDataValue.widgetId,
    );

    if (!targetPanel || !targetPanel.params) {
      console.error(`Panel widgetId "${widgetDataValue.widgetId}" not found`);
      return;
    }

    const updatedTitle =
      widgetDataValue.widgetType === WidgetType.Watchlist
        ? (widgetDataValue.title ?? "")
        : updateWidgetName(widgetDataValue.title, targetPanel.params.widget.widgetType);
    targetPanel.setTitle(updatedTitle);

    targetPanel.api.updateParameters({
      widget: {
        ...targetPanel.params.widget,
        widgetName: updatedTitle,
      },
      workspaceId: targetPanel.params.workspaceId,
    });
  };
}
