import { DockviewApi, GridConstraintChangeEvent, IDockviewPanelProps } from "dockview";
import { DockerviewPanelProps, WatchlistType, Widget, WidgetType, Workspace } from "../../types";
import AvailableProducts from "../widgets/availableProducts/AvailableProducts";
import Orders from "../widgets/orders/Orders";
import Positions from "../widgets/positions/Positions";
import OrdersMobile from "../../components/OrdersWidget";
import PositionsMobile from "../../components/PositionsWidget";
import Watchlist from "../widgets/watchlist/Watchlist";
import TradeLog from "../widgets/TradeLog/index";
import Trade from "../TradeTicketDrawer/Trade";
import TradingChart from "../widgets/tradingChart/TradingChart";
import React from "react";
import PriceLadder from "../widgets/priceLadder/PriceLadder";
import EconodayCalendar from "../widgets/calender/EconodayCalender";
import Options from "../widgets/options/Options";
import { OrdersDataProvider } from "../../OrdersDataProvider";
import { MarketDataProvider } from "../../MarketDataProvider";
import Commentary from "../widgets/commentary/Commentary";
import MobileWatchlist from "../widgets/watchlist/MobileWatchlist";

let panelCount = 0;

const TRADE_TICKET_HEIGHT = 735;
const TRADE_TICKET_WIDTH = 384;
const PANEL_TICKET_HEIGHT = 400;
const PANEL_TICKET_WIDTH = 600;
const PRICE_LADDER_HEIGHT = 700;
const PRICE_LADDER_WIDTH = 330;
const COMMENTARY_HEIGHT = 500;


const generateUniqueId = () => {
  return `id${Date.now().toString()}${(panelCount++).toString()}`;
};

export const addPanels = (workspace: Workspace, dockviewApi: DockviewApi, widgets: Widget[], referenceGroup: any) => {
  widgets?.forEach((widget, index) => {
    const uniqueId = generateUniqueId();
    const panel = dockviewApi.addPanel<DockerviewPanelProps>({
      id: uniqueId,
      component: "default",
      renderer: "always",
      title: widget.widgetType,
      params: {
        widget,
        workspaceId: workspace.id
      },
      floating: {
        width: PANEL_TICKET_WIDTH,
        height: PANEL_TICKET_HEIGHT,
      },
    });
    panel.group.locked = "no-drop-target";
  });
};

export const addPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace, dockviewRef: React.MutableRefObject<null | any>,) => {
  const uniqueId = generateUniqueId();

  const { icon, ...widgetWithoutIcon } = widget;
  const widgetWithHeight = {
    ...widgetWithoutIcon,
    layoutHeight: dockviewRef?.current?.clientHeight,
    panelHeight: Math.min(dockviewRef?.current?.clientHeight - 20, PRICE_LADDER_HEIGHT)
  };
  const totalPanels = dockviewApi.panels.length;
  const totalPriceLadderWidget = dockviewApi.panels.filter((panel) => panel?.params?.widget.widgetName === WidgetType.PriceLadder).length;
  
  const left = dockviewRef?.current?.clientWidth / 2 - PRICE_LADDER_WIDTH / 2 + (totalPriceLadderWidget * 50);
  const top = dockviewRef?.current?.clientHeight / 2 - PRICE_LADDER_HEIGHT / 2 + (totalPriceLadderWidget * 25);
  const floatingStyle = totalPanels === 0 ? {} : {floating: {
    position: widget.title === WidgetType.PriceLadder ? { top, left } : {
      left: 50 + totalPanels * 60,
      top: 50 + totalPanels * 30,
    },
    width: widget.title === WidgetType.PriceLadder ? PRICE_LADDER_WIDTH : PANEL_TICKET_WIDTH,
    height:
      widget.title === WidgetType.PriceLadder
        ? Math.min(dockviewRef?.current?.clientHeight - 20, PRICE_LADDER_HEIGHT)
        : widget.title === WidgetType.Commentary
          ? Math.min(dockviewRef?.current?.clientHeight - 20, COMMENTARY_HEIGHT)
          : PANEL_TICKET_HEIGHT,
  }}

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    ...floatingStyle,
    tabComponent: widget.watchlistType === WatchlistType.Featured ? 'featuredWatchlist' : undefined,
    title: 
      widget.widgetType === WidgetType.PriceLadder ||
      widget.widgetType === WidgetType.Options ||
      widget.widgetType === WidgetType.Watchlist || 
      widget.widgetType === WidgetType.Chart
        ? widget.widgetName : widget.widgetType,
    params: {
      widget: widgetWithHeight,
      workspaceId: workspace.id, 
    },
  });
  panel.group.locked = "no-drop-target";
};
export const addTradingTicketPanel = (
  dockviewApi: DockviewApi,
  widget: Widget,
  workspace: Workspace,
  dockviewRef: React.MutableRefObject<null | any>,
) => {
  const uniqueId = generateUniqueId();
  const left = dockviewRef?.current?.clientWidth / 2 - TRADE_TICKET_WIDTH / 2;
  const top = dockviewRef?.current?.clientHeight / 2 - TRADE_TICKET_HEIGHT / 2;
  const { icon, ...widgetWithoutIcon } = widget;

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: "Trade",
    floating: {
      width: TRADE_TICKET_WIDTH,
      height: TRADE_TICKET_HEIGHT,
      position: {
        left,
        top,
      },
    },
    params: {
      widget: widgetWithoutIcon,
      workspaceId: workspace.id, 
    },
  });
  dockviewApi.onWillDragPanel((event) => {
    if (event.panel.id === uniqueId) {
      event.nativeEvent.preventDefault();
    }
  });
  panel.group.locked = "no-drop-target";
};

export const addChartPanel = (dockviewApi: DockviewApi, widget: Widget, workspace: Workspace) => {
  const uniqueId = generateUniqueId();
  const { icon, ...widgetWithoutIcon } = widget;

  const panel = dockviewApi.addPanel<DockerviewPanelProps>({
    id: uniqueId,
    component: "default",
    renderer: "always",
    title: widget.widgetType,
    floating: {
      width: PANEL_TICKET_WIDTH,
      height: PANEL_TICKET_HEIGHT,
    },
    params: {
      widget: widgetWithoutIcon,
      workspaceId: workspace.id, 
    },
  });

  panel.group.locked = "no-drop-target";
};

export const componentFactory = (widget: Widget, workspaceId: number, constraints: any) => {
  if (!workspaceId) return <>No workspace found</>;

  const workspaceClassName = "grid-layout-workspace";

  const isTradingChartWidget = widget.title === WidgetType.Chart || widget.title === WidgetType.PriceLadder;

  switch (widget.title) {
    case WidgetType.Watchlist:
      return <MarketDataProvider><Watchlist widget={widget} workspaceId={workspaceId} workspaceClassName={workspaceClassName} /></MarketDataProvider>;
    case WidgetType.AvailableProducts:
      return <MarketDataProvider><AvailableProducts workspaceId={workspaceId} workspaceClassName={workspaceClassName} /></MarketDataProvider>;
    case WidgetType.Orders:
      return <OrdersDataProvider><Orders workspaceId={workspaceId} workspaceClassName={workspaceClassName} /></OrdersDataProvider>;
    case WidgetType.Positions:
      return <Positions workspaceId={workspaceId} workspaceClassName={workspaceClassName} />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.TradeTicket:
      return <Trade workspaceId={workspaceId} externalData={widget.widgetData} expired={widget.expired} />;
    case WidgetType.PriceLadder:
      return <OrdersDataProvider><MarketDataProvider><PriceLadder workspaceId={workspaceId} widget={widget} /></MarketDataProvider></OrdersDataProvider>;
    case WidgetType.EconodayCalendar:
      return <EconodayCalendar workspaceId={workspaceId} />;
    case WidgetType.Options:
      return <Options workspaceId={workspaceId} widget={widget} />;
    case WidgetType.Commentary:
      return <Commentary workspaceId={workspaceId} widget={widget} />;
    default:
      if (isTradingChartWidget) {
        return <TradingChart symbols={widget.contractIds} workspaceId={workspaceId} widget={widget} />;
      }
      return <div>No widget found</div>;
  }
};
export const componentFactoryMobile = (widget: Widget, workspaceId: number) => {
  if (!workspaceId) return <>No workspace found</>;

  const workspaceClassName = "grid-layout-workspace";

  const isTradingChartWidget = widget.title === WidgetType.Chart || widget.title === WidgetType.PriceLadder;

  switch (widget.title) {
    case WidgetType.Watchlist:
      return <MarketDataProvider><MobileWatchlist /></MarketDataProvider>;
    case WidgetType.AvailableProducts:
      return <MarketDataProvider><AvailableProducts workspaceId={workspaceId} workspaceClassName={workspaceClassName} isMobile={true} /></MarketDataProvider>;
    case WidgetType.Orders:
      return <OrdersDataProvider><OrdersMobile /></OrdersDataProvider>;
    case WidgetType.Positions:
      return <PositionsMobile />;
    case WidgetType.TradeLogs:
      return <TradeLog />;
    case WidgetType.PriceLadder:
      return <OrdersDataProvider><MarketDataProvider><PriceLadder isMobile workspaceId={workspaceId} widget={widget} /></MarketDataProvider></OrdersDataProvider>;
    case WidgetType.EconodayCalendar:
      return <EconodayCalendar workspaceId={workspaceId} />
    case WidgetType.Options:
      return <Options workspaceId={workspaceId} widget={widget} isMobile={true} />
    default:
      if (isTradingChartWidget) {
        return <TradingChart symbols={widget.contractIds} workspaceId={workspaceId}/>;
      }
      return <div>No widget found</div>;
  }
};

function RenderWhenVisible(component: React.FunctionComponent<IDockviewPanelProps>) {
  const HigherOrderComponent = (props: IDockviewPanelProps) => {
    return React.createElement(component, props);
  };
  return HigherOrderComponent;
}

export const components = {
  default: RenderWhenVisible((props: IDockviewPanelProps<DockerviewPanelProps>) => {
    const [constraints, setConstraints] = React.useState<GridConstraintChangeEvent | null>(null);

    React.useEffect(() => {
      if (props.params.widget.title === WidgetType.PriceLadder) {
        props.api.group.api.setConstraints({
          maximumWidth: PRICE_LADDER_WIDTH,
          minimumWidth: PRICE_LADDER_WIDTH,
        });

        const handleConstraintsChange = (event: GridConstraintChangeEvent) => {
          setConstraints(event);
        };
        
        props.api.group.api.onDidConstraintsChange(handleConstraintsChange);
      }
    }, [props.api.group.api, props.params.widget]);
    return (
      <div
        style={{
          height: "100%",
          overflow: "auto",
          color: "white",
          position: "relative",
          width: "100%",
        }}
      >
        {componentFactory(props.params.widget, props.params.workspaceId ?? 0, constraints)}
      </div>
    );
  }),
};
