import { Newspaper, SwapHoriz, ViewColumn, Watch } from "@mui/icons-material";
import { Widget, WidgetType } from "../../types";
import { CalendarWidIcon, ChartWidgetIcon, MarketIcon, OptionWidIcon, PositionsWidIcon } from "../../images/Icons";

export const widgets: Widget[] = [
  {
    key: 1,
    title: WidgetType.AvailableProducts,
    widgetType: WidgetType.AvailableProducts,
    icon: MarketIcon,
  },
  {
    key: 2,
    title: WidgetType.Watchlist,
    widgetType: WidgetType.Watchlist,
    icon: Watch,
  },
  {
    key: 3,
    title: WidgetType.Chart,
    widgetType: WidgetType.Chart,
    icon: ChartWidgetIcon,
  },
  {
    key: 4,
    title: WidgetType.PriceLadder,
    widgetType: WidgetType.PriceLadder,
    icon: ViewColumn,
  },
  {
    key: 10,
    title: WidgetType.Options,
    widgetType: WidgetType.Options,
    icon: OptionWidIcon,
  },
  {
    key: 5,
    title: WidgetType.Positions,
    widgetType: WidgetType.Positions,
    icon: PositionsWidIcon,
  },
  {
    key: 6,
    title: WidgetType.Orders,
    widgetType: WidgetType.Orders,
    icon: SwapHoriz,
  },
  {
    key: 7,
    title: WidgetType.Commentary,
    widgetType: WidgetType.Commentary,
    icon: Newspaper,
  },
  {
    key: 8,
    title: WidgetType.EconodayCalendar,
    widgetType: WidgetType.EconodayCalendar,
    icon: CalendarWidIcon,
  },
];
