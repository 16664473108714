import { Box, Tab, Tabs } from "@mui/material";
import { mobileTabs } from "../mobileTabs";
import { componentFactoryMobile } from "../../dockview/utils";
import { useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import layoutActions from "../../../redux/layout/layoutActions";
import layoutSelectors from "../../../redux/layout/layoutSelectors";

const tabStyle = {
  "& .MuiTabs-flexContainer": {
    gap: "2px",
  },
  "& .MuiTab-root": {
    borderBottom: "2px solid #d1d1d1",
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "1px",
  },
  "& button.Mui-selected": {
    color: "#081D37",
  },
};
const tabPanelStyle = {
  position: "sticky",
  top: "44px",
  zIndex: 998,
  backgroundColor: "#fff",
}

const MobileTabs = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(layoutSelectors.selectActiveTab);

  const handleTabClick = useCallback((tab: any) => {
    dispatch(layoutActions.doUpdateAciveTab(tab));
  }, []);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box sx={tabPanelStyle}>
        <Tabs
          sx={tabStyle}
          value={selectedTab.title}
          variant="scrollable"
          scrollButtons="auto"
        >
          {mobileTabs?.map((tab) => (
            <Tab
              key={tab.key}
              sx={{ textTransform: "none" }}
              onClick={() => handleTabClick(tab)}
              label={tab?.displayName}
              value={tab?.displayName}
              disabled={!tab?.isActive}
            />
          ))}
        </Tabs>
      </Box>
      {selectedTab && componentFactoryMobile(selectedTab, 1)}
    </Box>
  );
};

export default MobileTabs;
