import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, IconButton, Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { saveTradeLog, useTradeLog } from "../../../../hooks/api/tradeLog";
import { TradeFormValues, TradeLog, TradeLog as TradeLogType } from "../../../../hooks/api/types";
import { StyledTableCell, StyledTableRow } from "../../../widgets/TradeLog/styles";
import "./TradeLog.css";
import CategoriesDropdown from "./filters/CategoriesDropdown";
import EditTradeForm from "./EditTradeForm";
import TradeLogHeader from "./TradeLogHeader";
import Row from "./TradeTableRow";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import mobile from "is-mobile";
import clsx from "clsx";
import MobileTradeLogHeader from "./MobileTradeLogHeader";
import { AccountType, Mode_Type, USER_MODE } from "../../../../types";
import { getFromLS } from "../../../../storage";

const tradelogtitle = {
  fontFamily: "Averta-Regular",
  fontWeight: 600,
  fontSize: "16px",
  whiteSpace: "nowrap",
  padding: 0,
  margin: 0,
  color: "#081D37",
  alignSelf: "center",
};
export default function TradeLogComponent({ onHandleClose }: { onHandleClose: () => void }) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
  const { tradelog, loadTradeLogData } = useTradeLog();
  const [editOpen, setEditOpen] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState<TradeLogType | null>(null);
  const [selectedSideType, setSelectedSideType] = useState<string | undefined>(undefined);
  const [filteredData, setFilteredData] = useState<TradeLog[]>(tradelog);
  const [loading, setLoading] = useState(false);
  const userMode = getFromLS(USER_MODE);
  const initialMode = userMode === Mode_Type.CHALLENGE_MODE ? AccountType.Challenge : AccountType.Simulator;

  useEffect(() => {
    loadTradeLogData();
  }, [loadTradeLogData]);

  const handleEditOpen = useCallback((trade: TradeLogType, sideType: string) => {
    setSelectedTrade(trade);
    setSelectedSideType(sideType);
    setEditOpen(true);
  }, []);

  const handleEditClose = useCallback(() => {
    setSelectedTrade(null);
    setSelectedSideType(undefined);
    setEditOpen(false);
  }, []);

  const handleEditSubmit = useCallback(
    async (updatedTrade: TradeFormValues) => {
      try {
        setLoading(true);
        await saveTradeLog(updatedTrade);
        const updatedData = await loadTradeLogData();
        setFilteredData(updatedData);
        setEditOpen(false);
      } catch (error) {
        console.error("Failed to save updated trade log:", error);
      } finally {
        setLoading(false);
      }
    },
    [loadTradeLogData],
  );

  return (
    <div
      className={clsx({
        "fluid-tradeplan": true,
        "fluid-tradeplan--mobile": isMobileView,
      })}
    >
      <Box
        sx={{
          width: isMobileView ? "calc(100% - 25px)" : "calc(100% - 7px)",
          margin: !isMobileView ? "auto" : "",
          padding: "16px",
          backgroundColor: "white",
          height: "100%",
          paddingTop: "5px"
        }}
      >
        <Box
          className={clsx({
            "trade-log-header-mobile": isMobileView,
          })}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography sx={tradelogtitle}>Trade Log</Typography>
          <IconButton onClick={onHandleClose}>
            <Close />
          </IconButton>
        </Box>
        {isMobileView ? (
          <MobileTradeLogHeader isMobileView={isMobileView} initialMode={initialMode}/>
        ) : (
          <TradeLogHeader isMobileView={isMobileView} initialMode={initialMode} />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "8px", sm: "16px" },
            height: { xs: "100%", sm: "764px" },
            background: "#FFFFFF",
            boxShadow:
              "0px 0px 4px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)",
            gap: "8px",
            overflow: "hidden",
          }}
        >
          <Box>
            <CategoriesDropdown
              tradelog={tradelog}
              onFilterChange={setFilteredData}
              setLoading={setLoading}
              isMobileView={isMobileView}
            />
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden", flexGrow: 1, boxShadow: "none" }}>
            <TableContainer
              sx={{
                maxHeight: { xs: "100%", sm: "700px" },
                overflow: "auto",
              }}
              className={clsx({ "trade-log-table-container": isMobileView })}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell>Transaction Date and Time</StyledTableCell>
                    <StyledTableCell align="left">Open/Close</StyledTableCell>
                    <StyledTableCell align="left">Buy/Sell</StyledTableCell>
                    <StyledTableCell align="left">C/P</StyledTableCell>
                    <StyledTableCell align="left">Strike Px</StyledTableCell>
                    <StyledTableCell align="left">Qty</StyledTableCell>
                    <StyledTableCell align="left">Type</StyledTableCell>
                    <StyledTableCell align="left">Px</StyledTableCell>
                    <StyledTableCell align="left">TIF</StyledTableCell>
                    <StyledTableCell align="left">Fill Px</StyledTableCell>
                    <StyledTableCell align="left">Stop</StyledTableCell>
                    <StyledTableCell align="left">Stop Px</StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ position: "sticky", right: 0, background: "#F5F5F5", zIndex: 11 }}
                    >
                      {!isMobileView && "Action"}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={14} align="center">
                        {loading ? "Loading..." : "No Data Available"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    filteredData.reverse().map((row) => <Row key={row.id} row={row} onEdit={handleEditOpen} />)
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        {editOpen && (
          <EditTradeForm
            trade={selectedTrade as TradeLogType}
            sideType={selectedSideType}
            open={editOpen}
            onSave={handleEditSubmit}
            onClose={handleEditClose}
            loading={loading}
            isMobileView={isMobileView}
          />
        )}
      </Box>
    </div>
  );
}
