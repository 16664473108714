import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import mobile from "is-mobile";
import clsx from "clsx";
import { Box } from "@mui/system";
import { Button, CircularProgress, IconButton, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";
import {
  boxContainerStyles,
  boxHeaderStyles,
  callIconButtonStyles,
  callIconStyles,
  catalogButtonStyles,
  educationtitle,
} from "./styles";
import Tabs from "./Tabs";
import useResponsivePanel from "../../../../hooks/useResponsivePanel";
import { CallMadeIcon as CallMadeIconBlue } from "../../../../images/Icons";
import CallMadeIcon from "@mui/icons-material/CallMade";
import "./Education.css";
import { useEffect, useState } from "react";
import { videos } from "./educationApiClient";
import { Result, TabFilter } from "./types";
import Notification from "../../../shared/notification/Notification";
import axios from "axios";
import BrightCoveVideo from "./BrightCoveVideo";

const categories: Record<TabFilter, string> = {
  [TabFilter.NewToFutures]: "1",
  [TabFilter.Options]: "6",
  [TabFilter.Stategies]: "8",
  [TabFilter.RiskManagement]: "",
  [TabFilter.Financials]: "",
  [TabFilter.Commodities]: "",
};

const Education = ({ onHandleClose }: { onHandleClose: () => void }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();
  const { panelRef, isSmallPanel } = useResponsivePanel(600);
  const [activeTab, setActiveTab] = useState<TabFilter>(TabFilter.NewToFutures);
  const [loading, setLoading] = useState(false);

  const [courseResults, setCourseResults] = useState<Result[]>([]);

  const handleTabChange = (tab: TabFilter) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const getCourses = async () => {
      try {
        setLoading(true);
        if (!categories[activeTab]) {
          setCourseResults([]);
        } else {
          // TODO: Need to remove
          // const resp = await apiEducationClient.get(`/education/${categories[activeTab]}`);
          const resp = await axios.get(`${process.env.REACT_APP_EDUCATION_URL}${categories[activeTab]}.json`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
            },
          });
          setCourseResults(resp.data.results);
        }
      } catch (e) {
        console.error("Something went wrong", e);
        Notification.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    getCourses();
  }, [activeTab]);

  return (
    <div
      className={clsx({
        "education-container": true,
        "education-container--mobile": isMobileView,
      })}
      ref={panelRef}
    >
      <Box
        sx={{
          ...boxContainerStyles,
          width: isMobileView ? "calc(100% - 25px)" : "calc(100% - 7px)",
          margin: !isMobileView ? "auto" : "",
        }}
      >
        <Box
          className={clsx({
            "education-header-mobile": isMobileView,
          })}
          sx={boxHeaderStyles}
        >
          <Typography sx={educationtitle}>CME Group Education</Typography>
          <IconButton onClick={onHandleClose}>
            <Close />
          </IconButton>
        </Box>

        <div className="education-header">
          <Tabs
            isMobile={isMobileView}
            isSmallPanel={isSmallPanel}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
          <div
            className="view-catalog"
            onClick={() => window.open("https://beta.cmegroup.com/education/courses.html", "_blank")}
          >
            {isMobileView || isSmallPanel ? (
              <Button
                sx={catalogButtonStyles}
                variant="outlined"
                endIcon={<CallMadeIcon style={callIconButtonStyles} />}
              >
                FULL CATALOG
              </Button>
            ) : (
              <>
                <div>View Full Course Catalog</div>
                <div>
                  <CallMadeIconBlue style={callIconStyles} />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="education-courses-text">Courses</div>
        {loading && (
          <div className="course-grid--loader">
            <CircularProgress />
          </div>
        )}
        <div className="course-grid--loader">{!loading && !courseResults.length && "No Data"}</div>
        <div className="course-grid">
          {/* Displaying the first 8 course results */}
          {courseResults
            ?.slice(0, 8)
            .map((result: Result) => <div dangerouslySetInnerHTML={{ __html: result?.text }} />)}
        </div>

        <div className="education-courses-text">Videos</div>
        {loading && (
          <div className="course-grid--loader">
            <CircularProgress />
          </div>
        )}
        <div className="course-grid education-videos-container">
          {/* TODO: videos are hardcoded */}
          {!loading &&
            categories[activeTab] &&
            courseResults.length > 0 &&
            videos[activeTab].map((video) => <BrightCoveVideo key={video.videoId} video={video} />)}
        </div>
        <div className="course-grid--loader">{!loading && !courseResults.length && "No Videos"}</div>
      </Box>
    </div>
  );
};

export default Education;
