import { useState } from "react";
import FeaturedList from "./List";
import { Box } from "@mui/material";
import InfoTooltip from "../home/components/SideMenu/menu-item-drawer/InfoTooltip";
import { ActionButton, ClearSelectionButton, FeaturedContainer, Heading, InfoOutlinedIconStyled } from "./styles";
import { FeaturedWatchlist } from "../../cme-watchlist-api";
import { WatchlistType, Widget, WidgetType } from "../../types";
import { IContract } from "./types";
import { useDispatch } from "react-redux";
import featuredWatchlistActions from "../../redux/featuredWatchlist/featuredWatchlistActions";

type FeaturedWatchlistsProps = {
  onCreateNewWatchlistClick: (wType?: WatchlistType) => void;
  watchlist: FeaturedWatchlist[];
  onWatchlistNameChange: (watchlistName: string) => void;
  setSelectedTreeItems: (selectedTreeItems: string[]) => void;
  addToWorkspace: (widget: Widget) => void;
};

const FeaturedWatchlists = ({ onCreateNewWatchlistClick, watchlist, onWatchlistNameChange, setSelectedTreeItems, addToWorkspace }: FeaturedWatchlistsProps) => {
  const [selectedWatchlists, setSelectedWatchlists] = useState<{ watchListId: number, watchlistName: string, productsCount: number} | null>(null);
  const [selectedContracts, setSelectedContracts] = useState<IContract[]>([]);
  const [isAddToWorkSpaceDisable, setIsAddToWorkSpaceDisable] = useState<boolean>(true);
  const [isSaveCopyDisable, setIsSaveCopyDisable] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [key, setKey] = useState(0); // Unique key to force re-render

  const onSelectWatchlist = ({watchListId, watchlistName, productsCount}: { watchListId: number, watchlistName: string, productsCount: number}) => {
    setSelectedWatchlists({watchListId, watchlistName, productsCount});
    setIsAddToWorkSpaceDisable(false);
  }

  const updateSelectedContracts = (contractCodes: IContract[]) => {
    setSelectedContracts([...contractCodes]);
    setIsSaveCopyDisable(contractCodes.length === 0);
  }

  const updateSelectedProduct = (productCode: IContract, selected: boolean) => {
    let updatedContracts;
    if (selected) {
      updatedContracts = [...selectedContracts, productCode];
    } else {
      updatedContracts = selectedContracts.filter(contract => contract.product.contractCode !== productCode.product.contractCode);
    }
    setSelectedContracts(updatedContracts);
    setIsSaveCopyDisable(updatedContracts.length === 0);
    const allProductsOfSameWatchlist = updatedContracts.every((contract) => contract.watchlistName === updatedContracts[0]?.watchlistName);
    if (allProductsOfSameWatchlist && selectedWatchlists?.productsCount === updatedContracts.length) {
      setIsAddToWorkSpaceDisable(false);
    } else {
      setIsAddToWorkSpaceDisable(true);
    }
  }

  const clearSelection = () => {
    setSelectedWatchlists(null);
    setKey(key + 1);
    setSelectedContracts([]);
    setIsAddToWorkSpaceDisable(true);
    setIsSaveCopyDisable(true);
  };
  
  const saveACopy = () => {
    onWatchlistNameChange(selectedWatchlists?.watchlistName ?? '');
    onCreateNewWatchlistClick(WatchlistType.User);
    setSelectedTreeItems(selectedContracts.map(contracts => contracts.product.contractCode));
    dispatch(featuredWatchlistActions.saveSelectedProducts(selectedContracts.map(contracts => contracts.product)));
  }

  const handleAddToWorkspace = () => {
    setIsAddToWorkSpaceDisable(true);
    const widget: Widget = {
      title: WidgetType.Watchlist,
      widgetName: selectedWatchlists?.watchlistName ?? '',
      contractIds: selectedContracts.map(contracts => contracts.product.contractCode),
      widgetId: selectedWatchlists?.watchListId ?? 0,
      widgetType: WidgetType.Watchlist,
      watchlistType: WatchlistType.Featured,
      key: 2,
    };
    addToWorkspace(widget);
    dispatch(featuredWatchlistActions.addFeaturedWatchlistInDockView({
      ...widget,
      title: selectedWatchlists?.watchlistName,
    }));
  }

  return (
    <FeaturedContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
        <Heading>Featured Watchlists
        <InfoTooltip
          title="Featured Watchlists are managed by CME Group. Add a Featured list to your workspace OR create a copy and customize to your preference."
          placement="top"
          arrow
        >
          <InfoOutlinedIconStyled />
        </InfoTooltip>
        </Heading>
        <ClearSelectionButton onClick={clearSelection}>Clear Selection</ClearSelectionButton>
      </Box>
      <FeaturedList
        keyValue={key}
        selectedWatchlist={selectedWatchlists}
        list={watchlist}
        onSelectWatchlist={onSelectWatchlist}
        selectedContracts={selectedContracts}
        updateSelectedContracts={updateSelectedContracts}
        updateSelectedProduct={updateSelectedProduct}
      />
      <Box sx={{ marginTop: '20px', }}>
        <ActionButton onClick={saveACopy} disabled={isSaveCopyDisable}>Save a copy</ActionButton>
        <ActionButton onClick={handleAddToWorkspace} disabled={isAddToWorkSpaceDisable}>Add to Workspace</ActionButton>
      </Box>
    </FeaturedContainer>
  )
};

export default FeaturedWatchlists;
