// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oco-mobile-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 6px 0;
}

.oco-mobile-heading--text {
  color: #25323c;
  font-family: Averta-Regular;
}

.oco-mobile-action {
  position: absolute;
  bottom: 20px;
  width: 95%;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.oco-mobile-close {
  height: 18px !important;
  width: 18px !important;
}

@media screen and (orientation: landscape) {
  .oco-mobile-action {
    position: relative;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/priceLadder/mobile/OrderSettingsDialogMobile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,QAAQ;AACV;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".oco-mobile-heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 4px 0 6px 0;\n}\n\n.oco-mobile-heading--text {\n  color: #25323c;\n  font-family: Averta-Regular;\n}\n\n.oco-mobile-action {\n  position: absolute;\n  bottom: 20px;\n  width: 95%;\n  display: flex;\n  justify-content: center;\n  gap: 8px;\n}\n\n.oco-mobile-close {\n  height: 18px !important;\n  width: 18px !important;\n}\n\n@media screen and (orientation: landscape) {\n  .oco-mobile-action {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
