import { makeStyles } from "@mui/styles";
import { Box, styled } from "@mui/material";

export const DiscoverContainer = styled(Box)`
  width: calc(100%);
  padding: 16px;
  background-color: white;
  height: 100%;
  padding-top: 5px;
`;

export const DiscoverHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BodyHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
`;

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    width: "691px",
    height: "168px",
  },
  imageSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "40px",
    width: "267px",
    height: "168px",
    position: "relative",
  },
  rectangle: {
    position: "absolute",
    width: "267px",
    height: "168px",
    left: "0px",
    top: "0px",
    backgroundColor: "#EBF9FF",
  },
  playButton: {
    position: "absolute",
    width: "24px",
    height: "24px",
    left: "calc(50% - 12px)",
    top: "calc(50% - 12px)",
  },
  vector: {
    position: "absolute",
    left: "27.08%",
    right: "27.08%",
    top: "20.83%",
    bottom: "20.83%",
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    width: "408px",
    // height: "113px",
  },
  title: {
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "25px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
    height: "25px",
  },
  description: {
    fontFamily: "Averta-Regular",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "20px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
    height: "80px",
  },

  publicChallengesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    height: "230px",
    padding: "0px",
  },

  publicChallengesText: {
    fontFamily: "Averta-Regular",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "30px",
    color: "#25323C",
    display: "flex",
    alignItems: "center",
    height: "30px",
  },
});