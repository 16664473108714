import { Product } from "../../cme-watchlist-api";
import { Instrument } from "../models/Instrument";
import { PriceUtil } from "./PriceUtil";

export class DisplayUtil {
  static toDisplayTime = (utcTime: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(utcTime);
  };

  static toDisplayDate = (utcTime: Date) => {
    return "NOT IMPLEMENTED"; //Globalize.format(utcTime, env.localDateFormat);
  };

  static toDisplayUtcDate = (utcTime: Date) => {
    var time = new Date(utcTime);
    time.setMinutes(time.getMinutes() + time.getTimezoneOffset());
    var result = this.toDisplayDate(time);
    return result;
  };

  static toDisplayPrice = (correctPrice: number, instrument: Instrument) : number  | null => {
    var displayScale = instrument && instrument.displayPriceScale;
    var tickSize = instrument && instrument.tickSize;
    return PriceUtil.rawDecimalsToPrice(correctPrice, displayScale as number, tickSize as number);
  };

  static fromDisplayPrice = (displayPrice:number, instrument: Instrument) => {
    var displayScale = instrument && instrument.displayPriceScale;
    var tickSize = instrument && instrument.tickSize;
    return PriceUtil.priceToRawDecimals(displayPrice, displayScale as number, tickSize as number);
  };

  static stepPrice = (displayPrice: number, instrument: Instrument, step: number) => {
    var displayScale = instrument && instrument.displayPriceScale;
    var tickSize = instrument && instrument.tickSize;
    return PriceUtil.stepPrice(displayPrice, displayScale as number, tickSize as number, step);
  };

  static rawStepPrice = (rawPrice:number, instrument: Instrument, step: number) => {
    var displayScale = instrument && instrument.displayPriceScale;
    var tickSize = instrument && instrument.rawTickSize;
    return PriceUtil.rawStepPrice(rawPrice, displayScale as number, tickSize as number, step);
  };

  static priceToTicks = (displayPrice: number, instrument: Instrument, isRawPrice: boolean) => {
    let tickSize: number;

    if (isRawPrice === true) {
      tickSize = instrument && instrument.rawTickSize as number;
    } else {
      tickSize = instrument && instrument.tickSize as number;
    }
    return PriceUtil.convertPriceToTicks(displayPrice, instrument.getTicksPerDecimalPoint(), tickSize);
  };
  static toDisplayPrices = (correctPrice: number, instrument: Product) : number  | null => {
    var displayScale = instrument && instrument.displayPriceScale;
    var tickSize = instrument && instrument.tickSize;
    return PriceUtil.rawDecimalsToPrice(correctPrice, displayScale as number, tickSize as number);
  };
}
