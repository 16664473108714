// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-component-dock-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.tab-component-dock--title {
  font-family: Averta-Regular;
  font-weight: 600;
}

.tab-component-close-action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 0 8px;
  > svg {
    height: 7.8px;
    width: 7.8px;
  }
}

.tab-component-close-action:hover {
  border-radius: 2px;
  background-color: var(--dv-icon-hover-background-color);
}

.tab-component-star-icon {
  margin: 0 8.01px 0 12.01px;
  height: 12px !important;
  width: 12px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/dockview/tab-components/tabComponents.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,aAAa;EACb;IACE,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE,kBAAkB;EAClB,uDAAuD;AACzD;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".tab-component-dock-container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n\n.tab-component-dock--title {\n  font-family: Averta-Regular;\n  font-weight: 600;\n}\n\n.tab-component-close-action {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 16px;\n  width: 16px;\n  margin: 0 8px;\n  > svg {\n    height: 7.8px;\n    width: 7.8px;\n  }\n}\n\n.tab-component-close-action:hover {\n  border-radius: 2px;\n  background-color: var(--dv-icon-hover-background-color);\n}\n\n.tab-component-star-icon {\n  margin: 0 8.01px 0 12.01px;\n  height: 12px !important;\n  width: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
