import { IconButton, Tooltip } from "@mui/material";
import { Workspace } from "../../../../types";
import DotsIcon from "../../../common/icons/dots-vertical";
import { StarDefaultWSIcon } from "../../../../images/Icons";

const truncateText = (text: string, length: number) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

const WorkspaceTab: React.FC<{
  workspace: Workspace;
  active: boolean;
  onClick: () => void;
  onMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ workspace, active, onClick, onMenuOpen }) => {
  const title = workspace.title || String(workspace.id || "");
  const truncatedTitle = truncateText(title, 15);

  return (
    <>
      <div onClick={onClick} className={`Tab ${active ? "active" : ""}`}>
        <button  className="workspace-button">
          {workspace.isDefault && <StarDefaultWSIcon />}
          <Tooltip title={title} arrow>
            <span className={`workspace-title ${active ? "active-title" : ""}`}>{truncatedTitle}</span>
          </Tooltip>
        </button>
        <IconButton onClick={onMenuOpen} sx={{padding: "5px", "&:focus-visible": { color: "rgb(0 0 0 / 0%)" } }}>
          <DotsIcon />
        </IconButton>
      </div>
    </>
  );
};

export default WorkspaceTab;
