import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProductsList } from './Product';
import { useState } from 'react';
import { ListItemProps } from './types';
import InfoTooltip from "../home/components/SideMenu/menu-item-drawer/InfoTooltip";

import { Button, Container, InstrumentName, InstrumentProducts, InstrumentSelector, Wrapper } from './styles';
import Radio from '@mui/material/Radio';
import { FormControlLabel } from "@mui/material";
import { useSelector } from 'react-redux';
import { getWatchlistFromDockView } from '../../redux/featuredWatchlist/featuredWatchlistSelectors';

const ListItem = ({
  selectedWatchlist,
  updateSelectedContracts,
  updateSelectedProduct,
  selectedContracts,
  watchlist,
}: ListItemProps) => {
  const watchlistsFromDockView = useSelector(getWatchlistFromDockView);
  const renderComponent = (disabled?: boolean) => (
    <Component
    watchlist={watchlist}
    updateSelectedContracts={updateSelectedContracts}
    updateSelectedProduct={updateSelectedProduct}
    selectedContracts={selectedContracts}
    selectedWatchlist={selectedWatchlist}
    isDisable={disabled}
  />
  )
  const isDisabled = watchlistsFromDockView.includes(watchlist.name);
  if (isDisabled) {
    return (
      <InfoTooltip
        title="Watchlist already on workspace"
        placement="top"
        arrow
      >
      <Container disabled>
        {renderComponent(true)}
        </Container>
      </InfoTooltip>
    )
  }
  return (
    <Container>
      {renderComponent()}
    </Container>
  )
};

const Component = ({ 
  selectedWatchlist,
  updateSelectedContracts,
  updateSelectedProduct,
  selectedContracts,
  watchlist,
  isDisable,
}: ListItemProps) => {
  const updateProducts = () => {
    const contractCodes = watchlist.products?.map(product => ({
      product,
      watchlistName: watchlist.name,
    })) ?? [];
    updateSelectedContracts(contractCodes);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleProducts = () => {
    if (!isDisable) {
      setIsOpen(!isOpen);
    }
  };
  const watchlistProducts = watchlist.products;
  return (
    <>
        <InstrumentSelector>
          <FormControlLabel
            disabled={isDisable}
            value={watchlist.watchlistId}
            control={<Radio size='small' name="radio-buttons" onChange={updateProducts} />}
            label=""
          />
          <InstrumentName>{watchlist.name}</InstrumentName>
      </InstrumentSelector> 
      <Wrapper>
          <InstrumentProducts>{`${watchlist.products?.length ?? 0} Products`}</InstrumentProducts>
          <Button onClick={toggleProducts}>{isOpen ? <KeyboardArrowUpIcon sx={{ color: '#081D378F' }} /> : <KeyboardArrowDownIcon sx={{ color: '#081D378F' }} />}</Button>
      </Wrapper>
      {isOpen && <ProductsList
        products={watchlistProducts}
        selected={selectedWatchlist?.watchListId === watchlist.watchlistId}
        updateSelected={updateSelectedProduct}
        selectedContracts={selectedContracts}
        instrumentName={watchlist.name}
        />}
    </>
    
  )
};
export default ListItem;
