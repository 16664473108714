import { useContext, useEffect, useMemo, useState } from "react";
import { CMEWatchlistAPI, FeaturedWatchlist, UserWatchlist, UserWatchlistService } from "../../../cme-watchlist-api";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import featuredWatchlistActions from "../../../redux/featuredWatchlist/featuredWatchlistActions";
import { getFeaturedWatchlist, getWatchlistLocal } from "../../../redux/featuredWatchlist/featuredWatchlistSelectors";
import { AvailableProductsContext } from "../../../AvailableProductsProvider";
import { useMarketData } from "../../../MarketDataProvider";
import { ContractWithProduct } from "../../featuredWatchlist/types";
import { Instrument } from "../../../cqg-api/models/Instrument";
import { MapTreeData } from "../../../utils/utils";
import { subscribeToContracts, unsubscribeContractIds } from "../../../utils/subscriptions";
import { Product } from "../../../cme-watchlist-api/models/common";
import { getFromLS, saveToLS } from "../../../storage";
import { MY_WATCHLIST, WatchlistType } from "../../../types";

export const useMobileWatchlist = () => {
    const [loadingMyWatchlist, setLoadingMyWatchlist] = useState(false);
    const myWatchlists = useSelector(getWatchlistLocal);
    const featuredWatchlistLocal = useSelector(getFeaturedWatchlist);
    const myWatchlist = getFromLS(MY_WATCHLIST);
    const localWatchlist  = useMemo(() => {
      const isFeaturedWatchlist = myWatchlist?.watchlistType === WatchlistType.Featured;
    
      return isFeaturedWatchlist
        ? featuredWatchlistLocal?.find((watchlist: FeaturedWatchlist) => watchlist?.watchlistId === myWatchlist?.watchlistId)
        : myWatchlists.find((watchlist: UserWatchlist) => watchlist?.watchlistId === myWatchlist?.watchlistId);
    }, [featuredWatchlistLocal, myWatchlist?.watchlistId, myWatchlist?.watchlistType, myWatchlists]);
    
    const [selectedWatchlist, setSelectedWatchlist] = useState<UserWatchlist | FeaturedWatchlist>(localWatchlist);
    const [isSelectedIsFeatured, setIsSelectedIsFeatured] = useState(false);
    const [watchlistSelector, setWatchlistSelector] = useState(false);
    const dispatch = useDispatch();
    const { monthlyContractsByDisplayNameMap } = useContext(AvailableProductsContext);
    const { getUserWatchlists, getUserWatchlistById } = UserWatchlistService;
    const { realTimeMarketData } = useMarketData();
    const [contractDataList, setContractDataList] = useState<ContractWithProduct[]>([]);
    const [watchlistData, setWatchlistData] = useState<Instrument[]>([]);

    // This useEffect get user watchlist on first load and store it to Redux.
   // Fetch user watchlist on first load and store it in Redux
useEffect(() => {
  const getUserDefinedWatchlists = async () => {
    try {
      setLoadingMyWatchlist(true);
      let featuredWatchlists: FeaturedWatchlist[] = [];
      if (!myWatchlist) {
        // Fetch and store featured watchlist if no watchlist is set
        featuredWatchlists = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlists();
        const defaultFeaturedWatchlist = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(
          featuredWatchlists[0].watchlistId
        );

        saveToLS(MY_WATCHLIST, {
          watchlistType: WatchlistType.Featured,
          watchlistId: featuredWatchlists[0].watchlistId,
        });

        setSelectedWatchlist(defaultFeaturedWatchlist);
        // Fetch all user watchlists and update Redux store
        const userWatchlists = await getUserWatchlists();
        dispatch(featuredWatchlistActions.dispatchSetWatchlist(userWatchlists));
      } else {
        let watchlist:FeaturedWatchlist | UserWatchlist;

        try {
          watchlist = myWatchlist.watchlistType === WatchlistType.Featured
            ? await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(myWatchlist.watchlistId)
            : await getUserWatchlistById(myWatchlist.watchlistId);
            setSelectedWatchlist(watchlist);
        } catch (error) {
          console.error("Failed to fetch user watchlist, falling back to featured watchlist:", error);
          
          try {
            const featuredWatchlists = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlists();
            if (featuredWatchlists.length > 0) {
              watchlist = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(
                featuredWatchlists[0].watchlistId
              );
              setSelectedWatchlist(watchlist);
            } else {
              throw new Error("No featured watchlists available");
            }
          } catch (fallbackError) {
            console.error("Failed to fetch featured watchlist:", fallbackError);; // Or handle this case as needed
          }
        }
        
        // Fetch all user watchlists and update Redux store
        const userWatchlists = await getUserWatchlists();
        const userWatchlistsWithSelectedProducts = myWatchlist.watchlistType === WatchlistType.User ?
          userWatchlists.map((userWatchlist) => userWatchlist.watchlistId ===watchlist.watchlistId ? watchlist : userWatchlist) 
          : userWatchlists
        dispatch(featuredWatchlistActions.dispatchSetWatchlist(userWatchlistsWithSelectedProducts));
      }

    } catch (error) {
      console.error("Error fetching watchlists:", error);
    } finally {
      setLoadingMyWatchlist(false);
    }
  };

  // Only fetch if the watchlists are not already loaded in Redux
  if (myWatchlists.length === 0) {
    getUserDefinedWatchlists();
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, getUserWatchlistById, getUserWatchlists, myWatchlists.length]);


    // This useEffect get featured watchlists
    useEffect(() => {
        const getFeaturedWatchlist = async () => {
            // API to get featured Watchlist
            const getFeaturedWatchlistApi = async () => {
                const data = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlists();
                // API calls to get products for watchlist
                const updatedData = await Promise.all(
                data.map(async (featuredWatchlist) => {
                    const watchlistData = await CMEWatchlistAPI.FeaturedWatchlist.getFeaturedWatchlistById(
                    featuredWatchlist.watchlistId,
                    );
                    featuredWatchlist.products = watchlistData.products;
                    return featuredWatchlist;
                }),
                );
                return updatedData;
            };
            const response = await getFeaturedWatchlistApi();
            dispatch(featuredWatchlistActions.dispatchSetFeaturedWatchlist(response));
        };
        // Only call API first time.
        if (featuredWatchlistLocal.length === 0) {
        getFeaturedWatchlist()
        };
    }, [dispatch, featuredWatchlistLocal]);

    // This useEffect listen to changes to selected watchlist and map the products
    useEffect(() => {
        if (!selectedWatchlist?.products?.length) return;
        const mappedContracts = selectedWatchlist?.products.reduce((acc: any[], product: Product): ContractWithProduct[] => {
        const contractDetails = product.contractCode && monthlyContractsByDisplayNameMap[product.contractCode];
        acc.push({
            ...contractDetails,
            ...product,
        });

        return acc;
        }, []);

        setContractDataList(mappedContracts);
    }, [monthlyContractsByDisplayNameMap, selectedWatchlist?.products]);
    
    // This useEffect subscribe to the products
    useEffect(() => {
        const contractIds = selectedWatchlist?.products
        ?.map((contract: any) => monthlyContractsByDisplayNameMap[contract.contractCode]?.contractId)
        .filter((contractId: any): contractId is number => contractId !== undefined && contractId !== null);
    
        if (contractIds) subscribeToContracts(contractIds);
    
        return () => {
          if (contractIds?.length) {
            unsubscribeContractIds(contractIds);
          }
        };
      }, [contractDataList, monthlyContractsByDisplayNameMap, selectedWatchlist?.products]);

      useEffect(() => {
        const hasMonthlyContracts = Object.keys(monthlyContractsByDisplayNameMap).length;
        if (!hasMonthlyContracts || !selectedWatchlist?.products) {
          setWatchlistData([]);
          return;
        }
        if (watchlistData?.length) {
          return;
        }
    
        const instrumentsWithId = selectedWatchlist?.products
          ?.map((product) => {
            const contract = monthlyContractsByDisplayNameMap[product.contractCode];
            if (contract) return { ...contract, id: contract?.contractId };
            const instrument = new Instrument(null);
            instrument.displayName = product.contractCode;
            instrument.cmeSymbolName = product.productName;
            instrument.cqgSymbol = null;
            return { ...instrument, id: uuidv4() };
          })
          .filter(Boolean);
        if (instrumentsWithId) setWatchlistData(instrumentsWithId);
        // knowingly removed watchlist.length dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [monthlyContractsByDisplayNameMap, selectedWatchlist?.products]);
    
      useEffect(() => {
        if (contractDataList && contractDataList.length) {
          setWatchlistData(MapTreeData(contractDataList, realTimeMarketData));
        }
      }, [realTimeMarketData, contractDataList]);
       
    
    const handleSelectWatchlist = async (watchlistId: number) => {
        setIsSelectedIsFeatured(false);
        setLoadingMyWatchlist(true);
        setWatchlistSelector(false);
        const watchlistItems = await getUserWatchlistById(watchlistId);
        setSelectedWatchlist(watchlistItems);
        dispatch(featuredWatchlistActions.dispatchAddProductsToWatchlist(watchlistItems)); 
        saveToLS(MY_WATCHLIST, {watchlistType: WatchlistType.User, watchlistId});
        setLoadingMyWatchlist(false);
    };

    const handleSelectFeaturedWatchlist = (watchlistId: number) => {
        setWatchlistSelector(false);
        const selected = featuredWatchlistLocal.find((watchlist: any) => watchlist.watchlistId === watchlistId);
        setSelectedWatchlist(selected);
        setIsSelectedIsFeatured(true);
        saveToLS(MY_WATCHLIST, {watchlistType: WatchlistType.Featured, watchlistId});
    };

    return {
        loadingMyWatchlist,
        selectedWatchlist,
        handleSelectWatchlist,
        isSelectedIsFeatured,
        watchlistSelector,
        handleSelectFeaturedWatchlist,
        watchlistData,
        setWatchlistSelector,
    }
};