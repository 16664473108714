import { useCallback, useState } from "react";
import { Watchlist } from "../../types";
import Notification from "../../components/shared/notification/Notification";
import axios, { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import watchlistActions from "../../redux/watchlist/watchlistActions";
import { useGetCurrentUser } from "./authentication";

export const useFetchWorkspaceWatchlist = () => {
  const [watchlist, setWatchlist] = useState<Watchlist | null>(null);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const loadWorkspaceWatchlist = useCallback(
    async (watchlistId: Number) => {
      try {
        const response = await axios.get<Watchlist>(`watchlists/${watchlistId}`);
        setWatchlist(response.data);
        dispatch(watchlistActions.dispatchSetWatchlist(response.data));
      } catch (error: any) {
        setError(error);
        Notification.error(`Could not load workspace watchlist, ${error?.message}`);
      } finally {
        setLoaded(true);
      }
    },
    [dispatch],
  );

  return { watchlist, error, loaded, loadWorkspaceWatchlist, setWatchlist };
};

export const useFetchWorkspaceWatchlists = () => {
  const [watchlists, setWatchlists] = useState<Watchlist[]>([]);
  const [error, setError] = useState<Error | AxiosError>();
  const [loaded, setLoaded] = useState(false);

  const loadWorkspaceWatchlists = useCallback(async (workspaceId: Number) => {
    try {
      const response = await axios.get<Watchlist[]>(`get_workspace_watchlists/${workspaceId}`);
      setWatchlists(response.data);
    } catch (error: any) {
      setError(error);
      Notification.error(`Could not load workspace watchlists, ${error?.message}`);
    } finally {
      setLoaded(true);
    }
  }, []);

  return { watchlists, error, loaded, loadWorkspaceWatchlists, setWatchlists };
};

export const useCreateWatchlist = () => {
  const [error, setError] = useState<Error>();
  const [createWatchlistdLoaded, setCreateWatchlistLoaded] = useState(true);
  const currentUser = useGetCurrentUser();

  const createWatchlistAsync = useCallback(
    async (
      associatedProducts: string[],
      onSuccess?: (response: Watchlist) => void,
      workspaceId?: Number,
      onFail?: () => void,
    ) => {
      try {
        setCreateWatchlistLoaded(false);
        const response = await axios.post(`watchlists`, {
          associatedProducts: associatedProducts,
          memberId: currentUser.id,
          workspaceId: workspaceId,
        });
        Notification.success(`Watchlist created successfully`);
        onSuccess?.(response.data as Watchlist);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not create watchlist, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setCreateWatchlistLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { createWatchlistAsync, error, createWatchlistdLoaded };
};

export const useUpdateWatchlist = () => {
  const [error, setError] = useState<Error>();
  const [updateWatchlistLoaded, setUpdateWatchlistLoaded] = useState(true);

  const updateWatchlistAsync = useCallback(
    async (
      watchlistId: Number,
      associatedProducts: string,
      onSuccess?: (response: Watchlist) => void,
      onFail?: () => void,
    ) => {
      try {
        const watchlistParams: any = {
          associatedProducts,
        };
        setUpdateWatchlistLoaded(false);
        const response: Watchlist = await axios.put(`${"watchlists"}/${watchlistId}`, watchlistParams);
        Notification.success("Watchlist updated successfully");
        onSuccess?.(response);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not update watchlist, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setUpdateWatchlistLoaded(true);
      }
    },
    [],
  );

  return { updateWatchlistAsync, error, updateWatchlistLoaded };
};

export const useDeleteWatchlist = () => {
  const [error, setError] = useState<Error>();
  const [deleteWatchlistdLoaded, setDeleteWatchlistLoaded] = useState(false);

  const deleteWatchlistAsync = useCallback(
    async (watchlistId: Number, onSuccess?: (response: Watchlist) => void, onFail?: () => void) => {
      try {
        setDeleteWatchlistLoaded(true);
        const response = await axios.post(`watchlists/soft_delete/${watchlistId}`);
        // Notification.success("Watchlist delete successfully");
        onSuccess?.(response.data as Watchlist);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not delete watchlist, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setDeleteWatchlistLoaded(false);
      }
    },
    [],
  );

  return { deleteWatchlistAsync, error, deleteWatchlistdLoaded };
};

export const useFetchMyWatchlists = () => {
  const [error, setError] = useState<Error>();
  const [myWatchlistdLoaded, setMyWatchlistLoaded] = useState<boolean>();
  const [myWatchlists, setMyWatchlists] = useState([]);
  const currentUser = useGetCurrentUser();

  const loadMyWatchlistsAsync = useCallback(
    async (onSuccess?: (response: Watchlist) => void, onFail?: () => void) => {
      if(!currentUser.id){
        console.error("UserID not found");
        return; 
      }
      try {
        setMyWatchlistLoaded(false);
        const response = await axios.get(`get_member_watchlists?member_id=${currentUser.id}`);
        setMyWatchlists(response.data);
        // Notification.success("Watchlist delete successfully");
        onSuccess?.(response.data as Watchlist);
      } catch (error: any) {
        console.error(error);
        Notification.error(`Could not fetch watchlists list, ${error?.message}`);
        setError(error);
        onFail?.();
      } finally {
        setMyWatchlistLoaded(true);
      }
    },
    [currentUser.id],
  );

  return { loadMyWatchlistsAsync, error, myWatchlistdLoaded, myWatchlists };
};
