import { Menu, MenuItem } from "@mui/material";
import { DeleteWSIcon, DuplicateWSIcon, RenameWSIcon, StarWSIcon } from "../../../../images/Icons";

const WorkspaceMenu: React.FC<{
    anchorEl: null | HTMLElement;
    open: boolean;
    onClose: () => void;
    onRenameClick: () => void;
    onDeleteClick: () => void;
    onChangeDefaultClick: () => void;
    onDuplicateClick: () => void;
}> = ({
    anchorEl,
    open,
    onClose,
    onRenameClick,
    onDeleteClick,
    onChangeDefaultClick,
    onDuplicateClick
}) => (
    <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ marginTop: "10px", padding: "5px" }}
    >
        <MenuItem onClick={onDuplicateClick}>
            <DuplicateWSIcon sx={{ width: 14, height: 14 }} />
            <span className="menuText">Duplicate</span>
        </MenuItem>
        <MenuItem onClick={onChangeDefaultClick}>
            <StarWSIcon sx={{ width: 14, height: 14 }} />
            <span className="menuText">Set as Default</span>
        </MenuItem>
        <MenuItem onClick={onRenameClick}>
            <RenameWSIcon sx={{ width: 14, height: 14 }} />
            <span className="menuText">Rename Workspace</span>
        </MenuItem>
        <hr className="divider-tab" />
        <MenuItem onClick={onDeleteClick}>
            <DeleteWSIcon sx={{ width: 14, height: 14 }} />
            <span className="menuText">Delete</span>
        </MenuItem>
    </Menu>
);

export default WorkspaceMenu;
