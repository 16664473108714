// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label1{
  width: 50%;
}
.label2-desktop {
  width: 30%;
  text-align: right;
  white-space: nowrap;
}
.label3-desktop {
  width: 20%;
  text-align: right;
}
.label2-mobile {
  width: 25%;
  text-align: right;
}
.label3-mobile {
  width: 25%;
  text-align: right;
}
.label1Value { 
  margin-left: 44px;
  width: calc(50% - 44px);
};
.label1Value1 {
  margin-left: 24px;
  width: calc(50% - 24px) !important;
}
.label1Value1Select {
  margin-left: 24px;
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/tree-view/TreeView.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,uBAAuB;AACzB,CAAA;AACA;EACE,iBAAiB;EACjB,kCAAkC;AACpC;AACA;EACE,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".label1{\n  width: 50%;\n}\n.label2-desktop {\n  width: 30%;\n  text-align: right;\n  white-space: nowrap;\n}\n.label3-desktop {\n  width: 20%;\n  text-align: right;\n}\n.label2-mobile {\n  width: 25%;\n  text-align: right;\n}\n.label3-mobile {\n  width: 25%;\n  text-align: right;\n}\n.label1Value { \n  margin-left: 44px;\n  width: calc(50% - 44px);\n};\n.label1Value1 {\n  margin-left: 24px;\n  width: calc(50% - 24px) !important;\n}\n.label1Value1Select {\n  margin-left: 24px;\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
