import { EmptyWSIcon } from "../../images/Icons";

const EmptyWorkspace = () => (
  <div className="emptyWorkspace">
    <EmptyWSIcon sx={{ width: 48, height: 46 }} />
    <h3>Your workspace is empty</h3>
    <p>Add a new widget from the menu on the left to get started!</p>
  </div>
);

export default EmptyWorkspace;
