import { useCollateralStatus, usePositionsList } from "../../../../../cqg-api/hooks/ServiceHooks";
import { CQGEnvironment } from "../../../../../cqg-api/services/CQGEnvironment";
import { formatCurremcy } from "../../../../../cqg-api/utils/lib";

const useFundsInfo = () => {
  const { account } = usePositionsList();
  const { collateralStatus } = useCollateralStatus();

  const purchasingPower = account?.purchasingPower || collateralStatus[0]?.purchasingPower || 0;

  const formattedPurchasingPower = formatCurremcy(purchasingPower);
  const formattedPositionMargin = formatCurremcy(account?.totalMargin || 0);
  const formattedProfitLoss = formatCurremcy(account?.oteMvoAndPl || 0);

  const availableFunds = account ? (account.purchasingPower - account.totalMargin) : (purchasingPower - (account?.totalMargin ?? 0));
  const formattedAvailableFunds = formatCurremcy(availableFunds);

  return {
    formattedPurchasingPower,
    formattedPositionMargin,
    formattedProfitLoss,
    formattedAvailableFunds,
  };
};

export default useFundsInfo;
