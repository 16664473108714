import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StrikePriceOptionsController } from "../../../cqg-api/controllers/StrikePriceOptionsController";
import { useOptionsData, useRealTimeMarketData } from "../../../cqg-api/hooks/ServiceHooks";
import useColumns from "./hooks/useColumns";
import { dataGridStyles } from "./optionsStyles";
import { styled as muiStyled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { MapInstrumentOptionsData } from "../../../utils/utils";
import MobileOptionsTable from "./mobile/MobileOptionsTable";

const StyledGridOverlay = muiStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay({ message, loadError }: { message: string; loadError?: boolean }) {
  return (
    <StyledGridOverlay>
      <Box style={{ color: loadError ? "red" : "" }}>{message}</Box>
    </StyledGridOverlay>
  );
}

interface TableProps {
  selectedInstrument: any;
  workspaceId: number;
  isMobile?: boolean;
}

const Table = ({ selectedInstrument, workspaceId, isMobile }: TableProps) => {
  const optionTableContainer = useRef<any>(null);
  const [controller, setController] = useState<StrikePriceOptionsController | null>(null);
  const [gridRows, setGridRows] = useState<any[]>([]);
  const [optionMaturityId, setOptionMaturityId] = useState<string>("");

  useEffect(() => {
    setGridRows([]);
    const newController = new StrikePriceOptionsController({
      addOrUpdate: (contractMetadata) => {},
      instruments: selectedInstrument as any,
    });

    setController(newController);
  }, [selectedInstrument]);

  const { realTimeMarketData } = useRealTimeMarketData();

  useEffect(() => {
    if (controller && selectedInstrument) {
      controller.onShowOptions({ show: true, instrument: selectedInstrument });
    }
  }, [controller, selectedInstrument]);

  useEffect(() => {
    if (controller?.strikeOptions) {
      const targetStrikeIndex = controller.strikeOptions.findIndex((option) => option.strike === controller.atmStrike);
      const maxStrikesVisible = controller?.maxStrikesScrollbar ?? 0;

      const visibleStrikeOptions = controller.strikeOptions.slice(
        targetStrikeIndex - maxStrikesVisible,
        targetStrikeIndex + maxStrikesVisible + 1,
      );

      setOptionMaturityId(visibleStrikeOptions[0]?.putOption?.contractMetadata?.optionMaturityId ?? "");

      const gridRows = visibleStrikeOptions.map((strikeOption) => ({
        ...strikeOption,
        callsLow: strikeOption.callOption.labelLow,
        callsHigh: strikeOption.callOption.labelHigh,
        callsPrior: strikeOption.callOption.labelSettlement,
        callsChange: strikeOption.callOption.labelPriceNetChange,
        callsLast: strikeOption.callOption.labelPrice,
        callsQty: strikeOption.callOption.labelTotalVolume,
        callsBid: strikeOption.callOption.labelBid,
        callsOffer: strikeOption.callOption.labelAsk,
        callsTheo: null,
        callsVol: null,
        callsTheta: null,
        callsVega: null,
        callsGamma: null,
        callsDelta: null,
        callsImpVol: null,

        strikePrice: strikeOption.strike,

        putsLow: strikeOption.putOption.labelLow,
        putsHigh: strikeOption.putOption.labelHigh,
        putsPrior: strikeOption.putOption.labelSettlement,
        putsChange: strikeOption.putOption.labelPriceNetChange,
        putsLast: strikeOption.putOption.labelPrice,
        putsQty: strikeOption.putOption.labelTotalVolume,
        putsBid: strikeOption.putOption.labelBid,
        putsOffer: strikeOption.putOption.labelAsk,
        putsTheo: null,
        putsVol: null,
        putsTheta: null,
        putsVega: null,
        putsGamma: null,
        putsDelta: null,
        putsImpVol: null,

        id: strikeOption.callOption.contractId,
      }));

      setGridRows(gridRows);
    }
  }, [controller?.atmStrike, controller?.maxStrikesScrollbar, controller?.strikeOptions]);

  const { columnGroupingModel, columns } = useColumns(selectedInstrument, workspaceId, controller);

  const noDataMessage = useMemo(
    () => (controller?.loadError ? "This product does not have an options contract" : "No Rows"),
    [controller?.loadError],
  );

  const { realTimeOptionsData } = useOptionsData(optionMaturityId);

  useEffect(() => {
    if (gridRows?.length) {
      setGridRows(MapInstrumentOptionsData(gridRows, realTimeMarketData, realTimeOptionsData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeMarketData, realTimeOptionsData]);

  const handleCenterScrollPosition = useCallback(() => {
    if (optionTableContainer.current) {
      const centerPosition = optionTableContainer.current.scrollWidth - optionTableContainer.current.clientWidth;
      optionTableContainer.current.scrollLeft += centerPosition / 2;
    }
  }, []);

  useEffect(() => {
    const tableContainer = optionTableContainer.current;
    const observer = new ResizeObserver(() => {
      tableContainer.scrollLeft = 0;
      handleCenterScrollPosition();
    });

    if (isMobile) {
      handleCenterScrollPosition();
      observer.observe(tableContainer);
    }

    return () => {
      if (tableContainer && isMobile) {
        tableContainer.scrollLeft = 0;
        observer.disconnect();
      }
    };
  }, [selectedInstrument, isMobile, handleCenterScrollPosition]);
  const typedColumns = columns as GridColDef<any>[];

  return (
    <div className="options-table-container" ref={isMobile ? optionTableContainer : null}>
      {isMobile ? (
        <MobileOptionsTable rows={gridRows} controller={controller} selectedInstrument={selectedInstrument} />
      ) : (
        <Box sx={{ position: "absolute", top: "75px", bottom: 0, width: "100%" }}>
          <DataGridPro
            rows={gridRows}
            columns={typedColumns}
            disableRowSelectionOnClick
            columnGroupingModel={columnGroupingModel}
            sx={dataGridStyles}
            rowHeight={30}
            disableColumnSorting
            slots={{
              noRowsOverlay: () => <CustomNoRowsOverlay message={noDataMessage} loadError={controller?.loadError} />,
              noResultsOverlay: () => <CustomNoRowsOverlay message={noDataMessage} loadError={controller?.loadError} />,
            }}
            columnGroupHeaderHeight={20}
            columnHeaderHeight={20}
          />
        </Box>
      )}
    </div>
  );
};

export default Table;
