import { Button, Radio } from "@mui/material";
import { useState } from "react";
import clsx from "clsx";
import { WatchlistType, Widget, WidgetType } from "../../../../../types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoTooltip from "./InfoTooltip";
import { buttonStyles, confirmButtonStyle, radioButtonStyles } from "./menuItemDrawerStyles";
import { MessageApiResponse, UserWatchlist } from "../../../../../cme-watchlist-api";
import { ArrowIcon, CopyIcon, WatchlistDeleteIcon } from "../../../../../images/Icons";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Notification from "../../../../shared/notification/Notification";
import featuredWatchlistActions from "../../../../../redux/featuredWatchlist/featuredWatchlistActions";
import { useDispatch } from "react-redux";

const MyWatchlists = ({
  watchlists,
  deleteUserWatchlist,
  onCreateNewWatchlistClick,
  onAddToWorkspaceClick,
  onCopyWatchlistClick,
  deleteWatchlistdLoaded,
  onEditWatchlistClick,
  loadingMyWatchlist,
  loadingEditMyWatchlist,
}: {
  watchlists: UserWatchlist[];
  deleteUserWatchlist: (watchlistId: number) => Promise<MessageApiResponse>;
  onCreateNewWatchlistClick: (wType: WatchlistType) => void;
  onAddToWorkspaceClick: (widget: Widget) => void;
  onCopyWatchlistClick: (watchlist: UserWatchlist) => void;
  deleteWatchlistdLoaded: boolean;
  onEditWatchlistClick: (watchlist: any) => void;
  loadingMyWatchlist: boolean;
  loadingEditMyWatchlist: boolean;
}) => {
  const [deleteDictionary, setDeleteDictionary] = useState<any>({});
  const [deletedDictionary, setDeletedDictionary] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteWatchlistClick = async (item: any) => {
    try {
      setLoading(true);
      await deleteUserWatchlist(item.watchlistId);
      setDeletedDictionary((prev: any) => ({ ...prev, [item.watchlistId]: item }));
      setTimeout(() => {
        dispatch(featuredWatchlistActions.dispatchDeleteProductsFromWatchlist(item.watchlistId));
      }, 3000);
      setSelectWatchlist(undefined);
    } catch (e) {
      Notification.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const hanldeOnAddToWorkspaceClick = async (watchlist: UserWatchlist) => {
    setLoading(true);
    const widget: Widget = {
      title: WidgetType.Watchlist,
      widgetName: watchlist.name,
      widgetId: watchlist.watchlistId,
      widgetType: WidgetType.Watchlist,
      watchlistType: WatchlistType.User,
      key: 2,
    };
    await onAddToWorkspaceClick(widget);
    setLoading(false);
  };

  const toggleDeleteConfirmation = (watchlistId: any) => {
    setDeleteDictionary((prev: any) => {
      const { [watchlistId]: _, ...newState } = prev;
      return newState;
    });
  };

  const handleCopyWatchlistClick = async (watchlist: UserWatchlist) => {
    setLoading(true);
    await onCopyWatchlistClick(watchlist);
    setLoading(false);
  };

  const renderDeleteConfirmation = (watchlist: any) => (
    <div
      className={clsx("watchlist-list-item", {
        "watchlist-list-item--delete": deletedDictionary[watchlist.watchlistId],
        "watchlist-list-item--deleted": !deletedDictionary[watchlist.watchlistId],
      })}
    >
      <div className="watchlist-list-item-delete-description">
        {deletedDictionary[watchlist.watchlistId]
          ? "Watchlist has been Deleted"
          : "Do you wish to permanently delete this watchlist?"}
      </div>
      <div className="watchlist-list-item-delete-right">
        {deletedDictionary[watchlist.watchlistId] ? (
          <span
            className="watchlist-list-item-cross"
            onClick={() => {
              toggleDeleteConfirmation(watchlist.watchlistId);
              dispatch(
                featuredWatchlistActions.dispatchSetWatchlist(
                  watchlists.filter((watch) => watch.watchlistId !== watchlist.watchlistId),
                ),
              );
            }}
          >
            x
          </span>
        ) : (
          <>
            <Button
              disabled={deleteWatchlistdLoaded || loading}
              sx={confirmButtonStyle}
              variant="contained"
              onClick={() => handleDeleteWatchlistClick(watchlist)}
            >
              Yes
            </Button>
            <Button
              sx={confirmButtonStyle}
              variant="outlined"
              onClick={() => toggleDeleteConfirmation(watchlist.watchlistId)}
            >
              No
            </Button>
          </>
        )}
      </div>
    </div>
  );

  const [selectWatchlist, setSelectWatchlist] = useState<any>();

  const renderWatchlistItem = (watchlist: UserWatchlist) => (
    <div className="watchlist-list-item">
      <div className="watchlist-radio-button-container" onClick={() => setSelectWatchlist(watchlist)}>
        <Radio
          checked={selectWatchlist?.watchlistId === watchlist?.watchlistId}
          size="small"
          name="radio-buttons"
          sx={radioButtonStyles}
        />
        {watchlist.name ?? watchlist.watchlistId}
      </div>
      <div className="watchlist-list-item-icons">
        <InfoTooltip title="Save a Copy" placement="top" arrow>
          <div className="watchlist-icon--tooltip">
            <CopyIcon
              style={{ color: "#006EB6" }}
              onClick={() => !loading && handleCopyWatchlistClick(watchlist)}
              className="watchlist-list-item-icons--icon"
            />
          </div>
        </InfoTooltip>
        <InfoTooltip title="Delete Watchlist" placement="top" arrow>
          <div className="watchlist-icon--tooltip">
            <WatchlistDeleteIcon
              className="watchlist-list-item-icons--icon"
              onClick={() => setDeleteDictionary({ ...deleteDictionary, [watchlist.watchlistId]: watchlist })}
            />
          </div>
        </InfoTooltip>
        <InfoTooltip title="Add to Workspace" placement="top" arrow>
          <div className="watchlist-icon--tooltip">
            <ArrowIcon
              className="watchlist-list-item-icons--icon"
              style={{ color: "#006EB6" }}
              onClick={() => !loading && hanldeOnAddToWorkspaceClick(watchlist)}
            />
          </div>
        </InfoTooltip>
      </div>
    </div>
  );

  return (
    <div>
      <div className="watchlist-list-heading">
        <span>Your Watchlists</span>
        <InfoTooltip
          title="Create and organize your personalized Watchlist to help you monitor your favorite products"
          placement="top"
          arrow
        >
          <InfoOutlinedIcon style={{ height: "16px", width: "16px" }} />
        </InfoTooltip>
      </div>
      {loadingMyWatchlist && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {!!watchlists.length &&
        watchlists?.map((watchlist: any) => (
          <div key={watchlist.watchlistId}>
            {deleteDictionary[watchlist.watchlistId]
              ? renderDeleteConfirmation(watchlist)
              : renderWatchlistItem(watchlist)}
          </div>
        ))}

      {!loadingMyWatchlist && watchlists.length === 0 && (
        <div className="no-watchlist-container">You currently have no Watchlists created</div>
      )}
      {watchlists.length > 0  && <Button
        disabled={!selectWatchlist || loadingEditMyWatchlist}
        onClick={() => onEditWatchlistClick(selectWatchlist)}
        variant="contained"
        sx={{ marginRight: "8px", ...buttonStyles }}
      >
        EDIT SELECTED
      </Button>}
      <Button sx={buttonStyles} onClick={() => onCreateNewWatchlistClick(WatchlistType.User)} variant="contained">
        CREATE NEW
      </Button>
      <hr style={{ margin: "24px 0", border: "1px solid #C5CED8" }} />
    </div>
  );
};

export default MyWatchlists;
