import React from "react";
import { Button } from "@mui/material";
import { buttonStyles } from "./style";

interface ButtonProps {
  text: string;
  onClick?: () => void;
}

const CustomButton: React.FC<ButtonProps> = ({ text, onClick }) => {
  return (
    <Button
      sx={{
        ...buttonStyles,
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
