import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const ErrorSuccess = ({
  hasWatchlistError,
  updateSucess,
  setHasWatchlistError,
  setUpdateSucess,
}: {
  hasWatchlistError: boolean;
  updateSucess: boolean;
  setHasWatchlistError: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateSucess: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      {hasWatchlistError && (
        <div className="duplicate-watchlist-name-error">
          <div>Already a Watchlist using this name. Please update</div>
          <CloseIcon
            onClick={() => setHasWatchlistError(false)}
            className="watchlist-list-item-icons--icon watchlist-close-icon"
          />
        </div>
      )}

      {updateSucess && (
        <div className="update-watchlist-name-error">
          <div className="watchlist-done-container">
            <DoneIcon className="watchlist-close-icon" />
            Your watchlist has been updated
          </div>
          <CloseIcon
            onClick={() => setUpdateSucess(false)}
            className="watchlist-list-item-icons--icon watchlist-close-icon"
          />
        </div>
      )}
    </>
  );
};

export default ErrorSuccess;
