import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import authActions from "../../../../redux/auth/authActions";
import clsx from "clsx";
import { AppGlobal } from "../../../../AppGlobal";

const Footer = () => {
  const dispatch = useDispatch();

  const handleLogoutClick = useCallback(() => {
    AppGlobal.removeCurrentUser();
    dispatch(authActions.doSignout());
  }, [dispatch]);

  return (
    <>
      <div>
        <Button
          className={clsx({
            "mobile-profile-footer": true,
            "mobile-profile-footer--accSettings": true,
          })}
        >
          <div className="mobile-profile-footer--accSettings__contents">
            <SettingsIcon className="mobile-add-icon" />
            <div className="mobile-footer-btn-label">Account Settings</div>
          </div>
          <ChevronRightIcon style={{ color: "#5a6874" }} />
        </Button>
      </div>
      <div>
        <Button className="mobile-profile-footer">
          <LiveHelpIcon className="mobile-add-icon" />
          <div className="mobile-footer-btn-label">HELP</div>
        </Button>
      </div>
      <Button className="mobile-profile-footer" onClick={handleLogoutClick}>
        <LogoutIcon className="mobile-add-icon" />
        <div className="mobile-footer-btn-label">LOG OUT</div>
      </Button>
    </>
  );
};

export default Footer;
