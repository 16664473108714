import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import useFundsInfo from "../../components/dashboard/hooks/useFundsInfo";

const labelStyle = {
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '10px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  color: 'rgba(8, 29, 55, 0.6)',
  display: 'flex',
  alignItems: 'center',
};

const valueStyle = {
  fontFamily: 'Averta-Regular',
  fontWeight: 600,
  fontSize: '11px',
  color: 'rgba(8, 29, 55, 0.87)',
  lineHeight: '1.6',
};
const WrapperGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  width: "100%",
  '&:last-child': {
    marginLeft: '10px',
  },
});
const FundsInfo = () => {
  const { formattedPurchasingPower, formattedPositionMargin, formattedProfitLoss, formattedAvailableFunds } =
    useFundsInfo();
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "12px",
        backgroundColor: "#006EB614",
        alignItems: 'center',
        margin: 0,
        width: "calc(100% + 0px)",
        '& .MuiGrid-item': {
          padding: 0,
        },
        rowGap: "6px",
        borderRadius: "6px"
      }}
    >
      {/* FUNDS */}
      <WrapperGrid>
        <Typography variant="body2" sx={labelStyle}>FUNDS</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedPurchasingPower}</Typography>
        <Typography variant="body2" sx={labelStyle}>NET P/L</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedProfitLoss}</Typography>
        <Typography variant="body2" sx={labelStyle}>MARGIN</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedPositionMargin}</Typography>
        <Typography variant="body2" sx={labelStyle}>AVAILABLE</Typography>
        <Typography variant="h6" sx={valueStyle}>{formattedAvailableFunds}</Typography>
      </WrapperGrid>
    </Grid>
  );
};

export default FundsInfo;
