import { useCallback } from "react";
import { OrderSide, OrderType, Widget, WidgetAction, WidgetType } from "../../../../types";
import workspacesActions from "../../../../redux/workspaces/workspacesActions";
import { setSelectedInstrument, setTradeTicketValue } from "../../../../redux/products/chartWidget";
import { useDispatch } from "react-redux";
import { OrderChain_Side } from "../../../../cqg-api/proto/traderouting_1";
import { orderTypeValue } from "../utils";
import { CQGConstants } from "../../../../cqg-api/constants/CQGConstants";

const useOpenTradeTicker = (workspaceId: number, oco: any, quantityValue: number, timeInForce: number) => {
  const dispatch = useDispatch();
  const openTradeTicket = useCallback(
    (
      params: any,
      orderType: OrderType,
      orderSide: OrderSide,
      price: number | null,
      action = WidgetAction.CreateOrder,
    ) => {
      let newOrder;

      if (action === WidgetAction.AmendOrder) {
        newOrder = params.clone();
        newOrder.limitPrice = price;
        newOrder.stopPrice = price;
      }

      const tradeTicketWidgetValue: Widget = {
        key: 3,
        title: WidgetType.TradeTicket,
        widgetType: WidgetType.TradeTicket,
        expired: false,
        widgetData: {
          action: action,
          data:
            action === WidgetAction.AmendOrder
              ? newOrder
              : {
                  ...params,
                  orderType: CQGConstants.getOrderTypeByString(orderTypeValue(orderType, orderSide, price, params)),
                  side: orderSide === OrderSide.Buy ? OrderChain_Side.BUY : OrderChain_Side.SELL,
                  size: quantityValue,
                  oco: oco,
                  limitPrice: price,
                  stopPrice: price,
                  timeInForce
                },
        },
      };
      dispatch(
        workspacesActions.dispatchSetWorkspace({
          workspaceId: workspaceId,
          instrument: action === WidgetAction.AmendOrder ? params?.instrument : params,
        }),
      );
      dispatch(setTradeTicketValue(tradeTicketWidgetValue));
      dispatch(
        setSelectedInstrument({
          instrument: params?.monthlyContracts,
          selected: params?.instrument,
        }),
      );
    },
    [dispatch, oco, quantityValue, timeInForce, workspaceId],
  );
  return { openTradeTicket };
};

export default useOpenTradeTicker;
