import { Button, Grid } from "@mui/material";
import clsx from "clsx";
import { Instrument } from "../../../../../cqg-api/models/Instrument";
import { useDispatch } from "react-redux";
import layoutActions from "../../../../../redux/layout/layoutActions";
import { SELECTED_MOBILE_WIDGET_INSTRUMENT, WidgetType } from "../../../../../types";
import { getFromLS, saveToLS } from "../../../../../storage";

type ModalFooterProps = {
  isInstrumentExpired: boolean;
  openTradeWindow: (Contract: Instrument) => void;
  selectedContract: Instrument;
};
const ModalFooter = ({ openTradeWindow, isInstrumentExpired, selectedContract }: ModalFooterProps) => {
  const dispatch = useDispatch();

  const goToScreen = (contract: Instrument, widgetType: WidgetType) => {
    const storedInstruments = getFromLS(SELECTED_MOBILE_WIDGET_INSTRUMENT);
    saveToLS(SELECTED_MOBILE_WIDGET_INSTRUMENT, { ...storedInstruments, [widgetType]: contract.displayName });
    dispatch(
      layoutActions.doUpdateAciveTab({
        title: widgetType,
        contractIds: [contract.displayName],
      }),
    );
  };

  return (
    <Grid item xs={12} sx={{ padding: "4px 16px" }}>
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            disabled={isInstrumentExpired}
            className={clsx({
              "chart-price-ladder-button-disabled": isInstrumentExpired,
              "chart-price-ladder-button": !isInstrumentExpired,
            })}
            onClick={() => goToScreen(selectedContract,WidgetType.Chart)}
          >
            CHART
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className={clsx({
              "chart-price-ladder-button-disabled": isInstrumentExpired,
              "chart-price-ladder-button": !isInstrumentExpired,
            })}
            onClick={() => goToScreen(selectedContract,WidgetType.PriceLadder)}
            disabled={isInstrumentExpired}
          >
            PRICE LADDER
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            className={clsx({
              "chart-price-ladder-button-disabled": isInstrumentExpired,
              "chart-price-ladder-button": !isInstrumentExpired,
            })}
            onClick={() => goToScreen(selectedContract,WidgetType.Options)}
            disabled={isInstrumentExpired}
          >
            OPTIONS
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={() => openTradeWindow(selectedContract)}
            variant="contained"
            disabled={isInstrumentExpired}
            className={clsx({
              "trade-button-disabled": isInstrumentExpired,
              "trade-button": !isInstrumentExpired,
            })}
          >
            TRADE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ModalFooter;
