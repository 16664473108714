import { SerializedDockview } from "dockview";
import { WatchlistType, WidgetType, Workspace } from "../types";

export const getEmptyWorkspace = (): Workspace => {
  const workspace: Workspace = {
    data: undefined,
    isDefault: true,
    title: "CME Group Workspace",
    description: "CME Group Workspace",
  };
  return workspace;
};

export const getUserCreatedFlagWorkspace = (): Workspace => {
  const workspace: Workspace = {
    title: "--FLAG-USER-CREATED-WORKSPACE--",
    description: "Represents user ever created any workspace",
  };
  return workspace;
};

export const getWorkspaceData = (newWorkspaceId: number): SerializedDockview => {
  const serializedDockview: SerializedDockview = {
    grid: {
      root: {
        type: "branch",
        data: [
          {
            type: "branch",
            data: [
              {
                type: "branch",
                data: [
                  {
                    type: "branch",
                    data: [
                      {
                        type: "leaf",
                        data: {
                          views: ["id17380624511492", "id17380643480519"],
                          activeView: "id17380624511492",
                          id: "1",
                          locked: true,
                        },
                        size: 221,
                      },
                      {
                        type: "leaf",
                        data: {
                          views: ["id17381542105728", "id17381542195499"],
                          activeView: "id17381542195499",
                          id: "3",
                          locked: true,
                        },
                        size: 226,
                      },
                    ],
                    size: 532,
                  },
                  {
                    type: "leaf",
                    data: {
                      views: ["id17380624829093"],
                      activeView: "id17380624829093",
                      id: "2",
                      locked: true,
                    },
                    size: 808,
                  },
                ],
                size: 455,
              },
              {
                type: "branch",
                data: [
                  {
                    type: "leaf",
                    data: {
                      views: ["id17380641518337"],
                      activeView: "id17380641518337",
                      id: "8",
                    },
                    size: 669,
                  },
                  {
                    type: "leaf",
                    data: {
                      views: ["id17380641726308"],
                      activeView: "id17380641726308",
                      id: "9",
                      locked: true,
                    },
                    size: 671,
                  },
                ],
                size: 133,
              },
            ],
            size: 1348,
          },
          {
            type: "leaf",
            data: {
              views: ["id17380640928716"],
              activeView: "id17380640928716",
              id: "10",
            },
            size: 317,
          },
        ],
        size: 596,
      },
      width: 1673,
      height: 596,
      orientation: "HORIZONTAL",
    },
    panels: {
      id17380624511492: {
        id: "id17380624511492",
        contentComponent: "default",
        params: {
          widget: {
            key: 1,
            title: "Markets",
            widgetType: "Markets",
          },
          workspaceId: newWorkspaceId,
        },
        title: "Markets",
        renderer: "always",
      },
      id17380643480519: {
        id: "id17380643480519",
        contentComponent: "default",
        params: {
          widget: {
            key: 2,
            title: "Watchlists",
            widgetType: "Watchlists",
            watchlistType: WatchlistType.Featured,
            widgetName: 'Featured Products',
            id: "6bfc1305-27ab-4a7c-b39a-20f72cc31722",
            widgetId: "0000",
          },
          workspaceId: newWorkspaceId,
        },
        title: "Featured Products",
        renderer: "always",
      },
      id17381542105728: {
        id: "id17381542105728",
        contentComponent: "default",
        params: {
          widget: {
            key: 7,
            title: WidgetType.Commentary,
            widgetType: WidgetType.Commentary,
          },
          workspaceId: newWorkspaceId,
        },
        title: WidgetType.Commentary,
        renderer: "always",
      },
      id17381542195499: {
        id: "id17381542195499",
        contentComponent: "default",
        params: {
          widget: {
            key: 9,
            title: "Calendar",
            widgetType: "Calendar",
          },
          workspaceId: newWorkspaceId,
        },
        title: "Calendar",
        renderer: "always",
      },
      id17380624829093: {
        id: "id17380624829093",
        contentComponent: "default",
        params: {
          widget: {
            key: 3,
            title: "Chart",
            widgetType: "ESH5 Chart",
            contractIds: ["ESH5"],
            id: "1cc3a413-2a74-4e66-8094-55992a94c4c6",
          },
          workspaceId: newWorkspaceId,
        },
        title: "ESH5 Chart",
        renderer: "always",
      },
      id17380641518337: {
        id: "id17380641518337",
        contentComponent: "default",
        params: {
          widget: {
            key: 5,
            title: "Positions",
            widgetType: "Positions",
          },
          workspaceId: newWorkspaceId,
        },
        title: "Positions",
        renderer: "always",
      },
      id17380641726308: {
        id: "id17380641726308",
        contentComponent: "default",
        params: {
          widget: {
            key: 6,
            title: "Orders",
            widgetType: "Orders",
          },
          workspaceId: newWorkspaceId,
        },
        title: "Orders",
        renderer: "always",
      },
      id17380640928716: {
        id: "id17380640928716",
        contentComponent: "default",
        params: {
          widget: {
            key: 4,
            title: "Price Ladder",
            widgetType: "Price Ladder",
            contractIds: ["ESH5"],
            id: "9f366ada-efa4-43be-b216-2e4335d1daef",
            widgetName: "E-mini S&P 500 - ES",
          },
          workspaceId: newWorkspaceId,
        },
        title: "E-mini S&P 500 - ES",
        renderer: "always",
      },
    },
    activeGroup: "9",
  };

  return serializedDockview;
}
