import React, { useCallback, useEffect, useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/SideMenu/SideMenu";
import Layout from "./components/Layout";
import useInitiateCQGEnvironment from "../../hooks/api/subscription";
import { useGetCurrentUser } from "../../hooks/api/authentication";
import { useWorkspaceManager } from "./components/dashboard/hooks/userWorkspaceManger";
import Dashboard from "./components/dashboard/Dashboard";
import { Mode_Type, USER_MODE, Widget } from "../../types";
import Notification from "../shared/notification/Notification";
import useAvailableProducts from "../widgets/availableProducts/useAvailableProducts";
import { AvailableProductsContext } from "../../AvailableProductsProvider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import mobile from "is-mobile";
import MobileTabs from "./mobile/MobileTabs";
import ProfileSection from "./mobile/profile/ProfileSection";
import { useConnectionCloseWatcher, useOrderUpdates } from "../../cqg-api/hooks/ServiceHooks";
import AppMessagesHost from "../shared/AppMessages";
import { getFromLS, saveToLS } from "../../storage";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";

// import useManageReduxEvents from "./useManageReduxEvents";

const Home = () => {
  useEffect(() => {
    if (!getFromLS(USER_MODE)) {
      saveToLS(USER_MODE,Mode_Type.PRACTICE_MODE);
    }
  }, []);
  const userMode = getFromLS(USER_MODE);
  const initialMode = userMode ? userMode : Mode_Type.PRACTICE_MODE;
  const [mode, setMode] = useState<Mode_Type>(initialMode);
  const currentUser = useGetCurrentUser();
  useInitiateCQGEnvironment(currentUser, mode);

  useConnectionCloseWatcher();

  const { availableProducts } = useAvailableProducts();
  useOrderUpdates();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm")) || mobile();

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };
  const [newWidgetToAddToPanel, setNewWidgetToAddToPanel] = useState<Widget | null>(null);
  const [selectedComponent, setSelectedComponent] = useState<JSX.Element | null>(null);
  const [isMobileProfileViewVisible, setIsMobileProfileViewVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = (event.target as HTMLInputElement).value;
    setIsLoading(true);
    setMode(newMode as Mode_Type);
    saveToLS(USER_MODE, newMode);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const {
    activeWorkspace,
    workSpaces,
    onDeleteWorkspace,
    onNewSpaceCreated,
    onActiveWorkSpaceChange,
    workspaceLoading,
    deleteWorkspaceLoading,
    onSetDefaultWorkspace,
    handleRenameWorkspace,
    updateWorkspaceLoaded,
    onWorkSpaceUpdated,
    onDuplicateWorkspace,
    workspaceTabsLimit,
    loadWorkspaceOnToolsSelection
  } = useWorkspaceManager(currentUser);

  const onAddNewWidgetToWorkSpaceRequestHandler = useCallback(
    (widget: Widget) => {
      if (workSpaces.length === 0) {
        Notification.error("You must create a workspace before adding a widget.");
      } else {
        setNewWidgetToAddToPanel(widget);
      }
    },
    [workSpaces],
  );

  // useManageReduxEvents(onAddNewWidgetToWorkSpaceRequestHandler);

  const handleNewWidgetProcessed = useCallback(() => {
    setNewWidgetToAddToPanel(null);
  }, []);

  const handleMenuItemClick = (component: JSX.Element | null) => {
    if (component){
      setIsMobileProfileViewVisible(false);
      setAccountInfoVisible(false);
      setSelectedComponent(component);
      return
    }
    if (component === null) {
      setSelectedComponent(null);
      return
    }
    if (isMobileView) {
      handleMobileMenu();
    }
  };
  const [accountInfoVisible, setAccountInfoVisible] = useState(false);

  const toggleLegalInfo = useCallback(() => {
    setAccountInfoVisible(prev => {
      if (isMobileView) {
        setIsMobileProfileViewVisible(false);
      }
      return !prev;
    });
  }, [isMobileView]);
  
  const handleMobileMenu = () => {
    setIsMobileProfileViewVisible(prev => {
      if (!prev) {
        setAccountInfoVisible(false);
      }
      return !prev;
    });
    if (selectedComponent) {
      setSelectedComponent(null);
    }
  };
  
  return (
    <>
      {isLoading ? (
        <LoadingScreen mode={mode} />
      ) : (
        <AvailableProductsContext.Provider value={availableProducts}>
          <Layout>
            <Header
              handleMobileMenu={handleMobileMenu}
              isMobileProfileViewVisible={isMobileProfileViewVisible}
              accountInfoVisible={accountInfoVisible}
              toggleLegalInfo={toggleLegalInfo}
              onMenuItemClick={handleMenuItemClick}
              handleModeChange={handleModeChange}
              mode={mode}
            />
            <main>
              {!isMobileView && (
                <>
                  <Sidebar
                    isOpen={isSidebarOpen}
                    onToggle={toggleSidebar}
                    onAddNewWidgetToWorkSpaceRequest={onAddNewWidgetToWorkSpaceRequestHandler}
                    activeWorkspace={activeWorkspace}
                    onMenuItemClick={handleMenuItemClick}
                    loadWorkspaceOnToolsSelection={loadWorkspaceOnToolsSelection}
                  />
                  {selectedComponent || (
                    <Dashboard
                      workspaceLoading={workspaceLoading}
                      onDeleteWorkspace={onDeleteWorkspace}
                      activeWorkspace={activeWorkspace}
                      workSpaces={workSpaces}
                      onNewWorkSpaceCreated={onNewSpaceCreated}
                      onActiveWorkSpaceChange={onActiveWorkSpaceChange}
                      deleteWorkspaceLoading={deleteWorkspaceLoading}
                      onSetDefaultWorkspace={onSetDefaultWorkspace}
                      handleRenameWorkspace={handleRenameWorkspace}
                      updateWorkspaceLoaded={updateWorkspaceLoaded}
                      onWorkSpaceUpdated={onWorkSpaceUpdated}
                      onDuplicateWorkspace={onDuplicateWorkspace}
                      onSidebarToggle={toggleSidebar}
                      isSidebarOpen={isSidebarOpen}
                      workspaceTabsLimit={workspaceTabsLimit}
                      newWidgetToAddToPanel={newWidgetToAddToPanel}
                      onNewWidgetProcessed={handleNewWidgetProcessed}
                    />
                  )}
                </>
              )}
              <AppMessagesHost />
              {(isMobileView && selectedComponent) || (!isMobileProfileViewVisible && isMobileView && <MobileTabs />)}
              {isMobileView && isMobileProfileViewVisible && (
                <ProfileSection
                  isMobileProfileViewVisible={isMobileProfileViewVisible}
                  onMenuItemClick={handleMenuItemClick}
                  handleModeChange={handleModeChange}
                  mode={mode}
                />
              )}
            </main>
          </Layout>
        </AvailableProductsContext.Provider>
      )}
    </>
  );
};

export default Home;
